<table mat-table class="bg-white w-full table overflow-auto" [dataSource]="objs()">
  <!-- User name Definition -->
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef i18n>name</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <a [routerLink]="row.id" class="anchor">
        {{row.name}}
      </a>
    </mat-cell>
  </ng-container>

  <!-- Age Definition -->
  <ng-container matColumnDef="paperFormat">
    <mat-header-cell *matHeaderCellDef i18n>paper format</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.paperFormat}}</mat-cell>
  </ng-container>

  <!-- Title Definition -->
  <ng-container matColumnDef="margins">
    <mat-header-cell *matHeaderCellDef i18n>margins ( cm )</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <div class="flex gap-1  flex-wrap">
        {{row.topMargin}} / {{row.rightMargin}} / {{row.bottomMargin}} / {{row.leftMargin}}
      </div>
    </mat-cell>
  </ng-container>

  <!-- Header and Row Declarations -->
  <mat-header-row *matHeaderRowDef="['name', 'paperFormat', 'margins']"></mat-header-row>
  <mat-row *matRowDef="let row; columns: ['name', 'paperFormat', 'margins']"></mat-row>
</table>