<form [formGroup]="form">
  <mat-form-field class="w-full">
    <mat-label i18n>subject</mat-label>
    <input type="text" matInput formControlName="description" />
  </mat-form-field>
  <rte-rte
    class="col-start-2 row-start-1 row-span-full col-span-2"
    formControlName="note"
  ></rte-rte>
</form>
