import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RouterNavigationHelperService {
  medicalRecordRoute(
    patientId: number | string | null | undefined,
    appointmentId?: string,
    feature?: string,
  ): any[] | undefined {
    return patientId
      ? [
          '/',
          localStorage.getItem('account-id'),
          'patients',
          patientId,
          'medical-record',
          {
            outlets: {
              center: [feature ?? 'summary'],
            },
          },
        ]
      : undefined;
  }

  get officeRoute(): any[] {
    return ['/', localStorage.getItem('account-id'), 'office'];
  }

  get schedulerRoute(): any[] {
    return this.officeRoute.map(route => route.concat(['scheduler']));
  }

  getPatientListRoute(): any[] {
    return ['/', localStorage.getItem('account-id'), 'office', 'patient'];
  }

  getPatientRoute = (
    patientId: number | string | null | undefined,
  ): any[] | undefined =>
    patientId
      ? ['/', localStorage.getItem('account-id'), 'patients', patientId]
      : undefined;
}
