import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { QuillViewComponent } from 'ngx-quill';
import { DocumentModel } from '../../../../shared/data-access/graphql/src';
import { BaseEntityDetailsComponent } from '../../../../shared/feature-entity/src';
import { KeyValueComponent } from '../../../../shared/ui-layout/src';

@Component({
    selector: 'lib-document-model-details',
    imports: [CommonModule, KeyValueComponent, QuillViewComponent],
    template: `<section class="grid grid-cols-2 gap-6">
    <ui-layout-key-value key="model name" i18n-key>
      {{ obj().name }}
    </ui-layout-key-value>

    <ui-layout-key-value key="document title" i18n-key>
      {{ obj().documentTitle }}
    </ui-layout-key-value>

    <ui-layout-key-value class="col-span-full" key="document content" i18n-key>
      <quill-view [content]="obj().template"></quill-view>
    </ui-layout-key-value>
  </section> `,
    styles: ``
})
export class DocumentModelDetailsComponent extends BaseEntityDetailsComponent<DocumentModel> {}
