<entity-list-wrapper [objs]="objs()">
  <table mat-table [dataSource]="objs()">
    <ng-container matColumnDef="status">
      <th mat-header-cell i18n *matHeaderCellDef>status</th>
      <td mat-cell *matCellDef="let element">
        <doctorus-front-end-monorepo-status
          [matTooltip]="isOutdated(element) ? outdatedMsg : null"
          [class.blink]="isOutdated(element)"
          entity="appointment"
          mode="BOTH"
          [status]="element.status"
        ></doctorus-front-end-monorepo-status>
      </td>
    </ng-container>

    <ng-container matColumnDef="schedules">
      <th mat-header-cell i18n *matHeaderCellDef>schedules</th>
      <td mat-cell *matCellDef="let element">
        <a class="anchor no-underline" [routerLink]="[element.id]">
          <appointment-dates [appointment]="element"></appointment-dates>
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef i18n>type</th>
      <td mat-cell *matCellDef="let element">
        {{ element.task_type?.name | coalesce: '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef i18n>location</th>
      <td mat-cell *matCellDef="let element">
        {{ element.location?.name | coalesce: '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="fees">
      <th mat-header-cell *matHeaderCellDef i18n>fees</th>
      <td mat-cell *matCellDef="let element">
        {{ $any(element.fees | accountCurrency) | coalesce: '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef i18n>description</th>
      <td mat-cell *matCellDef="let element">
        {{ element.description | coalesce: '-' }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns()"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns()"></tr>
  </table>
</entity-list-wrapper>
