import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule, NgComponentOutlet } from '@angular/common';
import { Component, inject, signal, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isNil } from 'lodash';
import {
  debounceTime,
  finalize,
  first,
  map,
  startWith,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs';
import { EntityMutationService, IEntity } from '../../../feature-entity/src';
import { updateAllValidityMarkAllTouched } from '../../../form/util-form/src';
import { FormDialogPanelData, IFormComponent } from './slide-out-panel-types';

@Component({
  selector: 'shared-ui-form-panel-base',
  standalone: true,
  imports: [CommonModule],
  template: `<p>form-panel-base works!</p>`,
  styles: ``,
})
export class FormPanelBaseComponent<
  E extends IEntity,
  C extends IFormComponent,
> {
  data: FormDialogPanelData<E, C> =
    inject(DIALOG_DATA, { optional: true }) ??
    inject(MAT_DIALOG_DATA, { optional: true });
  headerTitle = this.data?.entityConfig
    ? this.data.entityConfig.writeTitle(isNil(this.data.entity))
    : (this.data.title ?? 'N/A');
  componentRef = this.data.cmp;

  error: any = {};
  loading = signal(false);
  mutationService = inject(EntityMutationService);
  @ViewChild(NgComponentOutlet, { static: false })
  ngComponentOutlet!: NgComponentOutlet;

  inputs = { obj: this.data.entity, extra: this.data.extra };

  dialogRef = inject(DialogRef<E, FormPanelBaseComponent<E, C>>, {
    optional: true,
  });
  matDialogRef = inject(MatDialogRef<FormPanelBaseComponent<E, C>, E>, {
    optional: true,
  });

  closeDialog(payload?: any): void {
    this.dialogRef
      ? this.dialogRef.close(payload)
      : this.matDialogRef?.close(payload);
  }

  // setAttachedForm(cmpRef: CdkPortalOutletAttachedRef): void {
  //   this.componentRef = cmpRef as ComponentRef<C>;
  // }
  // private loadCmp() {
  //   const _injector = Injector.create({
  //     providers: [
  //       {
  //         provide: SLIDE_OUT_DATA_TOKEN,
  //         useValue: this.data
  //       }
  //     ]
  //   });

  //   return new ComponentPortal<C>(this.data.cmp as any, undefined, _injector);
  // }
  submit(): void {
    const form = this.ngComponentOutlet['_componentRef'].instance.form;
    updateAllValidityMarkAllTouched(form);
    form.statusChanges
      .pipe(
        tap(x => this.loading.set(true)),
        startWith(form.status),
        debounceTime(300),
        first(x => x !== 'PENDING'),
        takeWhile(x => x !== 'INVALID'),
        tap(() => console.warn(form)),
        switchMap(() => {
          const serializerFun = this.data.entityConfig?.serializerFun;
          const payload = serializerFun
            ? serializerFun(form.value)
            : form.value;
          //this.dialogRef.close(payload);
          return this.mutationService
            .mutate({
              payload: payload,
              id: this.data.entity?.id,
            })
            .pipe(
              tap(x => (this.error = x.errors)),
              map(x => x.data),
            );
        }),
        first(),
        map((_res: any) => Object.values(_res)[0]),
        finalize(() => this.loading.set(false)),
      )
      .subscribe({
        next: (x: E) => this.closeDialog(x),
        error: (error: any) => {
          console.warn(error);
        },
      });
  }
}
