import '@angular/localize/init';
import { bootstrapApplication } from '@angular/platform-browser';
import { Amplify } from 'aws-amplify';
import { setDefaultOptions } from 'date-fns';
import { fr } from 'date-fns/locale';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { amplifyconfig } from './app/aws-exports';
setDefaultOptions({
  locale: fr,
});
Amplify.configure(amplifyconfig);

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
