<form class="flex flex-col gap-6" [formGroup]="form" matDialogContent>
  <ui-layout-container border="false" title="general" i18n-title>
    <div class="flex flex-col gap-6">
      <mat-form-field uiFormMatFormFieldErrorDisplayer>
        <mat-label i18n>configuration name</mat-label>
        <input matInput formControlName="name">
        <mat-error></mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n>paper format</mat-label>
        <mat-select formControlName="paperFormat" required>
          <mat-option [value]="paperFormat.value" *ngFor="let paperFormat of paperFormats" [value]="paperFormat.value">
            {{ paperFormat.value | titlecase }}
            <span class="ml-2 text-gray-800">({{ paperFormat.dimension }})</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ui-layout-container>
  <ui-layout-container border="false" title="margins (cm)" i18n-title>
    <div class="grid grid-cols-2 gap-4">
      <mat-form-field>
        <mat-label i18n>top</mat-label>
        <input formControlName="topMargin" type="number" matInput>
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n>bottom</mat-label>
        <input formControlName="bottomMargin" type="number" matInput>
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n>left</mat-label>
        <input formControlName="leftMargin" placeholder="left" type="number" matInput>
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n>right</mat-label>
        <input formControlName="rightMargin" placeholder="right" type="number" matInput>
      </mat-form-field>
    </div>
  </ui-layout-container>

</form>