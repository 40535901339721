import { Dialog } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, Input, inject, signal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SlideOutPanelService } from '@doctorus-front-end-monorepo/slide-out-panel';
import { v4 as uuidv4 } from 'uuid';
import { FileBoxComponent } from '../file-box/file-box.component';
import { FileUploadService } from '../file-upload.service';
import { ImagePickerDialogComponent } from '../image-picker-dialog/image-picker-dialog.component';
import { ImgSrcPipe } from '../pipes/img-src/img-src.pipe';
import { CustomInEvent } from '../types';
import { findExt, isAnImage } from '../utils';

@Component({
  selector: 'util-document-file-upload-field',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FileUploadFieldComponent,
    },
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    FileBoxComponent,
    ImgSrcPipe,
  ],
  templateUrl: './file-upload-field.component.html',
  styleUrl: './file-upload-field.component.scss',
})
export class FileUploadFieldComponent implements ControlValueAccessor {
  @Input() initiaImages?: string[] = [];
  @Input() fileName = '';
  @Input() initSrc?: string;
  onChange = (a: string) => {};
  onTouched = () => {};
  isDisabled = false;
  file = signal<Blob | undefined>(undefined);
  private url?: string;
  writeValue(obj?: string): void {
    this.url = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  get name() {
    return this.fileName ?? '';
  }

  get imageSrc(): string | Blob | undefined {
    return this.file() ?? this.initSrc;
  }

  dialog = inject(Dialog);
  slideOutService = inject(SlideOutPanelService);
  overlay = inject(Overlay);
  fus = inject(FileUploadService);

  uploading = signal(false);

  pickImage(): void {
    this.dialog
      .open<Blob | undefined>(ImagePickerDialogComponent, {
        width: 'calc(100vw - 148px)',
        height: '100vh',
        hasBackdrop: true,
        panelClass: ['rounded-md'],
        positionStrategy: this.overlay.position().global().top('0').right('0'),
        data: {
          initiaImages: [],
        },
      })
      .closed.subscribe(x => {
        if (x) {
          this.uploadFile(x);
        }
      });
  }

  uploadFile(blob: Blob): void {
    const ext = findExt(blob);

    const path =
      [isAnImage(blob) ? 'medical-images' : 'medical-documents', uuidv4()].join(
        '/',
      ) + (ext ? '.' + ext : '');
    this.uploading.set(true);
    this.fus.uploadFile(blob, path, blob.type).subscribe(x => {
      this.file.set(blob);
      this.uploading.set(false);
      this.onChange(x);
    });
  }
  onFilePick = (event: Event): void => {
    const _event = event as CustomInEvent<InputEvent, HTMLInputElement>;
    const file = _event.target?.files && _event.target?.files[0];

    if (file) {
      this.uploadFile(file);
    }
  };
}
