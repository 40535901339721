import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { PatientPayment } from '@doctorus-front-end-monorepo/graphql';
import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';
import { formatISO } from 'date-fns';
import { BaseEntityFormComponent } from '../../../../../../../libs/shared/feature-entity/src';
@Component({
  selector: 'doctorus-front-end-monorepo-write-patient-payment-form',
  templateUrl: './write-patient-payment-form.component.html',
  styleUrls: ['./write-patient-payment-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    UiMatFormFieldErrorDisplayerDirective,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
  ],
})
export class WritePatientPaymentFormComponent extends BaseEntityFormComponent<PatientPayment> {
  initFormFun = () =>
    this.fb.group({
      id: [this.obj()?.id],

      amount: [
        this.obj()?.amount ?? 0,
        [Validators.required, Validators.min(0)],
      ],
      patient: [
        this.obj()?.patient ?? this.extra().patient.id,
        [Validators.required],
      ],
      date: [
        this.obj()?.date ?? formatISO(new Date(), { representation: 'date' }),
        [Validators.required],
      ],
    });
}
