import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, signal } from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import {
  AccountDataService,
  ActivePatientsFilterInput,
  GetAllPatientsGQL,
  Patient,
} from '@doctorus-front-end-monorepo/graphql';
import { HumanNamePipe } from '@doctorus-front-end-monorepo/shared-util';
import {
  EmptyStateComponent,
  LoadingComponent,
} from '@doctorus-front-end-monorepo/ui-layout';
import { CoalescePipe } from '@doctorus-front-end-monorepo/util-formatting';
import { first, map, tap } from 'rxjs';
import { PatientsListBaseComponent } from '../patient-list.base.component';

@Component({
  selector: 'doctorus-front-end-monorepo-active-patients-list',
  imports: [
    CommonModule,
    MatButtonModule,
    MatTableModule,
    MatFormFieldModule,
    RouterModule,
    MatSortModule,
    MatPaginatorModule,
    LoadingComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    HumanNamePipe,
    EmptyStateComponent,
    MatToolbarModule,
    CoalescePipe,
  ],
  templateUrl: './active-patients-list.component.html',
  styleUrl: './active-patients-list.component.scss',
})
export class ActivePatientsListComponent extends PatientsListBaseComponent {
  currency = inject(AccountDataService).account?.currency;
  deleted = input.required<boolean>();
  ordering = signal<string | null | undefined>(null);
  searchCtrl = new FormControl('');
  displayedColumns = ['name', 'reference', 'added', 'total_due'];
  containerDescription = computed(
    () => $localize`found ${this.count() ?? 'no'} patient(s)`,
  );
  queryParams = computed<ActivePatientsFilterInput>(() => ({
    commons: {
      page_size: this.pageSize(),
      ...(this.pageIndex() && { page: this.pageIndex()! + 1 }),
      ordering: this.ordering(),
    },
    deleted: this.deleted(),
    search: this.searchTerm(),
  }));
  patientListGql = inject(GetAllPatientsGQL);
  patientsRessource = rxResource({
    request: this.queryParams,
    loader: params =>
      this.patientListGql
        .fetch(
          {
            payload: params.request,
          },
          { fetchPolicy: this.fetchPolicy() },
        )
        .pipe(
          first(),
          tap(res => {
            this.count.set(res.data.getPatients.count);
            this.fetchPolicy.set('cache-first');
          }),
          map(res => res.data.getPatients.results),
        ),
  });

  expandedElement = signal<Patient | null>(null);

  /** Checks whether an element is expanded. */
  isExpanded(element: Patient) {
    return this.expandedElement()?.id === element.id;
  }

  /** Toggles the expanded state of an element. */
  toggle(element: Patient) {
    this.expandedElement.set(this.isExpanded(element) ? null : element);
  }
  updateSort(event: Sort): void {
    const direction = event.direction === 'asc' ? '' : '-';
    const value = event.direction ? `${direction}${event.active}` : '';
    this.ordering.set(value);
  }
  showDetails = (index: number, rowData: Patient) => this.isExpanded(rowData);
}
