import { Component, input, model, signal } from '@angular/core';
import { FetchPolicy } from '@apollo/client/core';
@Component({
  selector: 'doctorus-front-end-monorepo-patient-list-base',
  template: '',
})
export class PatientsListBaseComponent {
  count = model<number>();
  fetchPolicy = signal<FetchPolicy>('network-only');
  pageIndex = model<number | null>(null);
  pageSize = input(50);
  searchTerm = input<string | null>();
}
