import { Route } from '@angular/router';
import { BreadcrumbRouteData } from '../../../../shared/breadcrumb/src/lib/types';
import {
  DeletePrescriptionGQL,
  PutPrescriptionGQL,
  resolveMedicalRecordItem,
} from '../../../../shared/data-access/graphql/src';
import {
  DetailsWrapperComponent,
  ENTITY_CONFIG,
  ENTITY_DETAILS_COMPONENT,
  ENTITY_WRITE_COMPONENT,
  EntityMutationService,
  WriteWrapperComponent,
} from '../../../../shared/feature-entity/src';
import { EntityDeleteMutationService } from '../../../../shared/ui-entity-dialog/src';
import { PrescriptionBoxComponent } from './prescription-box/prescription-box.component';
import { prescriptionConfig } from './prescription.entity.model';
import { WritePrescriptionFormComponent } from './write-prescription-form/write-prescription-form.component';

export const featurePrescriptionRoutes: Route = {
  path: 'prescription',
  data: {
    breadcrumbChunck: {
      name: null,
    } as BreadcrumbRouteData,
  },
  providers: [
    {
      provide: ENTITY_CONFIG,
      useValue: prescriptionConfig,
    },
    {
      provide: EntityDeleteMutationService,
      useClass: DeletePrescriptionGQL,
    },
    {
      provide: EntityMutationService,
      useClass: PutPrescriptionGQL,
    },
    {
      provide: ENTITY_DETAILS_COMPONENT,
      useValue: PrescriptionBoxComponent,
    },
    {
      provide: ENTITY_WRITE_COMPONENT,
      useValue: WritePrescriptionFormComponent,
    },
  ],
  children: [
    {
      path: 'new',
      component: WriteWrapperComponent,
      data: {
        successRedirect: ['../..'],
        cancelRedirect: ['../..'],
        obj: null,
        breadcrumbChunck: {
          name: $localize`new`,
        } as BreadcrumbRouteData,
      },
    },
    {
      path: ':id',
      resolve: {
        obj: resolveMedicalRecordItem('prescriptions'),
      },
      data: {
        breadcrumbChunck: {
          name: $localize`prescription:{{obj.id}}`,
        } as BreadcrumbRouteData,
      },
      children: [
        {
          path: '',
          component: DetailsWrapperComponent,

          data: {
            deleteRedirect: ['../..'],

            breadcrumbChunck: {
              name: null,
            } as BreadcrumbRouteData,
          },
        },
        {
          path: 'edit',
          component: WriteWrapperComponent,
          data: {
            breadcrumbChunck: {
              name: $localize`edit`,
            } as BreadcrumbRouteData,
          },
        },
      ],
    },
  ],
};
