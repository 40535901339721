import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
@Component({
  selector: 'shared-ui-date',
  imports: [CommonModule],
  templateUrl: './date.component.html',
  styleUrl: './date.component.scss',
})
export class DateComponent {
  @Input() timezone?: string;
  @Input() date!: string | number | Date;
  @Input() format: 'dateOnly' | 'timeOnly' | 'all' = 'all';
}
