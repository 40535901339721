import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, numberAttribute } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
enum FeedbackPattern {
  ALERT = 1,
  FLASHBAR,
  SPINNER,
  PROGRESS_BAR,
}
enum MessageType {
  INFO = 1,
  SUCCESS,
  ERROR,
  WARNING,
}

const FEEDBACK_MAP: Record<MessageType, { icon: string; color: string }> = {
  [MessageType.INFO]: {
    icon: 'info',
    color: 'blue',
  },
  [MessageType.SUCCESS]: {
    icon: 'check_circle',
    color: 'green',
  },
  [MessageType.ERROR]: {
    icon: 'error',
    color: 'red',
  },
  [MessageType.WARNING]: {
    icon: 'warning',
    color: 'orange',
  },
};

@Component({
    selector: 'ui-layout-feedback',
    imports: [CommonModule, MatIconModule],
    templateUrl: './feedback.component.html',
    styleUrl: './feedback.component.scss'
})
export class FeedbackComponent {
  @Input({ transform: numberAttribute }) pattern: FeedbackPattern = 1;
  @Input({ transform: numberAttribute }) type: MessageType = 1;
  @Input() title: string = '';
  @Input() message: string = '';

  get feedbackAttrs() {
    return FEEDBACK_MAP[this.type];
  }

  @HostBinding('class')
  get classes() {
    return ` border-${this.feedbackAttrs.color}-800 p-4 flex flex-wrap gap-2 justify-between w-full rounded-lg  bg-${this.feedbackAttrs.color}-200`;
  }
}
