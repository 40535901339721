import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from '@angular/router';
import {
  BreadcrumbComponent,
  resolveBreadcrumbs,
} from '@doctorus-front-end-monorepo/breadcrumb';
import { debounceTime, filter, map, tap } from 'rxjs';

@Component({
    selector: 'doctorus-front-end-monorepo-medical-record-center-board',
    imports: [CommonModule, RouterModule, BreadcrumbComponent],
    templateUrl: './medical-record-center-board.component.html',
    styleUrl: './medical-record-center-board.component.scss'
})
export class MedicalRecordCenterBoardComponent {
  route = inject(ActivatedRoute);
  router = inject(Router);
  breadcrumbs$ = this.router.events.pipe(
    filter(_event => _event instanceof NavigationEnd),
    debounceTime(300),
    tap(console.warn),
    map(_event => resolveBreadcrumbs(this.route)),
  );
}
