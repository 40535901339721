import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'doctorus-front-end-monorepo-office-created',
    templateUrl: './office-created.component.html',
    styleUrls: ['./office-created.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule, MatButtonModule, RouterLink, AsyncPipe]
})
export class OfficeCreatedComponent {}
