import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChild,
  Input,
  OnChanges,
  OnDestroy,
  TemplateRef,
  inject,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Subscription } from 'rxjs';

@Component({
    selector: 'lib-inline-form-array',
    exportAs: 'inlineArray',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatDividerModule,
    ],
    viewProviders: [
        {
            provide: ControlContainer,
            useFactory: () => inject(ControlContainer, { skipSelf: true }),
        },
    ],
    templateUrl: './inline-form-array.component.html',
    styleUrl: './inline-form-array.component.scss'
})
export class InlineFormArrayComponent<T> implements OnDestroy, OnChanges {
  @Input({ required: true }) controlKey = '';
  @Input({ required: true }) formBuildFn!: (
    payload?: Maybe<T>,
  ) => AbstractControl;
  @Input() init?: T[] | null = [];
  @Input() min = 0;
  @Input() extra = 0;
  @ContentChild('fields', { static: false }) formTemplateRef!: TemplateRef<any>;

  subscription = new Subscription();

  fb = inject(FormBuilder);
  parentContainer = inject(ControlContainer);

  get parentFormGroup() {
    return this.parentContainer.control as FormGroup;
  }

  ngOnDestroy(): void {
    this.parentFormGroup.removeControl(this.controlKey);
  }
  ngOnChanges() {
    const formArray = this.fb.array(
      this.init?.map(x => this.formBuildFn(x)) ?? [],
    );

    const diff = this.extra - formArray.length;
    for (let step = 0; step < diff; step++) {
      formArray.push(this.formBuildFn());
    }

    this.parentFormGroup.setControl(this.controlKey, formArray);
  }

  get ctrls(): FormArray {
    return this.parentFormGroup.get(this.controlKey) as FormArray;
  }

  get removeDisabled() {
    return this.ctrls.length === this.min;
  }

  removeForm(index: number): void {
    (this.parentFormGroup.get(this.controlKey) as FormArray).removeAt(index);
  }
  pushNewForm(): void {
    (this.parentFormGroup.get(this.controlKey) as FormArray).push(
      this.formBuildFn(),
    );
  }
}
