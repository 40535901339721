<ng-container>
  <mat-calendar [selected]="selectedDate" (selectedChange)="toDate($event)" startView="month"></mat-calendar>
  <form class="flex flex-col gap-4 mt-4" [formGroup]="queryParamsFg">
    <!-- <div class="flex items-center gap-2">
            <mat-icon>location_on</mat-icon>
            <mat-form-field subscriptSizing="dynamic" appearance="fill" class="compact-text-field flex-1">
                <mat-select formControlName="location" multiple placeholder="location" i18n-placeholder place>
                    <mat-option [value]="location.id" *ngFor="let location of account.locations">
                        {{location.name | titlecase}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->
    <div class="flex items-center gap-2">
      <mat-icon>event</mat-icon>
      <mat-form-field subscriptSizing="dynamic" appearance="fill" class="compact-text-field flex-1">
        <mat-select formControlName="status" multiple placeholder="status" i18n-placeholder place>
          <mat-option [value]="status[0]" *ngFor="let status of statusList">
            {{status[1].label | titlecase}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <button class="mt-6" type="reset" mat-stroked-button>
      <mat-icon>restart_alt</mat-icon>
      <span i18n>reset filters</span>
    </button>


  </form>
</ng-container>