import { Directive, HostBinding, Input, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Human } from '../../../shared-type/src';

@Directive({
  selector: '[authAvatarLoader]',
  standalone: true,
})
export class AvatarLoaderDirective {
  @Input({ required: true }) user!: Human;
  @Input() size = 80;
  sanitizer = inject(DomSanitizer);
  @HostBinding('attr.src')
  get avatar() {
    return `https://ui-avatars.com/api/?name=${this.user.given_name?.at(0)} ${this.user.family_name?.at(0)}`;
  }
}
