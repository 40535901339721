<header mat-dialog-title class="flex gap-2 mb-4 dense-icon text-gray-800">
  <mat-icon (click)="closeWith('EDIT')" class="clickable">edit</mat-icon>
  <mat-icon (click)="closeWith('DELETE')" class="clickable" type="button">delete</mat-icon>
  <mat-icon (click)="closeWith('NONE')" class="clickable ml-auto" type="button">close</mat-icon>
</header>
<mat-dialog-content class="flex flex-col gap-2">

  <h3>{{ event.start | date : 'fullDate' }}</h3>
  <div class="flex gap-2  flex-wrap">
    <mat-icon>schedule</mat-icon>
    <div>
      <p>{{ event.start | date : 'HH:mm ' }} - {{
        event.end | date : 'HH:mm' }}
        @if(differentTimeZone) {
        <span>({{currentTimezone}} {{event.end | date :
          'OO'}})</span>
        }
      </p>
      @if(differentTimeZone) {
      <span>{{ event.start | date :
        'HH:mm':appointmentOffset }} -
        {{ event.end | date : 'HH:mm' :appointmentOffset
        }} <span>({{event.extendedProps.timezone}}
          {{event.end | date
          : 'OO'
          :appointmentOffset}})</span></span>
      }
    </div>
  </div>
  @if (event.extendedProps.task_type?.name) {
  <div class="flex gap-2 items-center">
    <!-- <mat-icon>medical_services</mat-icon> -->
    <span class="w-4 h-4 rounded-full mx-1" [style.background-color]="event.extendedProps.task_type?.color"></span>
    <span>{{ event.extendedProps.task_type?.name |
      titlecase }}</span>
  </div>
  }
  <div *authShowForMultiLocations class="flex gap-2 items-center">
    <mat-icon>location_on</mat-icon>
    <span>{{ event.extendedProps.location?.name |
      titlecase }}</span>
  </div>
  @if
  (event.extendedProps.status|resolveStatusConfig:'appointment';as
  status) {
  <appointment-status-picker (statusChange)="updateStatus($event)" [status]="event.extendedProps.status">
    <div class="flex gap-2 items-center">
      <mat-icon [style.color]="status.color">{{status.svgIcon}}</mat-icon>
      <span [style.color]="status.color">{{status.label}}</span>
    </div>

  </appointment-status-picker>
  }

  <mat-divider></mat-divider>
  <div class="flex gap-2">
    <mat-icon>badge</mat-icon>
    <div class="flex flex-col gap-2">
      <a (click)="closeWith('NONE')" [routerLink]="rnhs.getPatientRoute(event.extendedProps.patient_info.id)"
        class="underline">{{event.extendedProps.patient_info |
        humanName:'full':true | titlecase}}
        &nbsp;-&nbsp;
        {{event.extendedProps.patient_info.reference}}
      </a>
      <section class="flex flex-col">
        @for (phonenumber of event.extendedProps.patient_info.phone_numbers; track $index) {
        <patient-phone-number-box class="text-sm" mode="SMALL" [phonenumber]="phonenumber"></patient-phone-number-box>
        }
      </section>
    </div>


  </div>
</mat-dialog-content>

<mat-dialog-actions class="flex justify-end mt-4">
  <button (click)="closeWith('NONE')" *showForRoles="[accountRole.DOCTOR]" [queryParams]="{initAppointmentId:event.id}"
    [routerLink]="rnhs.medicalRecordRoute(event.extendedProps.patient_info.id,event.id)"
    mat-flat-button><mat-icon>medical_information</mat-icon><span>medical
      record</span></button>

</mat-dialog-actions>