import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  Account,
  UpdateGeneralSettingsGQL,
} from '@doctorus-front-end-monorepo/graphql';
import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';
import { updateAllValidityMarkAllTouched } from '@doctorus-front-end-monorepo/util-form';
import { getCountryDataList } from 'countries-list';
import * as CurrencyCodes from 'currency-codes';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { map, startWith } from 'rxjs';
@Component({
  selector: 'account-settings-update-general-settings-dialog',
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    UiMatFormFieldErrorDisplayerDirective,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatDialogModule,
  ],
  template: `<h2 matDialogTitle>update general settings</h2>
    <form
      class="pt-4 flex flex-col gap-6"
      [formGroup]="form"
      mat-dialog-content
    >
      <mat-form-field uiFormMatFormFieldErrorDisplayer>
        <mat-label i18n>alias</mat-label>
        <input type="text" formControlName="alias" matInput />
        <mat-error></mat-error>
      </mat-form-field>

      <mat-form-field uiFormMatFormFieldErrorDisplayer>
        <mat-select formControlName="currency" placeholder="select currency">
          <mat-option>
            <ngx-mat-select-search
              placeholderLabel="search by typing currency name"
              i18n-placeholderLabel
              i18n-noEntriesFoundLabel
              noEntriesFoundLabel="no currency found"
              [formControl]="currencyFilterCtrl"
            ></ngx-mat-select-search>
          </mat-option>

          <mat-option
            *ngFor="let currency of filtredCurrencies$ | async"
            [value]="currency.code"
          >
            {{ currency.currency | titlecase }} ({{ currency.code }})
          </mat-option>
        </mat-select>
        <mat-error></mat-error>
      </mat-form-field>
    </form>
    <footer class="flex gap-4" matDialogActions>
      <button mat-stroked-button mat-dialog-close>
        <span i18n>delete</span>
      </button>
      <button mat-stroked-button (click)="submit()" type="submit">
        <span i18n>edit</span>
      </button>
    </footer>`,
  styles: ``,
})
export class UpdateGeneralSettingsDialogComponent {
  private matDialogRef = inject(MatDialogRef);
  private updatePreferencesGql = inject(UpdateGeneralSettingsGQL);
  private fb = inject(FormBuilder);
  public account: Account = inject(MAT_DIALOG_DATA).account;
  countries = getCountryDataList().sort((a, b) => (a.name > b.name ? 1 : -1));
  currencies = CurrencyCodes.data;

  // Object.values(
  //   CurrencyList.getAll('fr_FR') as {
  //     [code: string]: {
  //       name: string;
  //       symbol_native: string;
  //       symbol: string;
  //       code: string;
  //       name_plural: string;
  //       rounding: number;
  //       decimal_digits: number;
  //     };
  //   },
  // );
  form = this.fb.group({
    alias: [this.account.alias, Validators.required],
    currency: [this.account.currency, Validators.required],
  });
  currencyFilterCtrl = new FormControl();

  filtredCurrencies$ = this.currencyFilterCtrl.valueChanges.pipe(
    startWith(null),
    map(value =>
      value
        ? this.currencies.filter(country =>
            country.code?.toLowerCase()?.includes(value.toLowerCase()),
          )
        : this.currencies,
    ),
  );
  submit(): void {
    updateAllValidityMarkAllTouched(this.form);
    if (this.form.valid) {
      this.updatePreferencesGql
        .mutate({ payload: this.form.value as any })
        .subscribe(res =>
          this.matDialogRef.close(res.data?.updateGeneralSettings),
        );
    }
  }
}
