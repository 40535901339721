<mat-sidenav-container class="h-full">
  <aside class="h-full">
    <button (click)="toggle()" mat-mini-fab><mat-icon>menu</mat-icon></button>
  </aside>

  <mat-sidenav #sidenav [opened]="open || !(pushSideNav$|async)" [mode]="(pushSideNav$|async) ? 'over':'side'"
    fixedTopGap="64" class="max-w-sm w-full border-r border-r-gray-300" fixedInViewport [opened]="open">
    <header class="flex p-4 justify-between items-center">
      <h2 class="capitalize text-black" i18n>settings and preferences</h2>

      <button (click)="toggle()" mat-icon-button><mat-icon>close</mat-icon></button>

    </header>
    <mat-divider></mat-divider>
    <ui-layout-nav-tree [navLinks]="links"></ui-layout-nav-tree>

  </mat-sidenav>
  <mat-sidenav-content class="flex gap-2 my-4">
    <!-- @if (!open) {
    <div class="left-0 top-6 w-16 absolute flex flex-col gap-2 items-center">
      <button (click)="toggle()" mat-mini-fab><mat-icon>menu</mat-icon></button>
    </div>

    } -->
    @if(!(xSmall$|async)) {
    @if (!sidenav.opened) {
    <button class="ml-4" (click)="toggle()" mat-mini-fab><mat-icon>menu</mat-icon></button>

    }

    }

    <div class="flex flex-col gap-4 mt-2 container">
      <header class="flex gap-2 items-center">
        @if((xSmall$|async)) {
        <button (click)="toggle()" mat-icon-button><mat-icon>menu</mat-icon></button>

        }
        @if ((breadcrumbs$|async);as breadcrumbs ) {
        @if(breadcrumbs.length > 1) {
        <ui-layout-breadcrumb [breadcrumbs]="breadcrumbs"></ui-layout-breadcrumb>

        }


        }

      </header>

      <router-outlet></router-outlet>

    </div>
  </mat-sidenav-content>
</mat-sidenav-container>