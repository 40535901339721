import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  NoPreloading,
  provideRouter,
  withComponentInputBinding,
  withDebugTracing,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig,
} from '@angular/router';
import { authInterceptor } from '@doctorus-front-end-monorepo/auth';
import { graphQlProvider } from '@doctorus-front-end-monorepo/graphql';
import { quillConfig } from '@doctorus-front-end-monorepo/rte';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { provideQuillConfig } from 'ngx-quill';
import { appRoutes } from './app.routes';
import { CustomErrorHandlerService } from './core/services/custom-error-handler/custom-error-handler.service';
import { httpInterceptorProviders } from './http-interceptors';
import { materialProviders } from './providers';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserModule, MatSnackBarModule, MatDateFnsModule),
    provideQuillConfig(quillConfig ?? {}),
    provideCharts(withDefaultRegisterables()),
    provideHttpClient(withInterceptors([authInterceptor])),
    provideRouter(
      appRoutes,
      withPreloading(NoPreloading),
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
        onSameUrlNavigation: 'reload',
      }),
      withDebugTracing(),
      withComponentInputBinding(),
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
      }),
    ),
    graphQlProvider,

    {
      provide: ErrorHandler,
      useClass: CustomErrorHandlerService,
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: loadInitData,
    //   deps: [AppInitService],
    //   multi: true,
    // },
    httpInterceptorProviders,
    materialProviders,
    //provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
  ],
};
