export * from './lib/feature-patient/feature-patient.component';
export * from './lib/types';

export * from './lib/patient-details-base/patient-details-base.component';
export * from './lib/patient-form-builder.service';
export * from './lib/patient-write-form/patient-write-form.component';
export * from './lib/patient.entity.model';
export * from './lib/phone-number-box/phone-number-box.component';

export * from './lib/write-patient-properties/write-patient-properties.component';

export * from './lib/medical-notes/medical-note-details.component';

export * from './lib/medical-notes/medical-note-form.component';

export * from './lib/patient-property-inline-form/patient-property-inline-form.component';

export * from './lib/write-patient-public-properties/write-patient-public-properties.component';
