import { CommonModule } from '@angular/common';
import { Component, computed, inject, INJECTOR, Injector } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { SlideOutPanelService } from '@doctorus-front-end-monorepo/slide-out-panel';
import {
  CarePlan,
  MedicalTask,
  PutMedicalTaskGQL,
} from '../../../../../shared/data-access/graphql/src';
import {
  BaseEntityDetailsComponent,
  BaseEntityFormComponent,
  EntityMutationService,
} from '../../../../../shared/feature-entity/src';
import { HumanNamePipe } from '../../../../../shared/shared-util/src';
import {
  ContainerComponent,
  EmptyStateComponent,
  KeyValueComponent,
} from '../../../../../shared/ui-layout/src';
import { CoalescePipe } from '../../../../../shared/util-formatting/src';
import { medicalTaskConfig } from '../entity-config';
import { WriteMedicalTaskDialogComponent } from '../write-medical-task-dialog/write-medical-task-dialog.component';

@Component({
  selector: 'lib-care-plan-details',
  imports: [
    CommonModule,
    KeyValueComponent,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    HumanNamePipe,
    MatDividerModule,
    CoalescePipe,
    ContainerComponent,
    EmptyStateComponent,
  ],
  templateUrl: './care-plan-details.component.html',
  styleUrl: './care-plan-details.component.scss',
})
export class CarePlanDetailsComponent extends BaseEntityDetailsComponent<CarePlan> {
  parentInject = inject(INJECTOR);
  medicalTaskInjector = Injector.create({
    parent: this.parentInject,
    providers: [
      { provide: EntityMutationService, useClass: PutMedicalTaskGQL },
    ],
  });

  private sds = inject(SlideOutPanelService);

  medicalTasksTitle = computed(
    () => $localize`medical tasks (${this.obj().medical_tasks?.length})`,
  );

  writeMedicalTask(medicalTask?: MedicalTask): void {
    this.sds
      .openDialogWriteForm<
        MedicalTask,
        BaseEntityFormComponent<MedicalTask, any>,
        Pick<CarePlan, 'id' | 'medical_tasks'>
      >(
        {
          cmp: WriteMedicalTaskDialogComponent,
          entity: medicalTask,
          entityConfig: medicalTaskConfig,
          extra: {
            care_plan: this.obj(),
          },
        },
        this.medicalTaskInjector,
      )
      .subscribe(res =>
        this.obj.update(_old => ({
          ..._old,
          ...res,
        })),
      );
  }
}
