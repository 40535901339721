import { CommonModule, DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FullCalendarComponent,
  FullCalendarModule,
} from '@fullcalendar/angular';
import { CalendarEventComponent } from '../calendar-event/calendar-event.component';

import {
  CalendarOptions,
  EventClickArg,
  EventDropArg,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';

import interactionPlugin, {
  DateClickArg,
  EventResizeDoneArg,
} from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';

import { AppointmentEventFieldsFragment } from '@doctorus-front-end-monorepo/graphql';
import { AppointmentStatus } from '@doctorus-front-end-monorepo/shared-util';
import { EventImpl } from '@fullcalendar/core/internal';
import luxonPlugin from '@fullcalendar/luxon3';
import * as dateFns from 'date-fns';
import { Subscription, debounceTime, fromEvent } from 'rxjs';
import { DAY_VIEW } from '../const';
import { CalendarView } from '../types';
import { toCalendatEvent } from '../util-scheduler';
@Component({
  selector: 'medical-space-web-calendar',
  imports: [CommonModule, CalendarEventComponent, FullCalendarModule],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss',
  exportAs: 'calendar',
})
export class CalendarComponent implements OnDestroy {
  @Input({
    required: true,
    transform: (value: AppointmentEventFieldsFragment[]) =>
      value?.map(appointment => toCalendatEvent(appointment)),
  })
  appointments!: AppointmentEventFieldsFragment[];
  @Input({ required: true })
  set view(input: CalendarView) {
    this.calendarComponent?.getApi()?.changeView(input.viewType);
  }
  @Input({ required: true })
  set date(payload: Date) {
    this.calendarComponent?.getApi()?.gotoDate(payload);
  }

  @Output() eventUiEdit = new EventEmitter<EventResizeDoneArg | EventDropArg>();
  @Output() dateClick = new EventEmitter<DateClickArg>();
  @Output() eventClick = new EventEmitter<EventClickArg>();
  @Output() eventChange = new EventEmitter<EventClickArg>();
  @Output() dateChange = new EventEmitter<Date>();
  @Output() viewChange = new EventEmitter<CalendarView>();
  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;

  subscription = new Subscription();

  constructor(
    @Inject(LOCALE_ID) private localeId: any,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.subscription.add(
      fromEvent(document, 'click')
        .pipe(debounceTime(300))
        .subscribe(() => {
          console.warn('click');
          this.calendarComponent?.getApi()?.updateSize();
        }),
    );
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onDayClick = (date: Date): void => {
    const dayView = DAY_VIEW;
    this.switchView(dayView);
    this.viewChange.emit(dayView);
    this.toDate(date);
  };
  private toDate(date: Date): void {
    this.calendarComponent?.getApi()?.gotoDate(date);
    this.dateChange.emit(date);
  }
  // public resize() {
  //   this.calendarComponent?.getApi()?.updateSize();
  // }
  putEvent = (info: EventResizeDoneArg | EventDropArg): void => {
    this.eventUiEdit.emit(info);
  };
  private eventOverlap = (stillEvent: EventImpl, movingEvent: EventImpl) => {
    return (
      stillEvent.extendedProps['location'].id !==
        movingEvent?.extendedProps['location'].id ||
      (stillEvent.extendedProps['location'].id ===
        movingEvent.extendedProps['location'].id &&
        (stillEvent.extendedProps['status'] === AppointmentStatus.ABSENT ||
          movingEvent.extendedProps['status'] === AppointmentStatus.ABSENT)) ||
      !dateFns.areIntervalsOverlapping(
        stillEvent as dateFns.Interval,
        movingEvent as dateFns.Interval,
      )
    );
  };
  private switchView(view: CalendarView): void {
    //this.calendarView = view;
    this.calendarComponent?.getApi()?.changeView(view.viewType);
    // if (withFetch) {
    //   this.fetch();
    // }
  }

  calendarOptions: CalendarOptions = {
    plugins: [
      timeGridPlugin,
      interactionPlugin,
      dayGridPlugin,
      listPlugin,
      luxonPlugin,
    ],
    allDaySlot: false,
    headerToolbar: false,
    navLinks: true,
    eventTextColor: 'black',
    navLinkDayClick: this.onDayClick,
    locale: this.localeId,
    nowIndicator: true,
    dayMaxEvents: 5,
    editable: true,
    firstDay: 1,
    eventBorderColor: 'transparent',
    eventDrop: this.putEvent,
    eventResize: this.putEvent,
    eventOverlap: this.eventOverlap as any,
    dayCellClassNames: 'hover:bg-gray-50 hover:cursor-pointer',
    slotLaneClassNames: 'hover:bg-gray-50 hover:cursor-pointer',
    eventClassNames: ['truncate'],
    height: 'calc(100vh - 8rem)',
    initialView: 'dayGridMonth',
    //dayHeaderContent: false,
    eventClick: event => this.eventClick.emit(event),
    dateClick: arg => this.dateClick.emit(arg),
  };
}
