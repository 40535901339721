import { Mutation, TypedDocumentNode } from 'apollo-angular';
import { DocumentNode } from 'graphql';

export class EntityDeleteMutationService extends Mutation<
  { id: string | number },
  { id: string | number }
> {
  override document!:
    | DocumentNode
    | TypedDocumentNode<{ id: string | number }, { id: string | number }>;
}
