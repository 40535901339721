import { ActivatedRouteSnapshot } from '@angular/router';

export const resolveParam = (
  param: string,
  route: ActivatedRouteSnapshot,
): string | null => {
  if (route.paramMap.has(param)) {
    return route.paramMap.get(param);
  }
  return route.parent ? resolveParam(param, route.parent) : null;
};
