import {
  CommonModule,
  CurrencyPipe,
  DatePipe,
  NgFor,
  NgIf,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  inject,
  model,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { EntityMutationService } from '@doctorus-front-end-monorepo/feature-entity';
import {
  CorePatientFieldsFragment,
  DeletePatientPaymentGQL,
  PatientPayment,
  PutPatientPaymentGQL,
} from '@doctorus-front-end-monorepo/graphql';
import { SlideOutPanelService } from '@doctorus-front-end-monorepo/slide-out-panel';
import {
  EntityDeleteMutationService,
  EntityDialogService,
} from '@doctorus-front-end-monorepo/ui-entity-dialog';
import { ArrayExistPipe } from '@doctorus-front-end-monorepo/util-array';
import { EmptyStateComponent } from '@doctorus-front-end-monorepo/ui-layout';
import { AccountCurrencyPipe } from '@doctorus-front-end-monorepo/util-formatting';
import { patientPaimentConfig } from '../../../models/entities/patient-billing/paiment.entity.model';
import { WritePatientPaymentFormComponent } from '../../forms/write-patient-payment-form/write-patient-payment-form.component';
@Component({
    selector: 'doctorus-front-end-monorepo-patient-payment-list',
    templateUrl: './patient-payment-list.component.html',
    styleUrls: ['./patient-payment-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'paymentsList',
    providers: [
        {
            provide: EntityMutationService,
            useClass: PutPatientPaymentGQL,
        },
        {
            provide: EntityDeleteMutationService,
            useClass: DeletePatientPaymentGQL,
        },
    ],
    imports: [
        NgIf,
        AccountCurrencyPipe,
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatListModule,
        MatDividerModule,
        NgFor,
        MatMenuModule,
        MatDividerModule,
        CurrencyPipe,
        DatePipe,
        ArrayExistPipe,
        EmptyStateComponent,
    ]
})
export class PatientPaymentListComponent {
  entities = model.required<PatientPayment[]>();
  @Input({ required: true }) patient!: CorePatientFieldsFragment;
  entityConfig = patientPaimentConfig;
  paimentMessageMapping: { [k: string]: string } = {
    '=0': 'No paiments',
    '=1': 'One paiment',
    other: '# Paiments',
  };
  private sds = inject(SlideOutPanelService);
  private eds = inject(EntityDialogService);
  private injector = inject(Injector);
  onWrite(payment?: PatientPayment): void {
    this.sds
      .openDialogWriteForm(
        {
          cmp: WritePatientPaymentFormComponent,
          entity: payment,
          entityConfig: patientPaimentConfig,
          extra: {
            patient: this.patient,
          },
        },
        this.injector,
      )
      .subscribe();
  }
  onDelete(payment: PatientPayment): void {
    this.eds
      .openEntityDeleteDialog(payment, this.injector, patientPaimentConfig)
      .subscribe();
  }
}
