import { NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { AccountRole } from '@doctorus-front-end-monorepo/auth';
import { Membership } from '@doctorus-front-end-monorepo/graphql';
import { HumanNamePipe } from '@doctorus-front-end-monorepo/shared-util';
import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';
import { TitleCaseFirstPipe } from '../../../../../../../apps/medical-space-web/src/app/core/pipes/title-case-first.pipe';

@Component({
  selector: 'doctorus-front-end-monorepo-create-member-dialog',
  templateUrl: './create-member-dialog.component.html',
  styleUrls: ['./create-member-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatDialogModule,
    NgIf,
    ReactiveFormsModule,
    MatFormFieldModule,
    UiMatFormFieldErrorDisplayerDirective,
    MatInputModule,
    MatListModule,
    NgFor,
    MatButtonModule,
    TitleCasePipe,
    TitleCaseFirstPipe,
    HumanNamePipe,
  ],
})
export class CreateMemberDialogComponent {
  membershipForm: FormGroup;
  headerStr = this.data
    ? $localize`edit membership`
    : $localize`add new member`;
  roles: {
    title: string;
    value: AccountRole;
    description: string;
  }[] = [
    {
      title: $localize`doctor`,
      value: AccountRole.DOCTOR,
      description: $localize`Read and write access to all patient ressources.`,
    },
    {
      title: $localize`manager`,
      value: AccountRole.MANAGER,
      description: $localize`Read and write access to account configuration.`,
    },
    {
      title: $localize`medical assistant`,
      value: AccountRole.MEDICAL_ASSISTANT,
      description: $localize`Read and write access to patient appointment and administrative data.`,
    },
  ];
  constructor(
    public dialogRef: MatDialogRef<CreateMemberDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      memberships?: Membership[];
      membership?: Membership;
    },
  ) {
    this.membershipForm = this.fb.group({
      email: [
        {
          value: '',
          disabled: data.membership ? true : false,
        },
        [Validators.required, Validators.email, this.forbiddenEmailValidator],
      ],
      roles: [data?.membership?.roles, Validators.required],
      userId: [
        {
          value: data?.membership?.user?.sub,
          disabled: data?.membership ? false : true,
        },
        [Validators.required],
      ],
    });
  }

  private forbiddenEmailValidator = (
    control: AbstractControl,
  ): ValidationErrors | null => {
    const email = control?.value;
    if (email) {
      const membership = this.data?.memberships?.find(
        x => x.user.email === email,
      );
      return membership ? { existantMembership: true } : null;
    }
    return null;
  };

  submit(): void {
    this.membershipForm.markAllAsTouched();
    if (this.membershipForm.valid) {
      this.dialogRef.close(this.membershipForm.value);
    }
  }
}
