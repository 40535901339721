<form matDialogContent class="flex flex-col gap-2 pt-4" [formGroup]="form">
  <mat-form-field appearance="outline" uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>date</mat-label>
    <input placeholder="DD/MM/YYYY" #input matInput type="date" formControlName="date" />
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>amount</mat-label>
    <input min="0" step="10" required formControlName="amount" matInput type="number" />
    <mat-icon matSuffix>price_check</mat-icon>
    <mat-error></mat-error>
  </mat-form-field>
</form>
