<form class="flex flex-col gap-6" [formGroup]="form">
  <ui-layout-container border="false" title="general informations" i18n-title>
    <mat-form-field uiFormMatFormFieldErrorDisplayer>
      <mat-label i18n>name</mat-label>
      <input matInput type="text" formControlName="name">
      <mat-error></mat-error>
    </mat-form-field>
    <mat-form-field uiFormMatFormFieldErrorDisplayer>
      <mat-label i18n>slot intervals</mat-label>
      <input list="defaultSlots" min="15" step="15" formControlName="slot_interval" matInput type="number">
      <mat-hint i18n>interval, in minutes, between slot times</mat-hint>
      <mat-error></mat-error>
    </mat-form-field>
  </ui-layout-container>


  <ui-layout-container border="false" [title]="workingRuledTitle">
    <div ngProjectAs="actions"> <button (click)="inline.pushNewForm()" mat-stroked-button><span i18n>new working rule
        </span></button>
    </div>

    <lib-inline-form-array #inline="inlineArray" [init]="$any(obj()?.working_periods)" [min]="1" [extra]="1"
      [formBuildFn]="createWorkingPeriodFg" controlKey="working_periods">
      <ng-template let-index="index" #fields>
        <lib-working-period-inline-form [index]="index" formArrayKey="working_periods"></lib-working-period-inline-form>
      </ng-template>
    </lib-inline-form-array>

    <!-- <div class="max-w-screen-md flex flex-col gap-4" formArrayName="working_periods">
      @for (control of workingPeriodsCrls; track $index;let i = $index) {
      <form [formGroup]="control">
        <div class="flex justify-between items-center">
          <p class="text-red-700" *ngIf="workingPerionError(i) as errors">
            <span *ngFor="let error of errors|keyvalue"> {{error.value}} </span>
          </p>

        </div>

        <div class="flex gap-4 w-full items-center">
          <mat-form-field uiFormMatFormFieldErrorDisplayer subscriptSizing="dynamic" class="flex-1">
            <mat-select i18n-placeholder placeholder="select starting time" [formControlName]="0">
              <mat-option [value]="time" *ngFor="let time of dayTimes">{{time |
                time}}</mat-option>
            </mat-select>
            <mat-error></mat-error>
          </mat-form-field>
          <mat-form-field uiFormMatFormFieldErrorDisplayer subscriptSizing="dynamic" class="flex-1">
            <mat-select i18n-placeholder placeholder="select end time" [formControlName]="1">
              <mat-option [value]="time" *ngFor="let time of dayTimes">{{time |
                time}}</mat-option>
            </mat-select>
            <mat-error></mat-error>
          </mat-form-field>
          <button class="ml-auto" [disabled]="workingPeriodsCount < 2" (click)="removeWorkingPeriod(i)" mat-icon-button>
            <mat-icon>clear</mat-icon>
          </button>
        </div>

      </form>
      }
    </div> -->

  </ui-layout-container>
