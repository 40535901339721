<form [formGroup]="form">
  <mat-form-field appearance="outline" uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>date</mat-label>
    <input placeholder="DD/MM/YYYY" #input matInput type="date" formControlName="date" />
    <mat-icon matIconSuffix class="hover:cursor-pointer" (click)="input.showPicker()">calendar_month</mat-icon>
    <mat-error></mat-error>
  </mat-form-field>


  <!-- <mat-form-field class="flex-1">
          <mat-label i18n>favorite prescriptions</mat-label>

          <mat-select [formControl]="prescriptionModelCtl">
              <mat-option [value]="undefined">
                  ----------------
              </mat-option>
              <mat-option [value]="prescriptionModel" *ngFor="let prescriptionModel of prescriptionModels$|async">
                  {{prescriptionModel.title}}
              </mat-option>
          </mat-select>
          <mat-hint i18n>use one of your stored favorites prescriptions</mat-hint>
      </mat-form-field> -->

  <ui-layout-container [border]="false" [title]="treatmentsContainerTitle">

    <button (click)="addTreatment()" ngProjectAs="actions" mat-stroked-button>
      <mat-icon>add_circle</mat-icon>
      <span i18n>add treatment</span>
    </button>

    <lib-inline-form-array [init]="obj()?.treatment_set" [min]="1" [extra]="1" [formBuildFn]="createTreatment"
      controlKey="treatment_set">
      <ng-template let-index="index" #fields>
        <prescription-treatment-form [index]="index" formArrayKey="treatment_set"></prescription-treatment-form>
      </ng-template>
    </lib-inline-form-array>



  </ui-layout-container>

</form>
