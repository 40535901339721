import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { ArrayFilterElement } from './types';

@Pipe({
  name: 'arrayFilter',
  standalone: true
})
export class ArrayFilterPipe implements PipeTransform {
  transform(array: any[] | null | undefined, filters: ArrayFilterElement[]): any[] {
    return (
      array?.filter((val) =>
        filters.every((filter) => {
          const props = filter.prop.split('.');
          let _val = props
            ? props.reduce((acc: any, current: string) => acc[current], val)
            : val;
          _val = _.isNumber(_val) ? _val.toString() : _val;
          return filter.compareFn
            ? filter.compareFn(_val, filter.value)
            : _.isEqual(_val,filter.value);
        })
      ) ?? []
    );
  }
}
