import { EntityConfig } from '@doctorus-front-end-monorepo/feature-entity';
import { CarePlan, MedicalTask } from '@doctorus-front-end-monorepo/graphql';
import { humanize } from '@doctorus-front-end-monorepo/shared-util';
import { format } from 'date-fns';

export const carePlanEntityConfig = new EntityConfig<CarePlan>({
  //baseUri: 'medical_note/generatedDocuments/',
  feature: $localize`care plan`,
  plural: $localize`care plans`,
  title: (carePlan: CarePlan) => `${carePlan.name}`,
  description: (carePlan: CarePlan) =>
    carePlan.by &&
    carePlan.at &&
    $localize`created by ${humanize(carePlan.by, 'full')} on ${format(new Date(carePlan.at), 'dd-MM-yyyy')} at ${format(new Date(carePlan.at), 'HH:mm')}`,
});

export const medicalTaskConfig = new EntityConfig<MedicalTask>({
  //baseUri: 'medical_note/generatedDocuments/',
  feature: $localize`medical task`,
  title: (obj: MedicalTask) => `${obj?.id}`,
  description: (obj: MedicalTask) =>
    obj.by &&
    obj.at &&
    $localize`created by ${humanize(obj.by, 'full')} on ${format(new Date(obj.at), 'dd-MM-yyyy')} at ${format(new Date(obj.at), 'HH:mm')}`,
});
