import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'appointment-feature-appointment',
    imports: [CommonModule],
    templateUrl: './feature-appointment.component.html',
    styleUrl: './feature-appointment.component.scss'
})
export class FeatureAppointmentComponent {}
