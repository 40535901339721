import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';
import { featureCarePlanMedicalRecordRoutes } from '@doctorus-front-end-monorepo/feature-care-plan';
import {
  DetailsWrapperComponent,
  ENTITY_CONFIG,
  ENTITY_DETAILS_COMPONENT,
  ENTITY_WRITE_COMPONENT,
  EntityConfig,
  EntityMutationService,
  LAYOUT_CONFIG,
  WriteWrapperComponent,
} from '@doctorus-front-end-monorepo/feature-entity';
import { featureGeneratedDocumentRoutes } from '@doctorus-front-end-monorepo/feature-generated-document';
import { MEASURE_ROUTE } from '@doctorus-front-end-monorepo/feature-medical-measure';
import {
  MedicalNoteDetailsComponent,
  MedicalNoteFormComponent,
} from '@doctorus-front-end-monorepo/feature-patient';
import { featurePrescriptionRoutes } from '@doctorus-front-end-monorepo/feature-prescription';
import { featureUploadedDocumentRoutes } from '@doctorus-front-end-monorepo/feature-uploaded-document';
import {
  GetPatientMedicalRecordGQL,
  GetPatientMedicalRecordQuery,
  PutPatientMedicalNotesGQL,
  PutPatientMedicalPropertiesGQL,
} from '@doctorus-front-end-monorepo/graphql';
import { resolveParam } from '@doctorus-front-end-monorepo/util-navigation';
import { map } from 'rxjs';
import { BreadcrumbRouteData } from '../../../../../libs/shared/breadcrumb/src/lib/types';
import { MedicalDocumentsListComponent } from '../medical-documents-list/medical-documents-list.component';
import { MedicalRecordCenterBoardComponent } from '../medical-record/components/medical-record-center-board/medical-record-center-board.component';
import { MedicalRecordComponent } from '../medical-record/components/medical-record/medical-record.component';
import { MedicalTimelineComponent } from '../medical-record/components/medical-timeline/medical-timeline.component';

const patientMedicalRecordResolver: ResolveFn<
  GetPatientMedicalRecordQuery['getPatient']
> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const patientId = resolveParam('patId', route);
  return inject(GetPatientMedicalRecordGQL)
    .fetch({
      id: patientId,
    })
    .pipe(map(x => x.data.getPatient));
};

export const medicalRecordsRoutes: Routes = [
  {
    path: 'medical-record',
    component: MedicalRecordComponent,
    runGuardsAndResolvers: 'always',
    resolve: {
      patient: patientMedicalRecordResolver,
      obj: patientMedicalRecordResolver,
    },
    providers: [
      {
        provide: LAYOUT_CONFIG,
        useValue: { withBorder: false },
      },
      {
        provide: EntityMutationService,
        useClass: PutPatientMedicalPropertiesGQL,
      },
    ],
    data: {
      breadcrumbChunck: {
        name: $localize`medical record:{{patient.reference}}`,
      } as BreadcrumbRouteData,
    },

    children: [
      {
        path: '',
        outlet: 'center',
        data: {
          breadcrumbChunck: {
            name: null,
          } as BreadcrumbRouteData,
        },
        children: [
          {
            path: '',
            component: MedicalRecordCenterBoardComponent,
            data: {
              breadcrumbChunck: {
                name: null,
              } as BreadcrumbRouteData,
            },

            children: [
              {
                path: '',
                redirectTo: 'summary',
                pathMatch: 'full',
              },
              {
                path: 'summary',
                component: MedicalTimelineComponent,
              },
              MEASURE_ROUTE,
              featureCarePlanMedicalRecordRoutes,
              {
                path: 'documents',
                data: {
                  breadcrumbChunck: {
                    name: $localize`documents`,
                  } as BreadcrumbRouteData,
                },

                children: [
                  {
                    path: '',
                    component: MedicalDocumentsListComponent,
                    data: {
                      deleteRedirect: ['../..'],
                      breadcrumbChunck: {
                        name: null,
                      } as BreadcrumbRouteData,
                    },
                  },
                  featureGeneratedDocumentRoutes,
                  featureUploadedDocumentRoutes,
                  featurePrescriptionRoutes,
                ],
              },
              {
                path: 'medical-notes',
                providers: [
                  {
                    provide: LAYOUT_CONFIG,
                    useValue: {
                      canDelete: false,
                      withBorder: false,
                    },
                  },
                  {
                    provide: ENTITY_CONFIG,
                    useValue: new EntityConfig({
                      feature: $localize`medical notes`,
                    }),
                  },
                  {
                    provide: EntityMutationService,
                    useClass: PutPatientMedicalNotesGQL,
                  },
                  {
                    provide: ENTITY_DETAILS_COMPONENT,
                    useValue: MedicalNoteDetailsComponent,
                  },
                  {
                    provide: ENTITY_WRITE_COMPONENT,
                    useValue: MedicalNoteFormComponent,
                  },
                ],
                data: {
                  breadcrumbChunck: {
                    name: $localize`medical notes`,
                  },
                },
                children: [
                  {
                    path: '',
                    component: DetailsWrapperComponent,
                    data: {
                      breadcrumbChunck: {
                        name: null,
                      },
                    },
                  },
                  {
                    path: 'edit',
                    component: WriteWrapperComponent,
                    data: {
                      cancelRedirect: ['..'],

                      breadcrumbChunck: {
                        name: $localize`edit`,
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
