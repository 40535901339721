import { DatePipe, NgFor, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import {
  MatChipSelectionChange,
  MatChipsModule,
} from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Appointment } from '@doctorus-front-end-monorepo/graphql';
import { HumanNamePipe } from '@doctorus-front-end-monorepo/shared-util';
import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';
import { BaseEntityFormComponent } from '../../../../../feature-entity/src';

@Component({
  selector: 'appointment-fees-dialog',
  templateUrl: './fees-dialog.component.html',
  styleUrls: ['./fees-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    UiMatFormFieldErrorDisplayerDirective,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatChipsModule,
    MatAutocompleteModule,
    NgFor,
    MatOptionModule,
    MatButtonModule,
    TitleCasePipe,
    DatePipe,
    HumanNamePipe,
  ],
})
export class FeesDialogComponent extends BaseEntityFormComponent<
  Pick<Appointment, 'id' | 'fees'>
> {
  override initFormFun = () =>
    new FormGroup({
      id: new FormControl(this.obj()?.id),
      fees: new FormControl(this.obj()?.fees, [Validators.min(0)]),
    });

  updateTextField(event: MatChipSelectionChange): void {
    this.form.controls['fees'].setValue(event.source.value);
  }
  clear(): void {
    this.form.controls['fees'].reset();
  }
}
