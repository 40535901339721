<section class="grid grid-cols-3">
  <ui-layout-key-value key="name" i18n-name>
    {{obj().name}}
  </ui-layout-key-value>
  <ui-layout-key-value key="default duration" i18n-name>
    {{obj().default_duration}}
  </ui-layout-key-value>
  <ui-layout-key-value key="default fees" i18n-name>
    {{obj().default_fees}}
  </ui-layout-key-value>
</section>