import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'doctorus-front-end-monorepo-feature-patients-viewer',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, RouterModule],
    templateUrl: './feature-patients-viewer.component.html',
    styleUrl: './feature-patients-viewer.component.scss'
})
export class FeaturePatientsViewerComponent {}
