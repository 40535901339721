import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { Account } from '../../../../../../shared/data-access/graphql/src';

@Component({
  selector: 'account-settings-patient-settings',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './patient-settings.component.html',
  styleUrl: './patient-settings.component.scss',
})
export class PatientSettingsComponent {
  account = input.required<Account>();
}
