import * as dateFns from 'date-fns';
import { Temporal } from 'temporal-polyfill';

export const parseDuration = (input: string):dateFns.Duration => {
  return Temporal.Duration.from(input);
};
export const overlaps = (
  [a, b]: [string, string],
  [c, d]: [string, string],
): boolean =>
  dateFns.compareAsc(new Date(a), new Date(d)) === -1 &&
  dateFns.compareAsc(new Date(c), new Date(b)) === -1;

export const overlapsBy = (
  [a, b]: [string, string],
  [c, d]: [string, string],
  minutes: number,
): boolean =>
  Math.abs(dateFns.differenceInMinutes(new Date(a), new Date(c))) < minutes ||
  Math.abs(dateFns.differenceInMinutes(new Date(a), new Date(d))) < minutes;

export const formatStandardDuration = (duration: dateFns.Duration): string => {
  return duration.days
    ? duration.days?.toString()?.padStart(2, '0') + ' '
    : '' +
        `${duration.hours?.toString()?.padStart(2, '0') ?? '00'}:${
          duration.minutes?.toString()?.padStart(2, '0') ?? '00'
        }:${duration.seconds?.toString()?.padStart(2, '0') ?? '00'}`;
};

export const minutesToTime = (
  minutes: number,
  mode: 'time' | 'duration' = 'time',
): string =>
  `${Math.floor(minutes / 60)
    .toString()
    .padStart(2, '0')}${mode === 'time' ? ':' : 'h'}${Math.floor(minutes % 60)
    .toString()
    .padStart(2, '0')}${mode === 'time' ? '' : 'm'}`;
