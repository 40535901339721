<form class="flex flex-col gap-4" [formGroup]="form">
  <mat-form-field>
    <mat-label i18n>event date</mat-label>
    <input matInput [matDatepicker]="datepicker" formControlName="at">
    <mat-datepicker #datepicker/>
    <mat-datepicker-toggle [for]="datepicker" matSuffix/>
  </mat-form-field>
  
  <mat-form-field>
    <mat-label i18n>event time</mat-label>
    <input matInput
      [matTimepicker]="timepicker"
      formControlName="at">
    <mat-timepicker #timepicker/>
    <mat-timepicker-toggle [for]="timepicker" matSuffix/>
  </mat-form-field>
  

      <mat-form-field>
        <mat-label>notes</mat-label>
        <textarea matInput formControlName="notes" rows="3"></textarea>
      </mat-form-field>
</form>