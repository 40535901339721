import { Route } from '@angular/router';
import { BreadcrumbRouteData } from '@doctorus-front-end-monorepo/breadcrumb';
import {
  DetailsWrapperComponent,
  ENTITY_CONFIG,
  ENTITY_DETAILS_COMPONENT,
  ENTITY_WRITE_COMPONENT,
  EntityDeleteMutationService,
  LAYOUT_CONFIG,
  LayoutConfig,
  PutEntityService,
  WriteWrapperComponent,
} from '@doctorus-front-end-monorepo/feature-entity';
import {
  DeletePatientPaymentGQL,
  PutPatientPaymentGQL,
  resolveMedicalRecordItem,
} from '@doctorus-front-end-monorepo/graphql';
import { patientPaimentConfig } from './entity-config';
import { PatientPaymentDetailsComponent } from './patient-payment-details/patient-payment-details.component';
import { PatientPaymentListComponent } from './patient-payment-list/patient-payment-list.component';
import { WritePatientPaymentFormComponent } from './write-patient-payment-form/write-patient-payment-form.component';

export const featurePatientPaymentRoutes: Route = {
  path: 'payments',
  children: [
    {
      path: '',
      providers: [
        {
          provide: ENTITY_CONFIG,
          useValue: patientPaimentConfig,
        },
        {
          provide: EntityDeleteMutationService,
          useClass: DeletePatientPaymentGQL,
        },
        {
          provide: PutEntityService,
          useClass: PutPatientPaymentGQL,
        },
        {
          provide: ENTITY_DETAILS_COMPONENT,
          useValue: PatientPaymentDetailsComponent,
        },
        {
          provide: ENTITY_WRITE_COMPONENT,
          useValue: WritePatientPaymentFormComponent,
        },
      ],
      data: {
        breadcrumbChunck: {
          name: $localize`payments`,
        },
      },
      children: [
        {
          path: '',
          component: PatientPaymentListComponent,
          providers: [
            {
              provide: LAYOUT_CONFIG,
              useValue: { contentBorder: false } as LayoutConfig,
            },
          ],
          data: {
            breadcrumbChunck: {
              name: null,
            },
          },
        },
        {
          path: 'new',
          component: WriteWrapperComponent,
          data: {
            obj: null,
            breadcrumbChunck: {
              name: $localize`new`,
            } as BreadcrumbRouteData,
          },
        },
        {
          path: ':id',
          runGuardsAndResolvers: 'always',
          resolve: {
            obj: resolveMedicalRecordItem('payments'),
          },
          data: {
            breadcrumbChunck: {
              name: $localize`payment:{{obj.id}}`,
            } as BreadcrumbRouteData,
          },
          children: [
            {
              path: '',
              component: DetailsWrapperComponent,
              data: {
                deleteRedirect: ['..'],

                breadcrumbChunck: {
                  name: null,
                } as BreadcrumbRouteData,
              },
            },
            {
              path: 'edit',
              component: WriteWrapperComponent,
              data: {
                breadcrumbChunck: {
                  name: $localize`edit`,
                } as BreadcrumbRouteData,
              },
            },
          ],
        },
      ],
    },
  ],
  data: {
    breadcrumbChunck: { name: null },
  },
};
