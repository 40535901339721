<main class="container py-6">
  <ui-layout-container
    [description]="containerDescription()"
    border="false"
    [title]="title()"
  >
    <button ngProjectAs="actions" [routerLink]="['..', 'new']" mat-flat-button>
      new patient
    </button>

    <section class="flex justify-between flex-wrap gap-4 items-center">
      <mat-form-field class="max-w-sm w-full" subscriptSizing="dynamic">
        <mat-icon matPrefix>search</mat-icon>
        <input
          type="search"
          matInput
          placeholder="Search"
          [formControl]="searchCtrl"
        />
      </mat-form-field>
      @if (selectedType().type === 'profile') {
        <mat-slide-toggle
          [checked]="deletedUsers()"
          (change)="deletedUsers.set($event.checked)"
          >Show deleted users</mat-slide-toggle
        >
      }

      <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
        @for (searchType of patientSearchTypes; track searchType.type) {
          <mat-button-toggle
            (change)="this.selectedType.set(searchType)"
            [checked]="searchType.type === selectedType().type"
            >{{ searchType.label | titlecase }}</mat-button-toggle
          >
        }
      </mat-button-toggle-group>
    </section>
    <section class="flex justify-between flex-wrap gap-4">
      <mat-paginator
        [pageIndex]="pageIndex()"
        showFirstLastButtons
        [length]="count()"
        pageSize="50"
        class="flex-1"
        [hidePageSize]="true"
        (page)="pageIndex.set($event.pageIndex)"
      >
      </mat-paginator>
    </section>
    @if (selectedType().type === 'profile') {
      <doctorus-front-end-monorepo-active-patients-list
        [(pageIndex)]="pageIndex"
        [searchTerm]="searchTerm()"
        [(count)]="count"
        [deleted]="deletedUsers()"
        [pageSize]="pageSize()"
      ></doctorus-front-end-monorepo-active-patients-list>
    } @else {
      <doctorus-front-end-monorepo-patients-notes-explorer
        [(pageIndex)]="pageIndex"
        [searchTerm]="searchTerm()"
        [(count)]="count"
        [pageSize]="pageSize()"
      ></doctorus-front-end-monorepo-patients-notes-explorer>
    }
  </ui-layout-container>
</main>
