import { Route } from '@angular/router';
import {
  PutPrintLayoutGQL,
  resolveAccountSettingsCollection,
  resolveAccountSettingsItem,
} from '../../../../shared/data-access/graphql/src';
import {
  DetailsWrapperComponent,
  ENTITY_CONFIG,
  ENTITY_DETAILS_COMPONENT,
  ENTITY_LIST_COMPONENT,
  ENTITY_WRITE_COMPONENT,
  EntityConfig,
  EntityMutationService,
  ListWrapperComponent,
  WriteWrapperComponent,
} from '../../../../shared/feature-entity/src';
import { PrintLayoutDetailsComponent } from './print-layout-details/print-layout-details.component';
import { PrintLayoutFormComponent } from './print-layout-form/print-layout-form.component';
import { UiPrintLayoutComponent } from './ui-print-layout/ui-print-layout.component';

export const uiPrintLayoutRoutes: Route = {
  path: 'print-layouts',
  data: {
    breadcrumbChunck: { name: $localize`print layouts` },
  },
  providers: [
    {
      provide: ENTITY_CONFIG,
      useValue: new EntityConfig({ feature: $localize`print layout` }),
    },
    {
      provide: ENTITY_LIST_COMPONENT,
      useValue: UiPrintLayoutComponent,
    },
    {
      provide: ENTITY_WRITE_COMPONENT,
      useValue: PrintLayoutFormComponent,
    },
    {
      provide: EntityMutationService,
      useClass: PutPrintLayoutGQL,
    },
  ],
  children: [
    {
      path: '',
      component: ListWrapperComponent,
      resolve: {
        objs: resolveAccountSettingsCollection('printLayouts'),
      },
      data: {
        breadcrumbChunck: { name: null },
      },
    },
    {
      path: 'new',
      component: WriteWrapperComponent,
      data: {
        obj: null,
        breadcrumbChunck: { name: $localize`new` },
      },
    },
    {
      path: ':id',
      runGuardsAndResolvers: 'always',

      resolve: {
        obj: resolveAccountSettingsItem('printLayouts'),
      },
      data: {
        breadcrumbChunck: { name: $localize`print layout:{{obj.name}}` },
      },
      providers: [
        {
          provide: ENTITY_CONFIG,
          useValue: new EntityConfig({ feature: $localize`print layout` }),
        },
        {
          provide: ENTITY_DETAILS_COMPONENT,
          useValue: PrintLayoutDetailsComponent,
        },
      ],
      children: [
        {
          path: '',
          data: {
            breadcrumbChunck: { name: null },
          },
          component: DetailsWrapperComponent,
        },
        {
          path: 'edit',
          data: {
            breadcrumbChunck: { name: $localize`edit` },
          },
          component: WriteWrapperComponent,
        },
      ],
    },
  ],
};
