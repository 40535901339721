<div
  role="group"
  class="duration-input-container"
  [formGroup]="parts"
  [attr.aria-labelledby]="_formField?.getLabelId()"
  (focusin)="onFocusIn()"
  (focusout)="onFocusOut($event)"
>
  <input
    id="hours"
    (keyup.arrowright)="autoFocusNext(minutes)"
    (keyup.arrowleft)="autoFocusNext(minutes)"
    (keyup.backspace)="_eraseInput(parts.controls.hours, hours)"
    placeholder="--"
    class="duration-input-element"
    formControlName="hours"
    type="number"
    aria-label="Duration hours"
    (input)="_handleInput($any($event), parts.controls.hours, minutes)"
    #hours
  />
  <label for="hours" class="duration-input-spacer" i18n>:</label>
  <input
    id="minutes"
    (keyup.backspace)="_eraseInput(parts.controls.minutes, minutes)"
    (keyup.arrowright)="autoFocusNext(hours)"
    (keyup.arrowleft)="autoFocusNext(hours)"
    placeholder="--"
    class="duration-input-element"
    formControlName="minutes"
    type="number"
    aria-label="duration minutes"
    (input)="_handleInput($any($event), parts.controls.minutes)"
    #minutes
  />
  <!-- <label class="duration-input-spacer" i18n>{{ value }}</label> -->
</div>
