import {
  Appointment,
  MedicalHistory,
} from '@doctorus-front-end-monorepo/graphql';
import { Maybe } from 'graphql/jsutils/Maybe';

export type Human = {
  given_name: Maybe<string> | undefined;
  middle_name: Maybe<string> | undefined;
  family_name: Maybe<string> | undefined;
  gender: Maybe<string> | undefined;
};
export type NameOption = 'short' | 'full' | 'medium' | 'genderOnly';
export enum AppointmentStatus {
  ABSENT = 0,
  ON_PROGRESS = 1,
  DONE = 2,
  PENDING = 3,
}
export enum LogRecordAction {
  CREATION = 1,
  MODIFICATION = 2,
  DELETION = 3,
}
export enum StepStatus {
  PENDING = 0,
  DONE = 1,
}
export enum CarePlanStatus {
  PENDING = 0,
  APPROVED = 1,
  DENIED = 2,
  DONE = 3,
}
export enum MedicalHistoryStatus {
  CURRENT = 1,
  EXPIRED = 0,
}
export interface EnumDisplayConfig {
  color: string;
  bgColorClass: string;
  colorClass: string;
  label: string;
  shortLabel: string;
  svgIcon: string;
}
export type EntityStatusDef<
  T extends { [P in K]: number },
  K extends keyof T,
> = {
  [key: number]: EnumDisplayConfig;
};

export type StatusConf = Record<
  'appointment' | 'logRecord' | 'medicalHistory',
  EntityStatusDef<any, any>
>;

export const STATUS_CONF: StatusConf = {
  logRecord: {
    [LogRecordAction.DELETION]: {
      label: $localize`deletion`,
      shortLabel: $localize`deletion`,
      bgColorClass: 'bg-red-200',
      colorClass: 'text-red-900',
      color: 'red',
      svgIcon: 'delete',
    },

    [LogRecordAction.CREATION]: {
      label: $localize`creation`,
      shortLabel: $localize`creation`,
      bgColorClass: 'bg-lime-200',
      colorClass: 'text-lime-900',
      color: 'yellowgreen',
      svgIcon: 'add',
    },

    [LogRecordAction.MODIFICATION]: {
      label: $localize`modification`,
      shortLabel: $localize`modification`,
      bgColorClass: 'bg-blue-200',
      colorClass: 'text-blue-900',
      color: '#71cff5',
      svgIcon: 'edit',
    },
  },
  appointment: {
    [AppointmentStatus.ABSENT]: {
      label: $localize`absent`,
      shortLabel: $localize`absent`,
      bgColorClass: 'bg-red-200',
      colorClass: 'text-red-900',
      color: 'red',
      svgIcon: 'edit',
    },

    [AppointmentStatus.DONE]: {
      label: $localize`done`,
      shortLabel: $localize`done`,
      bgColorClass: 'bg-lime-200',
      colorClass: 'text-lime-900',
      color: 'yellowgreen',
      svgIcon: 'check_circle',
    },

    [AppointmentStatus.PENDING]: {
      label: $localize`pending`,
      shortLabel: $localize`pending`,
      bgColorClass: 'bg-blue-200',
      colorClass: 'text-blue-900',
      color: '#71cff5',
      svgIcon: 'pending',
    },

    [AppointmentStatus.ON_PROGRESS]: {
      label: $localize`on progress`,
      shortLabel: $localize`on progress`,
      bgColorClass: 'bg-amber-200',
      colorClass: 'text-amber-900',
      color: '#f0cd81',
      svgIcon: 'sync',
    },
  } as EntityStatusDef<Appointment, 'status'>,
  medicalHistory: {
    [MedicalHistoryStatus.CURRENT]: {
      label: $localize`active`,
      shortLabel: $localize`active`,
      bgColorClass: 'bg-lime-200',
      colorClass: 'text-lime-900',
      color: 'yellowgreen',
      svgIcon: 'check_circle',
    },
    [MedicalHistoryStatus.EXPIRED]: {
      label: $localize`expired`,
      shortLabel: $localize`expired`,
      bgColorClass: 'bg-grey-200',
      colorClass: 'text-grey-900',
      color: 'grey',
      svgIcon: 'do_not_disturb_on',
    },
  } as EntityStatusDef<MedicalHistory, 'status'>,
};
