import { CommonModule } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

import { MatIconModule } from '@angular/material/icon';
import { Patient } from '../../../../../data-access/graphql/src';
import { BaseEntityFormComponent } from '../../../../../feature-entity/src';
import { InlineFormArrayComponent } from '../../../../../form/inline-form-array/src';
import { KeyValueEditorComponent } from '../../../../../form/key-value-editor/src';
import { ContainerComponent } from '../../../../../ui-layout/src';
import { PatientFormBuilderService } from '../patient-form-builder.service';
import { PatientPropertyInlineFormComponent } from '../patient-property-inline-form/patient-property-inline-form.component';

@Component({
  selector: 'patient-write-patient-properties',
  standalone: true,
  imports: [
    CommonModule,
    KeyValueEditorComponent,
    ReactiveFormsModule,
    ContainerComponent,
    MatButtonModule,
    MatIconModule,
    MatButtonModule,
    InlineFormArrayComponent,
    PatientPropertyInlineFormComponent,
  ],
  templateUrl: './write-patient-properties.component.html',
  styleUrl: './write-patient-properties.component.scss',
})
export class WritePatientPropertiesComponent extends BaseEntityFormComponent<
  Pick<Patient, 'id' | 'medical_properties'>
> {
  /**
   *
   */
  private fbs = inject(PatientFormBuilderService);
  init = computed(() => this.extra().init);
  medicalProperties = this.obj()?.medical_properties;
  get propertiesTitle() {
    return $localize`medical properties (${this.obj()?.medical_properties?.length})`;
  }
  override initFormFun = () =>
    this.fb.group({
      id: [this.obj()?.id, Validators.required],
      medical_properties: this.fb.array([]),
    });

  createProperty = (payload?: any) => this.fbs.createPropertyForm(payload);
}
