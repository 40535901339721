<ng-template #menu>
  <header class="flex p-4 justify-between items-center">
    <h2 class="capitalize text-black" i18n>settings and preferences</h2>
  </header>
  <mat-divider></mat-divider>
  <ui-layout-nav-tree
    [authUserRoles]="roles$ | async"
    [navLinks]="links"
  ></ui-layout-nav-tree>
</ng-template>
<mat-toolbar>
  <button (click)="toggle()" mat-icon-button>
    <mat-icon>menu</mat-icon>
  </button>
  <lib-layout-breadcrumb
    class="text-sm"
    [breadcrumbs]="breadcrumbs$ | async"
  ></lib-layout-breadcrumb>
</mat-toolbar>
@if (isLarge$ | async) {
  <mat-sidenav-container class="h-full">
    <mat-sidenav #sidenav mode="side" fixedTopGap="120" opened fixedInViewport>
      <ng-container *ngTemplateOutlet="menu"></ng-container>
    </mat-sidenav>
    <mat-sidenav-content class="p-6">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
} @else {
  <div class="container py-6">
    <router-outlet></router-outlet>
  </div>
}
