import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BaseEntityFormComponent } from '@doctorus-front-end-monorepo/feature-entity';
import { Observation } from '@doctorus-front-end-monorepo/graphql';
import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';
import * as dateFns from 'date-fns';

@Component({
  selector: 'medical-measure-observation-dialog',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatButtonModule,
    UiMatFormFieldErrorDisplayerDirective,
    MatInputModule,
  ],
  templateUrl: './write-observation-dialog.component.html',
  styleUrls: ['./write-observation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WriteObservationDialogComponent extends BaseEntityFormComponent<Observation> {
  override initFormFun = () =>
    this.fb.group({
      id: [this.obj()?.id],
      date: [
        this.obj()?.date ??
          dateFns.formatISO(new Date(), { representation: 'date' }),
        Validators.required,
      ],
      value: [this.obj()?.value, Validators.required],
      measure: [this.extra().measure.id, Validators.required],
      note: [this.obj()?.note],
    });
}
