<header class="p-4 border-b border-b-gray-200 flex gap-2 sticky top-0">
  <button (click)="this.closeDialog()" mat-icon-button> <mat-icon>close</mat-icon>
  </button>
  <div>

    <h1 class="first-letter:uppercase">{{headerTitle}}</h1>
    <!-- <p class="text-gray-700">Apply the recommended max content area width and default panel states by setting
      contentType='form' in the app layout component.</p> -->

  </div>



</header>
@if (loading()) { <mat-progress-bar mode="query"></mat-progress-bar>
}
<main class="p-8 overflow-auto h-full">
  <ng-container #form [ngComponentOutletInputs]="inputs" [ngComponentOutlet]="$any(componentRef)"></ng-container>

</main>
<footer class="flex gap-2 mt-auto right-0 p-4 border-t border-t-gray-200 sticky bottom-0">
  <button type="button" (click)="this.closeDialog()" class="ml-auto" mat-button>
    <span class="capitalize" i18n>cancel</span>
  </button>
  <button [disabled]="loading()" type="button" (click)="submit()" color="primary" mat-flat-button>
    <span class="capitalize" i18n>submit</span>
  </button>
</footer>