import { CalendarView } from './types';
export const MONTH_VIEW: CalendarView = {
  type: 'month',
  label: $localize`month`,
  viewType: 'dayGridMonth',
  shortLabel: 'm',
  matIcon: 'calendar_view_month',
  dateFormat: 'MMM YYYY',
};
export const WEEK_VIEW: CalendarView = {
  type: 'week',
  label: $localize`week`,
  shortLabel: 'w',
  viewType: 'timeGridWeek',
  matIcon: 'calendar_view_week',
  dateFormat: 'mediumDate',
};
export const DAY_VIEW: CalendarView = {
  type: 'day',
  label: $localize`day`,
  shortLabel: 'd',
  viewType: 'timeGridDay',
  matIcon: 'calendar_view_day',
  dateFormat: 'mediumDate',
};
export const LIST_VIEW: CalendarView = {
  type: 'list',
  label: $localize`list`,
  shortLabel: 'l',
  viewType: 'listMonth',
  matIcon: 'view_list',
  dateFormat: 'mediumDate',
};
export const CALENDAR_VIEWS: CalendarView[] = [
  MONTH_VIEW,
  WEEK_VIEW,
  DAY_VIEW,
  LIST_VIEW,
];
