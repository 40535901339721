import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { validatePhoneNumberLength } from 'libphonenumber-js';
export function phoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const validation = validatePhoneNumberLength(control.value);
      if (validation) {
        return {
          [validation]: control.value,
        };
      }
    }
    return null;
  };
}
