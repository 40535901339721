import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Overlay } from '@angular/cdk/overlay';
import {
  AsyncPipe,
  DatePipe,
  NgFor,
  NgIf,
  TitleCasePipe,
  UpperCasePipe,
} from '@angular/common';
import {
  Component,
  Injector,
  OnDestroy,
  Signal,
  computed,
  signal,
} from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterLink } from '@angular/router';
import {
  AppointmentDialogsService,
  appointmentConfig,
} from '@doctorus-front-end-monorepo/feature-appointment';
import {
  Appointment,
  DeleteAppointmentGQL,
  GetAccountGQL,
  GetAppointmentsGQL,
  GetAppointmentsQuery,
  OnAppointmentUpdateGQL,
  PutAppointmentGQL,
  SetAppointmentStatusGQL,
  UpdateAppointmentSchedulesGQL,
} from '@doctorus-front-end-monorepo/graphql';
import { AppointmentStatus } from '@doctorus-front-end-monorepo/shared-type';
import {
  EntityDeleteMutationService,
  EntityDialogService,
} from '@doctorus-front-end-monorepo/ui-entity-dialog';
import {
  deleteFn,
  updateArrayFn,
  upsertArrayFn,
} from '@doctorus-front-end-monorepo/util-entity';
import { TimezoneOffsetPipe } from '@doctorus-front-end-monorepo/util-time';
import { FullCalendarModule } from '@fullcalendar/angular';
import { EventClickArg, EventDropArg } from '@fullcalendar/core';
import { DateClickArg, EventResizeDoneArg } from '@fullcalendar/interaction';
import * as dateFns from 'date-fns';
import {
  Observable,
  Subscription,
  debounceTime,
  finalize,
  map,
  shareReplay,
} from 'rxjs';
import { EntityMutationService } from '../../../../../shared/feature-entity/src';
import { AppointmentDetailDialogComponent } from '../appointment-detail-dialog/appointment-detail-dialog.component';
import { CalendarEventComponent } from '../calendar-event/calendar-event.component';
import { CalendarComponent } from '../calendar/calendar.component';
import { CALENDAR_VIEWS, MONTH_VIEW } from '../const';
import { ToEventPipe } from '../pipes/to-event.pipe';
import { SchedulerFilterComponent } from '../scheduler-filter/scheduler-filter.component';
import {
  CalendarEvent,
  CalendarView,
  EventDialogActionType,
  SchedulerFilterPayload,
} from '../types';
import { fromEventCalendar } from '../util-scheduler';
@Component({
    selector: 'medical-space-web-feature-scheduler',
    providers: [
        {
            provide: EntityDeleteMutationService,
            useExisting: DeleteAppointmentGQL,
        },
        { provide: EntityMutationService, useExisting: PutAppointmentGQL },
    ],
    imports: [
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        NgIf,
        MatMenuModule,
        NgFor,
        RouterLink,
        MatBadgeModule,
        TimezoneOffsetPipe,
        MatSidenavModule,
        DialogModule,
        SchedulerFilterComponent,
        SchedulerFilterComponent,
        MatProgressBarModule,
        FullCalendarModule,
        CalendarEventComponent,
        AsyncPipe,
        UpperCasePipe,
        TitleCasePipe,
        DatePipe,
        ToEventPipe,
        MatSnackBarModule,
        CalendarComponent,
    ],
    templateUrl: './feature-scheduler.component.html',
    styleUrl: './feature-scheduler.component.css'
})
export class FeatureSchedulerComponent implements OnDestroy {
  opened = true;
  calendarViews = CALENDAR_VIEWS;
  public filterdAppointments: Signal<GetAppointmentsQuery['getAppointments']>;
  public loading = signal(false);
  fetchedAppointments = signal<GetAppointmentsQuery['getAppointments']>([]);
  private subscription = new Subscription();
  selectedDate: Date;
  calendarView = MONTH_VIEW;
  schedulerFilter = signal<Partial<Partial<SchedulerFilterPayload>>>({});
  schedulerFilterCount = computed(
    () =>
      Object.values(this.schedulerFilter()).filter(
        x => x && Array.isArray(x) && x.length > 0,
      ).length,
  );
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  putEvent = (info: EventDropArg | EventResizeDoneArg): void => {
    this.updateAppointmentSchedulesGql
      .mutate({
        payload: {
          start: info.event.start,
          end: info.event.end,
          id: info.event.id,
        },
      })
      .subscribe(x => {
        this.updateCalendarAppointments(x.data?.updateAppointmentSchedules);
        this.matSnackBar.open($localize`Appointment schedule updated`);
      });
  };

  openAppointmentWriteForm = (info: DateClickArg): void => {
    if (window.innerWidth > 600) {
      this.writeAppointment({ date: info.date, timezone: this.timeZone });
    }
  };
  account$ = this.getAccountGQL
    .watch()
    .valueChanges.pipe(map(x => x.data.getAccount));

  isSmall$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(
      map(result => result.matches),
      shareReplay(),
    );

  isXSmall$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.XSmall])
    .pipe(map(result => result.matches));
  isXXSmall$: Observable<boolean> = this.breakpointObserver
    .observe(['(max-width: 400px)'])
    .pipe(map(result => result.matches));
  constructor(
    private dialog: Dialog,
    private setAppointmentStatusGQl: SetAppointmentStatusGQL,
    private injetor: Injector,
    private getAccountGQL: GetAccountGQL,
    private overlay: Overlay,
    private matSnackBar: MatSnackBar,
    private eds: EntityDialogService,
    private updateAppointmentSchedulesGql: UpdateAppointmentSchedulesGQL,
    private getAppointmentsGql: GetAppointmentsGQL,
    private breakpointObserver: BreakpointObserver,
    private ads: AppointmentDialogsService,
    private onAppointmentUpdateGQL: OnAppointmentUpdateGQL,
  ) {
    this.subscription.add(
      this.onAppointmentUpdateGQL
        .subscribe()
        .pipe(debounceTime(1000))
        .subscribe(() => this.fetch()),
    );
    // this.subscription.add(
    //   this.nths.listenAppointment().subscribe(x => {
    //     console.warn(x);
    //     if (x && x.metadata) {
    //       if (x.metadata?.action === ActionType.Delete) {
    //         this.deleteCalendarAppointment(x.id);
    //       } else {
    //         this.addToCalendarAppointments(x);
    //       }
    //     }
    //   }),
    // );
    // this.subscription.add(
    //   this.nths.listenToPatientAppointments().subscribe(patient => {
    //     if (patient) {
    //       patient?.appointments?.forEach(x =>
    //         this.addToCalendarAppointments(x),
    //       );
    //     }
    //   }),
    // );
    const d = new Date();
    d.setHours(0, 0, 0, 0);
    this.selectedDate = d;
    this.filterdAppointments = computed(() => {
      const appointments = this.fetchedAppointments();
      const params = this.schedulerFilter();
      const res = params
        ? appointments?.filter(appointment =>
            (Object.keys(params) as Array<keyof SchedulerFilterPayload>)
              .filter(
                key =>
                  appointment &&
                  Object.hasOwn(appointment, key) &&
                  Array.isArray(params[key]) &&
                  (params[key] as Array<any>).length > 0,
              )
              .every(
                key =>
                  appointment &&
                  (params[key] as Array<any>).includes(
                    appointment[key]['id']?.toString() ??
                      appointment[key]?.toString(),
                  ),
              ),
          )
        : appointments;

      return res;
    });
    this.fetch();
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
  today(): void {
    this.toDate(new Date());
  }
  toDate(date: Date): void {
    this.selectedDate = date;
    this.fetch();
  }
  changeDate(date: string | number | Date): void {
    this.toDate(new Date(date));
  }
  writeAppointment(
    args?: Partial<{
      appointment: Appointment;
      timezone: string;
      date: Date;
    }>,
  ): void {
    this.ads
      .openWriteForm(
        {
          ...(args?.appointment && {
            entity: args.appointment,
            patient: args.appointment.patient_info,
          }),
          date: args?.date ?? this.selectedDate,
        },
        this.injetor,
      )
      .subscribe(x => this.addToCalendarAppointments(x!));
  }

  switchView(view: CalendarView, withFetch = true): void {
    this.calendarView = view;
    if (withFetch) {
      this.fetch();
    }
  }
  navigate(direction: 'back' | 'next'): void {
    const step = direction === 'back' ? -1 : 1;
    const target = dateFns.formatISO(
      dateFns.add(this.selectedDate, {
        ...(this.calendarView.type === 'month' && { months: step }),
        ...(this.calendarView.type === 'list' && { months: step }),
        ...(this.calendarView.type === 'week' && { weeks: step }),
        ...(this.calendarView.type === 'day' && { days: step }),
      }),
      {
        representation: 'date',
      },
    );
    this.toDate(new Date(target));
  }

  updateSchedulerParams(payload: Partial<SchedulerFilterPayload>): void {
    this.schedulerFilter.set(payload);
  }

  openEventDetails = (info: EventClickArg): void => {
    this.dialog.closeAll();
    this.dialog
      .open<EventDialogActionType>(AppointmentDetailDialogComponent, {
        width: '360px',
        hasBackdrop: false,
        panelClass: ['drop-shadow-lg', 'rounded-md', 'p-4', 'bg-white'],
        positionStrategy: this.overlay

          .position()
          .flexibleConnectedTo(info.el)
          .withPositions([
            {
              originX: 'start',
              originY: 'bottom',
              overlayX: 'start',
              overlayY: 'top',
            },
          ]),
        data: {
          event: info.event,
          currentTimezone: this.timeZone,
        },
      })
      .closed.subscribe(x => {
        const appointment = fromEventCalendar(
          info.event as unknown as CalendarEvent,
        );
        switch (x?.type) {
          case 'EDIT': {
            this.writeAppointment({ appointment });
            break;
          }
          case 'DELETE': {
            this.eds
              .openEntityDeleteDialog(
                appointment,
                this.injetor,
                appointmentConfig,
              )
              .subscribe(x => {
                this.deleteCalendarAppointment(appointment.id);
              });
            break;
          }
          case 'STATUS': {
            this.setAppointmentStatusGQl
              .mutate({
                payload: {
                  id: appointment.id,
                  status: x.payload as AppointmentStatus,
                },
              })
              .subscribe(x => {
                this.matSnackBar.open($localize`Appointment status updated`);
                this.updateCalendarAppointments({
                  ...appointment,
                  ...x.data?.setAppointmentStatus,
                });
              });
            break;
          }
          default: {
            break;
          }
        }
      });
  };

  private updateCalendarAppointments(appointment: Partial<Appointment>): void {
    this.fetchedAppointments.update(value =>
      updateArrayFn([...(value ?? [])], appointment, 'id'),
    );
  }
  private addToCalendarAppointments(appointment: Appointment): void {
    this.fetchedAppointments.update(value => {
      const res = upsertArrayFn(value, appointment, 'id');
      return res;
    });
  }

  private deleteCalendarAppointment(id: string): void {
    this.fetchedAppointments.update(value => deleteFn(value, id, 'id'));
  }

  private fetch(): void {
    const year = this.selectedDate.getUTCFullYear();
    const week = dateFns.getISOWeek(this.selectedDate);
    const date = dateFns.formatISO(this.selectedDate, {
      representation: 'date',
    });
    let schedulerParams = {};
    switch (this.calendarView.type) {
      case 'month': {
        schedulerParams = {
          cmonth: date,
        };
        break;
      }
      case 'list': {
        schedulerParams = {
          cmonth: date,
        };
        break;
      }
      case 'week': {
        schedulerParams = {
          week,
          year,
        };
        break;
      }
      default: {
        schedulerParams = {
          day: date,
        };
        break;
      }
    }
    this.loading.set(true);
    this.getAppointmentsGql
      .fetch(schedulerParams, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
      })
      .pipe(finalize(() => this.loading.set(false)))
      .subscribe(x => {
        this.fetchedAppointments.set(x.data.getAppointments ?? []);
      });
  }
}
