<mat-select
  class="input-container h-fit w-full"
  (focusin)="onFocusIn($event)"
  (focusout)="onFocusOut($event)"
  [multiple]="multiple"
  [formControl]="selectionControl"
>
  <mat-select-trigger>
    <mat-chip-set>
      @for (item of selectedItems(); track $index) {
        @if (item) {
          <mat-chip
            >{{ displayFunc()(item) }}
            <mat-icon matChipRemove (click)="removeItem(fetchValue(item))"
              >cancel</mat-icon
            >
          </mat-chip>
        }
      }
    </mat-chip-set>
  </mat-select-trigger>
  <mat-option>
    <div class="flex justify-between items-center">
      <ngx-mat-select-search
        [placeholderLabel]="placeholderLabel"
        i18n-noEntriesFoundLabel
        [searching]="loading()"
        noEntriesFoundLabel="no matching object found"
        [formControl]="searchControl"
      >
      </ngx-mat-select-search>
    </div>
  </mat-option>
  @for (item of options().selected; track $index) {
    @if (item) {
      <mat-option [value]="fetchValue(item)">{{
        displayFunc()(item)
      }}</mat-option>
    }
  }
</mat-select>
