import {
  CommonModule,
  DOCUMENT,
  DatePipe,
  NgFor,
  NgIf,
  TitleCasePipe,
} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  QueryList,
  ViewChild,
  ViewChildren,
  computed,
  input,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { AppointmentDetailsComponent } from '@doctorus-front-end-monorepo/feature-appointment';
import { ObservationBoxComponent } from '@doctorus-front-end-monorepo/feature-medical-measure';
import { PrescriptionBoxComponent } from '@doctorus-front-end-monorepo/feature-prescription';
import { UploadDocumentBoxComponent } from '@doctorus-front-end-monorepo/feature-uploaded-document';
import {
  Appointment,
  GeneratedDocument,
  GetPatientMedicalRecordQuery,
  Measure,
  Observation,
  Prescription,
  UploadDocument,
} from '@doctorus-front-end-monorepo/graphql';
import { AppointmentStatus } from '@doctorus-front-end-monorepo/shared-type';
import {
  ContainerComponent,
  EmptyStateComponent,
  GroupHeaderComponent,
} from '@doctorus-front-end-monorepo/ui-layout';
import {
  ArrayExistPipe,
  ArrayFilterPipe,
  ArrayFindPipe,
} from '@doctorus-front-end-monorepo/util-array';
import * as dateFns from 'date-fns';
import { QuillViewComponent } from 'ngx-quill';
import {
  Observable,
  Subscription,
  TeardownLogic,
  distinctUntilChanged,
  filter,
  map,
  merge,
} from 'rxjs';
import { FileBoxComponent, IsImagePipe } from '@doctorus-front-end-monorepo/util-document';
type RecordsDef<K extends keyof PatientTimeLineRecords[string]> = {
  records: PatientTimeLineRecords[string][K];
  key: K;
  dateKey?: keyof PatientTimeLineRecords[string][K][number];
};
type PatientTimeLineRecords = {
  [key: string]: Partial<{
    documents: Array<
      | (UploadDocument & { type: 'UPLOADED' })
      | (GeneratedDocument & { type: 'GENERATED' })
    >;
    appointments: Array<Appointment>;
    prescriptions: Array<Prescription>;
    observations: Array<Observation & { measure: Measure }>;
  }>;
};
@Component({
    selector: 'doctorus-front-end-monorepo-medical-timeline',
    templateUrl: './medical-timeline.component.html',
    styleUrls: ['./medical-timeline.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgIf,
        MatButtonModule,
        MatIconModule,
        FileBoxComponent,
        CommonModule,
        MatMenuModule,
        IsImagePipe,
        MatChipsModule,
        NgFor,
        QuillViewComponent,
        ContainerComponent,
        UploadDocumentBoxComponent,
        ContainerComponent,
        EmptyStateComponent,
        ObservationBoxComponent,
        PrescriptionBoxComponent,
        AppointmentDetailsComponent,
        RouterLink,
        TitleCasePipe,
        GroupHeaderComponent,
        DatePipe,
        ArrayFindPipe,
        ArrayExistPipe,
        ArrayFilterPipe,
    ]
})
export class MedicalTimelineComponent implements AfterViewInit {
  patient = input.required<GetPatientMedicalRecordQuery['getPatient']>();
  appointmentStatus = AppointmentStatus;

  timeLineRecords = computed(() => {
    const obj = Object.entries(
      [
        {
          records: this.patient().appointments.filter(
            x => x.status === AppointmentStatus.DONE,
          ),
          key: 'appointments',
          dateKey: 'start',
        } as RecordsDef<'appointments'>,
        {
          records: this.patient()
            .measures.map(x =>
              x.observations.map(obs => ({ ...obs, measure: x })),
            )
            .flat(),
          key: 'observations',
          dateKey: 'date',
        } as RecordsDef<'observations'>,
        {
          records: this.patient().prescriptions,
          key: 'prescriptions',
          dateKey: 'date',
        } as RecordsDef<'prescriptions'>,
        {
          records: [
            ...this.patient().upload_documents.map(x => ({
              ...x,
              type: 'UPLOADED',
            })),
            ...this.patient().generated_documents.map(x => ({
              ...x,
              type: 'GENERATED',
            })),
          ],
          key: 'documents',
          dateKey: 'date',
        } as RecordsDef<'documents'>,
      ].reduce(
        (prev: PatientTimeLineRecords, current) =>
          Object.assign(
            current.records.reduce(
              (acc, record) =>
                this.updateTimeLine(
                  acc,
                  record,
                  current.key,
                  current.dateKey as any,
                ),
              {},
            ),
            prev,
          ),
        {},
      ),
    ).sort((a, b) => this.sort(a[0], b[0], this.order()));
    return obj;
  });
  private updateTimeLine<K extends keyof PatientTimeLineRecords[string]>(
    acc: PatientTimeLineRecords,
    record: PatientTimeLineRecords[string][K][number],
    key: K,
    dateKey?: keyof PatientTimeLineRecords[string][K][number],
  ) {
    console.warn(key);
    const date = dateFns.formatISO(new Date(record[dateKey] as string), {
      representation: 'date',
    });
    console.warn(date);
    console.warn(acc);
    const obj = {
      ...acc,
      [date]: {
        ...(acc[date] ?? {}),
        [key]: [record].concat(acc[date] ? acc[date][key] : []),
      },
    };
    console.warn(obj);
    return obj;
  }
  medicalRecordsDates = computed(() => {
    return Object.entries(
      this.timeLineRecords()
        .map(x => x[0])
        .reduce((acc: { [key: string]: string[] }, current: string) => {
          const _key = dateFns.formatISO(new Date(current).setDate(1), {
            representation: 'date',
          });
          return {
            ...acc,
            [_key]: [current].concat(acc[_key] ?? []),
          };
        }, {}),
    )
      .sort((a, b) => this.sort(a[0], b[0], this.order()))
      .map(x => [x[0], x[1].sort((a, b) => this.sort(a, b, this.order()))]) as [
      string,
      string[],
    ][];
  });

  private sort = (a: string, b: string, order: 'desc' | 'asc') =>
    dateFns[order === 'asc' ? 'compareAsc' : 'compareDesc'](
      new Date(a),
      new Date(b),
    );

  month = computed(() => this.medicalRecordsDates().map(x => x[0]));
  //months$ = this.medicalRecordsDateMap$.pipe(map((x) => x[0]));
  box = false;
  order = signal<'asc' | 'desc'>('desc');
  @ViewChild('listLane', { read: ElementRef })
  listElContainer: ElementRef<HTMLElement>;
  @ViewChildren('listEl', { read: ElementRef })
  listElements: QueryList<ElementRef<HTMLElement>>;
  subscription = new Subscription();
  constructor(@Inject(DOCUMENT) private document: Document) {}
  ngAfterViewInit(): void {
    merge(this.listElements.map(x => this.createAndObserve(x)))
      .pipe(filter((entry: any) => entry.isVisible))
      .subscribe();
  }

  scrollTo(id: string): void {
    this.document.getElementById(id)?.scrollIntoView({
      behavior: 'smooth',
    });
  }

  toggleOrdering(order: 'asc' | 'desc'): void {
    this.order.set(order);
  }
  get togglerIcon(): string {
    return this.box ? 'view_list' : 'grid_view';
  }

  private createAndObserve(
    element: ElementRef<HTMLElement>,
  ): Observable<IntersectionObserverEntry> {
    return new Observable((observer): TeardownLogic => {
      try {
        const intersectionObserver = new IntersectionObserver(
          entries => {
            observer.next(entries);
          },
          {
            root: this.listElContainer.nativeElement,
          },
        );

        intersectionObserver.observe(element.nativeElement);
        return function unsubscribe() {
          intersectionObserver.disconnect();
        };
      } catch (err) {
        observer.error(err);
      }
    }).pipe(
      map((entries: any) => entries),
      distinctUntilChanged(),
    );
  }
}
