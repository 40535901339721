import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { PrintLayout } from '../../../../../shared/data-access/graphql/src';
import { BaseEntityDetailsComponent } from '../../../../../shared/feature-entity/src';
import {
  ContainerComponent,
  KeyValueComponent,
} from '../../../../../shared/ui-layout/src';

@Component({
  selector: 'lib-print-layout-details',
  standalone: true,
  imports: [CommonModule, KeyValueComponent, ContainerComponent],
  templateUrl: './print-layout-details.component.html',
  styleUrl: './print-layout-details.component.scss',
})
export class PrintLayoutDetailsComponent extends BaseEntityDetailsComponent<PrintLayout> {
  marginProps: Array<keyof PrintLayout> = [
    'topMargin',
    'rightMargin',
    'bottomMargin',
    'leftMargin',
  ];
}
