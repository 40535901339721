<form class="flex flex-col gap-6" [formGroup]="form">
  <mat-form-field uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>name</mat-label>
    <input type="text" formControlName="name" matInput>
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n>notes</mat-label>
    <textarea matInput formControlName="notes" cols="30" rows="2"></textarea>
  </mat-form-field>
  <h3 i18n class="first-letter:uppercase">location</h3>
  <mat-form-field uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>location</mat-label>
    <mat-select formControlName="location">
      @for (location of account?.locations; track $index) {
      <mat-option [value]="location.id">{{location.name}}</mat-option>

      }
    </mat-select>
    <mat-error></mat-error>
  </mat-form-field>
  @if (hasPatientField()) {
    <h3 i18n class="first-letter:uppercase">patient</h3>
    <mat-form-field class="col-span-full" uiFormMatFormFieldErrorDisplayer>
      <mat-label i18n>patient</mat-label>
      <mat-icon matPrefix>person</mat-icon>
      <mat-select formControlName="patient" placeholder="patient">
        <mat-option>
          <div class="fles justify-between items-center">
            <ngx-mat-select-search [searching]="patientsLoading()" i18n-placeholderLabel
              placeholderLabel="search by reference, name, phone number or email" i18n-noEntriesFoundLabel
              noEntriesFoundLabel="no matching patient found" [formControl]="patientFilterCtrl">
            </ngx-mat-select-search>
          </div>
        </mat-option>
        <ng-container *ngFor="let patient of filtredPatients()">
          <mat-option *ngIf="patientsLoading() === false" [value]="patient.id">
            <span>{{ patient | humanName:'full':true }}</span>&nbsp;
            <span>{{ patient?.reference }}</span>
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  }
  <h3 i18n class="first-letter:uppercase">period</h3>
  <h4 i18n class="first-letter:uppercase">from</h4>
  <div class="flex gap-2 flex-wrap">

    <mat-form-field class="flex-1">
      <mat-label i18n>start date</mat-label>
      <input matInput [matDatepicker]="datepickerStart" formControlName="start">
      <mat-datepicker #datepickerStart/>
      <mat-datepicker-toggle [for]="datepickerStart" matSuffix/>
    </mat-form-field>
    
    <mat-form-field class="flex-1">
      <mat-label i18n>start time</mat-label>
      <input matInput
        [matTimepicker]="timepickerStart"
        formControlName="start">
      <mat-timepicker #timepickerStart/>
      <mat-timepicker-toggle [for]="timepickerStart" matSuffix/>
    </mat-form-field>
    </div>
    <h4 i18n class="first-letter:uppercase">to</h4>

    <div class="flex gap-2 flex-wrap">

      <mat-form-field class="flex-1">
        <mat-label i18n>end date</mat-label>
        <input matInput [matDatepicker]="datepickerEnd" formControlName="end">
        <mat-datepicker #datepickerEnd/>
        <mat-datepicker-toggle [for]="datepickerEnd" matSuffix/>
      </mat-form-field>
      
      <mat-form-field class="flex-1">
        <mat-label i18n>end time</mat-label>
        <input matInput
          [matTimepicker]="timepickerEnd"
          formControlName="end">
        <mat-timepicker #timepickerEnd/>
        <mat-timepicker-toggle [for]="timepickerEnd" matSuffix/>
      </mat-form-field>
      </div>

  <h3 i18n class="first-letter:uppercase">frequency</h3>
  <mat-form-field uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>occurences by day</mat-label>
    <input type="number" formControlName="frequency" matInput>
    <mat-error></mat-error>
    <mat-hint i18n>how many times a day must the medical tasks be perfomed</mat-hint>
  </mat-form-field>

  <h3 i18n class="first-letter:uppercase">contributors</h3>
  <mat-form-field uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>users</mat-label>
    <mat-select multiple="true" formControlName="contributors">
      @for (member of account?.memberships; track $index) {
      <mat-option [value]="member.user.sub">{{member.user|humanName:'full'}}</mat-option>

      }
    </mat-select>
    <mat-error></mat-error>
  </mat-form-field>

</form>
