import {
  CommonModule,
  DOCUMENT,
  DatePipe,
  NgFor,
  TitleCasePipe,
} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  QueryList,
  ViewChild,
  ViewChildren,
  computed,
  inject,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import {
  Appointment,
  CarePlan,
  GeneratedDocument,
  Measure,
  MedicalTask,
  Observation,
  PatientDataService,
  Prescription,
  UploadDocument,
} from '@doctorus-front-end-monorepo/graphql';
import {
  AppointmentStatus,
  HumanNamePipe,
} from '@doctorus-front-end-monorepo/shared-util';
import {
  ContainerComponent,
  EmptyStateComponent,
} from '@doctorus-front-end-monorepo/ui-layout';
import { ArrayExistPipe } from '@doctorus-front-end-monorepo/util-array';
import {
  FileBoxComponent,
  IsImagePipe,
} from '@doctorus-front-end-monorepo/util-document';
import { CoalescePipe } from '@doctorus-front-end-monorepo/util-formatting';
import * as dateFns from 'date-fns';
import { isNil } from 'lodash';
import { QuillViewComponent } from 'ngx-quill';
import {
  Observable,
  Subscription,
  TeardownLogic,
  distinctUntilChanged,
  filter,
  map,
  merge,
} from 'rxjs';

type ArrayElement<T> = T extends readonly any[] ? T[number] : never;

type RecordsDef<K extends keyof PatientTimeLineRecords[string]> = {
  records: PatientTimeLineRecords[string][K];
  key: K;
  dateKey?: keyof ArrayElement<PatientTimeLineRecords[string][K]>;
};
type PatientTimeLineRecords = {
  [date: string]: Partial<{
    // documents: Array<
    //   | (UploadDocument & { type: 'UPLOADED' })
    //   | (GeneratedDocument & { type: 'GENERATED' })
    // >;
    medical_tasks: Array<MedicalTask & { care_plan: CarePlan }>;
    uploaded_documents: Array<UploadDocument>;
    generated_documents: Array<GeneratedDocument>;
    appointments: Array<Appointment>;
    prescriptions: Array<Prescription>;
    observations: Array<Observation & { measure: Measure }>;
  }>;
};
@Component({
  selector: 'doctorus-front-end-monorepo-medical-timeline',
  templateUrl: './medical-timeline.component.html',
  styleUrls: ['./medical-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    EmptyStateComponent,
    MatButtonModule,
    CoalescePipe,
    MatIconModule,
    FileBoxComponent,
    CommonModule,
    MatMenuModule,
    IsImagePipe,
    MatChipsModule,
    NgFor,
    QuillViewComponent,
    HumanNamePipe,
    ContainerComponent,
    RouterLink,
    TitleCasePipe,
    DatePipe,
    ArrayExistPipe,
  ],
})
export class MedicalTimelineComponent implements AfterViewInit {
  patient = toSignal(inject(PatientDataService).selectedPatient$);
  appointmentStatus = AppointmentStatus;

  timeLineRecords = computed(() => {
    return !isNil(this.patient())
      ? Object.entries(
          [
            {
              records: this.patient()!.appointments.filter(
                x => x.status === AppointmentStatus.DONE,
              ),
              key: 'appointments',
              dateKey: 'start',
            },
            {
              records: this.patient()!
                .measures.map(
                  x =>
                    x.observations?.map(obs => ({ ...obs, measure: x })) ?? [],
                )
                .flat(),
              key: 'observations',
              dateKey: 'date',
            },
            {
              records: this.patient()!.prescriptions,
              key: 'prescriptions',
              dateKey: 'date',
            },
            {
              records: this.patient()!.generated_documents,
              key: 'generated_documents',
              dateKey: 'date',
            },
            {
              records: this.patient()!.upload_documents,
              key: 'uploaded_documents',
              dateKey: 'date',
            },
            {
              records: this.patient()!
                .care_plans.map(
                  x =>
                    x.medical_tasks?.map(obs => ({ ...obs, care_plan: x })) ??
                    [],
                )
                .flat(),
              key: 'medical_tasks',
              dateKey: 'at',
            },
          ].reduce(
            (prev: PatientTimeLineRecords, current) =>
              Object.assign(
                current.records.reduce(
                  (acc, record: any) =>
                    this.updateTimeLine(
                      acc,
                      record,
                      current.key as
                        | 'prescriptions'
                        | 'generated_documents'
                        | 'uploaded_documents'
                        | 'medical_tasks'
                        | 'appointments'
                        | 'observations',
                      current.dateKey as any,
                    ),
                  {},
                ),
                prev,
              ),
            {},
          ),
        ).sort((a, b) => this.sort(a[0], b[0], this.order()))
      : [];
  });
  private updateTimeLine<K extends keyof PatientTimeLineRecords[string]>(
    acc: PatientTimeLineRecords,
    record: ArrayElement<PatientTimeLineRecords[string][K]>,
    key: K,
    dateKey: keyof ArrayElement<PatientTimeLineRecords[string][K]>,
  ) {
    console.warn(key);
    const date = dateFns.formatISO(new Date(record[dateKey] as string), {
      representation: 'date',
    });
    console.warn(date);
    const obj = {
      ...acc,
      [date]: {
        ...(acc[date] ?? {}),
        [key]: [record].concat(acc[date] ? (acc[date][key] as Array<any>) : []),
      },
    };
    console.warn(obj);
    return obj;
  }
  medicalRecordsDates = computed(() => {
    return Object.entries(
      this.timeLineRecords()
        .map(x => x[0])
        .reduce((acc: { [key: string]: string[] }, current: string) => {
          const _key = dateFns.formatISO(new Date(current).setDate(1), {
            representation: 'date',
          });
          return {
            ...acc,
            [_key]: [current].concat(acc[_key] ?? []),
          };
        }, {}),
    )
      .sort((a, b) => this.sort(a[0], b[0], this.order()))
      .map(x => [x[0], x[1].sort((a, b) => this.sort(a, b, this.order()))]) as [
      string,
      string[],
    ][];
  });

  private sort = (a: string, b: string, order: 'desc' | 'asc') =>
    dateFns[order === 'asc' ? 'compareAsc' : 'compareDesc'](
      new Date(a),
      new Date(b),
    );

  month = computed(() => this.medicalRecordsDates().map(x => x[0]));
  //months$ = this.medicalRecordsDateMap$.pipe(map((x) => x[0]));
  box = false;
  order = signal<'asc' | 'desc'>('desc');
  @ViewChild('listLane', { read: ElementRef })
  listElContainer!: ElementRef<HTMLElement>;
  @ViewChildren('listEl', { read: ElementRef })
  listElements!: QueryList<ElementRef<HTMLElement>>;
  subscription = new Subscription();
  constructor(@Inject(DOCUMENT) private document: Document) {}
  ngAfterViewInit(): void {
    merge(this.listElements.map(x => this.createAndObserve(x)))
      .pipe(filter((entry: any) => entry.isVisible))
      .subscribe();
  }

  scrollTo(id: string): void {
    this.document.getElementById(id)?.scrollIntoView({
      behavior: 'smooth',
    });
  }

  toggleOrdering(order: 'asc' | 'desc'): void {
    this.order.set(order);
  }
  get togglerIcon(): string {
    return this.box ? 'view_list' : 'grid_view';
  }

  private createAndObserve(
    element: ElementRef<HTMLElement>,
  ): Observable<IntersectionObserverEntry> {
    return new Observable((observer): TeardownLogic => {
      try {
        const intersectionObserver = new IntersectionObserver(
          entries => {
            observer.next(entries);
          },
          {
            root: this.listElContainer.nativeElement,
          },
        );

        intersectionObserver.observe(element.nativeElement);
        return function unsubscribe() {
          intersectionObserver.disconnect();
        };
      } catch (err) {
        observer.error(err);
      }
    }).pipe(
      map((entries: any) => entries),
      distinctUntilChanged(),
    );
  }
}
