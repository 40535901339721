import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import {
  ControlContainer,
  FormArray,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { UiMatFormFieldErrorDisplayerDirective } from '../../../../../shared/form/ui-form/src';
import { TimePipe } from '../../../../../shared/util-time/src';

@Component({
    selector: 'lib-working-period-inline-form',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        TimePipe,
        UiMatFormFieldErrorDisplayerDirective,
    ],
    viewProviders: [
        {
            provide: ControlContainer,
            useFactory: () => inject(ControlContainer, { skipSelf: true }),
        },
    ],
    templateUrl: './working-period-inline-form.component.html',
    styleUrl: './working-period-inline-form.component.scss'
})
export class WorkingPeriodInlineFormComponent {
  @Input({ required: true }) index!: number;
  @Input({ required: true }) formArrayKey!: string;

  parentContainer = inject(ControlContainer);

  get formGroup() {
    return (
      this.parentContainer.control?.get(this.formArrayKey) as FormArray
    ).at(this.index) as FormGroup;
  }

  dayTimes = Array.from({ length: 49 }, (_, index) => index * 30);
}
