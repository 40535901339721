import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';
import { isEqual } from 'lodash';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { debounceTime, filter, map, Observable, startWith } from 'rxjs';

@Component({
  selector: 'lib-generic-mat-select-search',
  imports: [
    CommonModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatIconModule,
    ReactiveFormsModule,
    UiMatFormFieldErrorDisplayerDirective,
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
  templateUrl: './generic-mat-select-search.component.html',
  styleUrl: './generic-mat-select-search.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericMatSelectSearchComponent<
    K extends PropertyKey,
    T extends { [prop in K]: string },
  >
  implements OnInit, OnDestroy
{
  @Input({ required: true }) controlKey = '';
  @Input() label?: string;
  @Input() searchPlaceholderLabel = '';
  @Input() noEntriesFoundLabel = '';
  @Input() allOptions: T[] = [];
  @Input() valueProp?: K;
  @Input() prefixIcon?: string;
  @Input({ transform: booleanAttribute }) multiple = false;
  @Input() compareWith: (o1: T, o2: T) => boolean = isEqual;
  @Input() displayWith: (value: T) => string = value => value.toString();
  @Input() filterWith: (obj: T, term: string) => boolean = (obj, term) =>
    (obj as any) === term;
  parentContainer = inject(ControlContainer);
  searchControl = new FormControl('');
  get parentFormGroup() {
    return this.parentContainer.control as FormGroup;
  }
  filtredOptions$!: Observable<T[]>;
  ngOnInit() {
    this.filtredOptions$ = this.searchControl.valueChanges.pipe(
      startWith(''),
      debounceTime(200),
      filter(term => !!term),
      map(term =>
        term
          ? this.allOptions?.filter(_opt => this.filterWith(_opt, term!))
          : this.allOptions,
      ),
    );
  }
  ngOnDestroy() {
    this.parentFormGroup.removeControl(this.controlKey);
  }

  extractValue = (option: T) =>
    this.valueProp ? option[this.valueProp] : option;
}
