import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseJson',
  standalone: true,
})
export class ParseJsonPipe implements PipeTransform {
  transform(value: string): { [key: string]: any } {
    return JSON.parse(value);
  }
}
