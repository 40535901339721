import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {
  NO_AUTHORIZATION_DNS_LIST = [
    'stoma-media.s3.amazonaws.com',
    'yhph57qw9k.execute-api',
  ];

  constructor(@Inject(LOCALE_ID) private localId: string) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.NO_AUTHORIZATION_DNS_LIST.some((dns) =>
      request.url.includes(dns)
    )
      ? next.handle(request)
      : next.handle(
          request.clone({
            setHeaders: {
              'Cache-Control': 'no-cache',
              'Accept-Language': this.localId,
            },
          })
        );
  }
}
