import { Directive, HostListener } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Directive({
  selector: '[doctorusFrontEndMonorepoTimeZonedInput]',
  standalone: true,
})
export class TimeZonedInputDirective implements ControlValueAccessor {
  constructor(private control: NgControl) {} //<--inject NgControl
  onChange = (a: any) => {};
  onTouched = () => {};

  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  // setDisabledState?(isDisabled: boolean): void {
  //   throw new Error('Method not implemented.');
  // }

  @HostListener('input', ['$event'])
  onInputChange() {
    console.warn(this.control.value);
    console.warn(typeof this.control.value);
    console.warn(new Date(this.control.value));
    console.warn(new Date(this.control.value).toISOString());
    this.onChange(new Date(this.control.value).toISOString());
    //this.control.control?.setValue(new Date(this.control.value).toUTCString());
  }
}
