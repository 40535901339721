import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'lib-feature-care-plan',
    imports: [CommonModule, RouterModule, MatSidenavModule, MatListModule],
    templateUrl: './feature-care-plan.component.html',
    styleUrl: './feature-care-plan.component.scss'
})
export class FeatureCarePlanComponent {}
