import { CommonModule, CurrencyPipe } from '@angular/common';
import {
  Component,
  computed,
  inject,
  Injector,
  LOCALE_ID,
  ViewContainerRef,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterModule } from '@angular/router';
import { AccountRole, AuthService } from '@doctorus-front-end-monorepo/auth';
import {
  EntityConfig,
  EntityDialogService,
  PutEntityService,
} from '@doctorus-front-end-monorepo/feature-entity';
import {
  patientConfig,
  WritePatientPropertiesComponent,
  WritePatientPublicPropertiesComponent,
} from '@doctorus-front-end-monorepo/feature-patient';
import {
  AccountDataService,
  CorePatientFieldsFragment,
  Patient,
  PatientDataService,
  PutPatientMedicalPropertiesGQL,
  PutPatientPublicPropertiesGQL,
} from '@doctorus-front-end-monorepo/graphql';
import {
  AgePipe,
  AppointmentStatus,
} from '@doctorus-front-end-monorepo/shared-util';
import {
  IFormComponent,
  SlideOutPanelService,
} from '@doctorus-front-end-monorepo/slide-out-panel';
import {
  ContainerComponent,
  EmptyStateComponent,
  FeedbackComponent,
  KeyValueComponent,
  MessageType,
} from '@doctorus-front-end-monorepo/ui-layout';

import {
  AccountCurrencyPipe,
  CoalescePipe,
  PhoneNumberPipe,
} from '@doctorus-front-end-monorepo/util-formatting';
import { RouterNavigationHelperService } from '@doctorus-front-end-monorepo/util-navigation';
import { DurationPipe } from '@doctorus-front-end-monorepo/util-time';
import { compareAsc, isBefore } from 'date-fns';
import { isNil } from 'lodash';
import { map } from 'rxjs';

@Component({
  selector: 'doctorus-front-end-monorepo-patient-dashboard',
  templateUrl: './patient-dashboard.component.html',
  styleUrl: './patient-dashboard.component.css',
  imports: [
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    RouterModule,
    EmptyStateComponent,
    MatButtonModule,
    MatToolbarModule,
    AccountCurrencyPipe,
    FeedbackComponent,
    AgePipe,
    DurationPipe,
    CoalescePipe,
    MatIconModule,
    CommonModule,
    ContainerComponent,
    PhoneNumberPipe,
    KeyValueComponent,
  ],
})
export class PatientDashboardComponent {
  private pds = inject(PatientDataService);
  patient = toSignal(this.pds.selectedPatient$);

  isDoctor$ = inject(AuthService).user$.pipe(
    map(_user => _user?.['cognito:groups']?.includes(AccountRole.DOCTOR)),
  );
  currency = inject(AccountDataService).account?.currency;
  localeId = inject(LOCALE_ID);
  currencyPipe = new CurrencyPipe(this.localeId);
  isDoctor = inject(AuthService).isAuthUserDoctor;

  nextAppointment = computed(() =>
    this.patient()
      ?.appointments.filter(app => app.status === AppointmentStatus.DONE)
      .sort((a, b) => compareAsc(a.start, b.start))
      .shift(),
  );
  private rhns = inject(RouterNavigationHelperService);
  private router = inject(Router);

  missingMedicalHistoryChecks = toSignal(
    this.pds.missingMedicalHistoryChecks$,
    { initialValue: [] },
  );
  missingMedicalMeasureChecks = toSignal(this.pds.missingMeasureChecks$, {
    initialValue: [],
  });
  outdatedPendingAppointments = computed(
    () =>
      this.patient()?.appointments.filter(
        _app =>
          _app?.status === AppointmentStatus.PENDING &&
          isBefore(_app.end ?? new Date(), new Date()),
      ) ?? [],
  );
  paymentStatus = toSignal(this.pds.paymentStatus$, {
    initialValue: { total_due: 0, total_paid: 0 },
  });

  feeds = computed(() =>
    !isNil(this.patient())
      ? [
          {
            condition: this.outdatedPendingAppointments().length > 0,
            message: $localize`${this.outdatedPendingAppointments().length} outdated appointments`,
            type: MessageType.INFO,
          },
          {
            condition: this.paymentStatus().total_due < 0,
            message: $localize`${this.currencyPipe.transform(Math.abs(this.paymentStatus()?.total_due ?? 0), this.currency ?? 'EUR', 'symbol', '1.0-1')} due`,
            type: MessageType.WARNING,
          },
          {
            condition:
              this.paymentStatus() && this.paymentStatus().total_due > 0,
            message: $localize`${this.currencyPipe.transform(this.paymentStatus()?.total_due, this.currency ?? 'EUR', 'symbol', '1.0-1')} advance`,
            type: MessageType.INFO,
          },
          {
            condition:
              this.isDoctor &&
              this.missingMedicalHistoryChecks() &&
              this.missingMedicalHistoryChecks().length > 0,
            message: $localize`${this.missingMedicalHistoryChecks().length} missing medical history checks`,
            type: MessageType.WARNING,
          },
          {
            condition:
              this.isDoctor && this.missingMedicalMeasureChecks().length > 0,
            message: $localize`${this.missingMedicalMeasureChecks().length} missing measure checks`,
            type: MessageType.WARNING,
          },
        ].filter(feed => feed.condition)
      : [],
  );

  lastAppointment = computed(() =>
    this.patient()
      ?.appointments.filter(
        app =>
          app.status === AppointmentStatus.DONE &&
          isBefore(app.end, new Date()),
      )
      .sort((a, b) => compareAsc(a.start, b.start))
      .shift(),
  );

  private injector = inject(Injector);
  private vcr = inject(ViewContainerRef);

  private medicalPropertiesInjector = Injector.create({
    parent: this.injector,
    providers: [
      { provide: PutEntityService, useClass: PutPatientMedicalPropertiesGQL },
    ],
  });

  private PublicPropertiesInjector = Injector.create({
    parent: this.injector,
    providers: [
      { provide: PutEntityService, useClass: PutPatientPublicPropertiesGQL },
    ],
  });

  private sds = inject(SlideOutPanelService);
  private eds = inject(EntityDialogService);
  private account = inject(AccountDataService).account;

  deletePatient(): void {
    this.eds
      .openEntityDeleteDialog(
        this.patient(),
        this.injector,
        patientConfig,
        this.vcr,
      )
      .subscribe(() => this.router.navigate(this.rhns.getPatientListRoute()));
  }

  editProperties(medical = false, patient: CorePatientFieldsFragment): void {
    this.sds
      .openSlideOutEntityWriteForm<
        CorePatientFieldsFragment,
        IFormComponent,
        Patient
      >(
        {
          cmp: medical
            ? WritePatientPropertiesComponent
            : WritePatientPublicPropertiesComponent,
          entity: patient,
          extra: {
            init: this.account?.patientPropertyModels?.filter(
              prop => prop.is_medical === medical,
            ),
          },
          entityConfig: new EntityConfig({
            feature: medical
              ? $localize`medical properties`
              : $localize`public properties`,
          }),
        },
        medical
          ? this.medicalPropertiesInjector
          : this.PublicPropertiesInjector,
      )
      .subscribe();
  }
}
