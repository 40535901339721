<form class="flex gap-4 flex-wrap" [formGroup]="formGroup">
  <mat-form-field class="flex-auto w-64" uiFormMatFormFieldErrorDisplayer subscriptSizing="dynamic">
    <mat-label i18n>treatment</mat-label>
    <mat-icon matPrefix>medication</mat-icon>
    <input [matAutocomplete]="auto" type="text" placeholder="search by medication name" matInput formControlName="name">
    <mat-autocomplete #auto="matAutocomplete">
      @for (option of filtredMedications$|async; track option) {
      <mat-option [value]="option">{{option}}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field class="flex-auto w-64" subscriptSizing="dynamic" appearance="outline">
    <mat-label i18n>notes</mat-label>
    <input matInput formControlName="note" i18n-placeholder placeholder="note">
  </mat-form-field>
</form>
