<p mat-dialog-title i18n class="capitalize">document print configuration</p>
<main class="medium-text-field pt-4" matDialogContent>
  <mat-form-field class="w-full">
    <mat-label i18n>print configuration</mat-label>
    <mat-select (valueChange)="updateForm($event)" [formControl]="configCtrl">
      <mat-option [value]="printConfig.id" *ngFor="let printConfig of (printLayouts)">
        {{printConfig?.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <form class="flex flex-col gap-2" [formGroup]="configForm">
    <mat-form-field>
      <mat-label i18n>paper format</mat-label>
      <mat-select formControlName="paper_format" required>
        <mat-option [value]="paperFormat.value" *ngFor="let paperFormat of paperFormats" [value]="paperFormat.value">
          {{ paperFormat.value | titlecase }}
          <span class="ml-2 text-gray-800">({{ paperFormat.dimension }})</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <p class="field-label" i18n>margins (cm)</p>
    <div class="grid grid-cols-2 mt-2 gap-x-2 gap-y-4 items-start">
      <mat-form-field uiFormMatFormFieldErrorDisplayer subscriptSizing="dynamic" class="compact-text-field">
        <mat-label>top</mat-label>
        <input formControlName="top_margin" type="number" matInput>
        <mat-error></mat-error>
      </mat-form-field>
      <mat-form-field uiFormMatFormFieldErrorDisplayer subscriptSizing="dynamic" class="compact-text-field">
        <mat-label>bottom</mat-label>
        <input formControlName="bottom_margin" type="number" matInput>
        <mat-error></mat-error>
      </mat-form-field>
      <mat-form-field uiFormMatFormFieldErrorDisplayer subscriptSizing="dynamic" class="compact-text-field">
        <mat-label>left</mat-label>
        <input formControlName="left_margin" placeholder="left" type="number" matInput>
        <mat-error></mat-error>
      </mat-form-field>
      <mat-form-field uiFormMatFormFieldErrorDisplayer subscriptSizing="dynamic" class="compact-text-field">
        <mat-label>right</mat-label>
        <input formControlName="right_margin" placeholder="right" type="number" matInput>
        <mat-error></mat-error>
      </mat-form-field>
    </div>
  </form>
</main>

<footer align="end" mat-dialog-actions>
  <button (click)="generatePdf()" mat-flat-button>
    <mat-icon>print</mat-icon>
    <span i18n>print</span>
  </button>
</footer>