import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'entity-base-entity-details',
  standalone: true,
  imports: [CommonModule],
  template: `<p>base-entity-details works!</p>`,
  styles: ``,
})
export class BaseEntityDetailsComponent<T> {
  obj = input.required<T>();
}
