import { Maybe } from 'graphql/jsutils/Maybe';

export type Human = {
  given_name: Maybe<string> | undefined;
  middle_name: Maybe<string> | undefined;
  family_name: Maybe<string> | undefined;
  gender: Maybe<string> | undefined;
};
export type NameOption = 'short' | 'full' | 'medium' | 'genderOnly';
export enum AppointmentStatus {
  ABSENT = 0,
  ON_PROGRESS = 1,
  DONE = 2,
  PENDING = 3,
}
export enum LogRecordAction {
  CREATION = 1,
  MODIFICATION = 2,
  DELETION = 3,
}
export enum StepStatus {
  PENDING = 0,
  DONE = 1,
}
export enum CarePlanStatus {
  PENDING = 0,
  APPROVED = 1,
  DENIED = 2,
  DONE = 3,
}
export interface EnumDisplayConfig {
  color: string;
  bgColorClass: string;
  colorClass: string;
  label: string;
  shortLabel: string;
  svgIcon: string;
}

export type StatusConf = Record<
  'appointment' | 'logRecord',
  { [key: number]: EnumDisplayConfig }
>;

export const STATUS_CONF: StatusConf = {
  logRecord: {
    [LogRecordAction.DELETION]: {
      label: $localize`deletion`,
      shortLabel: $localize`deletion`,
      bgColorClass: 'bg-red-300',
      colorClass: 'text-red-800',
      color: 'red',
      svgIcon: 'delete',
    },

    [LogRecordAction.CREATION]: {
      label: $localize`creation`,
      shortLabel: $localize`creation`,
      bgColorClass: 'bg-lime-300',
      colorClass: 'text-lime-800',
      color: 'yellowgreen',
      svgIcon: 'add',
    },

    [LogRecordAction.MODIFICATION]: {
      label: $localize`modification`,
      shortLabel: $localize`modification`,
      bgColorClass: 'bg-blue-300',
      colorClass: 'text-blue-800',
      color: '#71cff5',
      svgIcon: 'edit',
    },
  },
  appointment: {
    [AppointmentStatus.ABSENT]: {
      label: $localize`absent`,
      shortLabel: $localize`absent`,
      bgColorClass: 'bg-red-300',
      colorClass: 'text-red-800',
      color: 'red',
      svgIcon: 'edit',
    },

    [AppointmentStatus.DONE]: {
      label: $localize`done`,
      shortLabel: $localize`done`,
      bgColorClass: 'bg-lime-300',
      colorClass: 'text-lime-800',
      color: 'yellowgreen',
      svgIcon: 'check_circle',
    },

    [AppointmentStatus.PENDING]: {
      label: $localize`pending`,
      shortLabel: $localize`pending`,
      bgColorClass: 'bg-blue-300',
      colorClass: 'text-blue-800',
      color: '#71cff5',
      svgIcon: 'pending',
    },

    [AppointmentStatus.ON_PROGRESS]: {
      label: $localize`on progress`,
      shortLabel: $localize`on progress`,
      bgColorClass: 'bg-amber-300',
      colorClass: 'text-amber-800',
      color: '#f0cd81',
      svgIcon: 'sync',
    },
  },
  // carePlan: {
  //   [CarePlanStatus.DENIED]: {
  //     label: $localize`denied`,
  //     shortLabel: $localize`denied`,
  //     bgColorClass: 'bg-red-300',
  //     colorClass: 'text-red-800',
  //     color: 'red',
  //     svgIcon: 'highlight_off',
  //   },

  //   [CarePlanStatus.DONE]: {
  //     label: $localize`done`,
  //     shortLabel: $localize`done`,
  //     bgColorClass: 'bg-lime-300',
  //     colorClass: 'text-lime-800',
  //     color: 'yellowgreen',
  //     svgIcon: 'check_circle',
  //   },

  //   [CarePlanStatus.PENDING]: {
  //     label: $localize`pending`,
  //     shortLabel: $localize`pending`,
  //     bgColorClass: 'bg-blue-300',
  //     colorClass: 'text-blue-800',
  //     color: '#71cff5',
  //     svgIcon: 'pending',
  //   },

  //   [CarePlanStatus.APPROVED]: {
  //     label: $localize`approved`,
  //     shortLabel: $localize`approved`,
  //     bgColorClass: 'bg-blue-300',
  //     colorClass: 'text-blue-800',
  //     color: '#71cff5',
  //     svgIcon: 'thumb_up',
  //   },
  // },
};
