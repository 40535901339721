import { ResourcesConfig } from 'aws-amplify';

// You can get the current config object
export const amplifyconfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: process.env['NX_COGNITO_USER_POOL_ID'] as string, //'eu-west-1_KEASFQol5',
      userPoolClientId: process.env[
        'NX_COGNITO_USER_POOL_WEB_CLIENT_ID'
      ] as string,
      signUpVerificationMethod: 'code',

      loginWith: {
        oauth: {
          domain: process.env['NX_COGNITO_OAUTH_DOMAIN'] as string, //'https://staging-etar.auth.eu-west-1.amazoncognito.com',
          scopes: [
            'phone',
            'email',
            'profile',
            'openid',
            'aws.cognito.signin.user.admin',
          ],
          redirectSignIn: [window.location.origin],
          redirectSignOut: [window.location.origin],
          responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
      },
    },
  },
};
