import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { BaseEntityDetailsComponent } from '@doctorus-front-end-monorepo/feature-entity';
import { Account } from '@doctorus-front-end-monorepo/graphql';
import { EmptyStateComponent } from '@doctorus-front-end-monorepo/ui-layout';

@Component({
  selector: 'lib-ui-patient-properties',
  imports: [
    CommonModule,
    MatDividerModule,
    MatChipsModule,
    EmptyStateComponent,
    MatIconModule,
    MatButtonModule,
    RouterModule,
  ],
  templateUrl: './ui-patient-properties.component.html',
  styleUrl: './ui-patient-properties.component.scss',
})
export class UiPatientPropertiesComponent extends BaseEntityDetailsComponent<Account> {}
