@if (mode==="large") {
<main class="flex items-center p-2 justify-between">
  <div>
    <p class="font-medium">{{observation.value}} {{measure.unit}}</p>
    <p class="text-sm text-gray-700">{{observation.date | date:'mediumDate'}}</p>
  </div>
  <p class="text-sm text-gray-700" *ngIf="observation.note">{{observation.note}}</p>
  <div class="flex gap-2">
    <button (click)="delete()" color="warn" mat-icon-button> <mat-icon>delete</mat-icon>
    </button>
    <button (click)="edit()" mat-icon-button> <mat-icon>edit</mat-icon>

    </button>
  </div>
</main>
}@else {
<h4>{{measure.name | titlecase}}</h4>
<p class="text-gray-800">{{observation.value}} {{measure.unit}}</p>
}
