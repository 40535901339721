import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MeasureModel } from '../../../../../shared/data-access/graphql/src';
import { BaseEntityDetailsComponent } from '../../../../../shared/feature-entity/src';
import { KeyValueComponent } from '../../../../../shared/ui-layout/src';
import { CoalescePipe } from '../../../../../shared/util-formatting/src';

@Component({
  selector: 'lib-measure-model-details',
  standalone: true,
  imports: [CommonModule, KeyValueComponent, CoalescePipe],
  templateUrl: './measure-model-details.component.html',
  styleUrl: './measure-model-details.component.scss',
})
export class MeasureModelDetailsComponent extends BaseEntityDetailsComponent<MeasureModel> {}
