import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs';
import { AuthService } from './auth-service/auth.service';
import { AccountRole } from './types';

export const accountFeatureGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const accountId = localStorage.getItem('account-id');
  return inject(AuthService).user$.pipe(
    map(user => user?.['cognito:groups']),
    map(roles => {
      const authorizeFor = route.data['authorizeFor'] as
        | AccountRole[]
        | undefined;
      if (!authorizeFor) {
        return true;
      }
      if (authorizeFor) {
        if (roles && roles.some(_role => authorizeFor.includes(_role)))
          return true;
        else {
          if (
            roles?.includes(AccountRole.MEDICAL_ASSISTANT) ||
            roles?.includes(AccountRole.MANAGER)
          )
            return ['/', accountId, 'scheduler'];
          if (roles?.includes(AccountRole.MANAGER))
            return ['/', accountId, 'settings'];
        }
      }
      return false;
    }),
  );
};
