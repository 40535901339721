/* eslint-disable @angular-eslint/no-input-rename */
import { CdkListboxModule } from '@angular/cdk/listbox';
import { CommonModule } from '@angular/common';
import { Component, Input, inject, signal } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatChipListboxChange, MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  GetAvailableSlotsGQL,
  GetAvailableSlotsQueryVariables,
} from '@doctorus-front-end-monorepo/graphql';
import { LoadingComponent } from '@doctorus-front-end-monorepo/ui-layout';
import { isEqual } from 'date-fns';
import { filter, finalize } from 'rxjs';

export type SlotQueryParamsType = GetAvailableSlotsQueryVariables;
@Component({
  selector: 'appointment-slot-picker',
  imports: [
    CommonModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    LoadingComponent,
    CdkListboxModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SlotPickerComponent,
    },
  ],
  templateUrl: './slot-picker.component.html',
  styleUrl: './slot-picker.component.scss',
})
export class SlotPickerComponent implements ControlValueAccessor {
  @Input({ required: true }) offset!: string;
  @Input() set params(payload: SlotQueryParamsType | null) {
    if (payload) {
      this.fetchSlots(payload);
    } else {
      this.availableSlots = [];
    }
  }
  fetchingSlots = signal(false);
  availableSlots: Array<string> = [];
  isDisabled = false;
  slotSelectorCtr = new FormControl();
  onChange = (obj: string | undefined) => {};

  onTouched = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  writeValue(obj: string | undefined): void {
    this.slotSelectorCtr.setValue(obj);
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  updateSelection(event: MatChipListboxChange): void {
    this.onChange(event.value);
  }

  compareFun = (a: string, b: string) => {
    return isEqual(a, b);
  };
  private getAvailableSlotsGQL = inject(GetAvailableSlotsGQL);

  private fetchSlots(payload: SlotQueryParamsType): void {
    this.fetchingSlots.set(true);
    this.getAvailableSlotsGQL
      .fetch(payload, {
        fetchPolicy: 'network-only',
      })
      .pipe(
        filter(x => (x?.data?.getAvailableSlots ? true : false)),
        finalize(() => this.fetchingSlots.set(false)),
      )
      .subscribe(x => {
        this.availableSlots = x.data.getAvailableSlots ?? [];
      });
  }
}
