import { CommonModule } from '@angular/common';
import {
  Component,
  HostListener,
  computed,
  input,
  linkedSignal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TitleCaseFirstPipe } from '@doctorus-front-end-monorepo/util-formatting';
import { NavLink } from '../types';
import { getMessageTypeAtrrs } from '../utils';
@Component({
  selector: 'ui-layout-nav-tree-element',
  imports: [CommonModule, MatIconModule, TitleCaseFirstPipe, RouterModule],
  templateUrl: './nav-tree-element.component.html',
  styleUrl: './nav-tree-element.component.scss',
})
export class NavTreeElementComponent {
  level = input(0);
  item = input.required<NavLink>();
  hasChildren = computed(
    () => this.item().children && this.item().children.length > 0,
  );
  feedbackAttrs = computed(() =>
    this.item().feedBackMessageType
      ? getMessageTypeAtrrs(this.item().feedBackMessageType)
      : null,
  );
  paddingStyle = computed(() => `${this.level() * 12 + 16}px`);
  fontSize = computed(
    () => `${16 - this.level() * 1.5 >= 14 ? 18 - this.level() * 1.5 : 14}px`,
  );

  expanded = linkedSignal(() => this.item().expanded ?? false);
  icon = computed(() => (this.expanded() ? 'expand_less' : 'expand_more'));
  @HostListener('click', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    this.expanded.update(value => !value);
  }
}
