<form class="max-w-screen-md flex flex-col gap-6" [formGroup]="form">
  <mat-form-field uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>name</mat-label>
    <input type="text" matInput formControlName="name" />
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n>order</mat-label>
    <input type="number" matInput formControlName="order" />
    <mat-error></mat-error>
    <mat-hint i18n
      >medical measure ordering in the patient medical record</mat-hint
    >
  </mat-form-field>
  <mat-form-field uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>unit</mat-label>
    <input type="text" matInput formControlName="unit" />
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n>description</mat-label>
    <textarea matInput formControlName="description"></textarea>
  </mat-form-field>
</form>
