import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from '@angular/router';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  tap,
  withLatestFrom,
} from 'rxjs';
import { AccountRole } from '../../../../../../shared/auth/src';
import {
  BreadcrumbComponent,
  resolveBreadcrumbs,
} from '../../../../../../shared/breadcrumb/src';
import {
  NavLink,
  NavTreeComponent,
} from '../../../../../../shared/ui-layout/src';

@Component({
    selector: 'account-settings-account-settings',
    imports: [
        CommonModule,
        RouterModule,
        MatIconModule,
        MatSidenavModule,
        MatDividerModule,
        NavTreeComponent,
        BreadcrumbComponent,
        MatButtonModule,
    ],
    templateUrl: './account-settings.component.html',
    styleUrl: './account-settings.component.scss'
})
export class AccountSettingsComponent {
  pushSideNav$ = inject(BreakpointObserver)
    .observe([Breakpoints.Small, Breakpoints.XSmall])
    .pipe(map(result => result.matches));

  xSmall$ = inject(BreakpointObserver)
    .observe([Breakpoints.XSmall])
    .pipe(map(result => result.matches));

  open = true;
  links: NavLink[] = [
    {
      name: $localize`general`,
      active: true,
      routerLink: ['general-settings'],
    },
    {
      name: $localize`memberships`,
      svgIcon: 'patient_list',
      active: true,
      requiredRoles: [AccountRole.DOCTOR, AccountRole.MEDICAL_ASSISTANT],
      routerLink: ['memberships'],
    },
    {
      name: $localize`forms`,
      active: true,
      routerLink: ['forms-preferences'],
    },
    {
      name: $localize`patient property models`,
      active: true,
      routerLink: ['patient-property-models'],
    },
    {
      name: $localize`medical settings`,
      svgIcon: 'settings',
      active: true,
      children: [
        {
          name: $localize`medical history models`,
          svgIcon: 'settings',
          active: true,
          routerLink: ['medical-history-models'],
        },
        {
          name: $localize`measure models`,
          svgIcon: 'settings',
          active: true,
          routerLink: ['measure-models'],
        },
      ],
    },
    {
      name: $localize`documents settings`,
      svgIcon: 'settings',
      active: true,
      children: [
        {
          name: $localize`document models`,
          svgIcon: 'settings',
          active: true,
          routerLink: ['document-models'],
        },
        {
          name: $localize`print layouts`,
          svgIcon: 'settings',
          active: true,
          routerLink: ['print-layouts'],
        },
      ],
    },
    {
      name: $localize`locations`,
      svgIcon: 'locations',
      active: true,
      routerLink: ['locations'],
    },
    {
      name: $localize`appointment types`,
      svgIcon: 'settings',
      active: true,
      routerLink: ['task-types'],
    },
  ];

  toggle(): void {
    this.open = !this.open;
  }
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  breadcrumbs$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(''),
    distinctUntilChanged(),
    debounceTime(100),
    withLatestFrom(this.pushSideNav$),
    tap(res => {
      if (res[1]) {
        this.open = false;
      }
    }),
    map(() => resolveBreadcrumbs(this.route, 'primary')),
  );
}
