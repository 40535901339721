import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { Router, RouterLink } from '@angular/router';
import { AuthService, UserAttributes } from '@doctorus-front-end-monorepo/auth';
import { PhoneNumberBoxComponent } from '@doctorus-front-end-monorepo/feature-patient';
import {
  Account,
  GetMembershipsGQL,
} from '@doctorus-front-end-monorepo/graphql';
import {
  AvatarLoaderDirective,
  HumanNamePipe,
} from '@doctorus-front-end-monorepo/shared-util';
import {
  ContainerComponent,
  GroupContainerComponent,
  KeyValueComponent,
} from '@doctorus-front-end-monorepo/ui-layout';
import { PhoneNumberPipe } from '@doctorus-front-end-monorepo/util-formatting';
import { isNil } from 'lodash';
import { filter, first, map, switchMap, takeWhile, tap } from 'rxjs';
import { EditUserDialogComponent } from '../edit-user-dialog/edit-user-dialog.component';
import { NewOfficeDialogComponent } from '../new-office-dialog/new-office-dialog.component';

@Component({
    selector: 'doctorus-front-end-monorepo-staff-board',
    templateUrl: './staff-board.component.html',
    styleUrls: ['./staff-board.component.scss'],
    imports: [
        NgIf,
        MatIconModule,
        MatButtonModule,
        AvatarLoaderDirective,
        MatListModule,
        ContainerComponent,
        PhoneNumberPipe,
        MatTableModule,
        PhoneNumberBoxComponent,
        GroupContainerComponent,
        KeyValueComponent,
        MatGridListModule,
        NgFor,
        RouterLink,
        AsyncPipe,
        HumanNamePipe,
    ]
})
export class StaffBoardComponent {
  authUser$ = this.as.authUserInfo$;
  //isNotApproved$ = this.as.user$.pipe(map(user => !approvedUser(user)));
  loading = false;
  // isNotProfileCompleted$ = this.as.user$.pipe(
  //   map(user => !completeProfile(user)),
  // );
  memberships$ = this.getMembershipsGQL.watch().valueChanges.pipe(
    tap(x => (this.loading = x.loading)),
    map(x => x.data.getMemberships),
  );
  displayedColumns: string[] = ['account', 'roles'];

  constructor(
    private getMembershipsGQL: GetMembershipsGQL,
    private as: AuthService,
    private dialog: MatDialog,
    private router: Router,
  ) {}

  openNewOfficeDialog(): void {
    this.dialog
      .open<NewOfficeDialogComponent, any, Account>(NewOfficeDialogComponent, {
        width: '420px',
      })
      .afterClosed()
      .pipe(takeWhile(account => !isNil(account)))
      .subscribe(account => {
        console.warn(account);
        this.router.navigate([account.id, 'account-created']);
      });
  }
  editProfileDialog(
    authUser: Partial<Omit<UserAttributes, 'account_id'>>,
  ): void {
    this.dialog
      .open(EditUserDialogComponent, {
        width: '600px',
        data: authUser,
      })
      .afterClosed()
      .pipe(
        first(),
        filter(result => (result ? true : false)),
        switchMap(result => this.as.updateUser(result)),
      )
      .subscribe();
  }
}
