<div class="border border-gray-200 rounded-md p-4 flex items-center gap-4 justify-between">
  <section class="flex items-center gap-2">
    @if(uploading()) {
    <mat-spinner></mat-spinner>
    <h3 class="first-letter:uppercase" i18n>uploading ...</h3>

    }@else {
    @if (imageSrc) {
    <util-document-file-box [width]="128" class="max-h-14" [fileSrc]="imageSrc"></util-document-file-box>
    } @else {
    <span class="material-symbols-outlined text-4xl">
      cloud_upload
    </span>
    <h3 class="first-letter:uppercase">upload medical document</h3>

    }
    }

  </section>

  <div class="flex flex-wrap gap-2">
    <button (click)="fileInput.click()" color="primary" mat-button><mat-icon>add_a_file</mat-icon><span>new
        file</span></button>
    <button color="primary" (click)="pickImage()" mat-button><mat-icon>add_a_photo</mat-icon><span>new
        image</span></button>
  </div>


</div>
<input hidden (change)="onFilePick($event)" #fileInput type="file" id="file">