import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { AccountRole } from '@doctorus-front-end-monorepo/auth';
import {
  appointmentConfig,
  StatusPickerComponent,
} from '@doctorus-front-end-monorepo/feature-appointment';
import { PhoneNumberBoxComponent } from '@doctorus-front-end-monorepo/feature-patient';
import { GetAppointmentGQL } from '@doctorus-front-end-monorepo/graphql';

import { rxResource } from '@angular/core/rxjs-interop';
import { MatChipsModule } from '@angular/material/chips';
import {
  AppointmentStatus,
  HumanNamePipe,
  StatusComponent,
} from '@doctorus-front-end-monorepo/shared-util';
import { RouterNavigationHelperService } from '@doctorus-front-end-monorepo/util-navigation';
import { timezoneToOffset } from '@doctorus-front-end-monorepo/util-time';
import { map } from 'rxjs';
import { CalendarEvent, EventDialogActionType } from '../types';
@Component({
  selector: 'medical-space-web-appointment-detail-dialog',
  templateUrl: './appointment-detail-dialog.component.html',
  styleUrls: ['./appointment-detail-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIconModule,
    MatDialogModule,
    StatusPickerComponent,
    PhoneNumberBoxComponent,
    MatDividerModule,
    StatusComponent,
    MatChipsModule,
    MatButtonModule,
    RouterLink,
    TitleCasePipe,
    DatePipe,
    HumanNamePipe,
    CommonModule,
  ],
})
export class AppointmentDetailDialogComponent {
  event = this.data.event;
  entityConfig = appointmentConfig;
  accountRole = AccountRole;
  currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  appointmentOffset = timezoneToOffset(this.event.extendedProps.timezone);

  differentTimeZone =
    this.currentTimezone.toLowerCase() !==
    this.data.event.extendedProps.timezone?.toLowerCase();
  endDateFormat = this.differentTimeZone ? 'HH:mm z' : 'HH:mm';
  private appointmentGql = inject(GetAppointmentGQL);
  appointmentRx = rxResource({
    loader: () =>
      this.appointmentGql
        .fetch({ id: this.data.event.id })
        .pipe(map(x => x.data.getAppointment)),
  });

  constructor(
    public rnhs: RouterNavigationHelperService,
    public dialogRef: DialogRef<EventDialogActionType>,
    @Inject(DIALOG_DATA)
    public data: {
      event: CalendarEvent;
      withPatient: boolean;
    },
  ) {}
  closeWith(type: 'EDIT' | 'DELETE' | 'CLOSE' | 'STATUS' | 'NONE'): void {
    this.dialogRef.close({ type, payload: this.appointmentRx.value() });
  }
  updateStatus(event: AppointmentStatus): void {
    this.dialogRef.close({ type: 'STATUS', payload: event });
  }
}
