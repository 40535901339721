import { Pipe, PipeTransform } from '@angular/core';
import {
  STATUS_CONF,
  StatusConf,
} from '@doctorus-front-end-monorepo/shared-type';

@Pipe({
  name: 'resolveStatusConfig',
  standalone: true,
})
export class ResolveStatusConfigPipe implements PipeTransform {
  transform<k extends keyof StatusConf>(
    status: keyof StatusConf[k],
    feature: k
  ) {
    return STATUS_CONF[feature][status];
  }
}
