import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TaskType } from '../../../../../shared/data-access/graphql/src';
import { BaseEntityFormComponent } from '../../../../../shared/feature-entity/src';
import { UiMatFormFieldErrorDisplayerDirective } from '../../../../../shared/form/ui-form/src';
import {
  DURATIONS_CHOICES,
  DurationPipe,
} from '../../../../../shared/util-time/src';

@Component({
  selector: 'lib-task-type-form',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    DurationPipe,
    MatIconModule,
    ReactiveFormsModule,
    UiMatFormFieldErrorDisplayerDirective,
  ],
  templateUrl: './task-type-form.component.html',
  styleUrl: './task-type-form.component.scss',
})
export class TaskTypeFormComponent extends BaseEntityFormComponent<TaskType> {
  override initFormFun = () =>
    this.fb.group({
      id: [this.obj()?.id],

      name: [this.obj()?.name, Validators.required],
      default_fees: [this.obj()?.default_fees],
      default_duration: [this.obj()?.default_duration],
      note_template: [this.obj()?.note_template],
      color: [this.obj()?.color ?? '#b4b8b5'],
      disabled: [this.obj()?.disabled ?? false],
    });

  durations = DURATIONS_CHOICES;
}
