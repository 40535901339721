import { CommonModule } from '@angular/common';
import {
  Component,
  HostBinding,
  HostListener,
  Input,
  booleanAttribute,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'ui-layout-key-value',
  imports: [CommonModule, MatIconModule, MatButtonModule],
  templateUrl: './key-value.component.html',
  styleUrl: './key-value.component.scss',
})
export class KeyValueComponent {
  @Input({ required: true }) key!: string;
  @Input({ transform: booleanAttribute }) clickable = false;
  @Input({ transform: booleanAttribute }) applyCase = true;

  private _showEdit = false;
  public get showEdit() {
    return this._showEdit;
  }
  public set showEdit(value) {
    this._showEdit = value;
  }

  @HostListener('mouseover')
  show(): void {
    this.showEdit = this.clickable;
  }

  @HostListener('mouseout')
  hide(): void {
    this.showEdit = false;
  }

  @HostBinding('class.cursor-pointer')
  get invalid() {
    return this.showEdit;
  }
}
