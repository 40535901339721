import { CommonModule } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { AuthService } from '@doctorus-front-end-monorepo/auth';
import { ContainerComponent } from '@doctorus-front-end-monorepo/ui-layout';
import { isNil } from 'lodash';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs';
import { ActivePatientsListComponent } from '../active-patients-list/active-patients-list.component';
import { PatientsNotesExplorerComponent } from '../patients-notes-explorer/patients-notes-explorer.component';
type PatientSearchType = {
  type: 'medical-note' | 'profile';
  label: string;
  searchPlaceholder: string;
};

@Component({
  selector: 'doctorus-front-end-monorepo-patients-list',
  imports: [
    CommonModule,
    RouterModule,
    MatTabsModule,
    MatSidenavModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    PatientsNotesExplorerComponent,
    MatToolbarModule,
    MatButtonToggleModule,
    MatIconModule,
    ActivePatientsListComponent,
    ContainerComponent,
    MatPaginatorModule,
    FormsModule,
    MatSlideToggleModule,
  ],
  templateUrl: './patients-list.component.html',
  styleUrl: './patients-list.component.scss',
})
export class PatientsListComponent {
  loading = signal(false);
  count = signal(0);
  pageIndex = signal<number | null>(null);
  pageSize = signal<number>(50);
  status: 'active' | 'inactive' = 'active';
  roles$ = inject(AuthService).authUserRoles$;
  title = computed(() => $localize`patients list (${this.count()})`);
  deletedUsers = signal(false);
  searchCtrl = new FormControl('');
  searchTerm = toSignal(
    this.searchCtrl.valueChanges.pipe(
      map(x => x?.trim()),
      filter(x => (!!x && x.length > 2) || isNil(x) || x === ''),
      distinctUntilChanged(),
      debounceTime(500),
    ),
  );
  containerDescription = computed(
    () => $localize`found ${this.count() ?? 'no'} patient(s)`,
  );
  patientSearchTypes: Array<PatientSearchType> = [
    {
      label: $localize`profile search`,
      type: 'profile',
      searchPlaceholder: $localize`search by family, given name, reference, contacts`,
    },
    {
      label: $localize`medical notes query`,
      type: 'medical-note',
      searchPlaceholder: $localize`query patients medicalnotes.`,
    },
  ];
  selectedType = signal(this.patientSearchTypes[0]);
  updatePage(event: PageEvent): void {
    this.pageSize.set(event.pageSize);
    this.pageIndex.set(event.pageIndex);
  }
}
