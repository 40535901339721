import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import {
  ControlContainer,
  FormArray,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
    selector: 'account-settings-patient-properties-model-inline-from',
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatInputModule,
        ReactiveFormsModule,
    ],
    viewProviders: [
        {
            provide: ControlContainer,
            useFactory: () => inject(ControlContainer, { skipSelf: true }),
        },
    ],
    templateUrl: './patient-properties-model-inline-from.component.html',
    styleUrl: './patient-properties-model-inline-from.component.scss'
})
export class PatientPropertiesModelInlineFromComponent {
  @Input({ required: true }) index!: number;
  @Input({ required: true }) formArrayKey!: string;

  parentContainer = inject(ControlContainer);

  get formGroup() {
    return (
      this.parentContainer.control?.get(this.formArrayKey) as FormArray
    ).at(this.index) as FormGroup;
  }
}
