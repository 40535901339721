import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Patient,
  Prescription,
  Treatment,
} from '@doctorus-front-end-monorepo/graphql';
import { formatISO } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class PrescriptionFormBuilderService {
  constructor(
    private fb: FormBuilder,
    private matSnackBar: MatSnackBar,
  ) {}

  createPrecriptionForm = (
    patient: Patient,
    entity?: Prescription,
  ): FormGroup =>
    this.fb.group({
      id: [entity?.id],

      date: [
        entity?.date ?? formatISO(new Date(), { representation: 'date' }),
        Validators.required,
      ],
      patient: [patient.id, Validators.required],
      treatment_set: this.fb.array([]),
    });

  // createPrecriptionModelForm = (entity?: IPrescriptionModel): FormGroup =>
  //   this.fb.group({
  //     title: [entity?.title, Validators.required],
  //     office: [entity?.office, Validators.required],
  //     description: [entity?.description],
  //     treatments: this.fb.group(
  //       Object.entries(entity?.treatments ?? { '': {} }).reduce(
  //         (
  //           acc: { [key: string]: FormGroup },
  //           current: [string, Treatment],
  //         ) => ({
  //           ...acc,
  //           [current[0]]: this.treatmentForm(current[1], current[0]),
  //         }),
  //         {},
  //       ),
  //     ),
  //   });

  treatmentForm = (payload?: Treatment): FormGroup =>
    this.fb.group({
      name: [payload?.name, Validators.required],
      note: [payload?.note],
    });

  addTreatment(
    form: FormGroup,
    treatment?: Treatment,
    title?: string,
  ): FormGroup {
    const _title =
      title ??
      Object.keys(
        (form.get('treatments') as FormGroup)?.controls ?? {},
      ).length.toString();
    (form.get('treatments') as FormGroup).addControl(
      _title,
      this.treatmentForm(treatment),
    );
    return form;
  }
  removeTreatment(form: FormGroup, index: string): FormGroup {
    (form.get('treatments') as FormGroup).removeControl(index);
    return form;
  }
  lastTreatment = (form: FormGroup): boolean => this.treatmentsCount(form) < 2;

  treatmentsCount = (form: FormGroup): number =>
    Object.keys(((form as FormGroup).get('treatments') as FormGroup).controls)
      .length ?? 0;

  validTreatments: ValidatorFn =
    (): any =>
    (form: AbstractControl): ValidationErrors | null => {
      return Object.keys((form as FormGroup)?.controls ?? {}).length === 0
        ? { invalidTreatments: true }
        : null;
    };
}
