import { DIALOG_DATA } from '@angular/cdk/dialog';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBar } from '@angular/material/progress-bar';
import { IEntity } from '@doctorus-front-end-monorepo/feature-entity';
import { FormPanelBaseComponent } from '../form-panel-base.component';
import { IFormComponent } from '../slide-out-panel-types';
@Component({
  selector: 'shared-ui-slide-out-form-panel',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    PortalModule,
    MatButtonModule,
    MatProgressBar,
  ],
  templateUrl: './slide-out-form-panel.component.html',
  styleUrl: './slide-out-form-panel.component.scss',
})
export class SlideOutFormPanelComponent<
  E extends IEntity,
  C extends IFormComponent,
  R = E,
> extends FormPanelBaseComponent<E, C> {
  override data = inject(DIALOG_DATA);
}
