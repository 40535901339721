import { Pipe, PipeTransform } from '@angular/core';
import { Human, NameOption } from '../../types';
import { humanize } from '../../utils';
@Pipe({
  name: 'humanName',
  standalone: true,
})
export class HumanNamePipe implements PipeTransform {
  transform(
    value: Partial<Human> | undefined | null,
    option: NameOption = 'medium',
    withCivility = false,
  ): string {
    return value ? (humanize(value, option, withCivility) ?? 'N/A') : 'N/A';
  }
}
