<main matDialogContent>
  <div class="flex items-center gap-4 mt-2 mb-4">
    <img
      class="w-12 rounded-full"
      doctorusFrontEndMonorepoAuthAvatarLoader
      [user]="$any(user)"
    />
    <div>
      <p class="capitalize text-black">
        {{ user.given_name }} {{ user.family_name }}
      </p>
      <p class="text-gray-700">{{ user.email }}</p>
    </div>
  </div>
  <mat-nav-list>
    <a [routerLink]="['/staff-board']" mat-list-item>
      <mat-icon matListItemIcon>account_circle</mat-icon>
      <span class="capitalize font-" matListItemTitle i18n>your profile</span>
    </a>

    <a (click)="logOut()" mat-list-item>
      <mat-icon matListItemIcon>logout</mat-icon>
      <span class="capitalize" matListItemTitle i18n>logout</span>
    </a>
  </mat-nav-list>
</main>
