<ng-template #actions>
  <button [routerLink]="cancelRoute()" mat-button>
    <span i18n>cancel</span>
  </button>
  <button (click)="submit()" mat-flat-button>
    <span i18n>save changes</span>
  </button>
</ng-template>
<ui-layout-container
  [contentBorder]="withBorder"
  border="false"
  [paddingLevel]="paddingLevel"
  level="4"
  [title]="title"
>
  @if (topActions) {
    <div class="flex gap-2" ngProjectAs="actions">
      <ng-container [ngTemplateOutlet]="actions"></ng-container>
    </div>
  }
  <ng-container
    #form
    [ngComponentOutletInputs]="{
      obj: obj(),
      extra: (extra$ | async),
      queryParams: queryParams$ | async,
    }"
    [ngComponentOutlet]="$any(component)"
  ></ng-container>
  @if (topActions === false) {
    <footer class="flex mt-6 justify-end gap-4">
      <ng-container [ngTemplateOutlet]="actions"></ng-container>
    </footer>
  }
</ui-layout-container>
