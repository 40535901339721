<form [formGroup]="form">
  <ui-layout-container [border]="false" [title]="propertiesContainerTitle">

    <button (click)="addProperty()" ngProjectAs="actions" mat-stroked-button>
      <mat-icon>add_circle</mat-icon>
      <span i18n>add property</span>
    </button>

    <lib-inline-form-array [init]="obj()?.patientPropertyModels" [extra]="1" [formBuildFn]="createFormFun"
      controlKey="patientPropertyModels">
      <ng-template let-index="index" #fields>
        <account-settings-patient-properties-model-inline-from [index]="index"
          formArrayKey="patientPropertyModels"></account-settings-patient-properties-model-inline-from>
      </ng-template>
    </lib-inline-form-array>
  </ui-layout-container>
</form>