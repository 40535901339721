import { EntityConfig } from '@doctorus-front-end-monorepo/feature-entity';
import { Measure } from '@doctorus-front-end-monorepo/graphql';
import * as dateFns from 'date-fns';
export const measureConfig: EntityConfig<Measure> = new EntityConfig<Measure>({
  feature: $localize`measure`,
});
export const observationConfig: EntityConfig<Measure> =
  new EntityConfig<Measure>({
    feature: $localize`observation`,
    serializerFun: value => ({
      ...value,
      date: dateFns.formatISO(new Date(value.date), {
        representation: 'date',
      }),
    }),
  });
