<section class="grid grid-cols-3 gap-6">
  <ui-layout-key-value key="name" i18n-key>{{
    obj().name
  }}</ui-layout-key-value>
  <ui-layout-key-value key="unit" i18n-key>{{
    obj().unit
  }}</ui-layout-key-value>

  <ui-layout-key-value key="order" i18n-key>{{
    obj().order
  }}</ui-layout-key-value>

  <ui-layout-key-value class="col-span-full" key="description" i18n-key>{{
    obj().description | coalesce: '-'
  }}</ui-layout-key-value>
</section>
