import { CdkListboxModule } from '@angular/cdk/listbox';
import { CommonModule } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatChipListboxChange, MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  AccountLocation,
  Appointment,
  GetAvailableSlotsGQL,
} from '@doctorus-front-end-monorepo/graphql';
import { LoadingComponent } from '@doctorus-front-end-monorepo/ui-layout';
import { parseDuration } from '@doctorus-front-end-monorepo/util-formatting';
import { TimezoneOffsetPipe } from '@doctorus-front-end-monorepo/util-time';
import { isEqual } from 'date-fns';
import { map, of } from 'rxjs';
import { Temporal } from 'temporal-polyfill';
type SlotType = Pick<Appointment, 'start' | 'end'>;

@Component({
  selector: 'appointment-slot-picker',
  imports: [
    CommonModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    TimezoneOffsetPipe,
    LoadingComponent,
    CdkListboxModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SlotPickerComponent,
    },
  ],
  templateUrl: './slot-picker.component.html',
  styleUrl: './slot-picker.component.scss',
})
export class SlotPickerComponent implements ControlValueAccessor {
  duration = input<string>('PT30M');
  timezone = input<string>(Intl.DateTimeFormat().resolvedOptions().timeZone);
  location = input<AccountLocation | null | undefined>(null);
  appointment = input<string | null | undefined>(null);
  date = input<string | null>(null);
  isDisabled = false;
  slotSelectorCtr = new FormControl();
  onChange = (obj: SlotType | undefined) => {};

  onTouched = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  writeValue(obj: SlotType | null | undefined): void {
    this.slotSelectorCtr.setValue(obj);
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  updateSelection(event: MatChipListboxChange): void {
    this.onChange(event.value);
  }

  compareFun = (a: SlotType, b: SlotType) => {
    return isEqual(a.start, b.start);
  };
  private getAvailableSlotsGQL = inject(GetAvailableSlotsGQL);

  params = computed(
    () =>
      this.location() && {
        date: this.date(),
        duration: this.duration()!,
        location_id: this.location()!.id,
        slot_interval: this.location()!.slot_interval,
        timezone: this.timezone(),
        working_periods: this.location()!.working_periods ?? [],
        appointment: this.appointment(),
      },
  );

  slotsRessource = rxResource({
    request: this.params,
    loader: ({ request }) =>
      request
        ? this.getAvailableSlotsGQL
            .fetch({ payload: request }, { fetchPolicy: 'network-only' })
            .pipe(
              map(x =>
                x.data.getAvailableSlots?.map(start => ({
                  start: start,
                  end: Temporal.Instant.from(start)
                    .add(parseDuration(this.duration()))
                    .toString(),
                })),
              ),
            )
        : of([]),
  });
}
