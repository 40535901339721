@let patients = patientsRessource.value();
@let isLoading = patientsRessource.isLoading();

@if (isLoading) {
  <ui-layout-loading></ui-layout-loading>
} @else {
  <table
    multiTemplateDataRows
    matSort
    (matSortChange)="updateSort($any($event))"
    mat-table
    [dataSource]="patients"
  >
    <ng-container sticky matColumnDef="name">
      <th sticky mat-header-cell mat-sort-header="given_name" *matHeaderCellDef>
        <span class="first-letter:uppercase" i18n="full-name">name</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <a
          class="anchor font-medium"
          [routerLink]="element.is_active ? ['..', element.id] : null"
          >{{ element | humanName: 'full' : true | titlecase }}

          @if (element.is_active === false) {
            <span i18n>( deleted )</span>
          }
        </a>
      </td>
    </ng-container>
    <ng-container matColumnDef="reference">
      <th sticky mat-header-cell mat-sort-header="reference" *matHeaderCellDef>
        <span class="first-letter:uppercase" i18n>reference</span>
      </th>
      <td mat-cell *matCellDef="let element">{{ element?.reference }}</td>
    </ng-container>
    <ng-container matColumnDef="added">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="added">
        <span class="first-letter:uppercase" i18n>added</span>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.added | date: 'shortDate' | coalesce: '-' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="total_due">
      <th mat-header-cell *matHeaderCellDef>
        <span class="first-letter:uppercase" i18n>balance</span>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.total_due | currency: currency : 'symbol' : '1.0-1' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="headline">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <div [innerHTML]="element.headline"></div>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="displayedColumns.length"
      >
        <div>Description</div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      [class.expandable]="row.is_active === false"
      mat-row
      (click)="toggle(row)"
      *matRowDef="let row; columns: displayedColumns; let idx = index"
    ></tr>

    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']; when: showDetails"
      class="example-detail-row"
    ></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">
        <ui-layout-empty-state>
          <p i18n>no patients</p>
          <button ngProjectAs="actions" mat-stroked-button>new patient</button>
        </ui-layout-empty-state>
      </td>
    </tr>
  </table>
}
