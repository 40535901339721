import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, debounceTime, filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutStateService {
  private readonly sideMenuOpenSubject = new BehaviorSubject<boolean>(false);
  private breakpointObserver = inject(BreakpointObserver);
  private router = inject(Router);
  sideMenuOpen$ = this.sideMenuOpenSubject.asObservable();
  showAccountCard$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(result => !result.matches));

  toggleSideMenu(): void {
    this.sideMenuOpenSubject.next(!this.sideMenuOpenSubject.getValue());
  }
  /**
   *
   */
  isCompact$ = this.breakpointObserver
    .observe([Breakpoints.HandsetPortrait])
    .pipe(map(result => result.matches));
  isMedium$ = this.breakpointObserver
    .observe([Breakpoints.TabletPortrait])
    .pipe(map(result => result.matches));
  isLarge$ = this.breakpointObserver
    .observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.TabletLandscape,
      Breakpoints.Web,
    ])
    .pipe(map(result => result.matches));
  constructor() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        debounceTime(200),
      )
      .subscribe(() => this.sideMenuOpenSubject.next(false));
  }
}
