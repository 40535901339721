import { Component, Inject, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import {
  EntityConfig,
  IEntity,
} from '@doctorus-front-end-monorepo/feature-entity';
import { EntityDeleteMutationService } from '../tokens';

@Component({
    selector: 'entity-dialog-generic-delete-dialog',
    templateUrl: './generic-delete-dialog.component.html',
    styleUrls: ['./generic-delete-dialog.component.scss'],
    imports: [MatDialogModule, MatButtonModule, MatIconModule]
})
export class GenericDeleteDialogComponent<T extends IEntity> {
  entityTitle: string;
  entity: T;
  confirmMsg: string;
  buttonLabel: string;
  headerStr: string;
  eds = inject(EntityDeleteMutationService);
  constructor(
    public dialogRef: MatDialogRef<T>,
    @Inject(MAT_DIALOG_DATA)
    public data: { entityConfig: EntityConfig<T>; entity: T },
  ) {
    this.entityTitle = this.data.entityConfig.feature;
    this.headerStr =
      this.data.entityConfig.messages?.title?.delete ??
      $localize`Delete ${this.entityTitle}`;
    this.entity = this.data.entity;
    this.buttonLabel =
      this.data.entityConfig.messages?.action?.deleted?.buttonLabel ??
      $localize`yes, delete it`;
    this.confirmMsg =
      this.data.entityConfig.messages?.action?.deleted?.confirmation ??
      $localize`Are you sure you want to delete this` +
        this.entityTitle +
        $localize`?`;
  }

  delete() {
    console.warn(this.entity);
    this.eds
      .mutate({
        id: this.entity.id,
      })
      .subscribe({
        next: () => this.dialogRef.close(this.entity),
      });
  }
}
