import { FocusMonitor } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  HostBinding,
  inject,
  Input,
  input,
  OnDestroy,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NgControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormFieldControl,
} from '@angular/material/form-field';
import { parseDuration } from '@doctorus-front-end-monorepo/util-formatting';
import { isNil } from 'lodash';
import { debounceTime, distinctUntilChanged, skip, Subject } from 'rxjs';
import { Temporal } from 'temporal-polyfill';

@Component({
  selector: 'time-duration-input',
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './duration-input.component.html',
  styleUrl: './duration-input.component.css',
  providers: [
    { provide: MatFormFieldControl, useExisting: DurationInputComponent },
  ],
  host: {
    '[class.duration-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DurationInputComponent
  implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy
{
  static nextId = 0;
  readonly hoursInput = viewChild.required<HTMLInputElement>('hours');
  readonly minutesInput = viewChild.required<HTMLInputElement>('minutes');
  ngControl = inject(NgControl, { optional: true, self: true });
  readonly parts = inject(FormBuilder).group({
    hours: [
      null as null | number,
      [
        Validators.required,
        Validators.max(99),
        Validators.min(0),
        //Validators.maxLength(2),
      ],
    ],
    minutes: [
      null as null | number,
      [
        Validators.required,
        Validators.max(59),
        Validators.min(0),

        //Validators.maxLength(2),
      ],
    ],
  });
  readonly stateChanges = new Subject<void>();
  readonly touched = signal(false);
  readonly controlType = 'duration-input';
  @HostBinding()
  readonly id = `duration-input-${DurationInputComponent.nextId++}`;
  readonly _userAriaDescribedBy = input<string>('', {
    alias: 'aria-describedby',
  });
  readonly _placeholder = input<string>('', { alias: 'placeholder' });
  readonly _required = input<boolean, unknown>(false, {
    alias: 'required',
    transform: booleanAttribute,
  });
  readonly _disabledByInput = input<boolean, unknown>(false, {
    alias: 'disabled',
    transform: booleanAttribute,
  });
  //readonly _value = model<string | null>(null, { alias: 'value' });
  onChange = (_: any) => {};
  onTouched = () => {};

  @Input()
  get value(): string | null {
    return this.parts.valid ? this.serialize(this.parts.value as any) : null;
  }
  set value(obj: string | null) {
    const res = this.deserialize(obj);
    if (res) {
      this.parts.setValue(res, { emitEvent: false });
    }
    this.stateChanges.next();
  }

  protected readonly _formField = inject(MAT_FORM_FIELD, {
    optional: true,
  });

  private readonly _focused = signal(false);
  private readonly _disabledByCva = signal(false);
  private readonly _disabled = computed(
    () => this._disabledByInput() || this._disabledByCva(),
  );
  private readonly _focusMonitor = inject(FocusMonitor);
  private readonly _elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

  get focused(): boolean {
    return this._focused();
  }

  get empty() {
    const {
      value: { hours, minutes },
    } = this.parts;

    return isNil(hours) && isNil(minutes);
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get userAriaDescribedBy() {
    return this._userAriaDescribedBy();
  }

  get placeholder(): string {
    return this._placeholder();
  }

  get required(): boolean {
    return this._required();
  }

  get disabled(): boolean {
    return this._disabled();
  }

  get errorState(): boolean {
    return this.ngControl?.invalid! && this.parts.invalid && this.touched();
  }

  validHour = signal<number | null>(null);
  validMinutes = signal<number | null>(null);
  constructor() {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.parts = inject(FormBuilder).group({
      hours: [
        null as null | number,
        [
          Validators.required,
          Validators.max(99),
          Validators.min(0),
          // Validators.pattern(/^\D*(\d)\D*(\d)\D*$/),
          // Validators.maxLength(2),
          // Validators.minLength(2),
        ],
      ],
      minutes: [
        null as null | number,
        [
          Validators.required,
          Validators.max(59),
          Validators.min(0),
          // Validators.pattern(/[0-5]?\d/),

          // Validators.maxLength(2),
          // Validators.minLength(2),
        ],
      ],
    });

    effect(() => {
      // Read signals to trigger effect.
      this._placeholder();
      this._required();
      this._disabled();
      // Propagate state changes.
      untracked(() => this.stateChanges.next());
    });

    effect(() => {
      if (this._disabled()) {
        untracked(() => this.parts.disable());
      } else {
        untracked(() => this.parts.enable());
      }
    });

    this.parts.statusChanges.pipe(takeUntilDestroyed()).subscribe(() => {
      this.stateChanges.next();
    });

    this.parts.valueChanges
      .pipe(
        skip(1),
        takeUntilDestroyed(),
        debounceTime(300),
        distinctUntilChanged(
          (prev, curr) =>
            prev.hours === curr.hours && prev.minutes === curr.minutes,
        ),
      )
      .subscribe(value => {
        const duration = this.parts.valid
          ? Temporal.Duration.from({
              hours: value.hours!,
              minutes: value.minutes!,
            }).toString()
          : null;
        this.onChange(duration);
      });
  }

  //autofilled?: boolean | undefined;
  //disableAutomaticLabeling?: boolean | undefined;

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn() {
    if (!this._focused()) {
      this._focused.set(true);
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched.set(true);
      this._focused.set(false);
      this.onTouched();
      this.stateChanges.next();
    }
  }

  autoFocusNext(nextElement?: HTMLInputElement): void {
    if (nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
      //nextElement.focus();
    } else {
      this._focused.set(false);
      //this.onFocusOut()
    }
  }

  // autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
  //   if (!control.value) {
  //     this._focusMonitor.focusVia(prevElement, 'program');
  //   }
  // }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.duration-input-container',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    if (this.parts.controls.hours.invalid) {
      this._focusMonitor.focusVia(this.hoursInput(), 'program');
    }
    // else if (this.parts.controls.minutes.valid) {
    //   this._focusMonitor.focusVia(this.minutesInput(), 'program');
    // } else {
    //   this._focusMonitor.focusVia(this.hoursInput(), 'program');
    // }
  }

  writeValue(duration: string | null): void {
    this.value = duration;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabledByCva.set(isDisabled);
  }

  _handleInput(
    event: InputEvent,
    control: AbstractControl<number | null>,
    nextElement?: HTMLInputElement,
  ): void {
    const _value = (event.target as HTMLInputElement).value;
    if (control.valid && _value.length < 3) {
      const newValue = _value.padStart(2, '0');
      (event.target as HTMLInputElement).value = newValue;
    } else if (_value) {
      const newValue = _value.slice(1);
      control.setValue(parseInt(newValue));
      (event.target as HTMLInputElement).value = newValue;
      this.autoFocusNext(nextElement);
    }
  }

  _eraseInput(control: AbstractControl, element: HTMLInputElement): void {
    control.reset(null);
    element.value = '';
  }

  private deserialize = (durationStr?: string | null) => {
    const res = durationStr ? parseDuration(durationStr) : null;
    if (res) {
      return {
        minutes: res.minutes,
        hours: res.hours,
      };
    }
    return null;
  };

  private serialize = (payload: { hours: number; minutes: number }) =>
    Temporal.Duration.from({
      hours: payload.hours,
      minutes: payload.minutes,
    }).toString();
}
