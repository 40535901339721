<span class="font-medium text-base" i18n matDialogTitle
  >Would you like to {{ action }} the following medical history
</span>
<main matDialogContent>
  <quill-view
    [content]="data.medicalHistory.content"
    format="html"
    theme="snow"
  ></quill-view>
</main>
<footer align="end" matDialogActions>
  <button matDialogClose mat-button>no</button>
  <button [matDialogClose]="payload" mat-flat-button color="primary">
    yes
  </button>
</footer>
