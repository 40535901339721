import { CommonModule, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  LOCALE_ID,
  computed,
  inject,
} from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AuthService } from '@doctorus-front-end-monorepo/auth';
import {
  Account,
  GeneratedDocument,
  Patient,
} from '@doctorus-front-end-monorepo/graphql';
import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';
import { formatISO } from 'date-fns';
import Handlebars from 'handlebars';
import { QuillEditorComponent } from 'ngx-quill';
import { filter, from, map, startWith, switchMap } from 'rxjs';
import { BaseEntityFormComponent } from '../../../../../shared/feature-entity/src';
import { FormContainerComponent } from '../../../../../shared/ui-layout/src';

@Component({
    selector: 'generated-document-write-generated-document',
    templateUrl: './write-generated-document.component.html',
    styleUrls: ['./write-generated-document.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatDialogModule,
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        UiMatFormFieldErrorDisplayerDirective,
        MatInputModule,
        MatDatepickerModule,
        FormContainerComponent,
        QuillEditorComponent,
        NgIf,
        MatIconModule,
    ]
})
export class WriteGeneratedDocumentComponent extends BaseEntityFormComponent<GeneratedDocument> {
  private as = inject(AuthService);
  private localId = inject(LOCALE_ID);
  account = computed<Account | undefined>(() => this.extra()?.account);
  patient = computed<Patient | undefined>(() => this.extra()?.patient);
  documentModels$ = this.as.authUserInfo$.pipe(
    map(
      x =>
        this.account()?.documentModels?.map(document => ({
          ...document,
          ...(document?.template && {
            template: Handlebars.compile(document.template)({
              patient: this.patient(),
              user: x,
              today: new Date().toLocaleDateString(this.localId),
            }),
          }),
        })) ?? [],
    ),
  );

  documentModelControl = this.fb.control(
    this.obj() ? undefined : this.account()?.defaultDocumentModel?.name,
  );

  override ngOnInit() {
    super.ngOnInit();
    if (!this.obj) {
      this.documentModelControl.setValue(
        this.account()?.defaultDocumentModel?.name,
      );
    }
    this.documentModelControl.valueChanges

      .pipe(
        startWith(this.documentModelControl.value),
        filter(x => (x ? true : false)),
        switchMap(x =>
          from(this.documentModels$).pipe(
            map(models =>
              models.find(
                model => model.name?.toLowerCase() === x?.toLowerCase(),
              ),
            ),
          ),
        ),
        filter(x => (x ? true : false)),
      )
      .subscribe(x => {
        this.form.patchValue({
          content: x?.template,
          title: x?.documentTitle,
        });
      });
  }

  override initFormFun = (...args: any[]) =>
    (this.form = this.fb.group({
      id: [this.obj()?.id],
      title: [this.obj()?.title, Validators.required],
      content: [this.obj()?.content],
      patient: [this.extra()?.patient?.id, Validators.required],
      date: [
        this.obj()?.date ??
          formatISO(new Date(), {
            representation: 'date',
          }),
      ],
    }));

  compareFun = (a: any, b: any) => a?.name === b?.name;

  toggleNoteField(): void {
    this.form.get('content')?.touched
      ? this.form.get('content')?.markAsUntouched()
      : this.form.get('content')?.markAsTouched();
  }
}
