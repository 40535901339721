import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, switchMap, tap } from 'rxjs/operators';
import {
  DocumentType,
  PrintDocumentGQL,
} from '../../../data-access/graphql/src';
import { PdfConfigDialogComponent } from './pdf-config-dialog/pdf-config-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class PdfConfigDialogService {
  constructor(
    private matDialog: MatDialog,
    private printDocumentGql: PrintDocumentGQL,
  ) {}

  // private downloadPdf(endPoint: string, payload: any): Observable<Blob> {
  //   const query: string = [
  //     process.env['NX_REST_API_URL'],
  //     'api',
  //     endPoint,
  //   ].join('/');
  //   return this.http
  //     .post<any>(query, payload, {
  //       headers: new HttpHeaders({
  //         'content-type': 'application/json',
  //       }),
  //       responseType: 'blob' as 'json',
  //     })
  //     .pipe(map(res => new Blob([res], { type: 'application/pdf' })));
  // }

  openPDFConfigDialog(documentId: string, documentType: DocumentType) {
    return this.matDialog
      .open(PdfConfigDialogComponent, {
        width: '480px',
        backdropClass: 'overlay--transition-none',
      })
      .beforeClosed()
      .pipe(
        filter(payload => (payload ? true : false)),
        switchMap(payload =>
          this.printDocumentGql.fetch({
            payload: {
              ...payload,
              document_id: documentId,
              document_type: documentType,
            },
          }),
        ),
        tap(x => window.open(x.data.printDocument.url, '_blank')),
      );
  }
}
