import { Route } from '@angular/router';
import { UpdatePreferencesGQL } from '../../../../shared/data-access/graphql/src';
import {
  DetailsWrapperComponent,
  ENTITY_CONFIG,
  ENTITY_DETAILS_COMPONENT,
  ENTITY_WRITE_COMPONENT,
  EntityConfig,
  EntityMutationService,
  WriteWrapperComponent,
} from '../../../../shared/feature-entity/src';
import { PatientProperyModelFormComponent } from './patient-propery-model-form/patient-propery-model-form.component';
import { UiPatientPropertiesComponent } from './ui-patient-properties/ui-patient-properties.component';

export const uiPatientPropertiesRoutes: Route = {
  path: 'patient-property-models',
  data: {
    breadcrumbChunck: { name: $localize`patient-property-models` },
  },
  providers: [
    {
      provide: ENTITY_CONFIG,
      useValue: new EntityConfig({
        feature: $localize`patient property model`,
      }),
    },
    {
      provide: ENTITY_WRITE_COMPONENT,
      useValue: PatientProperyModelFormComponent,
    },
    {
      provide: ENTITY_DETAILS_COMPONENT,
      useValue: UiPatientPropertiesComponent,
    },
    {
      provide: EntityMutationService,
      useClass: UpdatePreferencesGQL,
    },
  ],
  children: [
    {
      path: '',
      data: {
        breadcrumbChunck: { name: null },
      },
      component: DetailsWrapperComponent,
    },
    {
      path: 'edit',
      data: {
        breadcrumbChunck: { name: $localize`edit` },
      },
      component: WriteWrapperComponent,
    },
  ],
};
