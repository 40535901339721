import { CommonModule } from '@angular/common';
import {
  Component,
  HostListener,
  computed,
  input,
  signal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TitleCaseFirstPipe } from '../../../../../../apps/medical-space-web/src/app/core/pipes/title-case-first.pipe';
import { NavTreeComponent } from '../nav-tree/nav-tree.component';
import { NavLink } from '../types';
@Component({
    selector: 'ui-layout-nav-tree-element',
    imports: [
        CommonModule,
        MatIconModule,
        NavTreeComponent,
        TitleCaseFirstPipe,
        RouterModule,
    ],
    templateUrl: './nav-tree-element.component.html',
    styleUrl: './nav-tree-element.component.scss'
})
export class NavTreeElementComponent {
  level = input(0);
  item = input.required<NavLink>();
  hasChildren = computed(
    () => this.item().children && this.item().children?.length > 0,
  );
  paddingStyle = computed(() => `${this.level() * 12 + 16}px`);
  fontSize = computed(
    () => `${16 - this.level() * 1.5 >= 14 ? 18 - this.level() * 1.5 : 14}px`,
  );
  expanded = signal(false);
  icon = computed(() => (this.expanded() ? 'expand_less' : 'expand_more'));
  @HostListener('click', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    this.expanded.update(value => !value);
  }
}
