<div class="sticky top-0 pb-4 bg-white z-20">
  <!-- <header class="flex justify-between items-center mb-4">
    <h2 class="capitalize" i18n>patients <span class="text-gray-600">({{queryResponse()?.count}})</span></h2>
    <button [routerLink]="['new']" mat-flat-button>new patient</button>
  </header> -->
  <section class="flex gap-4 flex-wrap items-start ">
    <mat-form-field subscriptSizing="dynamic" class="compact-text-field w-72" appearance="outline">
      <mat-select [value]="searchType()" (selectionChange)="changeSearchType($event.value)">
        <mat-option i18n value="profile">by profile information</mat-option>
        <!-- <mat-option i18n value="medical-tag">by medical tags</mat-option> -->
        <mat-option i18n value="medical-note">by medical notes</mat-option>

      </mat-select>
    </mat-form-field>
    <doctorus-front-end-monorepo-patient-search-form class="flex-1"
      (valueChanged)="searchTerm.set($event)"></doctorus-front-end-monorepo-patient-search-form>
    <button [routerLink]="['new']" mat-flat-button>new patient</button>

    <!-- <mat-paginator [pageIndex]="pageIndex()" showFirstLastButtons [length]="queryResponse()?.count" pageSize="50"
      [hidePageSize]="true" (page)="updatePage($event)" [pageSizeOptions]="[50, 100, 150]">
    </mat-paginator> -->


  </section>
</div>
<table matSort (matSortChange)="updateSort($any($event))" mat-table [dataSource]="queryResponse()?.results">
  <ng-container sticky matColumnDef="name">
    <th sticky mat-header-cell mat-sort-header="given_name" *matHeaderCellDef>
      <span class="first-letter:uppercase" i18n="full-name">name</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <a class="text-blue-700 font-medium flex items-center py-2" [routerLink]="['.', element.id]">
        {{ element | humanName:'full':true | titlecase }}</a>
    </td>
  </ng-container>
  <ng-container matColumnDef="gender">
    <th sticky mat-header-cell *matHeaderCellDef>
      <span class="first-letter:uppercase" i18n>gender</span>
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element | humanName:'genderOnly'| titlecase }}
    </td>
  </ng-container>
  <ng-container matColumnDef="reference">
    <th sticky mat-header-cell mat-sort-header="reference" *matHeaderCellDef>
      <span class="first-letter:uppercase" i18n>reference</span>
    </th>
    <td mat-cell *matCellDef="let element">{{element?.reference}}</td>
  </ng-container>
  <ng-container matColumnDef="phone_number">
    <th mat-header-cell *matHeaderCellDef>
      <span class="first-letter:uppercase" i18n>phone number</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="flex gap-1">
        @if(element.phone_numbers|arrayExist) {
        @for (phone_number of element.phone_numbers; track $index;let isLast = $last) {
        <patient-phone-number-box [phonenumber]="phone_number" mode="SMALL"></patient-phone-number-box>

        @if (!isLast) {
        <span>|</span>
        }

        }
        }@else {
        -
        }
      </div>

    </td>
  </ng-container>
  <ng-container matColumnDef="added">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="added">
      <span class="first-letter:uppercase" i18n>added</span>
    </th>
    <td mat-cell *matCellDef="let element">
      {{element.added|date:'shortDate'|coalesce:'-'}}
    </td>
  </ng-container>
  <ng-container matColumnDef="total_due">
    <th mat-header-cell *matHeaderCellDef>
      <span class="first-letter:uppercase" i18n>balance</span>
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element?.total_due | currency :currency : 'symbol' : '1.0-1' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="headline">
    <th mat-header-cell *matHeaderCellDef>
    </th>
    <td mat-cell *matCellDef="let element">
      <div [innerHTML]="element.headline"></div>
    </td>
  </ng-container>

  <ng-container matColumnDef="action" stickyEnd>
    <th mat-header-cell i18n="action" *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <a *showForRoles="[accountRole.DOCTOR]" i18n class="anchor" i18n
        [routerLink]="['.', element.id,'medical-record',{outlets:{center:['summary']}}]">
        medical record</a>
    </td>
  </ng-container>



  <tr mat-header-row *matHeaderRowDef="displayedColumns()"></tr>
  <tr queryParamsHandling="merge" mat-row *matRowDef="let row; columns: displayedColumns()"></tr>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">
      <ui-layout-empty-state>
        <p i18n>no patients</p>
        <button ngProjectAs="actions" mat-stroked-button>new patient</button>
      </ui-layout-empty-state>

  </tr>
</table>
<!-- @if(loading()) {
<ui-layout-loading>
  <p i18n>fetching patients</p>

</ui-layout-loading>
}@else {
<table matSort (matSortChange)="updateSort($any($event))" mat-table [dataSource]="queryResponse()?.results">
  <ng-container sticky matColumnDef="name">
    <th sticky mat-header-cell mat-sort-header="family_name" *matHeaderCellDef>
      <span class="first-letter:uppercase" i18n="full-name">name</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <a class="text-blue-700 font-medium flex items-center py-2" [routerLink]="['.', element.id]">
        {{ element | humanName:'full':true | titlecase }}</a>
    </td>
  </ng-container>
  <ng-container matColumnDef="gender">
    <th sticky mat-header-cell *matHeaderCellDef>
      <span class="first-letter:uppercase" i18n>gender</span>
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element | humanName:'genderOnly'| titlecase }}
    </td>
  </ng-container>
  <ng-container matColumnDef="reference">
    <th sticky mat-header-cell mat-sort-header="reference" *matHeaderCellDef>
      <span class="first-letter:uppercase" i18n>reference</span>
    </th>
    <td mat-cell *matCellDef="let element">{{element?.reference}}</td>
  </ng-container>
  <ng-container matColumnDef="phone_number">
    <th mat-header-cell *matHeaderCellDef>
      <span class="first-letter:uppercase" i18n>phone number</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="flex gap-1">
        @if(element.phone_numbers|arrayExist) {
        @for (phone_number of element.phone_numbers; track $index;let isLast = $last) {
        <patient-phone-number-box [phonenumber]="phone_number" mode="SMALL"></patient-phone-number-box>

        @if (!isLast) {
        <span>|</span>
        }

        }
        }@else {
        -
        }
      </div>

    </td>
  </ng-container>
  <ng-container matColumnDef="added">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="added">
      <span class="first-letter:uppercase" i18n>added</span>
    </th>
    <td mat-cell *matCellDef="let element">
      {{element.added|date:'shortDate'|coalesce:'-'}}
    </td>
  </ng-container>
  <ng-container matColumnDef="total_due">
    <th mat-header-cell *matHeaderCellDef>
      <span class="first-letter:uppercase" i18n>balance</span>
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element?.total_due | currency : 'TND' : 'symbol' : '1.0-1' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="headline">
    <th mat-header-cell *matHeaderCellDef>
    </th>
    <td mat-cell *matCellDef="let element">
      <div [innerHTML]="element.headline"></div>
    </td>
  </ng-container>

  <ng-container matColumnDef="action" stickyEnd>
    <th mat-header-cell i18n="action" *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <a i18n class="anchor" i18n [routerLink]="['.', element.id,'medical-record']">
        medical record</a>
    </td>
  </ng-container>



  <tr mat-header-row *matHeaderRowDef="displayedColumns()"></tr>
  <tr queryParamsHandling="merge" mat-row *matRowDef="let row; columns: displayedColumns()"></tr>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">
      <ui-layout-empty-state>
        <p i18n>no patients</p>
        <button ngProjectAs="actions" mat-stroked-button>new patient</button>
      </ui-layout-empty-state>

  </tr>
</table>

} -->