import { Pipe, PipeTransform } from '@angular/core';
import { TCountryCode, getCountryData } from 'countries-list';

@Pipe({
  name: 'countryInfo',
  standalone: true,
})
export class CountryInfoPipe implements PipeTransform {
  transform(iso2countryCode: string | undefined | null) {
    return iso2countryCode
      ? getCountryData(iso2countryCode as unknown as TCountryCode)
      : null;
  }
}
