import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { formatDuration } from 'date-fns';
import { AccountLocation } from '../../../../../shared/data-access/graphql/src';
import { minutesToTime } from '../../../../../shared/util-formatting/src';

@Component({
  selector: 'lib-ui-locations',
  standalone: true,
  imports: [CommonModule, MatTableModule, RouterModule],
  templateUrl: './ui-locations.component.html',
  styleUrl: './ui-locations.component.scss',
})
export class UiLocationsComponent {
  objs = input.required<AccountLocation[]>();

  getWorkingHours = (working_periods: number[][]): string => {
    return working_periods
      ?.map(x => `[${minutesToTime(x[0])} -> ${minutesToTime(x[1])}]`)
      .join('  -  ');
  };
  getSlotIntervals = (slot_interval: number): string => {
    return formatDuration(
      {
        minutes: slot_interval,
      },
      { format: ['minutes'] },
    );
  };
}
