import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';
import { ListWrapperComponent } from '@doctorus-front-end-monorepo/feature-entity';
import { PatientDataService } from '@doctorus-front-end-monorepo/graphql';
import { HumanNamePipe } from '@doctorus-front-end-monorepo/shared-util';
import { map, tap } from 'rxjs';

@Component({
  selector: 'lib-care-plans-list',
  imports: [
    CommonModule,
    RouterModule,
    HumanNamePipe,
    MatDividerModule,
    ListWrapperComponent,
  ],
  templateUrl: './care-plans-list.component.html',
  styleUrl: './care-plans-list.component.scss',
})
export class CarePlansListComponent {
  objs = toSignal(
    inject(PatientDataService)?.selectedPatient$.pipe(
      tap(x => console.warn(x)),
      map(x => x?.care_plans ?? []),
    ),
  );
}
