<table mat-table class="bg-white" [dataSource]="objs()">
  <!-- User name Definition -->
  <ng-container matColumnDef="type">
    <mat-header-cell *matHeaderCellDef i18n>type</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <a [routerLink]="row.id" class="anchor">{{row.type}}</a>
    </mat-cell>
  </ng-container>

  <!-- Age Definition -->
  <ng-container matColumnDef="order">
    <mat-header-cell *matHeaderCellDef i18n>order</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.order}}
    </mat-cell>
  </ng-container>


  <mat-header-row *matHeaderRowDef="['type','order']"></mat-header-row>
  <mat-row *matRowDef="let row; columns: ['type','order']"></mat-row>
</table>
