import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseEntityListComponent } from '../../../../../shared/feature-entity/src';
import { DocumentModel } from '../../../../../shared/data-access/graphql/src';
import { MatTable, MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'lib-ui-document-models',
    imports: [CommonModule, MatTableModule, RouterModule],
    templateUrl: './ui-document-models.component.html',
    styleUrl: './ui-document-models.component.scss'
})
export class UiDocumentModelsComponent extends BaseEntityListComponent<DocumentModel> {

  fields = ['name', 'documentTitle']
}
