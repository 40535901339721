import { CommonModule } from '@angular/common';
import { Component, computed, inject, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTable, MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { ListWrapperComponent } from '@doctorus-front-end-monorepo/feature-entity';
import {
  AccountDataService,
  allChecks,
  MedicalHistory,
  MedicalHistoryStatusInput,
  PatientDataService,
  SetMedicalHistoryStatusGQL,
} from '@doctorus-front-end-monorepo/graphql';
import { StatusComponent } from '@doctorus-front-end-monorepo/shared-util';
import { FeedbackComponent } from '@doctorus-front-end-monorepo/ui-layout';
import { QuillViewHTMLComponent } from 'ngx-quill';
import { map, switchMap, takeWhile, tap } from 'rxjs';
import { MedicalHistoryStatusDialogComponent } from '../../medical-history-status-dialog/medical-history-status-dialog.component';

@Component({
  selector: 'medical-history-medical-history-table',
  templateUrl: './medical-history-table.component.html',
  styleUrl: './medical-history-table.component.css',
  standalone: true,
  imports: [
    MatTableModule,
    MatPaginatorModule,
    RouterModule,
    MatSortModule,
    CommonModule,
    FeedbackComponent,
    MatButton,
    StatusComponent,
    QuillViewHTMLComponent,
    ListWrapperComponent,
    RouterModule,
  ],
})
export class MedicalHistoryTableComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<any>;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['name', 'date', 'content', 'status'];

  medicalHistoryModels =
    inject(AccountDataService).account?.medicalHistoryModels ?? [];

  objs = toSignal(
    inject(PatientDataService)?.selectedPatient$.pipe(
      tap(x => console.warn(x)),

      map(x => x?.medical_histories ?? []),
    ),
  );

  data = computed(() =>
    allChecks(this.objs() ?? [], this.medicalHistoryModels, 'name', 'type'),
  );

  private matDialog = inject(MatDialog);
  private matSnackBar = inject(MatSnackBar);
  private updateMedicalHistoryStatusGql = inject(SetMedicalHistoryStatusGQL);

  openStatus(medicalHistory: MedicalHistory): void {
    this.matDialog
      .open<
        MedicalHistoryStatusDialogComponent,
        {
          medicalHistory: MedicalHistory;
        },
        MedicalHistoryStatusInput
      >(MedicalHistoryStatusDialogComponent, {
        data: { medicalHistory },
      })
      .afterClosed()
      .pipe(
        takeWhile(res => !!res),
        switchMap(payload =>
          this.updateMedicalHistoryStatusGql.mutate({ payload }),
        ),
      )
      .subscribe(() =>
        this.matSnackBar.open($localize`medical history status updated`),
      );
  }
}
