import { CommonModule } from '@angular/common';
import { Component, computed } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTimepickerModule } from '@angular/material/timepicker';
import { BaseEntityFormComponent } from '@doctorus-front-end-monorepo/feature-entity';
import { CarePlan, MedicalTask } from '@doctorus-front-end-monorepo/graphql';

@Component({
  selector: 'lib-write-medical-task-dialog',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatTimepickerModule,
  ],
  templateUrl: './write-medical-task-dialog.component.html',
  styleUrl: './write-medical-task-dialog.component.scss',
})
export class WriteMedicalTaskDialogComponent extends BaseEntityFormComponent<
  MedicalTask,
  { care_plan: CarePlan }
> {
  care_plan = computed(() => this.extra()?.care_plan);

  override initFormFun = () =>
    this.fb.group({
      id: [this.obj()?.id],
      care_plan: [
        this.obj()?.care_plan?.id ?? this.care_plan()?.id,
        Validators.required,
      ],
      at: [new Date(this.obj()?.at ?? null)],
      notes: [this.obj()?.notes ?? this.queryParams()?.notes],
    });
}
