import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  booleanAttribute,
  numberAttribute,
} from '@angular/core';
enum ContainerLevel {
  PAGE = 1,
  CONTAINER,
  SECTION,
}
enum PadingLevel {
  NONE = 0,
  SMALL,
  MEDIUM,
  LARGE,
}
@Component({
  selector: 'ui-layout-container',
  standalone: true,
  imports: [CommonModule],
  templateUrl: `./container.component.html`,
})
export class ContainerComponent {
  @Input() title!: string | null;
  @Input() description?: string | null;
  @Input({ transform: booleanAttribute }) border = true;
  @Input({ transform: booleanAttribute }) contentBorder = false;
  @Input({ transform: booleanAttribute }) headerDivider = false;
  @Input({ transform: booleanAttribute }) sticky = false;
  @Input({ transform: numberAttribute })
  level: ContainerLevel = 2;
  @Input({ transform: numberAttribute })
  paddingLevel: PadingLevel = 1;

  get paddingClass() {
    return `p-${(4 * this.paddingLevel).toString()}`;
  }

  get classes(): string {
    return `${this.paddingClass} ${this.border ? 'rounded-md border-gray-200 border' : ''} h-full`;
  }

  get contentClasses(): string {
    return `${this.contentBorder ? 'rounded-md border-gray-200 border p-4' : ''} flex flex-col gap-4`;
  }
}
