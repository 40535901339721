import { AppointmentPublicFieldsFragment } from '@doctorus-front-end-monorepo/graphql';
import {
  EnumDisplayConfig,
  STATUS_CONF,
} from '@doctorus-front-end-monorepo/shared-type';
import { appointmentConfig } from './appointment-entity.model';

export class AppointmentBase {
  statusConfig = (
    appointment: AppointmentPublicFieldsFragment,
  ): EnumDisplayConfig => STATUS_CONF.appointment[appointment.status];
  entityConfig = appointmentConfig;
  status = AppointmentBase;
  dateSortFun = (a: string, b: string): number => {
    return (
      new Date(a.split(' ')[0]).getTime() - new Date(b.split(' ')[0]).getTime()
    );
  };
}
