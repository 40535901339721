<main class="flex flex-col gap-4">

  <section class="grid grid-cols-3 gap-4">
    <ui-layout-key-value key="name" i18n-key>
      {{obj().name}}
    </ui-layout-key-value>
    @if (obj().patient) {
      <ui-layout-key-value key="patient" i18n-key>
        {{obj().patient|humanName}}
      </ui-layout-key-value>
    }




    <ui-layout-key-value key="contributors" i18n-key>
      <ul>
        @for (item of obj().contributors; track $index) {
        <li>{{item|humanName}}</li>
        }
      </ul>
    </ui-layout-key-value>

    <ui-layout-key-value key="location" i18n-key>
      {{obj().location.name}}
    </ui-layout-key-value>
    <ui-layout-key-value class="col-span-2" key="period" i18n-key>
      {{obj().start|date:'short'}} -> {{obj().end|date:'short'}}

    </ui-layout-key-value>
    <ui-layout-key-value key="frequency" i18n-key>
      <span i18n>{{obj().frequency}} a day</span>

    </ui-layout-key-value>
    <ui-layout-key-value class="col-span-3" key="notes" i18n-key>

      <p>{{obj().notes|coalesce:'-'}}</p>
    </ui-layout-key-value>

  </section>

  <ui-layout-container border="false"  paddingLevel="0" level="2" [title]="medicalTasksTitle()" i18n-title>
    <button (click)="writeMedicalTask()" ngProjectAs="actions" mat-stroked-button><span i18n>
      add medical task
    </span></button>
    <section class="flex flex-col gap-2">
      @for (medicalTask of obj().medical_tasks; track $index) {
        <ui-layout-container paddingLevel="1" level="1"  [title]="medicalTask.at | date:'medium'">
          <p ngProjectAs="description">by {{medicalTask.by | humanName}}</p>
          <div ngProjectAs="actions">
            <button mat-icon-button><mat-icon>delete</mat-icon></button>
            <button (click)="writeMedicalTask(medicalTask)" mat-icon-button><mat-icon>edit</mat-icon></button>
          </div>
          @if (medicalTask.notes) {
            <p>{{medicalTask.notes}}</p>
          }
        </ui-layout-container>
      } @empty { 
        <ui-layout-empty-state>
          <span i18n>no medical tasks for this care plan</span>
        </ui-layout-empty-state>
      }

    </section>
  </ui-layout-container>
</main>
