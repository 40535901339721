@let patients = patientRx.value()?.results ?? [];
@let count = patientRx.value()?.count ?? 0;
@let loading = patientRx.isLoading();

<header class="py-4 flex items-center px-2">
  <input
    [formControl]="searchCtrl"
    #input
    placeholder="Search ..."
    i18n-placeholder
    class="text-lg font-normal px-2 outline-none text-black w-full"
    type="text"
  />
  <mat-icon (click)="close()" class="hover:cursor-pointer">close</mat-icon>
</header>
<mat-divider></mat-divider>
@if (hasInput()) {
  @if (loading) {
    <ui-layout-loading><span i18n>search patients ...</span></ui-layout-loading>
  } @else {
    <mat-paginator
      [length]="count"
      [pageSize]="20"
      [pageIndex]="page()"
      hidePageSize
      (page)="page.set($event.pageIndex)"
    >
    </mat-paginator>
    <mat-nav-list>
      @for (patient of patients; track patient.id) {
        <mat-list-item
          [routerLink]="
            patient.is_active ? rhns.getPatientRoute(patient) : null
          "
        >
          <div matListItemTitle>{{ patient | humanName }}</div>
        </mat-list-item>
      } @empty {
        <ui-layout-empty-state>
          <h3 i18n>no patients found</h3>
        </ui-layout-empty-state>
      }
    </mat-nav-list>
  }
} @else {
  <ui-layout-empty-state>
    <h3 i18n>
      Search patients by their name, email, reference number or phone number
    </h3>
  </ui-layout-empty-state>
}
