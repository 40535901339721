@if(appointments()|arrayExist) {
<ul class="flex flex-col gap-2 items-stretch">
  @for (appointment of appointments(); track $index;let last = $last) {
  <li (click)="selected.set(appointment)" [class.hover:bg-gray-100]="selectable"
    [class.hover:cursor-pointer]="selectable" class="px-1 py-2 rounded-md ease-out flex flex-col gap-2 ">
    <div class="flex items-center gap-2 w-full">
      <appointment-status-picker disabled="!editableStatus" (statusChange)="updateStatus($event,appointment)"
        [status]="appointment.status">
        @if (appointment.status|resolveStatusConfig:'appointment';as status) {
        <mat-icon [matTooltip]="status.label" [style.color]="status.color">{{status.svgIcon}}</mat-icon>
        }

      </appointment-status-picker>
      <div class="flex flex-col w-full">
        <div class="flex items-center justify-between">
          <appointment-dates [timeOnly]="timeOnly" class=" text-base font-medium"
            [appointment]="appointment"></appointment-dates>
          @if(editable) {
          <button class="ml-auto" (click)="deleteAppointment($any(appointment))"
            mat-icon-button><mat-icon>delete</mat-icon></button>
          <button (click)="writeAppointment($any(appointment))" mat-icon-button><mat-icon>edit</mat-icon></button>


          }
        </div>

        @if (includePatientInfo) {
        @if(appointment.patient_info) {
        <a [routerLink]="rnhs.getPatientRoute(appointment.patient_info.id)"
          class="text-gray-700 text-sm anchor">{{appointment.patient_info|humanName:'full':true}}</a>

        }

        }
      </div>
      @if(includeMedicalBoardLink) {
      <button [routerLink]="rnhs.medicalRecordRoute(appointment.patient_info?.id,appointment.id)"
        *showForRoles="[accountRole.DOCTOR]" mat-icon-button>
        <mat-icon>medical_information</mat-icon>
      </button>


      }
    </div>
    <mat-chip-set class="mat-chip-sm text-sm">


      @if(includeMeta) {
      @if(appointment.task_type) {
      <mat-chip>
        <p matChipAvatar class="w-3 h-3 rounded-full" [style.background-color]="appointment.task_type.color"></p>
        <p class="chip-text">{{appointment.task_type.name}}</p>


      </mat-chip>

      }

      @if(appointment.location) {
      <mat-chip *authShowForMultiLocations>
        <mat-icon matChipAvatar>location_on</mat-icon>

        <p class="chip-text">{{appointment.location.name}}</p>

      </mat-chip>

      }
      @if(appointment.fees) {
      <mat-chip>
        <mat-icon matChipAvatar>payments</mat-icon>
        <p class="chip-text"> {{appointment.fees}}</p>

      </mat-chip>

      }
      @if(appointment.description) {
      <mat-chip>
        <mat-icon matChipAvatar>medical_information</mat-icon>
        <p class="chip-text">{{appointment.description}}</p>

      </mat-chip>

      }
      }

    </mat-chip-set>

  </li>
  @if (!(last || appointments.length === 1)) {
  <mat-divider></mat-divider>
  }
  }
</ul>
}
<mat-menu #actionMenu>
  <ng-template matMenuContent let-appointment="appointment">
    <button (click)="writeAppointment(appointment)" mat-menu-item>
      <mat-icon>edit</mat-icon>
      <span i18n>edit</span>
    </button>
    <button (click)="deleteAppointment(appointment)" mat-menu-item>
      <mat-icon>delete</mat-icon>
      <span i18n>delete</span>
    </button>
  </ng-template>
</mat-menu>