import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { Router, RouterLink } from '@angular/router';
import { filter, first, map, switchMap, tap } from 'rxjs';
import {
  AuthService,
  UserAttributes,
} from '../../../../../../../libs/shared/auth/src';
import {
  CreateAccountGQL,
  GetMembershipsGQL,
} from '../../../../../../../libs/shared/data-access/graphql/src';
import { PhoneNumberBoxComponent } from '../../../../../../../libs/shared/patient/feature-patient/src';
import {
  AvatarLoaderDirective,
  HumanNamePipe,
} from '../../../../../../../libs/shared/shared-util/src';
import {
  ContainerComponent,
  GroupContainerComponent,
  KeyValueComponent,
} from '../../../../../../../libs/shared/ui-layout/src';
import { PhoneNumberPipe } from '../../../../../../../libs/shared/util-formatting/src';
import { EditUserDialogComponent } from '../edit-user-dialog/edit-user-dialog.component';
import { NewOfficeDialogComponent } from '../new-office-dialog/new-office-dialog.component';

@Component({
  selector: 'doctorus-front-end-monorepo-staff-board',
  templateUrl: './staff-board.component.html',
  styleUrls: ['./staff-board.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatIconModule,
    MatButtonModule,
    AvatarLoaderDirective,
    MatListModule,
    ContainerComponent,
    PhoneNumberPipe,
    MatTableModule,
    PhoneNumberBoxComponent,
    GroupContainerComponent,
    KeyValueComponent,
    MatGridListModule,
    NgFor,
    RouterLink,
    AsyncPipe,
    HumanNamePipe,
  ],
})
export class StaffBoardComponent {
  authUser$ = this.as.authUserInfo$;
  //isNotApproved$ = this.as.user$.pipe(map(user => !approvedUser(user)));
  loading = false;
  // isNotProfileCompleted$ = this.as.user$.pipe(
  //   map(user => !completeProfile(user)),
  // );
  memberships$ = this.getMembershipsGQL.watch().valueChanges.pipe(
    tap(x => (this.loading = x.loading)),
    map(x => x.data.getMemberships),
  );
  displayedColumns: string[] = ['account', 'roles'];

  constructor(
    private getMembershipsGQL: GetMembershipsGQL,
    private createAccountGql: CreateAccountGQL,
    private as: AuthService,
    private dialog: MatDialog,
    private router: Router,
  ) {}

  testRedirect(): void {
    this.router.navigate(['staff-board', 'office-created', 8]);
  }
  openNewOfficeDialog(): void {
    this.dialog
      .open(NewOfficeDialogComponent, {
        width: '600px',
      })
      .afterClosed()
      .pipe(
        first(),
        filter(result => (result ? true : false)),
        switchMap(result => this.createAccountGql.mutate(result)),
      )
      .subscribe(office => {
        this.router.navigate(
          ['staff-board', 'office-created', office.data.createAccount.id],
          {},
        );
      });
  }
  editProfileDialog(
    authUser: Partial<Omit<UserAttributes, 'account_id'>>,
  ): void {
    this.dialog
      .open(EditUserDialogComponent, {
        width: '600px',
        data: authUser,
      })
      .afterClosed()
      .pipe(
        first(),
        filter(result => (result ? true : false)),
        switchMap(result => this.as.updateUser(result)),
      )
      .subscribe();
  }
}
