<!-- <mat-card appearance="raised">
  <mat-card-header class="flex items-center">
    <div mat-card-avatar>
      <button mat-icon-button (click)="toggleMode()"><mat-icon>{{modeSwitchIcon()}}</mat-icon></button>

    </div>
    <mat-card-title>{{obj().name}} ({{obj().unit}})</mat-card-title>
    @if (obj().description) {
    <mat-card-subtitle>
      {{obj().description}}
    </mat-card-subtitle>
    }

  </mat-card-header>
  <mat-card-content>
    <p class="mb-2 text-gray-700">{{observations().length}} observations@if (latestObs(); as
      obs)
      {<span i18n>, latest : {{obs.value}} {{obj().unit}} on {{obs.date
        |date}}</span>}</p>
    <div [style.height]="chartHeight()" class="chart-container w-full">
      <canvas baseChart [data]="lineChartData()" [options]="lineChartOptions()" [type]="'line'"> </canvas>

    </div>
    @if(mode() === 'LARGE') {
    <ul>
      @for (observation of (observations()); track $index;let first = $first) {
      <mat-divider></mat-divider>

      <li>


        <medical-measure-observation-box [observation]="observation"
          [measure]="measure()"></medical-measure-observation-box>
      </li>


      }
    </ul>
    }
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions align="end">
    <button color="warn" (click)="delete()" mat-icon-button><mat-icon>delete</mat-icon></button>
    <button (click)="edit()" mat-icon-button><mat-icon>edit</mat-icon></button>
    <button (click)="addObservation()" mat-button>
      <mat-icon>blood_pressure</mat-icon>
      <span i18n>add observation</span>
    </button>
  </mat-card-actions>
</mat-card> -->
<section class="grid grid-cols-4 gap-4 mb-6">
  <ui-layout-key-value key="name" i18n-key>
    {{obj().name}}
  </ui-layout-key-value>
  <ui-layout-key-value key="unit" i18n-key>
    {{obj().unit}}
  </ui-layout-key-value>
  <ui-layout-key-value key="description" i18n-key>
    {{obj().description |coalesce:'-'}}
  </ui-layout-key-value>


</section>

<ui-layout-container level="3" [border]="true" [title]="observationsTitle">
  <medical-measure-measure-observations-summary ngProjectAs="description"
    [measure]="obj()"></medical-measure-measure-observations-summary>

  <button [mat-menu-trigger-for]="menu" ngProjectAs="actions" (click)="addObservation()" mat-stroked-button><mat-icon
      i18n>blood_pressure
    </mat-icon><span i18n>new observation</span></button>
  <medical-measure-measure-graph [measure]="obj()"></medical-measure-measure-graph>

  <ul>
    @for (observation of (obj().observations); track $index;let first = $first) {
    <mat-divider></mat-divider>

    <li>


      <medical-measure-observation-box [observation]="observation" [measure]="obj()"></medical-measure-observation-box>
    </li>


    }
  </ul>
</ui-layout-container>


<!-- <ui-layout-container [border]="false" [title]="title">

  <p ngProjectAs="description">{{obj().description}}</p>

  <button [mat-menu-trigger-for]="menu" ngProjectAs="actions" mat-icon-button><mat-icon i18n>more_vert
    </mat-icon></button>



  <mat-divider></mat-divider>




</ui-layout-container> -->

<mat-menu #menu="matMenu">
  <ng-template matMenuContent>

    <button [routerLink]="['edit']" mat-menu-item>
      <mat-icon>edit</mat-icon>
      <span i18n>edit</span>
    </button>
    <button mat-menu-item>
      <mat-icon>delete</mat-icon>
      <span i18n>delete</span>
    </button>
  </ng-template>
</mat-menu>