<form class="grid grid-cols-1 lg:grid-cols-3  gap-8 w-full" [formGroup]="form">
  <mat-form-field class="col-span-full" uiFormMatFormFieldErrorDisplayer subscriptSizing="dynamic">
    <mat-label i18n>patient</mat-label>
    <mat-icon matPrefix>person</mat-icon>
    <mat-icon (click)="openPatientForm($event)" class="hover:cursor-pointer" matSuffix>person_add</mat-icon>
    <mat-select formControlName="patient" placeholder="patient">
      <mat-option>
        <div class="fles justify-between items-center">
          <ngx-mat-select-search [searching]="patientsLoading" i18n-placeholderLabel
            placeholderLabel="search by reference, name, phone number or email" i18n-noEntriesFoundLabel
            noEntriesFoundLabel="no matching patient found" [formControl]="patientFilterCtrl">
          </ngx-mat-select-search>
        </div>


      </mat-option>
      <ng-container *ngFor="let patient of filtredPatients()">
        <mat-option *ngIf="patientsLoading === false" [value]="patient.id">
          <span>{{ patient | humanName:'full':true }}</span>&nbsp;
          <span>{{ patient?.reference }}</span>
        </mat-option>
      </ng-container>
    </mat-select>

  </mat-form-field>

  <mat-form-field appearance="outline" uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n="appointment-type">type</mat-label>
    <mat-icon matPrefix>info</mat-icon>

    <mat-select (selectionChange)="updateExpectedDuration()" formControlName="task_type_id">
      <mat-option [value]="taskType.id" *ngFor="let taskType of taskTypes">
        {{taskType?.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>location</mat-label>
    <mat-icon matPrefix>location_on</mat-icon>
    <mat-select (selectionChange)="loadParams()" formControlName="location_id">
      <mat-option [value]="location.id" *ngFor="let location of locations">
        {{ location?.name}}
      </mat-option>
    </mat-select>
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>time zone</mat-label>
    <mat-icon matPrefix>language</mat-icon>

    <mtx-select [compareWith]="compareStr" (change)="loadParams()" formControlName="timezone">
      <mtx-option [value]="_timezone" *ngFor="let _timezone of timezones">
        {{ _timezone}}
      </mtx-option>
    </mtx-select>
    <mat-error></mat-error>
  </mat-form-field>



  <mat-accordion class="col-span-full">
    <mat-expansion-panel expanded>
      <mat-expansion-panel-header>
        <mat-panel-title class="first-letter:uppercase">select one slot time</mat-panel-title>
        @if (obj(); as appointment) {
        <mat-panel-description class="flex flex-wrap  gap-2 ">
          <p>{{appointment.start | date:'medium':offset}}</p>
          <p>-></p>
          <p>{{appointment.end | date:'medium':offset}}</p>
        </mat-panel-description>
        }

      </mat-expansion-panel-header>
      <section class="flex flex-col">
        <div class="flex gap-2 w-full flex-wrap">
          <mat-form-field class="flex-1">
            <mat-label i18n>choose a date</mat-label>
            <input (change)="loadParams()" #dateInput type="date" [formControl]="appointmentDay" matInput>
            <mat-icon (click)="dateInput.showPicker()" matSuffix>calendar_month</mat-icon>

          </mat-form-field>
          <mat-form-field class="flex-1" appearance="outline" uiFormMatFormFieldErrorDisplayer>
            <mat-label i18n>expected duration</mat-label>
            <mat-icon matPrefix>timer</mat-icon>

            <mat-select (valueChange)="loadParams()" [formControl]="expectedDurationCtrl">
              <mat-option [value]="duration" *ngFor="let duration of durations">
                {{ duration |duration}}
              </mat-option>
            </mat-select>
            <mat-error></mat-error>
          </mat-form-field>
        </div>
        <appointment-slot-picker [formControl]="slotSelectorCtr" [offset]="offset"
          [params]="slotFetchParams"></appointment-slot-picker>
      </section>
    </mat-expansion-panel>


    <!-- <mat-expansion-panel disabled>
      <mat-expansion-panel-header>
        <mat-panel-title> manually insert the appointment slot </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field>
        <mat-label>start</mat-label>
        <input #startInput type="datetime-local" formControlName="start" matInput>
        <mat-icon matIconSuffix class="hover:cursor-pointer" (click)="startInput.showPicker()">calendar_month</mat-icon>
      </mat-form-field>
      <mat-form-field>
        <mat-label>end</mat-label>
        <input #endInput type="datetime-local" formControlName="end" matInput>
        <mat-icon matIconSuffix class="hover:cursor-pointer" (click)="endInput.showPicker()">calendar_month</mat-icon>
      </mat-form-field>
      <p class=" text-red-700 first-letter:capitalize" i18n *ngIf="form.errors && form.errors['endAfterStart']">
        appointment end date can't be earlier than the start date
      </p>

    </mat-expansion-panel> -->
  </mat-accordion>





</form>