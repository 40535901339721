<form class="flex flex-col w-full gap-4" [formGroup]="form">
  <util-document-file-upload-field [initSrc]="obj()?.multi_sizes_urls?.M"
    formControlName="file"></util-document-file-upload-field>
  <!-- <util-document-file-uploader [url]="data.entity?.multi_sizes_urls?.O" [formControl]="fileCtr"
    class="w-1/2 flex-1"></util-document-file-uploader> -->
  <mat-form-field appearance="outline" uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>date</mat-label>
    <input placeholder="DD/MM/YYYY" #input matInput type="date" formControlName="date" />
    <mat-icon matIconSuffix class="hover:cursor-pointer" (click)="input.showPicker()">calendar_month</mat-icon>
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n>name</mat-label>
    <input formControlName="name" matInput type="text" />
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n>description</mat-label>
    <textarea matInput formControlName="description"></textarea>
    <mat-error></mat-error>
  </mat-form-field>
</form>
