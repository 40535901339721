<form class="flex flex-col gap-4 max-w-screen-md" [formGroup]="form">
  <mat-form-field appearance="outline" uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>date</mat-label>
    <input
      placeholder="DD/MM/YYYY"
      #input
      matInput
      type="date"
      formControlName="date"
    />
    <mat-icon
      matIconSuffix
      class="hover:cursor-pointer"
      (click)="input.showPicker()"
      >calendar_month</mat-icon
    >
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>category</mat-label>
    <input
      type="text"
      formControlName="name"
      matInput
      list="medicalHistoryNames"
      type="text"
    />
    <datalist id="medicalHistoryNames">
      @for (item of medicalHistoryNames$ | async; track $index) {
        <option [value]="item">{{ item }}</option>
      }
    </datalist>
    <mat-error></mat-error>
  </mat-form-field>
  <div>
    <rte-rte
      placeholder="enter medical history description"
      formControlName="content"
    ></rte-rte>
    <span
      i18n-placeholder
      class="pl-4 text-xs text-red-600"
      *ngIf="
        form &&
        form.get('content')?.invalid &&
        (form.get('content')?.dirty || form.get('content')?.touched)
      "
      i18n
      >medical history description can't be empty</span
    >
  </div>

  <fieldset class="flex flex-col gap-4">
    <legend
      i18n
      for="status-radio-group-label"
      class="text-lg first-letter:uppercase"
    >
      medical history status
    </legend>
    <mat-radio-group
      formControlName="status"
      class="flex flex-col gap-2"
      id="status-radio-group-label"
      aria-label="Select an option"
    >
      <mat-radio-button class="first-letter:uppercase text-lg" i18n [value]="1"
        >current</mat-radio-button
      >
      <mat-radio-button class="first-letter:uppercase text-lg" i18n [value]="0"
        >expired</mat-radio-button
      >
    </mat-radio-group>
  </fieldset>
</form>
