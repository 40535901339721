import { CommonModule } from '@angular/common';
import { Component, model } from '@angular/core';

@Component({
    selector: 'entity-base-entity-details',
    imports: [CommonModule],
    template: `<p>base-entity-details works!</p>`,
    styles: ``
})
export class BaseEntityDetailsComponent<T> {
  obj = model.required<T>();
}
