import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import {
  ControlContainer,
  FormArray,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UiMatFormFieldErrorDisplayerDirective } from '../../../../../form/ui-form/src';

@Component({
    imports: [
        CommonModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        UiMatFormFieldErrorDisplayerDirective,
    ],
    viewProviders: [
        {
            provide: ControlContainer,
            useFactory: () => inject(ControlContainer, { skipSelf: true }),
        },
    ],
    selector: 'patient-property-inline-form',
    templateUrl: './patient-property-inline-form.component.html',
    styleUrl: './patient-property-inline-form.component.scss'
})
export class PatientPropertyInlineFormComponent {
  @Input({ required: true }) index!: number;
  @Input({ required: true }) formArrayKey!: string;
  parentContainer = inject(ControlContainer);

  get formGroup() {
    return (
      this.parentContainer.control?.get(this.formArrayKey) as FormArray
    )?.at(this.index) as FormGroup;
  }
}
