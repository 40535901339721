import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  Component,
  ElementRef,
  HostBinding,
  inject,
  Input,
  numberAttribute,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FEEDBACK_MAP, FeedbackPattern, MessageType } from '../types';

@Component({
  selector: 'ui-layout-feedback',
  imports: [CommonModule, MatIconModule, MatButtonModule],
  templateUrl: './feedback.component.html',
  styleUrl: './feedback.component.scss',
})
export class FeedbackComponent {
  @Input({ transform: numberAttribute }) pattern: FeedbackPattern = 1;
  @Input({ transform: numberAttribute }) type: MessageType = 1;
  @Input({ transform: booleanAttribute }) removable = false;
  @Input({ transform: booleanAttribute }) withIcon = true;
  @Input({ transform: booleanAttribute }) fullWidth = true;

  @Input() title = '';
  @Input() message = '';

  private host = inject(ElementRef);

  get feedbackAttrs() {
    return FEEDBACK_MAP[this.type];
  }
  @HostBinding('class')
  get classes() {
    return [
      {
        condition: true,
        class: `flex gap-2 items-center border-${this.feedbackAttrs.color}-800 rounded-lg p-3 bg-${this.feedbackAttrs.color}-200`,
      },
      {
        condition: this.pattern === FeedbackPattern.ALERT,
        class: `border-2`,
      },
      {
        condition: this.fullWidth,
        class: `w-full `,
      },
    ]
      .filter(x => x.condition)
      .map(x => x.class)
      .join(' ');
  }

  get iconClasses() {
    return `text-${this.feedbackAttrs.color}-800 font-medium`;
  }

  dismiss() {
    this.host.nativeElement.remove();
  }

  // @HostBinding('class')
  // get classes() {
  //   return ` border-${this.feedbackAttrs.color}-800  flex gap-2  w-full rounded-lg p-3  bg-${this.feedbackAttrs.color}-200`;
  // }
}
