<section class="grid grid-cols-3 gap-6">
  <ui-layout-key-value key="name" i18n-key>{{obj().name}}</ui-layout-key-value>
  <ui-layout-key-value key="paper format" i18n-key>{{obj().paperFormat}}</ui-layout-key-value>
  <ui-layout-key-value key="margins" i18n-key>
    <ul>
      <li><span i18n>top</span> : {{obj().topMargin}} cm</li>
      <li><span i18n>right</span> : {{obj().rightMargin}} cm</li>
      <li><span i18n>bottom</span> : {{obj().bottomMargin}} cm</li>
      <li><span i18n>left</span> : {{obj().leftMargin}} cm</li>


    </ul>

  </ui-layout-key-value>


</section>