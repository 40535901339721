import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  computed,
  input,
  numberAttribute,
} from '@angular/core';
import { Measure } from '../../../../shared/data-access/graphql/src';
enum Size {
  SMALL = 1,
  MEDIUM,
}
@Component({
    selector: 'medical-measure-measure-observations-summary',
    imports: [CommonModule],
    template: `
    @if (latestObs(); as obs) {
      <span i18n>
        @if (size === 2) {
          latest observation :
        }
        {{ obs.value }} {{ measure().unit }} on {{ obs.date | date }}</span
      >
    } @else {
      <span i18n>no observations</span>
    }
  `,
    styles: ``
})
export class MeasureObservationsSummaryComponent {
  measure = input.required<Measure>();
  @Input({ transform: numberAttribute }) size: Size = 2;

  latestObs = computed(() =>
    this.measure().observations.length > 0
      ? this.measure().observations[this.measure().observations.length - 1]
      : null,
  );
}
