import { Route } from '@angular/router';
import {
  DetailsWrapperComponent,
  ENTITY_CONFIG,
  ENTITY_DETAILS_COMPONENT,
  ENTITY_LIST_COMPONENT,
  ENTITY_WRITE_COMPONENT,
  EntityConfig,
  EntityDeleteMutationService,
  ListWrapperComponent,
  PutEntityService,
  WriteWrapperComponent,
} from '@doctorus-front-end-monorepo/feature-entity';
import {
  DeleteMedicalHistoryModelGQL,
  PutMedicalHistoryModelGQL,
  resolveAccountSettingsCollection,
  resolveAccountSettingsItem,
} from '@doctorus-front-end-monorepo/graphql';
import { MedicalHistoryModelDetailsComponent } from './medical-history-model-details/medical-history-model-details.component';
import { MedicalHistoryModelFormComponent } from './medical-history-model-form/medical-history-model-form.component';
import { UiMedicalHistoryModelComponent } from './ui-medical-history-model/ui-medical-history-model.component';

export const uiMedicalHistoryModelRoutes: Route = {
  path: 'medical-history-models',
  data: {
    breadcrumbChunck: { name: $localize`medical history model` },
  },
  providers: [
    {
      provide: ENTITY_CONFIG,
      useValue: new EntityConfig({ feature: $localize`medical history model` }),
    },
    {
      provide: ENTITY_LIST_COMPONENT,
      useValue: UiMedicalHistoryModelComponent,
    },
    {
      provide: ENTITY_WRITE_COMPONENT,
      useValue: MedicalHistoryModelFormComponent,
    },
    {
      provide: PutEntityService,
      useClass: PutMedicalHistoryModelGQL,
    },
    {
      provide: EntityDeleteMutationService,
      useClass: DeleteMedicalHistoryModelGQL,
    },
  ],
  children: [
    {
      path: '',
      component: ListWrapperComponent,
      resolve: {
        objs: resolveAccountSettingsCollection('medicalHistoryModels'),
      },
      data: {
        breadcrumbChunck: { name: null },
      },
    },
    {
      path: 'new',
      component: WriteWrapperComponent,
      data: {
        obj: null,
        breadcrumbChunck: { name: $localize`new` },
      },
    },
    {
      path: ':id',
      runGuardsAndResolvers: 'always',

      resolve: {
        obj: resolveAccountSettingsItem('medicalHistoryModels'),
      },
      data: {
        breadcrumbChunck: {
          name: $localize`medical history model:{{obj.type}}`,
        },
      },
      providers: [
        {
          provide: ENTITY_CONFIG,
          useValue: new EntityConfig({
            feature: $localize`medical history model`,
          }),
        },
        {
          provide: ENTITY_DETAILS_COMPONENT,
          useValue: MedicalHistoryModelDetailsComponent,
        },
      ],
      children: [
        {
          path: '',
          data: {
            breadcrumbChunck: { name: null },
          },
          component: DetailsWrapperComponent,
        },
        {
          path: 'edit',
          data: {
            breadcrumbChunck: { name: $localize`edit` },
          },
          component: WriteWrapperComponent,
        },
      ],
    },
  ],
};
