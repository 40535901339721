import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AccountDataService } from '@doctorus-front-end-monorepo/auth';
import { BaseEntityFormComponent } from '@doctorus-front-end-monorepo/feature-entity';
import { PatientFormBuilderService } from '@doctorus-front-end-monorepo/feature-patient';
import { Patient } from '@doctorus-front-end-monorepo/graphql';
import { FieldSetComponent } from '@doctorus-front-end-monorepo/shared-ui';
import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';
import { UiPhoneNumberPickerComponent } from '@doctorus-front-end-monorepo/ui-phone-number-picker';
import { PhoneNumberPipe } from '@doctorus-front-end-monorepo/util-formatting';
@Component({
    selector: 'appointment-appointment-new-patient-form',
    imports: [
        MatIconModule,
        NgIf,
        MatButtonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        UiMatFormFieldErrorDisplayerDirective,
        MatInputModule,
        MatSelectModule,
        MatOptionModule,
        MatDatepickerModule,
        UiPhoneNumberPickerComponent,
        NgFor,
        MatDividerModule,
        FieldSetComponent,
        AsyncPipe,
        PhoneNumberPipe,
    ],
    templateUrl: './appointment-new-patient-form.component.html',
    styleUrl: './appointment-new-patient-form.component.scss'
})
export class AppointmentNewPatientFormComponent extends BaseEntityFormComponent<Patient> {
  private ptfb = inject(PatientFormBuilderService);
  private ads = inject(AccountDataService);
  public account$ = this.ads.currentAccount$;

  override initFormFun = () => this.ptfb.createForm();

  removePhoneNumberCtrl(index: number): void {
    (this.form.get('phone_numbers') as FormArray).removeAt(index);
  }
  addPhoneNumberCtrl(): void {
    (this.form.get('phone_numbers') as FormArray).push(
      this.ptfb.createPhoneNumberForm(),
    );
  }
  get phoneNumberCtrls() {
    return (this.form.get('phone_numbers') as FormArray)
      .controls as FormGroup[];
  }
}
