import { CommonModule } from '@angular/common';
import { Component, OnInit, inject, input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'entity-base-entity-form',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule],
  template: `<p>base-entity-form works!</p>`,
  styles: ``,
})
export abstract class BaseEntityFormComponent<T> implements OnInit {
  ngOnInit(): void {
    this.form = this.initFormFun();
  }
  obj = input<T>();
  extra = input<any>();
  queryParams = input<any>();
  form!: FormGroup<any>;
  initFormFun!: () => FormGroup;
  fb = inject(FormBuilder);
}
