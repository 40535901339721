<ng-container>
  <mat-calendar
    [selected]="selectedDate"
    (selectedChange)="toDate($event)"
    startView="month"
  ></mat-calendar>
  <form
    class="flex flex-col gap-4 p-2 medium-text-field"
    [formGroup]="queryParamsFg"
  >
    <mat-form-field subscriptSizing="dynamic" appearance="fill">
      <mat-icon matPrefix>location_on</mat-icon>

      <mat-select
        formControlName="location_id__in"
        multiple
        placeholder="select locations"
        i18n-placeholder
      >
        <mat-option
          [value]="location.id"
          *ngFor="let location of account?.locations"
        >
          {{ location.name | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field subscriptSizing="dynamic" appearance="fill">
      <mat-icon matPrefix>event</mat-icon>

      <mat-select
        formControlName="status__in"
        multiple
        placeholder="select status"
        i18n-placeholder
      >
        <mat-option [value]="status[0]" *ngFor="let status of statusList">
          {{ status[1].label | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field subscriptSizing="dynamic" appearance="fill">
      <mat-icon matPrefix>person</mat-icon>

      <mat-select
        formControlName="assignees__overlap"
        multiple
        placeholder="select assignees"
        i18n-placeholder
      >
        <mat-option
          [value]="member.user.sub"
          *ngFor="let member of account?.memberships"
        >
          {{ member.user | humanName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field subscriptSizing="dynamic" appearance="fill">
      <mat-icon matPrefix>category</mat-icon>

      <mat-select
        formControlName="task_type_id__in"
        multiple
        placeholder="select task types"
        i18n-placeholder
      >
        <mat-option
          [value]="taskType.id"
          *ngFor="let taskType of account?.taskTypes"
        >
          {{ taskType.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button class="mt-6" type="reset" mat-stroked-button>
      <mat-icon>restart_alt</mat-icon>
      <span i18n>reset filters</span>
    </button>
  </form>
</ng-container>
