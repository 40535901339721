import { Routes } from '@angular/router';
import { PatientWriteFormComponent } from '@doctorus-front-end-monorepo/feature-patient';
import { PatientsListComponent } from '@doctorus-front-end-monorepo/feature-patients-viewer';
import { BreadcrumbRouteData } from '../../../../../libs/shared/breadcrumb/src/lib/types';
import { PatientDetailResolverService } from '../office/resolvers/patient-detail-resolver.service';
import { PatientDetailComponent } from '../patient/patient-detail/patient-detail.component';
import { medicalRecordsRoutes } from './medical-record-routes';

export const patientsRoutes: Routes = [
  {
    path: '',
    component: PatientsListComponent,
    data: {
      breadcrumbChunck: {
        name: 'list',
      } as BreadcrumbRouteData,
    },
  },
  {
    path: 'new',
    component: PatientWriteFormComponent,
    data: {
      breadcrumbChunck: {
        name: $localize`create patient`,
      } as BreadcrumbRouteData,
    },
  },
  {
    path: ':patId',
    resolve: {
      patient: PatientDetailResolverService,
    },
    data: {
      breadcrumbChunck: {
        name: $localize`{{patient.given_name}} {{patient.family_name}}`,
      } as BreadcrumbRouteData,
    },
    children: [
      {
        path: '',
        component: PatientDetailComponent,
        data: {
          breadcrumbChunck: {
            name: undefined,
            byPass: true,
          } as BreadcrumbRouteData,
        },
      },
      {
        path: 'edit',
        component: PatientWriteFormComponent,
        data: {
          breadcrumbChunck: {
            name: $localize`edit`,
          } as BreadcrumbRouteData,
        },
      },
      ...medicalRecordsRoutes,
    ],
  },
];
