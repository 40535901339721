<div class="container">
  <header class="sticky top-0 bg-white z-10 p-4 flex gap-2 items-center">
    <h1 class="first-letter:uppercase" i18n>{{titleStr()}}</h1>
    <button class="ml-auto" [disabled]="loading()" type="button" [routerLink]="['..']" mat-button>
      <span i18n>cancel</span>
    </button>
    <button [disabled]="loading()" type="submit" (click)="submit()" color="primary" mat-flat-button>
      <span i18n>{{actionStr}}</span>
    </button>

  </header>
  <form class="flex flex-col gap-6 pb-6" [formGroup]="patientForm">

    <ui-layout-container title="general informations" i18n-title>
      <main class="flex gap-4 flex-wrap">
        <div class="flex flex-col gap-4 flex-1">
          <mat-form-field uiFormMatFormFieldErrorDisplayer class="col-span-full">
            <mat-label i18n>given name</mat-label>
            <input matInput formControlName="given_name">
            <mat-error></mat-error>
          </mat-form-field>
          <mat-form-field class="col-span-full md:col-span-2">
            <mat-label i18n>middle name</mat-label>
            <input matInput formControlName="middle_name">
            <mat-error></mat-error>
          </mat-form-field>
          <mat-form-field uiFormMatFormFieldErrorDisplayer class="col-span-full md:col-span-4">
            <mat-label i18n>family name</mat-label>
            <input matInput formControlName="family_name">
            <mat-error></mat-error>
          </mat-form-field>

        </div>
        <div class="flex flex-col gap-4 flex-1">
          <mat-form-field>
            <mat-label i18n>email</mat-label>
            <input matInput type="email" formControlName="email" />
            <mat-error></mat-error>
          </mat-form-field>
          <mat-form-field class="col-span-full md:col-span-3" uiFormMatFormFieldErrorDisplayer>
            <mat-label i18n>gender</mat-label>
            <mat-select required formControlName="gender">
              <mat-option value="female"><span class="first-letter:uppercase" i18n>female</span></mat-option>
              <mat-option value="male"><span class="first-letter:uppercase" i18n>male</span></mat-option>
            </mat-select>
            <mat-error></mat-error>
          </mat-form-field>
          <mat-form-field class="col-span-full md:col-span-3" uiFormMatFormFieldErrorDisplayer>
            <mat-label i18n>date of birth</mat-label>
            <input #input matInput type="date" formControlName="birthdate" />
            <mat-icon matIconSuffix class="hover:cursor-pointer" (click)="input.showPicker()">calendar_month</mat-icon>
            <mat-error></mat-error>
          </mat-form-field>
        </div>


      </main>
    </ui-layout-container>
    @if (isNewPatient()) {
    <ui-layout-container [title]="phoneNumberTitle">

      <button ngProjectAs="actions" (click)="newPhoneNumber()" ngCon mat-stroked-button><span>new phone
          number</span></button>


      <section class="flex flex-col gap-4" formArrayName="phone_numbers">
        <form [formGroupName]="i" *ngFor="let control of phoneNumberCtrls;index as i"
          class="flex flex-wrap gap-4 w-full">
          <!-- <header class="bg-gray-100 flex items-center justify-between p-2 col-span-6">
            <h3># {{control.value.phone_number |phoneNumber}}</h3>
            <mat-icon (click)="removePhoneNumberCtrl(i)" class="hover:cursor-pointer">close</mat-icon>
          </header> -->
          <mat-form-field class="flex-auto" uiFormMatFormFieldErrorDisplayer>
            <shared-ui-phone-number-picker #picker="phonePicker" [initCountry]="oss.account?.defaultCountryCode"
              formControlName="phone_number">
            </shared-ui-phone-number-picker>
            <mat-hint *ngIf="picker.exampleNumber"><span class="mr-1" i18n>eg
                :</span>{{picker.exampleNumber}}</mat-hint>

            <mat-error></mat-error>
          </mat-form-field>
          <!-- <mat-chip-listbox formControlName="availabilities" aria-label="Phone number availabilities">
            @for (availability of availabilities; track availability) {
            <mat-chip-option>{{availability.value}}</mat-chip-option>
            }
          </mat-chip-listbox> -->
          <mat-form-field class="flex-auto">
            <mat-label i18n>remarks</mat-label>
            <input type="text" matInput formControlName="remarks">
          </mat-form-field>
          <mat-form-field>
            <mat-label i18n>availabilities</mat-label>
            <mat-select multiple formControlName="availabilities">
              <mat-option [value]="'WHATSAPP'">WhatsApp</mat-option>
              <mat-option [value]="'VIBER'">Viber</mat-option>
            </mat-select>
          </mat-form-field>
          <button type="button" (click)="removePhoneNumberCtrl(i)" mat-flat-button><span i18n>remove</span></button>


        </form>
      </section>
    </ui-layout-container>


    }
    <ui-layout-container [title]="propertiesTitle">

      <button (click)="addProperyCtrl()" ngProjectAs="actions" mat-stroked-button>
        <mat-icon>add_circle</mat-icon>
        <span i18n>add treatment</span>
      </button>
      <lib-inline-form-array [init]="initPublicProperties()" [min]="0" [extra]="1" [formBuildFn]="createProperty"
        controlKey="public_properties">
        <ng-template let-index="index" #fields>
          <patient-property-inline-form [index]="index" formArrayKey="public_properties"></patient-property-inline-form>
        </ng-template>
      </lib-inline-form-array>



    </ui-layout-container>

    <ui-layout-container title="remarks" i18n-title>
      <mat-form-field class="w-full">
        <textarea placeholder="type your remarks" i18n-placeholder matInput type="text" formControlName="remarks">
        </textarea>
      </mat-form-field>
    </ui-layout-container>

    <footer *ngIf="patient()">
      <section class="rounded-md p-6 bg-red-100 flex justify-between items-center">
        <div class="text-red-700">
          <h3 class="first-letter:uppercase" i18n>delete patient</h3>
          <p i18n>The patient will be removed and it will no longer have access to your applications.</p>
        </div>
        <button (click)="deletePatient()" color="warn" mat-flat-button>
          <span>delete</span>
        </button>
      </section>
    </footer>
  </form>
</div>