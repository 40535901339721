import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BaseEntityFormComponent } from '@doctorus-front-end-monorepo/feature-entity';
import { Appointment } from '@doctorus-front-end-monorepo/graphql';
import { RteComponent } from '@doctorus-front-end-monorepo/rte';

@Component({
    selector: 'appointment-write-medica-note-from',
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        RteComponent,
    ],
    templateUrl: './write-medica-note-from.component.html',
    styleUrl: './write-medica-note-from.component.scss'
})
export class WriteMedicaNoteFromComponent extends BaseEntityFormComponent<
  Pick<Appointment, 'id' | 'note' | 'description'>
> {
  //quillConfig = mentionsConfig();
  override initFormFun = () =>
    new FormGroup({
      id: new FormControl(this.obj()?.id),
      note: new FormControl(this.obj()?.note),
      description: new FormControl(this.obj()?.description),
    });
}
