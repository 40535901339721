import { OperationVariables } from '@apollo/client/core';
import { Mutation, TypedDocumentNode } from 'apollo-angular';
import { EmptyObject } from 'apollo-angular/types';
import { DocumentNode } from 'graphql';

export interface IEntity {
  id: number | string;
}
export class EntityMutationService<
  T = {},
  V extends OperationVariables = EmptyObject,
> extends Mutation<T, V> {
  override document!: DocumentNode | TypedDocumentNode<{}, EmptyObject>;
}

// export interface IEntityFormComponent<T>
//   extends ComponentType<IEntityFormComponent<T>> {
//   obj: InputSignal<T | undefined>;
//   form: FormGroup;
// }
