<mat-form-field class="w-full" uiFormMatFormFieldErrorDisplayer>
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }
  @if (prefixIcon) {
    <mat-icon matPrefix>{{ prefixIcon }}</mat-icon>
  }
  <mat-select
    [multiple]="multiple"
    [compareWith]="compareWith"
    [formControlName]="controlKey"
  >
    <mat-option>
      <div class="flex justify-between items-center">
        <ngx-mat-select-search
          i18n-placeholderLabel
          [placeholderLabel]="searchPlaceholderLabel"
          [noEntriesFoundLabel]="noEntriesFoundLabel"
          [formControl]="searchControl"
        >
        </ngx-mat-select-search>
      </div>
    </mat-option>
    @for (option of (filtredOptions$ | async) ?? allOptions; track $index) {
      <mat-option [value]="extractValue(option)">
        {{ displayWith(option) }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>
