import {
  AfterContentInit,
  ContentChild,
  Directive,
  OnDestroy,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import {
  AbstractControl,
  FormControlStatus,
  FormGroupDirective,
  NgControl,
  NgForm,
  UntypedFormControl,
  ValidationErrors,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { Subscription } from 'rxjs';
import { FORM_ERROR_MSG_MAP } from '../validators-utils';

class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || (form && form.submitted))
    );
  }
}

@Directive({
  selector: '[uiFormMatFormFieldErrorDisplayer]',
  standalone: true,
})
export class UiMatFormFieldErrorDisplayerDirective
  implements OnDestroy, AfterContentInit
{
  _control!: AbstractControl | null;
  matcher = new MyErrorStateMatcher();

  @ContentChild(MatSelect, { static: false })
  matSelect?: MatSelect;

  @ContentChild(MatInput, { static: false })
  matInput?: MatInput;

  @ContentChild(NgControl, { static: false })
  set control(el: NgControl) {
    this._control = el?.control;
  }

  @ContentChild(MatError, { read: ViewContainerRef })
  errorMsgContainerEl?: ViewContainerRef;
  msgMap = FORM_ERROR_MSG_MAP;
  private subscription!: Subscription | undefined;

  constructor(private renderer: Renderer2) {}

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngAfterContentInit(): void {
    if (this.matInput) {
      this.matInput.errorStateMatcher = this.matcher;
    }
    if (this.matSelect) {
      this.matSelect.errorStateMatcher = this.matcher;
    }
    this.subscription = this._control?.statusChanges.subscribe(x => {
      this.errroHandler(x);
    });
  }

  errorsTextEls = (errors: ValidationErrors): any[] =>
    Object.entries(errors).map(([key, value]: [string, any]) => {
      const textVal = this.msgMap.get(key);
      return this.renderer.createText(
        textVal ? (typeof textVal === 'string' ? textVal : textVal(value)) : '',
      );
    });

  clearErrors(): void {
    if (this.errorMsgContainerEl) {
      this.errorMsgContainerEl.element.nativeElement.innerText = '';
    }
  }

  private errroHandler(x: FormControlStatus): void {
    {
      this.clearErrors();
      if (this.errorMsgContainerEl) {
        this.errorMsgContainerEl.clear();
        if (x === 'INVALID' && this._control?.errors) {
          this.errorsTextEls(this._control?.errors).forEach(errorEl =>
            this.renderer.appendChild(
              this.errorMsgContainerEl?.element?.nativeElement,
              errorEl,
            ),
          );
        }
      }
    }
  }
}
