import { TypePolicy } from '@apollo/client/cache/inmemory/policies';

import { gql } from 'apollo-angular';
import {
  Account,
  AccountFieldsFragmentDoc,
  Appointment,
  MedicalNotesFieldsFragmentDoc,
  Patient,
  PatientPayment,
} from '../../generated';
import { ALL_DOCUMENTS_FIELD } from './fields';
import { toMeasureSurvey, toMedicalHistoriesMap } from './utils';

export const patientTypePolicy: TypePolicy = {
  fields: {
    allDocuments: ALL_DOCUMENTS_FIELD,
    measuresSurvey: {
      // Field policy for the isInCart field
      read(
        obj,
        { variables, field, args, cache, toReference, readField, storage },
      ) {
        const patient = cache.readFragment<Pick<Patient, 'measures'>>({
          id: `Patient:${variables!['id']}`,
          fragmentName: 'PatientMedicalNotesFrag',
          fragment: gql`
            ${MedicalNotesFieldsFragmentDoc}
            fragment PatientMedicalNotesFrag on Patient {
              ...MedicalNotesFields
            }
          `,
        });
        const account = cache.readFragment<Pick<Account, 'measureModels'>>({
          id: `Account:${localStorage.getItem('account-id')}`,
          fragmentName: 'AccountMeasureModelsFrag',
          fragment: gql`
            ${AccountFieldsFragmentDoc}
            fragment AccountMeasureModelsFrag on Account {
              measureModels {
                ...MeasureModelFields
              }
            }
          `,
        });
        const val = toMeasureSurvey(
          patient?.measures ?? [],
          account?.measureModels ?? [],
        );
        return val;
      },
    },
    medicalHistoriesSurvey: {
      // Field policy for the isInCart field
      read(
        obj,
        { variables, field, args, cache, toReference, readField, storage },
      ) {
        const patient = cache.readFragment<Pick<Patient, 'medical_histories'>>({
          id: `Patient:${variables!['id']}`,
          fragmentName: 'PatientMedicalNotesFrag',
          fragment: gql`
            ${MedicalNotesFieldsFragmentDoc}
            fragment PatientMedicalNotesFrag on Patient {
              ...MedicalNotesFields
            }
          `,
        });
        const account = cache.readFragment<
          Pick<Account, 'medicalHistoryModels'>
        >({
          id: `Account:${localStorage.getItem('account-id')}`,
          fragmentName: 'AccountHistoryModelsFrag',
          fragment: gql`
            ${AccountFieldsFragmentDoc}
            fragment AccountHistoryModelsFrag on Account {
              medicalHistoryModels {
                ...MedicalHistoryModelFields
              }
            }
          `,
        });
        const val = toMedicalHistoriesMap(
          patient?.medical_histories ?? [],
          account?.medicalHistoryModels ?? [],
        );
        return val;
      },
    },
    medicalTags: {
      // Field policy for the isInCart field
      read(
        obj,
        { variables, field, args, cache, toReference, readField, storage },
      ) {
        const patient = cache.readFragment<
          Pick<
            Patient,
            | 'upload_documents'
            | 'medical_notes'
            | 'appointments'
            | 'generated_documents'
          >
        >({
          id: `Patient:${variables!['id']}`,
          fragmentName: 'PatientMedicalNotesFrag',
          fragment: gql`
            ${MedicalNotesFieldsFragmentDoc}
            fragment PatientMedicalNotesFrag on Patient {
              ...MedicalNotesFields
            }
          `,
        });
        return Array.from(
          new DOMParser()
            .parseFromString(
              [
                ...(patient?.appointments?.map(x => x.note) ?? []),
                ...(patient?.generated_documents?.map(x => x.content) ?? []),
                ...(patient?.upload_documents?.map(x => x.description) ?? []),
                ...[patient?.medical_notes ?? ''],
              ].join('</br>'),
              'text/html',
            )
            .getElementsByClassName('mention'),
        )
          .map(x => x.getAttribute('data-value'))
          .filter(x => (x ? true : false))
          .filter((value, index, array) => array.indexOf(value) === index);
      },
    },
    payment_status: {
      read(obj, { variables, field, args, cache, toReference, readField }) {
        const patient = cache.readFragment<{
          appointments: Array<Pick<Appointment, 'fees' | 'status'>>;
          payments: Array<Pick<PatientPayment, 'amount'>>;
        }>({
          id: `Patient:${variables['id']}`,
          fragment: gql`
            fragment PatientFrag on Patient {
              id
              appointments {
                status
                fees
              }
              payments {
                amount
              }
            }
          `,
        });
        return patient?.appointments && patient?.payments
          ? {
              total_due:
                patient.appointments.reduce(
                  (acc: number, current) => acc + (current?.fees ?? 0),
                  0,
                ) -
                patient.payments.reduce(
                  (acc: number, current) => acc + current.amount,
                  0,
                ),
              total_paid: patient.payments.reduce(
                (acc: number, current) => acc + current.amount,
                0,
              ),
            }
          : undefined;
      },
    },
  },
};
