import { formatISO } from 'date-fns';
import { Temporal } from 'temporal-polyfill';
import { Human, NameOption } from '..';

export function humanize(
  value: Partial<Human>,
  option: NameOption,
  withCivility = false,
) {
  const family_name = value.family_name ?? '';
  const given_name = value.given_name ?? '';
  const middle_name = value.middle_name ?? '';
  const init =
    withCivility && value.gender
      ? value.gender === 'male'
        ? $localize`Mr. `
        : $localize`Ms. `
      : '';
  switch (option) {
    case 'full':
      return init + [given_name, middle_name, family_name].join(' ');
    case 'medium':
      return (
        init +
        `${
          given_name.length > 0 ? given_name[0].toUpperCase() : 'N/A'
        }. ${family_name}`
      );
    case 'genderOnly':
      return value.gender && value.gender[0];
    default:
      return (
        init +
        `${
          given_name.length > 0 ? given_name[0].toUpperCase() : 'N/A'
        }. ${family_name}`
      );
  }
}

export function age(value: string | Date): string {
  const birthDate = Temporal.PlainDate.from(
    formatISO(value, { representation: 'date' }),
  );
  const now = Temporal.PlainDate.from(
    formatISO(new Date(), { representation: 'date' }),
  );
  const age = birthDate.until(now, { largestUnit: 'years' });
  return birthDate
    .until(now, {
      largestUnit: age.years < 4 ? 'auto' : 'years',
      smallestUnit: age.years < 1 ? 'days' : age.years < 3 ? 'weeks' : 'years',
      roundingMode: age.years < 6 ? 'trunc' : 'floor',
    })
    .toString();
}
