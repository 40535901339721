import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import {
  Observable,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  take,
  takeWhile,
  tap,
} from 'rxjs';

export const updateAllValueAndValidity = (control: AbstractControl): void => {
  if (control instanceof FormControl) {
    control.updateValueAndValidity();
  } else if (control instanceof FormGroup) {
    Object.values(control.controls).forEach(_control =>
      updateAllValueAndValidity(_control)
    );
  }
};

export const updateAllValidityMarkAllTouched = (
  control: AbstractControl
): void => {
  control.markAllAsTouched();
  updateAllValueAndValidity(control);
};

export const checkForm = (form: FormGroup): Observable<FormGroup> => {
  updateAllValidityMarkAllTouched(form);
  return form.statusChanges.pipe(
    startWith(form.status),
    tap(console.warn),
    distinctUntilChanged(),
    debounceTime(300),
    filter(x => x !== 'PENDING'),
    takeWhile(x => x !== 'INVALID'),
    take(1),
    tap(() => console.warn(form)),
    map(() => form)
  );
};
