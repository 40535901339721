import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  inject,
  signal,
  ViewChild,
} from '@angular/core';
import { rxResource, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterLink } from '@angular/router';
import { GetAllPatientsGQL } from '@doctorus-front-end-monorepo/graphql';
import { HumanNamePipe } from '@doctorus-front-end-monorepo/shared-util';
import {
  EmptyStateComponent,
  LoadingComponent,
} from '@doctorus-front-end-monorepo/ui-layout';

import { RouterNavigationHelperService } from '@doctorus-front-end-monorepo/util-navigation';
import { debounceTime, distinctUntilChanged, filter, map, of } from 'rxjs';
declare function cwr(operation: string, payload: any): void;

@Component({
  selector: 'doctorus-front-end-monorepo-patient-mini-list',
  templateUrl: './patient-mini-list.component.html',
  styleUrls: ['./patient-mini-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterLink,
    EmptyStateComponent,
    MatListModule,
    MatListModule,
    MatButtonModule,
    MatDividerModule,
    MatCardModule,
    MatIconModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    HumanNamePipe,
    LoadingComponent,
  ],
})
export class PatientMiniListComponent implements AfterViewInit {
  @ViewChild('input') inputRef: ElementRef<HTMLInputElement>;

  loading = signal(false);
  searchCtrl = new FormControl('');
  //user$ = this.as.authUserInfo$;
  dialogRef = inject(DialogRef);
  public rhns = inject(RouterNavigationHelperService);
  page = signal(null);
  patientRx = rxResource({
    request: () => ({
      term: this.searchTerm(),
      page: this.page(),
    }),
    loader: params =>
      params.request.term
        ? this.getPatientsGQL
            .fetch({
              payload: {
                search: params.request.term,
                commons: {
                  ...(this.page() && { page: this.page() + 1 }),
                  page_size: 20,
                },
              },
            })
            .pipe(map(_res => _res.data.getPatients))
        : of(null),
  });
  searchTerm = toSignal(
    this.searchCtrl.valueChanges.pipe(
      filter(x => x && x.trim().length > 0),
      map(x => x.toLowerCase().trim()),
      distinctUntilChanged(),
      debounceTime(300),
    ),
  );
  hasInput = computed(() => this.searchTerm() && this.searchTerm().length > 1);
  constructor(private getPatientsGQL: GetAllPatientsGQL) {
    cwr('recordPageView', { pageId: 'patients-mini-search' });
  }
  ngAfterViewInit(): void {
    this.inputRef.nativeElement.focus();
  }

  close(): void {
    this.dialogRef.close();
  }
}
