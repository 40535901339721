<form class="flex flex-col gap-2 max-w-screen-md" [formGroup]="form">

  <mat-form-field appearance="outline" uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>date</mat-label>
    <input placeholder="DD/MM/YYYY" #input matInput type="date" formControlName="date" />
    <mat-icon matIconSuffix class="hover:cursor-pointer" (click)="input.showPicker()">calendar_month</mat-icon>
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>name</mat-label>
    <input type="text" formControlName="name" matInput type="text" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of medicalhistoriesTypeProps" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
    <mat-error></mat-error>
  </mat-form-field>
  <rte-rte formControlName="content"></rte-rte>
  <span class="pl-4 text-xs text-red-600"
    *ngIf="form && form.get('content')?.invalid && (form.get('content')?.dirty || form.get('content')?.touched)"
    i18n>medical history field can't be empty</span>



</form>