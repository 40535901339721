import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'ui-form-ui-form',
    imports: [CommonModule],
    templateUrl: './ui-form.component.html',
    styleUrl: './ui-form.component.scss'
})
export class UiFormComponent {}
