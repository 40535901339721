import { CommonModule } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getCountryDataList } from 'countries-list';
import { Account } from '../../../../../../shared/data-access/graphql/src';
import { HumanNamePipe } from '../../../../../../shared/shared-util/src';
import {
  ContainerComponent,
  GroupHeaderComponent,
  KeyValueComponent,
} from '../../../../../../shared/ui-layout/src';
@Component({
  selector: 'account-settings-general-account-settings',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatButtonModule,
    MatGridListModule,
    ContainerComponent,
    MatInputModule,
    GroupHeaderComponent,
    KeyValueComponent,
    ReactiveFormsModule,
    MatSelectModule,
    HumanNamePipe,
  ],
  templateUrl: './general-account-settings.component.html',
  styleUrl: './general-account-settings.component.scss',
})
export class GeneralAccountSettingsComponent {
  account = input.required<Account>();
  matSnackBar = inject(MatSnackBar);
  fb = inject(FormBuilder);
  //updateAccountSettingsGql = inject(UpdatePreferencesGQL);

  form = this.fb.group({
    currency: [''],
    alias: [''],
    defaultPhoneCountryCode: [''],
  });
  countries = getCountryDataList().sort((a, b) => (a.name > b.name ? 1 : -1));
  // constructor() {
  //   this.account$
  //     .pipe(first())
  //     .pipe(
  //       tap(x => {
  //         this.form.patchValue(
  //           {
  //             currency: x?.currency,
  //             alias: x?.alias,
  //             defaultPhoneCountryCode: x?.defaultPhoneCountryCode,
  //           },
  //           { emitEvent: false },
  //         );
  //       }),
  //       switchMap(x =>
  //         this.form.valueChanges.pipe(
  //           debounceTime(200),
  //           switchMap(x =>
  //             this.updateAccountSettingsGql.mutate({
  //               payload: x,
  //             }),
  //           ),
  //           tap(() =>
  //             this.matSnackBar.open(
  //               $localize`account preferences updated`,
  //               undefined,
  //               {
  //                 duration: 5000,
  //               },
  //             ),
  //           ),
  //         ),
  //       ),
  //     )
  //     .subscribe();
  // }
}
