import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { GetPatientProfileGQL } from '@doctorus-front-end-monorepo/graphql';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PatientDetailResolverService {
  constructor(private getPatientgql: GetPatientProfileGQL) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return this.getPatientgql
      .fetch({
        id: route.paramMap.get('patId')
        //fetchPolicy: 'cache-first',
      })
      .pipe(map((x) => x.data?.getPatient));
  }
}
