@let isLoading = slotsRessource.isLoading();
@let slots = slotsRessource.value();

@if (isLoading === false) {
  <mat-chip-listbox
    required
    [compareWith]="compareFun"
    (change)="updateSelection($event)"
    class="text-sm"
    [formControl]="slotSelectorCtr"
  >
    <mat-chip-option [value]="slot" *ngFor="let slot of slots">
      {{ slot.start | date: 'HH:mm' : (timezone() | timezoneOffset) }}
    </mat-chip-option>
  </mat-chip-listbox>
} @else {
  <ui-layout-loading>
    <p i18n>fetching available slots ...</p>
  </ui-layout-loading>
}
