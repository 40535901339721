<div [ngClass]="classes()">
  <header [ngClass]="headerClass()">
    <section class="flex flex-wrap gap-4 justify-between items-center">
      @if(level() ===4) {
        <h1 class="first-letter:uppercase">{{ title }}</h1>

      }@else if(level() ===3){
        <h2 class="first-letter:uppercase">{{ title }}</h2>

      }@else if(level() ===2) {
        <h3 class="first-letter:uppercase">{{ title }}</h3>

      }@else {
        <h4 class="first-letter:uppercase">{{ title }}</h4>

      }
      <ng-content select="actions"></ng-content>
    </section>
    <ng-content class="text-gray-600 first-letter:uppercase" select="description"></ng-content>
  </header>
  <main  [ngClass]="contentClass()">
    <ng-content></ng-content>
  </main>
</div>
