import { AsyncPipe, CommonModule, NgIf } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule, RouterOutlet } from '@angular/router';
import { map } from 'rxjs';
import {
  AccountRole,
  AuthService,
  ShowForRolesDirective,
} from '../../../../../libs/shared/auth/src';
import { BreadcrumbComponent } from '../../../../../libs/shared/breadcrumb/src';
import { Account } from '../../../../../libs/shared/data-access/graphql/src';
import {
  NavLink,
  NavTreeComponent,
} from '../../../../../libs/shared/ui-layout/src';
import { LayoutStateService } from '../layout-state.service';

@Component({
  selector: 'doctorus-front-end-monorepo-office-manager',
  templateUrl: './office-manager.component.html',
  styleUrls: ['./office-manager.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    CommonModule,
    MatIconModule,
    MatDividerModule,
    NavTreeComponent,
    BreadcrumbComponent,
    MatChipsModule,
    ShowForRolesDirective,
    BreadcrumbComponent,
    MatButtonModule,
    RouterModule,
    RouterOutlet,
    AsyncPipe,
    MatSidenavModule,
    MatTooltipModule,
  ],
})
export class OfficeManagerComponent {
  account = input.required<Account>();
  roles = inject(AuthService).authUserInfo()['cognito:groups'];
  navLinks: NavLink[] = [
    {
      name: $localize`calendar`,
      svgIcon: 'calendar_month',
      active: true,
      routerLink: ['scheduler'],
      requiredRoles: [AccountRole.DOCTOR, AccountRole.MEDICAL_ASSISTANT],
    },
    {
      name: $localize`patients`,
      svgIcon: 'patient_list',
      active: true,
      requiredRoles: [AccountRole.DOCTOR, AccountRole.MEDICAL_ASSISTANT],
      routerLink: ['patients'],
    },
    {
      name: $localize`preferences and settings`,
      svgIcon: 'settings',
      requiredRoles: [AccountRole.MANAGER],
      active: true,
      routerLink: ['settings'],
    },
  ];
  private lss = inject(LayoutStateService);
  largeSideNav$ = this.lss.showAccountCard$.pipe(map(x => !x));
  sideMenuOpen = this.lss.sideMenuOpen$;

  toggle(): void {
    this.lss.toggleSideMenu();
  }
}
