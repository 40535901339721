import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';
import { CarePlan } from '../../../../../shared/data-access/graphql/src';
import { BaseEntityListComponent } from '../../../../../shared/feature-entity/src';
import { HumanNamePipe } from '../../../../../shared/shared-util/src';

@Component({
    selector: 'lib-care-plans-list',
    imports: [CommonModule, RouterModule, HumanNamePipe, MatDividerModule],
    templateUrl: './care-plans-list.component.html',
    styleUrl: './care-plans-list.component.scss'
})
export class CarePlansListComponent extends BaseEntityListComponent<CarePlan> {}
