import { CdkPortal, ComponentPortal, PortalModule } from '@angular/cdk/portal';
import { CommonModule, NgComponentOutlet } from '@angular/common';
import {
  Component,
  Input,
  ViewChild,
  computed,
  inject,
  input,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { EntityMutationService } from '@doctorus-front-end-monorepo/feature-entity';
import { updateAllValidityMarkAllTouched } from '../../../../form/util-form/src';
import { ContainerComponent } from '../../../../ui-layout/src';
import { BaseEntityFormComponent } from '../base-entity-form.component';
import { BaseWrapperComponent } from '../base-wrapper.component';
import { ENTITY_CONFIG, ENTITY_WRITE_COMPONENT } from '../tokens';

@Component({
    selector: 'entity-write-wrapper',
    imports: [
        CommonModule,
        CdkPortal,
        ContainerComponent,
        RouterModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        PortalModule,
    ],
    templateUrl: './write-wrapper.component.html',
    styleUrl: './write-wrapper.component.scss'
})
export class WriteWrapperComponent<
  T,
  C extends BaseEntityFormComponent<T>,
> extends BaseWrapperComponent {
  obj = input<T>();
  @Input() cancelRedirect = ['..'];
  @Input() successRedirect?: any[];
  component = inject<C>(ENTITY_WRITE_COMPONENT);
  portal = new ComponentPortal(this.component as any);
  private eds = inject(EntityMutationService);
  private entityConfig = inject(ENTITY_CONFIG);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  extra$ = this.route.data;
  queryParams$ = this.route.queryParams;

  @ViewChild(NgComponentOutlet, { static: false })
  ngComponentOutlet!: NgComponentOutlet;

  get title(): string {
    return this.obj()
      ? $localize`edit ${this.entityConfig.feature}`
      : $localize`new ${this.entityConfig.feature}`;
  }

  componentInputs = computed(() => ({
    obj: this.obj(),
  }));

  submit(): void {
    const form = this.ngComponentOutlet['_componentRef'].instance.form;
    if (form) {
      updateAllValidityMarkAllTouched(form);
      if (form.valid) {
        this.eds
          .mutate({
            payload: form.value,
          })
          .subscribe(x => {
            x.data;
            this.router.navigate(this.successRedirect ?? ['..'], {
              relativeTo: this.route,
            });
          });
      }
    }
  }
}
