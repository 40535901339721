<form class="flex flex-col gap-6 container" [formGroup]="form">
  <shared-ui-field-set>
    <h3 title class="flex items-center gap-2"><mat-icon>badge</mat-icon>
      <span i18n>personal informations</span>
    </h3>
    <section class="flex flex-col gap-3">
      <div class="grid grid-cols-3 gap-3">
        <mat-form-field uiFormMatFormFieldErrorDisplayer>
          <mat-label i18n>given name</mat-label>
          <input matInput formControlName="given_name">
          <mat-error></mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label i18n>middle name</mat-label>
          <input matInput formControlName="middle_name">
          <mat-error></mat-error>
        </mat-form-field>
        <mat-form-field uiFormMatFormFieldErrorDisplayer>
          <mat-label i18n>family name</mat-label>
          <input matInput formControlName="family_name">
          <mat-error></mat-error>
        </mat-form-field>
      </div>
      <div class="grid grid-cols-2 gap-3">
        <mat-form-field uiFormMatFormFieldErrorDisplayer>
          <mat-label i18n>gender</mat-label>
          <mat-select required formControlName="gender">
            <mat-option value="female"><span class="first-letter:uppercase" i18n>female</span></mat-option>
            <mat-option value="male"><span class="first-letter:uppercase" i18n>male</span></mat-option>
          </mat-select>
          <mat-error></mat-error>
        </mat-form-field>
        <mat-form-field uiFormMatFormFieldErrorDisplayer>
          <mat-label i18n>date of birth</mat-label>
          <input #input matInput type="date" formControlName="birthdate" />
          <mat-icon matIconSuffix class="hover:cursor-pointer" (click)="input.showPicker()">calendar_month</mat-icon>
          <mat-error></mat-error>
        </mat-form-field>
      </div>
    </section>
  </shared-ui-field-set>


  <shared-ui-field-set>
    <h3 title class="flex items-center gap-2"><mat-icon>contacts</mat-icon>
      <span i18n>contact</span>
    </h3>
    <div class="flex flex-col gap-3">
      <mat-form-field>
        <mat-label i18n>email</mat-label>
        <input matInput type="email" formControlName="email" />
        <mat-error></mat-error>
      </mat-form-field>
      <div class="field-label flex items-center justify-between">
        <span class="capitalize" i18n>phone numbers</span>
        <button (click)="addPhoneNumberCtrl()" mat-mini-fab><mat-icon>add</mat-icon></button>

      </div>
      <section class="flex flex-col gap-4" formArrayName="phone_numbers">
        <form [formGroupName]="i" *ngFor="let control of phoneNumberCtrls;index as i" class="grid grid-cols-6 gap-4">
          <header class="bg-gray-100 flex items-center justify-between p-2 col-span-6">
            <h3># {{control.value.phone_number |phoneNumber}}</h3>
            <mat-icon (click)="removePhoneNumberCtrl(i)" class="hover:cursor-pointer">close</mat-icon>
          </header>
          <mat-form-field class="col-span-3" uiFormMatFormFieldErrorDisplayer>
            <shared-ui-phone-number-picker #picker="phonePicker" [initCountry]="(account$|async)?.defaultCountryCode"
              formControlName="phone_number">
            </shared-ui-phone-number-picker>
            <mat-hint *ngIf="picker.exampleNumber"><span class="mr-1" i18n>eg
                :</span>{{picker.exampleNumber}}</mat-hint>

            <mat-error></mat-error>
          </mat-form-field>
          <mat-form-field class="col-span-3">
            <mat-label i18n>availabilities</mat-label>
            <mat-select multiple formControlName="availabilities">
              <mat-option [value]="'WHATSAPP'">WhatsApp</mat-option>
              <mat-option [value]="'VIBER'">Viber</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-span-6">
            <mat-label i18n>remarks</mat-label>
            <textarea matInput formControlName="remarks"></textarea>
          </mat-form-field>
        </form>
      </section>
      <!-- <mat-form-field uiFormMatFormFieldErrorDisplayer>
        <mat-label i18n>phone number</mat-label>
        <shared-ui-phone-number-picker [initCountry]="account.preferences?.defaultCountryCode"
          #picker="phonePicker" formControlName="phone_number">
        </shared-ui-phone-number-picker>
        <mat-hint *ngIf="picker.exampleNumber"><span class="mr-1" i18n>eg
            :</span>{{picker.exampleNumber}}</mat-hint>
        <mat-error></mat-error>
      </mat-form-field> -->
    </div>
  </shared-ui-field-set>

</form>
