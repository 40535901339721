<main class="flex flex-col">
  <button (click)="preview()" class="self-end" mat-stroked-button><mat-icon>preview</mat-icon><span
      i18n>preview</span></button>
  <section class="grid grid-cols-2 gap-4">
    <ui-layout-key-value key="name" i18n-key>
      {{obj().name | coalesce:'-'}}
    </ui-layout-key-value>
    <ui-layout-key-value key="date" i18n-key>
      {{obj().date | date}}
    </ui-layout-key-value>
    <ui-layout-key-value class="col-span-2" key="description" i18n-key>
      <p>{{obj().description |coalesce:'-'}}</p>
    </ui-layout-key-value>
    <ui-layout-key-value key="file type" i18n-key>
      {{obj().mime_type | coalesce:'-'}}
    </ui-layout-key-value>
    <ui-layout-key-value key="file url" i18n-key>

      <a class="anchor ellipsis" [href]="obj().multi_sizes_urls.O" target="_blank">{{obj().file}}
        <mat-icon>open_in_new</mat-icon></a>
    </ui-layout-key-value>

  </section>
</main>
