import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StoreObject } from '@apollo/client/cache';
import {
  EntityConfig,
  IEntity,
} from '@doctorus-front-end-monorepo/feature-entity';
import { Apollo } from 'apollo-angular';
import { first, takeWhile, tap } from 'rxjs';
import { GenericDeleteDialogComponent } from './generic-delete-dialog/generic-delete-dialog.component';
@Injectable({
  providedIn: 'root',
})
export class EntityDialogService<T extends IEntity = any, A = any, F = any> {
  constructor(
    private matSnackBar: MatSnackBar,
    private apollo: Apollo,
    //@Inject(ENTITY_CONFIG) private entityConfig: EntityConfig<T>,
    //private eds: GraphqlStoreObjectDataService<any, any, any>,
    // private putGQL: Mutation,
    // private deleteGQL: Mutation,
    private matDialog: MatDialog,
  ) {}

  // openEntityWriteDialog(args: {
  //   entity?: T;
  //   initData?: any;
  //   dialogConfig?: Partial<MatDialogConfig>;
  //   formComp: ComponentType<F>;
  // }): Observable<T> {
  //   return this.matDialog
  //     .open(EntityWriteDialogComponent, {
  //       width: '600px',
  //       data: {
  //         formComp: args.formComp,
  //         entityConfig: this.entityConfig,
  //         entity: args.entity,
  //         initData: args.initData,
  //       },
  //       ...args.dialogConfig,
  //     })
  //     .afterClosed()
  //     .pipe(
  //       takeWhile((x) => x),
  //       switchMap((x) =>
  //         x?.payload
  //           ? this.eds.upsert(x.payload, x.entity).pipe(first())
  //           : EMPTY,
  //       ),
  //     );
  // }

  openEntityDeleteDialog(
    entity: T,
    injector: Injector,
    entityConfig?: EntityConfig<T>,
  ) {
    return this.matDialog
      .open<GenericDeleteDialogComponent<T>, any, StoreObject | undefined>(
        GenericDeleteDialogComponent<T>,
        {
          width: '420px',
          backdropClass: 'overlay--transition-none',
          injector: injector,
          data: {
            entityConfig: entityConfig,
            entity,
          },
          componentFactoryResolver: injector.get(ComponentFactoryResolver),
        },
      )
      .afterClosed()
      .pipe(
        first(),

        takeWhile(payload => (payload ? true : false)),
        tap(x => {
          const normalizedId = this.apollo.client.cache.identify(
            x as StoreObject,
          );
          if (normalizedId) {
            this.apollo.client.cache.evict({
              id: normalizedId,
              broadcast: false,
            });
            this.apollo.client.cache.gc();
          }

          const msg = entityConfig?.messages?.action?.deleted?.success;
          this.matSnackBar.open(msg ?? 'N/A', '', { duration: 3500 });
        }),
      );
  }
  openEntityDeleteWarningDialog(entity: T, protectedObjects: string[]) {
    // this.matDialog.open(EntityDeleteWarningDialogComponent, {
    //   width: '320',
    //   data: {
    //     entity,
    //     protectedObjects,
    //     entityConfig: this.entityConfig,
    //   },
    // });
  }
}
