import { CommonModule } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AuthService } from '@doctorus-front-end-monorepo/auth';
import { ListWrapperComponent } from '@doctorus-front-end-monorepo/feature-entity';
import {
  Appointment,
  PatientDataService,
} from '@doctorus-front-end-monorepo/graphql';

import {
  AppointmentStatus,
  StatusComponent,
} from '@doctorus-front-end-monorepo/shared-util';
import {
  AccountCurrencyPipe,
  CoalescePipe,
} from '@doctorus-front-end-monorepo/util-formatting';
import { addDays, compareAsc } from 'date-fns';
import { map } from 'rxjs';
import { DatesComponent } from '../dates/dates.component';

@Component({
  selector: 'appointment-appointment-full-list',
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatTooltipModule,
    MatChipsModule,
    ListWrapperComponent,
    StatusComponent,
    MatTableModule,
    CoalescePipe,
    DatesComponent,
    AccountCurrencyPipe,
  ],
  templateUrl: './appointment-full-list.component.html',
  styleUrl: './appointment-full-list.component.scss',
})
export class AppointmentFullListComponent {
  objs = toSignal(
    inject(PatientDataService).selectedPatient$.pipe(
      map(x => x?.appointments ?? []),
    ),
    { requireSync: true },
  );
  showPatient = input(false);
  private isDoctor = inject(AuthService).isAuthUserDoctor;

  outdatedMsg = $localize`this is an outdated appointment`;

  displayedColumns = computed(() => [
    ...['schedules', 'status', 'type', 'location', 'fees'],
    ...(this.isDoctor ? ['description'] : []),
  ]);
  isOutdated = (appointment: Appointment) =>
    [AppointmentStatus.ON_PROGRESS, AppointmentStatus.ON_PROGRESS].includes(
      appointment.status,
    ) && compareAsc(new Date(appointment.end), addDays(new Date(), 1)) === -1;
}
