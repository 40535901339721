import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthUserCardComponent } from './components/auth-user-card/auth-user-card.component';
import { PatientMiniListComponent } from './components/patient-mini-list/patient-mini-list.component';
import { UpperMenuComponent } from './components/upper-menu/upper-menu.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    UpperMenuComponent,
    PatientMiniListComponent,
    AuthUserCardComponent,
  ],
  exports: [UpperMenuComponent],
})
export class MenuModule {}
