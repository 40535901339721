import { CommonModule } from '@angular/common';
import { Component, inject, input, model, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { combineLatest, debounceTime, filter, map, switchMap, tap } from 'rxjs';
import {
  AccountDataService,
  AccountRole,
  ShowForRolesDirective,
} from '../../../../../shared/auth/src';
import {
  GetPatientsByMedicalNotesFilterGQL,
  GetPatientsGQL,
  GetPatientsQuery,
} from '../../../../../shared/data-access/graphql/src';
import { PhoneNumberBoxComponent } from '../../../../../shared/patient/feature-patient/src';
import { HumanNamePipe } from '../../../../../shared/shared-util/src';
import {
  EmptyStateComponent,
  LoadingComponent,
} from '../../../../../shared/ui-layout/src';
import { ArrayExistPipe } from '../../../../../shared/util-array/src';
import { CoalescePipe } from '../../../../../shared/util-formatting/src';
import { PatientSearchFormComponent } from '../patient-search-form/patient-search-form.component';
type searchType = 'medical-tag' | 'profile' | 'medical-note';
type SearchConfig = Record<
  searchType,
  {
    fields: string[];
    filterKey: string;
  }
>;
const SEARCH_CONFIG_MAP: SearchConfig = {
  'medical-note': {
    fields: ['name', 'reference', 'headline', 'action'],
    filterKey: 'medical_notes__in',
  },
  'medical-tag': {
    fields: ['name', 'reference', 'headline', 'action'],
    filterKey: 'medical_tags__in',
  },
  profile: {
    fields: [
      'name',
      'reference',
      'phone_number',
      'added',
      'total_due',
      'action',
    ],
    filterKey: 'search',
  },
};
@Component({
  selector: 'doctorus-front-end-monorepo-active-patients-list',
  standalone: true,
  imports: [
    CommonModule,
    PatientSearchFormComponent,
    MatButtonModule,
    MatTableModule,
    MatFormFieldModule,
    MatSelectModule,
    ShowForRolesDirective,
    RouterModule,
    MatSortModule,
    MatPaginatorModule,
    LoadingComponent,
    HumanNamePipe,
    PhoneNumberBoxComponent,
    EmptyStateComponent,
    ArrayExistPipe,
    CoalescePipe,
  ],
  templateUrl: './active-patients-list.component.html',
  styleUrl: './active-patients-list.component.scss',
})
export class ActivePatientsListComponent {
  currency = inject(AccountDataService).account?.currency;
  count = model(0);
  loading = model(false);
  accountRole = AccountRole;
  searchType = signal<searchType>('profile');
  pageSize = input<number>(50);
  pageIndex = input<number | null>(null);
  ordering = signal<string | null | undefined>(null);
  searchTerm = signal<string | null | undefined>(null);
  displayedColumns = signal<string[]>([
    'name',
    'reference',
    'phone_number',
    'added',
    'total_due',
    'action',
  ]);
  queryResponse = signal<GetPatientsQuery['getPatients'] | null>(null);

  constructor(
    private patientListGql: GetPatientsGQL,
    private getPatientsByMedicalNotesFilterGQL: GetPatientsByMedicalNotesFilterGQL,
  ) {
    combineLatest({
      pageSize: toObservable(this.pageSize),
      pageIndex: toObservable(this.pageIndex),
      searchType: toObservable(this.searchType),
      ordering: toObservable(this.ordering),
      searchTerm: toObservable(this.searchTerm),
    })
      .pipe(
        filter(x => !(x.searchType === 'medical-tag' && !x.searchTerm)),
        tap(x =>
          this.displayedColumns.set(SEARCH_CONFIG_MAP[x.searchType].fields),
        ),
        map(x => ({
          payload: {
            page_size: x.pageSize,
            ...(x.pageIndex && { page: x.pageIndex + 1 }),
            ordering: x.ordering,
            [SEARCH_CONFIG_MAP[x.searchType].filterKey]: x.searchTerm,
          },
        })),
        debounceTime(500),
        tap(() => {
          this.loading.set(true);
          this.queryResponse.set(null);
        }),
        switchMap(params => {
          switch (this.searchType()) {
            case 'medical-tag':
              return this.getPatientsByMedicalNotesFilterGQL
                .watch(params, { fetchPolicy: 'no-cache' })
                .valueChanges.pipe(
                  map(x => ({
                    ...x.data.getPatientsByMedicalNotesFilter,
                    loading: x.loading,
                  })),
                );
            case 'profile':
              return this.patientListGql
                .watch(params, { fetchPolicy: 'no-cache' })
                .valueChanges.pipe(
                  map(x => ({
                    ...x.data.getPatients,
                    loading: x.loading,
                  })),
                );
            default:
              return this.getPatientsByMedicalNotesFilterGQL
                .watch(params, { fetchPolicy: 'no-cache' })
                .valueChanges.pipe(
                  map(x => ({
                    ...x.data.getPatientsByMedicalNotesFilter,
                    loading: x.loading,
                  })),
                );
          }
        }),
        tap(x => {
          this.loading.set(false);
          this.count.set(x?.count ?? 0);
        }),
      )
      .subscribe(x =>
        this.queryResponse.set(x as GetPatientsQuery['getPatients']),
      );
  }
  changeSearchType(type: searchType): void {
    this.searchType.set(type);
    this.searchTerm.set(null);
  }

  // updatePage(event: PageEvent): void {
  //   this.pageSize.set(event.pageSize);
  //   this.pageIndex.set(event.pageIndex);
  // }
  updateSort(event: Sort): void {
    const direction = event.direction === 'asc' ? '' : '-';
    const value = event.direction ? `${direction}${event.active}` : '';
    this.ordering.set(value);
  }
}
