<ui-layout-container border="false" level="4" [title]="title()">
  <div ngProjectAs="actions" class="flex  items-center">
    <mat-button-toggle-group [(ngModel)]="status" name="patientsStatus">
      <mat-button-toggle value="active">active</mat-button-toggle>
      <mat-button-toggle value="inactive">inactive</mat-button-toggle>
    </mat-button-toggle-group>
    <mat-paginator [pageIndex]="pageIndex()" showFirstLastButtons [length]="count()" pageSize="50" [hidePageSize]="true"
      (page)="updatePage($event)">
    </mat-paginator>


  </div>

  @if(loading()) {
  <ui-layout-loading ngProjectAs="description">
    <p i18n>fetching patients</p>

  </ui-layout-loading>
  }


  @if(status === 'active') {
  <doctorus-front-end-monorepo-active-patients-list [pageIndex]="pageIndex()" [pageSize]="pageSize()" [(count)]="count"
    [(loading)]="loading"></doctorus-front-end-monorepo-active-patients-list>

  }@else {
  <doctorus-front-end-monorepo-inactive-patients-list [(count)]="count"
    [(loading)]="loading"></doctorus-front-end-monorepo-inactive-patients-list>

  }


</ui-layout-container>