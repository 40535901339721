<ui-layout-container sticky="true" [border]="false"
  [title]="patient()?.allDocuments?.length| i18nPlural: messageMapping">
  <button [matMenuTriggerFor]="menu" ngProjectAs="actions" mat-stroked-button><mat-icon i18n>add_circle
    </mat-icon><span i18n>new document</span></button>
  @if(patient()?.allDocuments.length > 0) {
  <ul>
    @for (document of patient()?.allDocuments; track document) {
    <li class="hover:bg-gray-50 ease-out p-4">
      <a [routerLink]="documentDetailsLink(document)" class="flex gap-2">

        @switch (document.type) {
        @case (documentType.Uploaded) {
        @if (document.media|isImage) {
        <img [src]="document.media" class="object-contain h-12">
        }@else {
        <util-document-file-box height="32" width="32" rounded="true"
          [fileSrc]="document.media"></util-document-file-box>
        }

        }
        @case (documentType.Generated) {
        <mat-icon>edit_document</mat-icon>

        }

        @default {
        <mat-icon>prescriptions</mat-icon>

        }
        }
        <div class="flex flex-col">
          <h4>{{document.name | coalesce:'-'}}</h4>
          <quill-view [content]="document.content" format="html" theme="snow"></quill-view>

        </div>

        <p class="ml-auto">{{document.date | date}}</p>



        <!-- <div matListItemLine *ngIf="document.content">
            @if(document.content) {
            <quill-view [content]="document.content" format="html" theme="snow"></quill-view>
            }
            @else {-}
          </div> -->

      </a>
    </li>
    <mat-divider></mat-divider>
    }
  </ul>
  }@else {
  <ui-layout-empty-state>
    <span i18n>no medical documents</span>
  </ui-layout-empty-state>
  }


</ui-layout-container>


<mat-menu #menu="matMenu">
  <button [routerLink]="['generated-document','new']" mat-menu-item><mat-icon>edit_document</mat-icon><span i18n>write
      document</span></button>
  <button [routerLink]="['upload-document','new']" mat-menu-item><mat-icon>upload_file</mat-icon><span i18n>upload
      document</span></button>
  <button [routerLink]="['prescription','new']" mat-menu-item><mat-icon>prescriptions</mat-icon><span i18n>write
      prescription</span></button>
</mat-menu>