<h3 matDialogTitle i18n class="first-letter:uppercase">new medical account</h3>
<form matDialogContent [formGroup]="officeForm" class="flex flex-col gap-8 p-6">
  <mat-form-field appearance="outline" subscriptSizing="dynamic" uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>name</mat-label>
    <input matInput required formControlName="alias" matInput>
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" subscriptSizing="dynamic" uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n="country">country</mat-label>
    <mat-select [compareWith]="countryCompare" placeholder="Select office country" formControlName="country">
      <mat-select-trigger>
        <div class="flex gap-2 items-center" *ngIf="officeForm.get('country')?.value as value">
          <span>{{getFlag(value)}}</span>
          <span>{{ getCountry(value).name | titlecase }}</span>
        </div>
      </mat-select-trigger>
      <mat-option [value]="country.key" *ngFor="let country of countries|keyvalue">
        <div class="flex items-center gap-2">
          <span>{{getFlag(country.key)}}</span>
          <span>{{ country.value.name| titlecase }}</span>
        </div>
      </mat-option>
    </mat-select>
    <mat-error></mat-error>
  </mat-form-field>
  <footer mat-dialog-actions class="gap-4">
    <button type="submit" (click)="submit()" color="primary" mat-flat-button>
      <span i18n>create</span>
    </button>
    <button matDialogClose mat-stroked-button>
      <span i18n>cancel</span>
    </button>
  </footer>