import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';
import { GetAppointmentsGQL } from '@doctorus-front-end-monorepo/graphql';
import {
  HumanNamePipe,
  StatusComponent,
} from '@doctorus-front-end-monorepo/shared-util';
import {
  ContainerComponent,
  EmptyStateComponent,
  LoadingComponent,
} from '@doctorus-front-end-monorepo/ui-layout';
import { RouterNavigationHelperService } from '@doctorus-front-end-monorepo/util-navigation';
import * as dateFns from 'date-fns';
import * as _ from 'lodash';
import { first, map } from 'rxjs';
declare function cwr(operation: string, payload: any): void;

@Component({
  selector: 'doctorus-front-end-monorepo-today-appointments',
  templateUrl: './today-appointments.component.html',
  styleUrls: ['./today-appointments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatIconModule,
    StatusComponent,
    MatListModule,
    MatDividerModule,
    MatListModule,
    LoadingComponent,
    MatIconModule,
    RouterModule,
    MatButtonModule,
    HumanNamePipe,
    DatePipe,
    ContainerComponent,
    EmptyStateComponent,
  ],
})
export class TodayAppointmentsComponent {
  today = new Date();
  private dialogRef = inject(DialogRef);
  private getAppointmentsGQL = inject(GetAppointmentsGQL);
  public rhns = inject(RouterNavigationHelperService);

  constructor() {
    cwr('recordPageView', { pageId: 'today-appointments' });
  }
  appointmentsRx = rxResource({
    loader: () =>
      this.getAppointmentsGQL
        .fetch(
          {
            payload: {
              day: dateFns.formatISO(new Date(), {
                representation: 'date',
              }),
            },
          },
          {
            fetchPolicy: 'no-cache',
          },
        )
        .pipe(
          first(),
          map(x => _.sortBy(x.data.getAppointments, ['start'])),
        ),
  });
  close(): void {
    this.dialogRef.close();
  }
}
