/* eslint-disable @angular-eslint/no-input-rename */
import {
  CommonModule,
  DatePipe,
  KeyValue,
  KeyValuePipe,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  TitleCasePipe,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  Signal,
  computed,
  inject,
  input,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouterLink } from '@angular/router';
import { AccountDataService } from '@doctorus-front-end-monorepo/auth';
import { EntityMutationService } from '@doctorus-front-end-monorepo/feature-entity';
import {
  DeleteMedicalHistoryGQL,
  MedicalHistory,
  Patient,
  PutMedicalHistoryGQL,
  SetMedicalHistoryStatusGQL,
} from '@doctorus-front-end-monorepo/graphql';
import { SlideOutPanelService } from '@doctorus-front-end-monorepo/slide-out-panel';
import {
  EntityDeleteMutationService,
  EntityDialogService,
} from '@doctorus-front-end-monorepo/ui-entity-dialog';
import {
  EmptyStateComponent,
  FeedbackComponent,
  GroupHeaderComponent,
} from '@doctorus-front-end-monorepo/ui-layout';
import { ArrayExistPipe } from '@doctorus-front-end-monorepo/util-array';
import { QuillViewComponent } from 'ngx-quill';
import { switchMap, takeWhile, tap } from 'rxjs';
import { MedicalHistoryStatusDialogComponent } from '../medical-history-status-dialog/medical-history-status-dialog.component';
import { medicalHistoryConfig } from '../medical-history.entity.model';
import { WriteMedicalHistoryFormComponent } from '../write-medical-history-form/write-medical-history-form.component';

@Component({
  selector: 'medical-history-medical-history-list',
  templateUrl: './medical-history-list.component.html',
  styleUrls: ['./medical-history-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [
    {
      provide: EntityMutationService,
      useClass: PutMedicalHistoryGQL,
    },
    {
      provide: EntityDeleteMutationService,
      useClass: DeleteMedicalHistoryGQL,
    },
  ],
  imports: [
    NgIf,
    NgSwitch,
    NgSwitchCase,
    RouterLink,
    NgFor,
    CommonModule,
    QuillViewComponent,
    MatButtonModule,
    MatDividerModule,
    EmptyStateComponent,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    GroupHeaderComponent,
    TitleCasePipe,
    DatePipe,
    KeyValuePipe,
    ArrayExistPipe,
    FeedbackComponent,
  ],
})
export class MedicalHistoryListComponent {
  @Input() mode: 'compact' | 'full' = 'compact';
  patient = input.required<Patient>();

  // medicalHistoriesMap = computed<Array<MedicalHistoryEntry>>(() =>
  //   toMedicalHistoriesMap(this.medical_histories(), this.defaultTypes),
  // );

  expanded = false;
  hasCurrentAntecedents: Signal<boolean> = computed(
    () =>
      this.patient().medical_histories?.filter(x => x.is_current).length > 0,
  );
  hasNonCurrentAntecedents: Signal<boolean> = computed(
    () =>
      this.patient().medical_histories.filter(x => !x.is_current).length > 0,
  );
  defaultTypes = inject(AccountDataService).account?.medicalHistoryModels ?? [];
  originalOrder = (a: KeyValue<any, any>, b: KeyValue<any, any>): number => {
    return 0;
  };
  constructor(
    private matSnack: MatSnackBar,
    private matDialog: MatDialog,
    private injector: Injector,
    private slideDialogService: SlideOutPanelService,
    private entityDialogService: EntityDialogService,
    private setMedicalHistoryStatusGql: SetMedicalHistoryStatusGQL,
  ) {}
  get togglerIconStr(): string {
    return this.expanded ? 'unfold_less' : 'unfold_more';
  }
  toggle(): void {
    this.expanded = !this.expanded;
  }
  statusIcon = (medicalHistory: MedicalHistory): string =>
    medicalHistory.is_current ? 'comments_disabled' : 'comment';

  statusStr = (medicalHistory: MedicalHistory): string =>
    medicalHistory.is_current ? $localize`disable` : $localize`enable`;

  delete(medicalHistory: MedicalHistory): void {
    this.entityDialogService
      .openEntityDeleteDialog(
        medicalHistory,
        this.injector,
        medicalHistoryConfig,
      )
      .subscribe();
  }

  updateMedicalHistoryStatus(medicalHistory: MedicalHistory): void {
    this.matDialog
      .open(MedicalHistoryStatusDialogComponent, {
        width: '420px',
        data: {
          medicalHistory: medicalHistory,
        },
      })
      .afterClosed()
      .pipe(
        takeWhile(x => x),
        switchMap(x => this.setMedicalHistoryStatusGql.mutate({ payload: x })),
        tap(() =>
          this.matSnack.open($localize`medical history status updated`),
        ),
      )
      .subscribe();
  }

  writeMedicalHistory(args?: {
    medicalHistory?: MedicalHistory;
    name?: string;
    mode?: 'full' | 'status';
  }): void {
    this.slideDialogService
      .openSlideOutEntityWriteForm(
        {
          entity: args?.medicalHistory,
          cmp: WriteMedicalHistoryFormComponent,
          entityConfig: medicalHistoryConfig,
          extra: {
            patient: this.patient(),
            name: args?.name,
            medicalhistoriesTypeProps: new Set([
              ...this.patient().medical_histories.map(x => x.name),
              ...this.defaultTypes.map(x => x.type),
            ]),
          },
        },
        this.injector,
      )
      .subscribe();
  }
}
