import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { getValidatorErrorMessage } from '../validators-utils';

@Component({
    selector: '[ui-form-error-message]',
    imports: [CommonModule],
    template: `<ng-container *ngIf="errorMessage !== null"
    >{{ errorMessage }}
  </ng-container>`,
    styleUrl: './error-message.component.scss'
})
export class ErrorMessageComponent {
  @Input()
  control!: AbstractControl;

  constructor() {}

  get errorMessage() {
    for (const validatorName in this.control?.errors) {
      if (this.control.touched)
        return getValidatorErrorMessage(
          validatorName,
          this.control.errors[validatorName],
        );
    }
    return null;
  }
}
