@if (medicalRecordsDates() | arrayExist) {
  <ui-layout-container border="false" title="patient timeline" i18n-title>
    <button
      ngProjectAs="actions"
      [mat-menu-trigger-for]="sortMenu"
      mat-icon-button
    >
      <mat-icon>sort</mat-icon>
    </button>

    <div class="flex gap-4 relative list-container overflow-y-auto pr-2 mt-2">
      <nav
        class="flex flex-col list-container overflow-y-auto sticky top-0 gap-2 min-w-fit border-r pr-4"
      >
        <div
          class="flex flex-col gap-1 items-center text-gray-700"
          *ngFor="let entry of medicalRecordsDates()"
        >
          <p class="font-medium">{{ entry[0] | date: 'MMM yyyy' }}</p>
          <a
            (click)="scrollTo(date)"
            class="text-sm"
            [routerLink]="[]"
            [fragment]="date | date: 'shortDate'"
            *ngFor="let date of entry[1]"
            >{{ date | date: 'dd' }}</a
          >
        </div>
      </nav>
      <main #listLane class="flex flex-col w-full gap-6">
        <article
          #listEl
          [id]="recordsDateGroup[0]"
          class="w-full"
          *ngFor="let recordsDateGroup of timeLineRecords()"
        >
          <h2 class="mb-2">
            {{ recordsDateGroup[0] | date: 'fullDate' | titlecase }}
          </h2>
          <section class="p-4 flex flex-col gap-6">
            @for (
              appointment of recordsDateGroup[1].appointments;
              track $index
            ) {
              <ui-layout-container
                level="2"
                [link]="['..', 'appointments', appointment.id]"
                [title]="appointment.task_type?.name"
              >
                @if (appointment.description) {
                  <p ngProjectAs="description">
                    {{ appointment.description | coalesce: '-' }}
                  </p>
                }

                <quill-view [content]="appointment.note"></quill-view>
              </ui-layout-container>
            }

            @for (
              document of recordsDateGroup[1].uploaded_documents;
              track $index
            ) {
              <ui-layout-container
                level="2"
                [link]="['..', 'documents', 'upload-document', document.id]"
                [title]="document.name"
              >
                @if (document.description) {
                  <p class="text-gray-800" ngProjectAs="description">
                    {{ document.description }}
                  </p>
                }

                @if (document.file | isImage) {
                  <a target="_blank" [href]="document.multi_sizes_urls.O">
                    <img
                      class="max-w-sm"
                      [src]="document.multi_sizes_urls.O"
                      alt="medical image"
                    />
                  </a>
                } @else {
                  <a
                    ngProjectAs="media"
                    target="_blank"
                    [href]="document.multi_sizes_urls.O"
                  >
                    <util-document-file-box
                      [fileSrc]="document.file"
                    ></util-document-file-box>
                  </a>
                }
              </ui-layout-container>
            }

            @for (
              document of recordsDateGroup[1].generated_documents;
              track $index
            ) {
              <ui-layout-container
                level="2"
                [link]="['..', 'documents', 'generated-document', document.id]"
                [title]="document.title"
              >
                @if (document.content) {
                  <quill-view [content]="document.content"></quill-view>
                }
              </ui-layout-container>
            }
            @for (
              prescription of recordsDateGroup[1].prescriptions;
              track $index
            ) {
              <ui-layout-container
                level="2"
                [link]="['..', 'documents', 'prescriptions', prescription.id]"
                title="prescription"
                i18n-title
              >
                <dl class="flex flex-col gap-1">
                  @for (treatment of prescription.treatment_set; track $index) {
                    <dt class="font-medium">{{ treatment.name }}</dt>
                    <dd class="pl-6 italic text-gray-700">
                      {{ treatment.note }}
                    </dd>
                  }
                </dl>
              </ui-layout-container>
            }
            @for (obs of recordsDateGroup[1].observations; track $index) {
              <ui-layout-container
                level="2"
                [link]="['..', 'measures', obs.measure.id]"
                [title]="
                  obs.measure.name + ' : ' + obs.value + ' ' + obs.measure.unit
                "
              >
                @if (obs.note) {
                  <p ngProjectAs="description" class="text-gray-800">
                    {{ obs.note }}
                  </p>
                }
              </ui-layout-container>
            }
            @for (
              medicalTask of recordsDateGroup[1].medical_tasks;
              track $index
            ) {
              <ui-layout-container
                level="2"
                [link]="['..', 'care-plans', medicalTask.care_plan.id]"
                [title]="medicalTask.care_plan.name"
              >
                <p i18n ngProjectAs="description" class="text-gray-800">
                  at {{ medicalTask.at | date }} by
                  {{ medicalTask.by | humanName }}
                </p>
                @if (medicalTask.notes) {
                  <p>{{ medicalTask.notes }}</p>
                }
              </ui-layout-container>
            }
          </section>
        </article>
      </main>
    </div>
  </ui-layout-container>
} @else {
  <ui-layout-empty-state>
    <h3 i18n>no medical data</h3>
    <small i18n>patient medical record is empty</small>
  </ui-layout-empty-state>
}

<mat-menu #sortMenu="matMenu">
  <button class="text-sm" (click)="toggleOrdering('desc')" mat-menu-item>
    <mat-icon [class.invisible]="order() !== 'desc'">check</mat-icon
    ><span class="capitalize">newest first</span>
  </button>
  <button class="text-sm" (click)="toggleOrdering('asc')" mat-menu-item>
    <mat-icon [class.invisible]="order() !== 'asc'">check</mat-icon>
    <span class="capitalize">oldest first</span>
  </button>
</mat-menu>
