import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { QuillEditorComponent } from 'ngx-quill';
import { Observable, filter, map } from 'rxjs';
import { DocumentModel } from '../../../../shared/data-access/graphql/src';
import { BaseEntityFormComponent } from '../../../../shared/feature-entity/src';
import { quillConfig } from '../../../../shared/rte/src';
import { ContainerComponent } from '../../../../shared/ui-layout/src';
type TemplateVar = Record<'id' | 'value' | 'display', string>;

@Component({
  selector: 'lib-document-model-form',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    ContainerComponent,
    QuillEditorComponent,
  ],
  template: `<form
    class="flex flex-col gap-8 max-w-screen-md"
    [formGroup]="form"
  >
    <ui-layout-container title="informations" border="false" i18n-title>
      <div class="flex flex-col gap-6">
        <mat-form-field>
          <mat-label i18n>name</mat-label>
          <input type="text" matInput formControlName="name" />
          <mat-error></mat-error>
          <mat-hint i18n
            >document model name , document model name must be unique</mat-hint
          >
        </mat-form-field>
        <mat-form-field>
          <mat-label i18n>document title</mat-label>
          <input type="text" matInput formControlName="documentTitle" />
          <mat-error></mat-error>
          <mat-hint i18n
            >document title that will appear when document is printed</mat-hint
          >
        </mat-form-field>
      </div>
    </ui-layout-container>

    <ui-layout-container border="false" title="content" i18n-title>
      <ng-container ngProjectAs="description" i18n>
        tape "&#64;" in order to add dynamic values
      </ng-container>
      <div>
        <quill-editor
          formControlName="template"
          class="h-48"
          [modules]="modules"
        ></quill-editor>
      </div>
    </ui-layout-container>
  </form> `,
  styles: ``,
})
export class DocumentModelFormComponent extends BaseEntityFormComponent<DocumentModel> {
  @ViewChild(QuillEditorComponent, { static: true })
  editor!: QuillEditorComponent;
  compiledTemplate$!: Observable<string>;
  templateVars: TemplateVar[] = [
    {
      id: 'patientGivenName',
      value: '{{patient.given_name}}',
      display: $localize`Patient:given name`,
    },
    {
      id: 'patientFamilyName',
      value: '{{patient.family_name}}',
      display: $localize`Patient:family name`,
    },
    {
      id: 'userGivenName',
      value: '{{user.given_name}}',
      display: $localize`User:given name`,
    },
    {
      id: 'userFamilyName',
      value: '{{user.family_name}}',
      display: $localize`User:family name`,
    },
    {
      id: 'today',
      value: '{{today}}',
      display: $localize`Today date`,
    },
  ];
  modules = {
    toolbar: quillConfig.modules!.toolbar,

    mention: {
      //allowedChars: /^*$/,
      mentionDenotationChars: ['@'],
      spaceAfterInsert: false,
      showDenotationChar: false,
      positioningStrategy: 'fixed',
      mentionContainerClass: 'mention-list-container',
      source: (searchTerm: string, renderList: any, mentionChar: any) => {
        let values: TemplateVar[] = [];
        if (mentionChar === '@') {
          values = this.templateVars;
        }
        //console.warn(values);
        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];
          for (let i = 0; i < values.length; i++)
            if (
              ~values[i]?.value
                ?.toLowerCase()
                ?.indexOf(searchTerm.toLowerCase())
            ) {
              matches.push(values[i]);
            }
          renderList(matches, searchTerm);
        }
      },
      renderItem: (item: any, searchTerm: string) => {
        return item.display;
      },
    },
  };
  override initFormFun = () =>
    this.fb.group({
      id: [this.obj()?.id],
      disabled: [this.obj()?.disabled ?? false, Validators.required],
      name: [this.obj()?.name, Validators.required],
      documentTitle: [this.obj()?.documentTitle, Validators.required],
      template: [this.obj()?.template, Validators.required],
    });

  override ngOnInit(): void {
    super.ngOnInit();

    this.compiledTemplate$ = this.form.get('template')!.valueChanges.pipe(
      filter(x => (x ? true : false)),
      map(x =>
        this.compileTemplate(x, {
          patient: {
            name: 'adel',
            fullName: 'adel abderahim',
          },
        }),
      ),
    );
  }

  private compileTemplate(htmlContent: string, context: any): string {
    const template = Handlebars.compile(htmlContent);
    return template(context);
  }
}
