import { Dialog } from '@angular/cdk/dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Overlay } from '@angular/cdk/overlay';
import {
  AsyncPipe,
  CommonModule,
  DOCUMENT,
  NgIf,
  TitleCasePipe,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenav } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
} from '@angular/router';
import {
  AccountDataService,
  AuthService,
} from '@doctorus-front-end-monorepo/auth';
import { TodayAppointmentsComponent } from '@doctorus-front-end-monorepo/feature-appointment';
import { AvatarLoaderDirective } from '@doctorus-front-end-monorepo/shared-util';
import { RouterNavigationHelperService } from '@doctorus-front-end-monorepo/util-navigation';
import { timezoneToOffset } from '@doctorus-front-end-monorepo/util-time';
import { Observable, debounceTime, filter, fromEvent, map, tap } from 'rxjs';
import { BreadcrumbComponent, resolveBreadcrumbs } from '@doctorus-front-end-monorepo/breadcrumb';
import { LayoutStateService } from '../../../layout-state.service';
import { AuthUserCardComponent } from '../../../menu/components/auth-user-card/auth-user-card.component';
import { PatientMiniListComponent } from '../patient-mini-list/patient-mini-list.component';

@Component({
    selector: 'doctorus-front-end-monorepo-upper-menu',
    templateUrl: './upper-menu.component.html',
    styleUrls: ['./upper-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatToolbarModule,
        NgIf,
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatDividerModule,
        RouterLink,
        AsyncPipe,
        TitleCasePipe,
        AvatarLoaderDirective,
        BreadcrumbComponent,
    ]
})
export class UpperMenuComponent {
  searchBarId = 'upper-menu-search-bar';

  authUser$ = this.as.authUserInfo$;
  searchActive = signal(false);
  fullMenu = false;
  routerHelper = this.rhns;
  today = new Date();
  currentOffset = timezoneToOffset(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );
  private lss = inject(LayoutStateService);
  isLarge$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.XLarge])
    .pipe(map(result => result.matches));
  isXsmall$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.XSmall])
    .pipe(map(result => result.matches));
  isTablet$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Tablet])
    .pipe(map(result => result.matches));
  showAccountCard$ = this.lss.showAccountCard$;

  amblemSrc$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(
      map(result =>
        result.matches ? 'assets/img/log-emblem.png' : 'assets/img/logo.png',
      ),
    );

  @ViewChild('drawer') matSideNav?: MatSidenav;
  currentAccount$ = this.oss.currentAccount$;

  drugBanners = [
    'assets/img/banner-2.png',
    'assets/img/banner-1.png',
    'assets/img/banner-3.webp',
  ];

  updateBanner(): void {
    this.selectedBanner.set(this.drugBanners.at(Math.floor(Math.random() * 4)));
  }

  selectedBanner = signal<string | undefined>('assets/img/banner-2.png');

  breadcrumbs$ = this.router.events.pipe(
    filter(_event => _event instanceof NavigationEnd),
    debounceTime(300),
    tap(console.warn),
    map(_event => resolveBreadcrumbs(this.route)),
  );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private matDialog: MatDialog,
    private dialog: Dialog,
    private router: Router,
    private route: ActivatedRoute,
    private overlay: Overlay,
    private rhns: RouterNavigationHelperService,
    private oss: AccountDataService,
    public as: AuthService,
    private elemenRef: ElementRef<HTMLElement>,
    @Inject(DOCUMENT) private document: Document,
  ) {
    fromEvent(this.document, 'click')
      .pipe(
        map(x => x.composedPath()),
        filter(
          events =>
            !events
              .filter(event => event instanceof HTMLElement)
              .some((event: HTMLElement) =>
                event.isEqualNode(this.elemenRef?.nativeElement),
              ),
        ),
      )
      .subscribe(x => {
        this.searchActive.set(false);
      });
  }
  toggleSearch(event: Event): void {
    this.dialog.closeAll();
    //this.searchActive.update(x => !x);
    this.dialog.open(PatientMiniListComponent, {
      width: '420px',
      maxWidth: '100vw',
      height: '100vh',
      hasBackdrop: false,
      panelClass: ['rounded-l-md', 'bg-white', 'drop-shadow'],
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(event.target as HTMLElement)
        .withPositions([
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
        ]),
    });
  }
  openTodayAppointments(): void {
    this.dialog.closeAll();
    this.dialog.open(TodayAppointmentsComponent, {
      width: '420px',
      maxWidth: '100vw',
      height: '100vh',
      hasBackdrop: true,
      panelClass: ['rounded-l-md', 'side-entry'],
      positionStrategy: this.overlay.position().global().top('0').right('0'),
    });
  }
  openAuthStaffCard(user: any) {
    this.matDialog.open(AuthUserCardComponent, {
      maxWidth: '480px',
      position: { right: '1rem', top: '4rem' },
      hasBackdrop: true,
      data: { user },
      backdropClass: 'overlay--transition-none',
    });
  }
  toggleMenu(): void {
    this.lss.toggleSideMenu();
  }
}
