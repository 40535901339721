<form [formGroup]="form">
    <mat-form-field class="w-full" uiFormMatFormFieldErrorDisplayer>
      <mat-label i18n>appointment type</mat-label>
      <mat-select formControlName="task_type_id">
        @for (taskType of taskTypes$|async; track $index) {
            <mat-option [value]="taskType.id">{{taskType.name}}</mat-option>
        }
      </mat-select>
      <mat-error></mat-error>
    </mat-form-field>
  </form>
  