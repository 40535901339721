import { Route } from '@angular/router';

import {
  DetailsWrapperComponent,
  ENTITY_CONFIG,
  ENTITY_DETAILS_COMPONENT,
  ENTITY_LIST_COMPONENT,
  ENTITY_WRITE_COMPONENT,
  EntityConfig,
  EntityDeleteMutationService,
  ListWrapperComponent,
  PutEntityService,
  WriteWrapperComponent,
} from '@doctorus-front-end-monorepo/feature-entity';
import {
  DeleteMeasureModelGQL,
  PutMeasureModelGQL,
  resolveAccountSettingsCollection,
  resolveAccountSettingsItem,
} from '@doctorus-front-end-monorepo/graphql';
import { MeasureModelDetailsComponent } from './measure-model-details/measure-model-details.component';
import { MeasureModelFormComponent } from './measure-model-form/measure-model-form.component';
import { UiMeasureModelsComponent } from './ui-measure-models/ui-measure-models.component';

export const uiMeasureModelsRoutes: Route = {
  path: 'measure-models',
  data: {
    breadcrumbChunck: { name: $localize`measure models` },
  },
  providers: [
    {
      provide: ENTITY_CONFIG,
      useValue: new EntityConfig({ feature: $localize`measure model` }),
    },
    {
      provide: ENTITY_LIST_COMPONENT,
      useValue: UiMeasureModelsComponent,
    },
    {
      provide: ENTITY_WRITE_COMPONENT,
      useValue: MeasureModelFormComponent,
    },
    {
      provide: PutEntityService,
      useClass: PutMeasureModelGQL,
    },
    {
      provide: EntityDeleteMutationService,
      useClass: DeleteMeasureModelGQL,
    },
  ],
  children: [
    {
      path: '',
      component: ListWrapperComponent,
      resolve: {
        objs: resolveAccountSettingsCollection('measureModels'),
      },
      data: {
        breadcrumbChunck: { name: null },
      },
    },
    {
      path: 'new',
      component: WriteWrapperComponent,
      data: {
        obj: null,
        breadcrumbChunck: { name: $localize`new` },
      },
    },
    {
      path: ':id',
      runGuardsAndResolvers: 'always',

      resolve: {
        obj: resolveAccountSettingsItem('measureModels'),
      },
      data: {
        breadcrumbChunck: { name: $localize`measure model:{{obj.name}}` },
      },
      providers: [
        {
          provide: ENTITY_CONFIG,
          useValue: new EntityConfig({ feature: $localize`measure model` }),
        },
        {
          provide: ENTITY_DETAILS_COMPONENT,
          useValue: MeasureModelDetailsComponent,
        },
      ],
      children: [
        {
          path: '',
          data: {
            breadcrumbChunck: { name: null },
          },
          component: DetailsWrapperComponent,
        },
        {
          path: 'edit',
          data: {
            breadcrumbChunck: { name: $localize`edit` },
          },
          component: WriteWrapperComponent,
        },
      ],
    },
  ],
};
