import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NavTreeElementComponent } from '../nav-tree-element/nav-tree-element.component';
import { NavLink } from '../types';

@Component({
    selector: 'ui-layout-nav-tree',
    imports: [CommonModule, MatIconModule, NavTreeElementComponent],
    templateUrl: './nav-tree.component.html',
    styleUrl: './nav-tree.component.scss'
})
export class NavTreeComponent {
  navLinks = input.required<NavLink[]>();
  level = input(0);

  paddingClass = computed(() => `pl-${this.level() * 2}`);
}
