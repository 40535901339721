import { Pipe, PipeTransform } from '@angular/core';
import { minutesToTime } from '@doctorus-front-end-monorepo/util-formatting';

@Pipe({
  name: 'time',
  standalone: true
})
export class TimePipe implements PipeTransform {
  transform(minutes: number): string {
    return minutesToTime(minutes);
  }
}
