/* eslint-disable @typescript-eslint/no-empty-interface */
import { EntityConfig } from '@doctorus-front-end-monorepo/feature-entity';
import { GeneratedDocument } from '@doctorus-front-end-monorepo/graphql';

export const generatedDocumentConfig: EntityConfig<GeneratedDocument, any> =
  new EntityConfig({
    //baseUri: 'medical_note/generatedDocuments/',
    feature: $localize`generated document`,
    toString: (document: GeneratedDocument) => `${document.title}`,

    // graphQl: {
    //   type: 'GeneratedDocument',
    //   parent: {
    //     parentProp: 'patient',
    //     typeName: 'Patient',
    //     childProp: 'generated_documents'
    //   }
    //   // mutation: {
    //   //   put: {
    //   //     name: 'putGeneratedDocument',
    //   //     query: gql<
    //   //       { putGeneratedDocument: GeneratedDocument },
    //   //       {
    //   //         payload: GeneratedDocumentForm;
    //   //         id?: any;
    //   //       }
    //   //     >`
    //   //       mutation putGeneratedDocument(
    //   //         $payload: GeneratedDocumentInput!
    //   //         $id: ID
    //   //       ) {
    //   //         putGeneratedDocument(payload: $payload, id: $id) {
    //   //           id
    //   //           content
    //   //           title
    //   //           date
    //   //           patient
    //   //         }
    //   //       }
    //   //     `,
    //   //   },
    //   //   delete: gql`
    //   //     mutation deleteGeneratedDocument($id: ID!) {
    //   //       deleteGeneratedDocument(id: $id) {
    //   //         id
    //   //       }
    //   //     }
    //   //   `,
    //   // },
    // },
  });
