<div class="flex gap-2">
  <mat-icon>{{feedbackAttrs.icon}}</mat-icon>
  <div>
    @if (title) {
    <h4 class="first-letter:uppercase">{{title}}</h4>
    }
    @if (message) {
    <p>{{message}}</p>
    }
  </div>

</div>

<ng-content select="action"></ng-content>