import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  PRIMARY_OUTLET,
} from '@angular/router';
import * as Handlebars from 'handlebars';
import { BreadcrumbService } from './breadcrumb-service/breadcrumb.service';
import { Breadcrumb } from './types';
export const breadcrumbServiceFactory = (
  route: ActivatedRoute,
  group?: string,
) => new BreadcrumbService(route);

function getResolvedUrl(route: ActivatedRouteSnapshot): string {
  return route.pathFromRoot
    .map(v => v.url.map(segment => segment.toString()).join('/'))
    .join('/');
}

export const resolveBreadcrumbs = (
  route: ActivatedRoute,
  group?: string,
  breadcrumbs: Breadcrumb[] = [],
  link: any[] = [],
  url = '',
): Breadcrumb[] => {
  const ROUTE_DATA_BREADCRUMB_DEF_KEY = 'breadcrumbChunck';
  const children: ActivatedRoute[] = route.children;
  if (children.length === 0 || route.outlet !== PRIMARY_OUTLET) {
    return breadcrumbs;
  }

  for (const child of children) {
    const _childLink = [...link, ...child.snapshot.url.map(x => x.path)];

    const name = child.snapshot.data[ROUTE_DATA_BREADCRUMB_DEF_KEY]?.name;
    if (name) {
      const compiledName = Handlebars.compile(name, {})(
        child.snapshot.data,
        {},
      );
      const breadcrumb: Breadcrumb = {
        name: compiledName,
        group:
          child.snapshot.data[ROUTE_DATA_BREADCRUMB_DEF_KEY]?.group ??
          PRIMARY_OUTLET,
        params: child.snapshot.params,
        queryParams: child.snapshot.queryParams,
        link: _childLink,
        url: '',
      };
      breadcrumbs.push(breadcrumb);
    }

    return resolveBreadcrumbs(child, group, breadcrumbs, _childLink, '');
  }
  return breadcrumbs;
};
