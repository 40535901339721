import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RouterNavigationHelperService {
  get officeRoute() {
    const accountId = localStorage.getItem('account-id');

    return accountId ? ['/', accountId, 'office'] : null;
  }

  getPatientListRoute(): any[] {
    return ['/', localStorage.getItem('account-id'), 'patients', 'list'];
  }

  getPatientRoute = (
    patient: { id: string; is_active: boolean } | undefined | null,
  ) =>
    patient && patient.is_active
      ? ['/', localStorage.getItem('account-id'), 'patients', patient.id]
      : null;

  getApppointmentRoute = (patientId: string, appId: string) => [
    '/',
    localStorage.getItem('account-id'),
    'patients',
    patientId,
    'appointments',
    appId,
  ];
}
