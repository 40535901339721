import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { BaseEntityFormComponent } from '@doctorus-front-end-monorepo/feature-entity';
import {
  AccountDataService,
  Appointment,
} from '@doctorus-front-end-monorepo/graphql';
import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';
import { map, tap } from 'rxjs';

@Component({
  selector: 'appointment-appointment-type-dialog',
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    UiMatFormFieldErrorDisplayerDirective,
  ],
  templateUrl: './appointment-type-dialog.component.html',
  styleUrl: './appointment-type-dialog.component.scss',
})
export class AppointmentTypeDialogComponent extends BaseEntityFormComponent<
  Pick<Appointment, 'id' | 'task_type'>
> {
  taskTypes$ = inject(AccountDataService).currentAccount$.pipe(
    map(x => x?.taskTypes),
    tap(console.warn),
  );
  override initFormFun = () =>
    new FormGroup({
      id: new FormControl(this.obj()?.id),
      task_type_id: new FormControl(this.obj()?.task_type?.id, [
        Validators.required,
      ]),
    });
}
