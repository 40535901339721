import { Pipe, PipeTransform } from '@angular/core';
import { formatISO } from 'date-fns';
import { Temporal } from 'temporal-polyfill';

@Pipe({
  name: 'age',
  standalone: true,
})
export class AgePipe implements PipeTransform {
  transform(value: string | Date): string {
    const birthDate = Temporal.PlainDate.from(
      formatISO(value, { representation: 'date' }),
    );
    const now = Temporal.PlainDate.from(
      formatISO(new Date(), { representation: 'date' }),
    );
    const age = birthDate.until(now, { largestUnit: 'years' });
    return birthDate
      .until(now, {
        largestUnit: age.years < 4 ? 'auto' : 'years',
        smallestUnit:
          age.years < 1 ? 'days' : age.years < 3 ? 'weeks' : 'years',
        roundingMode: age.years < 6 ? 'trunc' : 'floor',
      })
      .toString();
  }
}
