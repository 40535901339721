<section class="grid grid-cols-3 gap-4">
  <ui-layout-key-value key="date" i18n-key>
    {{ obj().date | date }}
  </ui-layout-key-value>
  <ui-layout-key-value key="amount" i18n-key>
    {{ obj().amount | accountCurrency }}
  </ui-layout-key-value>
  <ui-layout-key-value key="payment method" i18n-key>
    {{ obj().payment_method | coalesce: '-' }}
  </ui-layout-key-value>
  <ui-layout-key-value class="col-span-full" key="notes" i18n-key>
    {{ obj().notes | coalesce: '-' }}
  </ui-layout-key-value>
</section>
