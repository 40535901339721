import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Subscription, debounceTime } from 'rxjs';

@Component({
  selector: 'doctorus-front-end-monorepo-patient-search-form',
  templateUrl: './patient-search-form.component.html',
  styleUrls: ['./patient-search-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    CommonModule,
    MatChipsModule,
    MatOptionModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class PatientSearchFormComponent implements OnDestroy {
  @Output() valueChanged = new EventEmitter<string>();

  searchCtrl = new FormControl();
  subscription = new Subscription();

  constructor(private fb: FormBuilder) {
    this.subscription.add(
      this.searchCtrl.valueChanges
        .pipe(debounceTime(300))
        .subscribe(x => this.valueChanged.emit(x)),
    );
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  reset(): void {
    this.searchCtrl;
  }
}
