import { CommonModule } from '@angular/common';
import { Component, Injector, inject, model } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { map, tap } from 'rxjs';
import {
  GetInactivePatientsGQL,
  RestLogEntry,
  RestoreObjectGQL,
} from '../../../../../shared/data-access/graphql/src';
import { EntityConfig } from '../../../../../shared/feature-entity/src';
import { HumanNamePipe } from '../../../../../shared/shared-util/src';
import {
  EntityDeleteMutationService,
  EntityDialogService,
} from '../../../../../shared/ui-entity-dialog/src';
import { EmptyStateComponent } from '../../../../../shared/ui-layout/src';

@Component({
  selector: 'doctorus-front-end-monorepo-inactive-patients-list',
  standalone: true,

  providers: [
    {
      provide: EntityDeleteMutationService,
      useExisting: RestoreObjectGQL,
    },
  ],
  imports: [
    CommonModule,
    EmptyStateComponent,
    MatTableModule,
    HumanNamePipe,
    MatButtonModule,
  ],
  templateUrl: './inactive-patients-list.component.html',
  styleUrl: './inactive-patients-list.component.scss',
})
export class InactivePatientsListComponent {
  loading = model(true);
  count = model(0);
  patientsQueryRef = inject(GetInactivePatientsGQL).watch(
    {},
    {
      fetchPolicy: 'network-only',
    },
  );
  patients$ = this.patientsQueryRef.valueChanges.pipe(
    map(x => x.data.getInactivePatients),
    tap(x => {
      this.loading.set(false);
      this.count.set(x.count);
    }),
  );

  columns = ['name', 'reference', 'by', 'date', 'action'];

  eds = inject(EntityDialogService);
  injector = inject(Injector);

  restoreObject(entry: RestLogEntry): void {
    this.eds
      .openEntityDeleteDialog(
        entry,
        this.injector,
        new EntityConfig({
          messages: {
            title: {
              delete: $localize`Object Recovery`,
            },
            action: {
              deleted: {
                success: $localize`object successfully restored`,
                confirmation: $localize`Do you want to restore this object : ${entry.object_repr} ?`,
                buttonLabel: $localize`yes, restore`,
              },
            },
          },
        }),
      )
      .subscribe(() => {
        this.patientsQueryRef.refetch();
      });
  }
}
