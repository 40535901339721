<div [class.pointer-events-none]="disabled()" [class.hover:cursor-pointer]="!disabled()" class="flex items-center"
  [matMenuTriggerFor]="statusMenu">
  <ng-content>

  </ng-content>
</div>

<mat-menu #statusMenu>
  <menu [class.bg-gray-200]="_status[0] === status()" (click)="updateStatus(_status[0])" role="menuitemradio"
    mat-menu-item *ngFor="let _status of statues">
    <span>{{_status[1].label | titlecase}}</span>
    <mat-icon [class]="_status[1].colorClass">{{_status[1].svgIcon}}</mat-icon>
  </menu>
</mat-menu>