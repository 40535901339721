import { Route } from '@angular/router';
import {
  DetailsWrapperComponent,
  ENTITY_CONFIG,
  ENTITY_DETAILS_COMPONENT,
  ENTITY_LIST_COMPONENT,
  ENTITY_WRITE_COMPONENT,
  EntityMutationService,
  ListWrapperComponent,
  WriteWrapperComponent,
} from '@doctorus-front-end-monorepo/feature-entity';
import { BreadcrumbRouteData } from '../../../../shared/breadcrumb/src/lib/types';
import {
  DeleteMeasureGQL,
  PutMeasureGQL,
  resolveMedicalRecordCollection,
  resolveMedicalRecordItem,
} from '../../../../shared/data-access/graphql/src';
import { EntityDeleteMutationService } from '../../../../shared/ui-entity-dialog/src';
import { FeatureMedicalMeasureComponent } from './feature-medical-measure/feature-medical-measure.component';
import { MeasureBoxComponent } from './measure-box/measure-box.component';
import { measureConfig } from './utils';
import { WriteMeasureDialogComponent } from './write-measure-dialog/write-measure-dialog.component';

export const MEASURE_ROUTE: Route = {
  path: 'measures',
  runGuardsAndResolvers: 'always',
  resolve: {
    objs: resolveMedicalRecordCollection('measuresSurvey'),
  },
  providers: [
    {
      provide: ENTITY_CONFIG,
      useValue: measureConfig,
    },
    {
      provide: EntityDeleteMutationService,
      useClass: DeleteMeasureGQL,
    },
    {
      provide: EntityMutationService,
      useClass: PutMeasureGQL,
    },
    {
      provide: ENTITY_DETAILS_COMPONENT,
      useValue: MeasureBoxComponent,
    },
    {
      provide: ENTITY_WRITE_COMPONENT,
      useValue: WriteMeasureDialogComponent,
    },
    {
      provide: ENTITY_LIST_COMPONENT,
      useValue: FeatureMedicalMeasureComponent,
    },
  ],
  data: {
    breadcrumbChunck: {
      name: $localize`measures`,
    },
  },
  children: [
    {
      path: '',
      component: ListWrapperComponent,
      data: {
        breadcrumbChunck: {
          name: null,
        },
      },
    },
    {
      path: 'new',
      component: WriteWrapperComponent,
      data: {
        cancelRedirect: ['..'],
        successRedirect: ['..'],
        obj: null,
        breadcrumbChunck: {
          name: $localize`new`,
        } as BreadcrumbRouteData,
      },
    },
    {
      path: ':id',
      resolve: {
        obj: resolveMedicalRecordItem('measures'),
      },
      data: {
        breadcrumbChunck: {
          name: $localize`measure:{{obj.name}}`,
        } as BreadcrumbRouteData,
      },
      children: [
        {
          path: '',
          component: DetailsWrapperComponent,
          data: {
            deleteRedirect: ['..'],

            breadcrumbChunck: {
              name: null,
            } as BreadcrumbRouteData,
          },
        },
        {
          path: 'edit',
          component: WriteWrapperComponent,
          data: {
            breadcrumbChunck: {
              name: $localize`edit`,
            } as BreadcrumbRouteData,
          },
        },
      ],
    },
  ],
};
