import { CdkPortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  Component,
  Injector,
  Input,
  Type,
  computed,
  inject,
  input,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { EntityDialogService } from '../../../../ui-entity-dialog/src';
import { ContainerComponent } from '../../../../ui-layout/src';
import { BaseWrapperComponent } from '../base-wrapper.component';
import { ENTITY_CONFIG, ENTITY_DETAILS_COMPONENT } from '../tokens';
import { IEntity } from '../types';

@Component({
  selector: 'entity-details-wrapper',
  standalone: true,
  imports: [
    CommonModule,
    CdkPortal,
    ContainerComponent,
    RouterModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './details-wrapper.component.html',
  styleUrl: './details-wrapper.component.scss',
})
export class DetailsWrapperComponent<
  T extends IEntity,
  C extends Type<C>,
> extends BaseWrapperComponent {
  obj = input.required<T>();
  @Input() deleteRedirect = ['..'];
  component = inject<C>(ENTITY_DETAILS_COMPONENT);

  private entityConfig = inject(ENTITY_CONFIG);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private injector = inject(Injector);
  private dialogService = inject(EntityDialogService);
  canDelete = this.layoutConfig?.canDelete ?? true;
  get title(): string {
    return `${this.entityConfig.feature}`;
  }

  componentInputs = computed(() => ({
    obj: this.obj(),
  }));

  delete() {
    this.dialogService
      .openEntityDeleteDialog(this.obj(), this.injector, this.entityConfig)
      .subscribe(x => {
        this.router.navigate(this.deleteRedirect ?? ['..'], {
          relativeTo: this.route,
        });
      });
  }
}
