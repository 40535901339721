import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PhoneNumber } from '@doctorus-front-end-monorepo/graphql';
import { PhoneNumberPipe } from '@doctorus-front-end-monorepo/util-formatting';

@Component({
    selector: 'patient-phone-number-box',
    imports: [CommonModule, PhoneNumberPipe],
    templateUrl: './phone-number-box.component.html',
    styleUrl: './phone-number-box.component.scss'
})
export class PhoneNumberBoxComponent {
  @Input() phonenumber!: PhoneNumber;
  @Input() mode: 'SMALL' | 'LARGE' = 'LARGE';
}
