<div [ngClass]="classes">
  <header [ngClass]="{
      'sticky top-0 bg-white z-10': sticky,
      'border-b pb-4 border-b-gray-200': headerDivider,
    }" class="mb-8">
    <section class="flex flex-wrap gap-4 justify-between items-center">
      @switch (level) {
      @case (1) {
      <h1 class="first-letter:uppercase">{{ title }}</h1>
      }
      @case (2) {
      <h2 class="first-letter:uppercase">{{ title }}</h2>
      }
      @default {
      <h3 class="first-letter:uppercase">{{ title }}</h3>
      }
      }
      <ng-content select="actions"></ng-content>
    </section>
    <ng-content class="text-gray-600 first-letter:uppercase" select="description"></ng-content>
  </header>
  <main [ngClass]="contentClasses">
    <ng-content></ng-content>
  </main>
</div>
