import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { AngularPinturaModule } from '@pqina/angular-pintura';
import { Observable } from 'rxjs';
import { ZoomParams } from '../types';

@Component({
    selector: 'util-document-image-zoom',
    templateUrl: './image-zoom.component.html',
    styleUrls: ['./image-zoom.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [AngularPinturaModule]
})
export class ImageZoomComponent implements AfterViewInit {
  @Input() url?: string;
  @Input() title?: string;
  @Input() content?: string;
  @ViewChild('img') imgEl!: ElementRef<HTMLImageElement>;
  @ViewChild('lens') lensEl!: ElementRef<HTMLDivElement>;
  @ViewChild('res') resEl!: ElementRef<HTMLDivElement>;
  zoomParams$!: Observable<ZoomParams>;
  zoomParams: ZoomParams = {
    resultBgPos: '',
    lensLeft: '0px',
    lensTop: '0px',
  };
  cx!: number;
  cy!: number;
  resultBgSize = '';

  ngAfterViewInit(): void {
    this.cx =
      this.resEl?.nativeElement?.offsetWidth /
      this.lensEl?.nativeElement?.offsetWidth;
    this.cy =
      this.resEl?.nativeElement?.offsetHeight /
      this.lensEl?.nativeElement?.offsetHeight;
  }
  updateRatios(): void {
    this.resultBgSize =
      this.imgEl.nativeElement.width * this.cx +
      'px ' +
      this.imgEl.nativeElement.height * this.cy +
      'px';
  }

  get resultBgUrl(): string {
    return "url('" + this.url + "')";
  }
  get lensPos(): DOMPoint {
    return DOMPoint.fromPoint({
      x: 0,
      y: 0,
    });
  }
  updateParams(event: Event) {
    this.zoomParams = this.moveLens(event as MouseEvent);
  }
  private moveLens = (e: MouseEvent): ZoomParams => {
    /* Prevent any other actions that may occur when moving over the image */
    e.preventDefault();
    /* Get the cursor's x and y positions: */
    const pos = this.getCursorPos(e);
    /* Calculate the position of the lens: */
    let x = pos.x - this.lensEl.nativeElement.offsetWidth / 2;
    let y = pos.y - this.lensEl.nativeElement.offsetHeight / 2;
    /* Prevent the lens from being positioned outside the image: */
    if (
      x >
      this.imgEl.nativeElement.width - this.lensEl.nativeElement.offsetWidth
    ) {
      x =
        this.imgEl.nativeElement.width - this.lensEl.nativeElement.offsetWidth;
    }
    if (x < 0) {
      x = 0;
    }
    if (
      y >
      this.imgEl.nativeElement.height - this.lensEl.nativeElement.offsetHeight
    ) {
      y =
        this.imgEl.nativeElement.height -
        this.lensEl.nativeElement.offsetHeight;
    }
    if (y < 0) {
      y = 0;
    }
    /* Set the position of the lens: */
    /* Display what the lens "sees": */
    return {
      resultBgPos: '-' + x * this.cx + 'px -' + y * this.cy + 'px',
      lensLeft: x + 'px',
      lensTop: y + 'px',
    };
  };
  private getCursorPos = (e: MouseEvent): DOMPoint => {
    //e = e || window.event;
    /* Get the x and y positions of the image: */
    const a = this.imgEl.nativeElement.getBoundingClientRect();
    /* Calculate the cursor's x and y coordinates, relative to the image: */
    let x = e.pageX - a.left;
    let y = e.pageY - a.top;
    /* Consider any page scrolling: */
    x = x - window.scrollX;
    y = y - window.scrollY;
    return DOMPoint.fromPoint({
      x,
      y,
    });
  };
}
