import { AsyncPipe, CommonModule, CurrencyPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnDestroy,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { AppointmentsListComponent } from '@doctorus-front-end-monorepo/feature-appointment';
import {
  PatientDetailsBaseComponent,
  PatientFetchService,
  PhoneNumberBoxComponent,
  WritePhoneNumberComponent,
} from '@doctorus-front-end-monorepo/feature-patient';
import {
  CorePatientFieldsFragment,
  DeletePhoneNumberGQL,
  GetPatientProfileGQL,
  PhoneNumber,
  PutPhoneNumberGQL,
} from '@doctorus-front-end-monorepo/graphql';
import { map, switchMap } from 'rxjs/operators';
import { AccountDataService } from '../../../../../../libs/shared/auth/src';
import { EntityMutationService } from '../../../../../../libs/shared/feature-entity/src';
import { phoneNumberConfig } from '../../../../../../libs/shared/patient/feature-patient/src/lib/patient.entity.model';
import { HumanNamePipe } from '../../../../../../libs/shared/shared-util/src';
import { EntityDeleteMutationService } from '../../../../../../libs/shared/ui-entity-dialog/src';
import {
  ContainerComponent,
  EmptyStateComponent,
  KeyValueComponent,
} from '../../../../../../libs/shared/ui-layout/src';
import {
  CoalescePipe,
  PhoneNumberPipe,
} from '../../../../../../libs/shared/util-formatting/src';
import { PatientPaymentListComponent } from '../../patient-payment/components/patient-payment-list/patient-payment-list.component';

@Component({
  selector: 'doctorus-front-end-monorepo-patient-detail',
  templateUrl: './patient-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./patient-detail.component.scss'],
  providers: [
    {
      provide: PatientFetchService,
      useExisting: GetPatientProfileGQL,
    },
  ],
  standalone: true,

  imports: [
    NgIf,
    RouterModule,
    CommonModule,
    MatGridListModule,
    MatCardModule,
    MatChipsModule,
    MatIconModule,
    MatButtonModule,
    CoalescePipe,
    MatTableModule,
    MatListModule,
    KeyValueComponent,
    PhoneNumberBoxComponent,
    MatTabsModule,
    MatIconModule,
    EmptyStateComponent,
    PhoneNumberPipe,
    ContainerComponent,
    HumanNamePipe,
    AppointmentsListComponent,
    PatientPaymentListComponent,
    AsyncPipe,
    CurrencyPipe,
  ],
})
export class PatientDetailComponent
  extends PatientDetailsBaseComponent
  implements OnDestroy
{
  currency = inject(AccountDataService).account.currency;
  private getPatientProfileGQL = inject(GetPatientProfileGQL);

  phoneNumberInjector = Injector.create({
    providers: [
      {
        provide: EntityMutationService,
        useExisting: PutPhoneNumberGQL,
      },
      {
        provide: EntityDeleteMutationService,
        useExisting: DeletePhoneNumberGQL,
      },
    ],
    parent: this.injector,
  });
  patient$ = this.route.parent.paramMap.pipe(
    map(x => x.get('patId')),
    switchMap(
      id =>
        this.getPatientProfileGQL.watch({
          id: id,
        }).valueChanges,
    ),
    map(x => x.data.getPatient),
  );

  getMeaning = (amount: number) =>
    amount >= 0 ? $localize`due` : $localize`advance`;

  ngOnDestroy(): void {
    this?.subscription.unsubscribe();
  }

  writePhoneNumber(
    entity?: PhoneNumber,
    patient?: CorePatientFieldsFragment,
  ): void {
    this.sds
      .openDialogWriteForm(
        {
          cmp: WritePhoneNumberComponent,
          entity,
          entityConfig: phoneNumberConfig,
          extra: { patient },
        },
        this.phoneNumberInjector,
      )
      .subscribe();
  }

  deletePhoneNumber(entity?: PhoneNumber): void {
    this.eds
      .openEntityDeleteDialog(
        entity,
        this.phoneNumberInjector,
        phoneNumberConfig,
      )
      .subscribe();
  }
}
