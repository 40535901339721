export function deleteFn<T, K extends keyof T>(
  array: T[] | null | undefined,
  el: T[K],
  compareProp: K,
): T[] {
  if (array) {
    const _array = [...array];
    const _index = _array.findIndex(
      ent => ent[compareProp]?.toString() === el?.toString(),
    );
    console.warn(_index);
    if (_index >= 0) {
      _array.splice(_index, 1);
    }
    return _array;
  } else {
    return [];
  }
}

export function updateArrayFn<T>(
  array: T[] | null | undefined,
  obj: Partial<T>,
  compareProp: keyof T,
) {
  if (array) {
    const _array = [...array];
    const _index = _array.findIndex(
      ent => ent[compareProp] === obj[compareProp],
    );
    if (_index >= 0) {
      _array.splice(_index, 1, {
        ...array[_index],
        ...obj,
      });
      return _array;
    }
  }
  return [];
}
export function upsertArrayFn<T>(
  array: T[] | null | undefined,
  newObj: T,
  compareProp: keyof T,
): T[] {
  if (array) {
    const el = newObj;
    const _array = [...array];
    const _index = _array.findIndex(
      ent => ent[compareProp] === el[compareProp],
    );
    if (_index >= 0) {
      _array.splice(_index, 1, {
        ...array[_index],
        ...newObj,
      });
    } else {
      _array.push(el);
    }
    return _array;
  } else {
    return [newObj];
  }
}

// export function mergeArraysFn<T, C extends T[] | null | undefined>(
//   origin: C,
//   newArray: C,
//   compareProp: keyof T,
// ): T[] {
//   if (!origin && newArray) {
//     return newArray;
//   } else if (!newArray && origin) {
//     return origin;
//   } else {
//     newArray?.forEach(_origin)
//   }
//     return  origin!.map(_originEl => ({
//       ..._originEl,
//       ...newArray?.find(
//         _newElm => _newElm[compareProp] === _originEl[compareProp],
//       ),
//     }));
// }
