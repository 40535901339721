<header class="flex items-center gap-2 p-2 border-b border-b-gray-200 sticky top-0 bg-white z-20">
  <button mat-icon-button (click)="close()"><mat-icon>close</mat-icon></button>
  <h2 class="capitalize" i18n>images explorer</h2>
</header>
<div class="p-4 flex gap-4">
  @if(data.images) {
  <aside class="w-28 flex flex-col gap-2">
    @for (image of data.images; track $index) {
    <div (click)="select(image)" [class]="selected().id === image.id ? 'border-green-600':'border-gray-100'"
      class="border-2 rounded-sm hover:cursor-pointer">
      <img class="max-w-full" [src]="image.multi_sizes_urls.S" alt="">

    </div>
    }
  </aside>
  }

  <util-document-image-zoom [title]="selected().name" [content]="selected().description" class="flex-1"
    [url]="selected().multi_sizes_urls.O"></util-document-image-zoom>

</div>