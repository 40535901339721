<ng-container *ngIf="(medicalRecordsDates() |arrayExist) || (patient().medicalTags|arrayExist) else noMedicalNote">
  <ui-layout-container title="patient timeline" i18n-title>
    <button ngProjectAs="actions" [mat-menu-trigger-for]="sortMenu" mat-icon-button>
      <mat-icon>sort</mat-icon>
    </button>


    <div class="flex gap-4 relative list-container overflow-y-auto pr-2 mt-2">
      <nav class="flex flex-col  list-container overflow-y-auto sticky top-0 gap-2 min-w-fit border-r pr-4">
        <div class="flex flex-col gap-1 items-center text-gray-700" *ngFor="let entry of medicalRecordsDates()">
          <p class="font-medium">{{entry[0] |date:'MMM yyyy'}}</p>
          <a (click)="scrollTo(date)" class="text-sm" [routerLink]="[]" [fragment]="date | date:'shortDate'"
            *ngFor="let date of entry[1]">{{date|date:'dd'}}</a>
        </div>
      </nav>
      <main #listLane class="flex flex-col w-full gap-6">
        <!-- @if (patient().medicalTags && patient().medicalTags.length > 0) {
      <div>
        <h2 class="capitalize mb-2" i18n>medical tags</h2>
        <mat-chip-listbox>
          <mat-chip *ngFor="let tag of patient().medicalTags">{{tag}}</mat-chip>
        </mat-chip-listbox>
      </div>

      } -->

        <article #listEl [id]="recordsDateGroup[0]" class=" w-full" *ngFor="let recordsDateGroup of timeLineRecords()">
          <h1 class="mb-2">{{recordsDateGroup[0]| date:'fullDate' | titlecase}}</h1>
          <section class="p-4 flex flex-col gap-6">
            @for (appointment of recordsDateGroup[1].appointments; track $index) {
            <div>
              @if(appointment.description) {
              <h4>{{appointment.description}}</h4>
              }
              <quill-view [content]="appointment.note"></quill-view>
            </div>

            }

            @for (document of recordsDateGroup[1].documents; track $index) {
            <article>
              @if(document.type === 'UPLOADED'){
              <a target="_blank" [href]="document.multi_sizes_urls.O">
                <figure>
                  @if(document.file|isImage) {
                  <img class="max-w-sm" [src]="document.multi_sizes_urls.O" alt="medical image" />

                  }@else {
                  <util-document-file-box [fileSrc]="document.file"></util-document-file-box>

                  }
                  <figcaption>
                    <h4>{{document.name}}</h4>@if(document.description){<p>{{document.description}}</p>}
                  </figcaption>
                </figure>
              </a>




              <!-- @if(document.name){
            <h3 class="flex items-center gap-2">
              @if(!(document.file|isImage)) {
              <util-document-file-box [fileSrc]="document.file"></util-document-file-box>
              }
              {{document.name}}
            </h3>
            @if(document.description) {
            <p class="text-gray-700"></p>

            }
            @if((document.file|isImage)) {
            <img [src]="document.multi_sizes_urls.O" class="max-w-sm">
            }
            <a class="anchor" [href]="document.multi_sizes_urls.O">{{document.file}}</a>

            }

            } @else {
          -->
              }@else {
              @if (document.title) {
              <h4>{{document.title}}</h4>
              }
              @if(document.content) {
              <quill-view [content]="document.content"></quill-view>
              }
              }
            </article>
            }
            @for (prescription of recordsDateGroup[1].prescriptions; track $index) {
            <h4 class="first-letter:uppercase" i18n>prescription</h4>
            <dl class="flex flex-col gap-1">
              @for (treatment of prescription.treatment_set; track $index) {

              <dt class="font-medium">{{treatment.name}}</dt>
              <dd class="pl-6 italic text-gray-700">{{treatment.note}}</dd>
              }

            </dl>

            }
            @for (obs of recordsDateGroup[1].observations; track $index) {
            <div class="flex gap-4 flex-wrap">
              <h4>{{obs.measure.name}} : {{obs.value}} {{obs.measure.unit}}</h4>
              @if (obs.note) {
              <p class="text-gray-700">{{obs.note}}</p>

              }
            </div>

            }
          </section>
        </article>
      </main>
    </div>
  </ui-layout-container>

</ng-container>

<ng-template #noMedicalNote>
  <div class="flex  flex-col items-center gap-2 h-full justify-center text-gray-700">
    <p class="first-letter:uppercase font-medium" i18n>no medical data</p>
    <small i18n>patient medical record is empty</small>
  </div>
</ng-template>
<mat-menu #sortMenu="matMenu">
  <button class="text-sm" (click)="toggleOrdering('desc')" mat-menu-item><mat-icon
      [class.invisible]="order() !== 'desc'">check</mat-icon><span class="capitalize">newest
      first</span></button>
  <button class="text-sm" (click)="toggleOrdering('asc')" mat-menu-item><mat-icon
      [class.invisible]="order() !== 'asc'">check</mat-icon>
    <span class="capitalize">oldest first</span></button>
</mat-menu>