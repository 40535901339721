import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { MeasureModel } from '../../../../../shared/data-access/graphql/src';
import { BaseEntityListComponent } from '../../../../../shared/feature-entity/src';
import { CoalescePipe } from '../../../../../shared/util-formatting/src';

@Component({
  selector: 'lib-ui-measure-models',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatIconModule,
    CoalescePipe,
    RouterModule,
  ],
  templateUrl: './ui-measure-models.component.html',
  styleUrl: './ui-measure-models.component.css',
})
export class UiMeasureModelsComponent extends BaseEntityListComponent<MeasureModel> {
  columns = ['name', 'unit', 'order'];
}
