<mat-table class="bg-white" [dataSource]="objs()">
  <!-- User name Definition -->
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef i18n>name</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span class="rounded-full h-4 w-4" [style.background-color]="row.color"></span>
      <a [routerLink]="row.id" class="ml-2 anchor">{{row.name}}</a>
    </mat-cell>
  </ng-container>

  <!-- Age Definition -->
  <ng-container matColumnDef="duration">
    <mat-header-cell *matHeaderCellDef i18n>duration</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.default_duration|duration}}
    </mat-cell>
  </ng-container>
  <!-- Age Definition -->
  <ng-container matColumnDef="fees">
    <mat-header-cell *matHeaderCellDef i18n>fees</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.default_fees}}
    </mat-cell>
  </ng-container>


  <!-- Header and Row Declarations -->
  <mat-header-row *matHeaderRowDef="['name','duration','fees']"></mat-header-row>
  <mat-row *matRowDef="let row; columns: ['name','duration','fees']"></mat-row>
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="3">
      <ui-layout-empty-state>
        <p i18n>no task types</p>
        <button ngProjectAs="actions" mat-stroked-button>new task type</button>
      </ui-layout-empty-state>

  </tr>
</mat-table>