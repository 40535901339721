<form class="flex flex-col gap-6 pb-6" [formGroup]="form">
  <ui-layout-container level="2" title="general informations" i18n-title>
    <main class="flex gap-4 flex-wrap">
      <div class="flex flex-col gap-4 flex-1">
        <mat-form-field uiFormMatFormFieldErrorDisplayer class="col-span-full">
          <mat-label i18n>given name</mat-label>
          <input matInput formControlName="given_name" />
          <mat-error></mat-error>
        </mat-form-field>
        <mat-form-field class="col-span-full md:col-span-2">
          <mat-label i18n>middle name</mat-label>
          <input matInput formControlName="middle_name" />
          <mat-error></mat-error>
        </mat-form-field>
        <mat-form-field
          uiFormMatFormFieldErrorDisplayer
          class="col-span-full md:col-span-4"
        >
          <mat-label i18n>family name</mat-label>
          <input matInput formControlName="family_name" />
          <mat-error></mat-error>
        </mat-form-field>
      </div>
      <div class="flex flex-col gap-4 flex-1">
        <mat-form-field>
          <mat-label i18n>email</mat-label>
          <input matInput type="email" formControlName="email" />
          <mat-error></mat-error>
        </mat-form-field>
        <mat-form-field
          class="col-span-full md:col-span-3"
          uiFormMatFormFieldErrorDisplayer
        >
          <mat-label i18n>gender</mat-label>
          <mat-select required formControlName="gender">
            <mat-option value="female"
              ><span class="first-letter:uppercase" i18n
                >female</span
              ></mat-option
            >
            <mat-option value="male"
              ><span class="first-letter:uppercase" i18n>male</span></mat-option
            >
          </mat-select>
          <mat-error></mat-error>
        </mat-form-field>
        <mat-form-field
          class="col-span-full md:col-span-3"
          uiFormMatFormFieldErrorDisplayer
        >
          <mat-label i18n>date of birth</mat-label>
          <input #input matInput type="date" formControlName="birthdate" />
          <mat-icon
            matIconSuffix
            class="hover:cursor-pointer"
            (click)="input.showPicker()"
            >calendar_month</mat-icon
          >
          <mat-error></mat-error>
        </mat-form-field>
      </div>
    </main>
  </ui-layout-container>
  @if (newObj()) {
    <ui-layout-container level="2" [title]="phoneNumberTitle">
      <button
        ngProjectAs="actions"
        (click)="newPhoneNumber()"
        mat-stroked-button
      >
        <span>new phone number</span>
      </button>

      <section class="flex flex-col gap-4" formArrayName="phone_numbers">
        <form
          [formGroupName]="i"
          *ngFor="let control of phoneNumberCtrls; index as i"
          class="flex flex-wrap gap-4 w-full"
        >
          <!-- <header class="bg-gray-100 flex items-center justify-between p-2 col-span-6">
            <h3># {{control.value.phone_number |phoneNumber}}</h3>
            <mat-icon (click)="removePhoneNumberCtrl(i)" class="hover:cursor-pointer">close</mat-icon>
          </header> -->
          <mat-form-field class="flex-auto" uiFormMatFormFieldErrorDisplayer>
            <shared-ui-phone-number-picker
              #picker="phonePicker"
              [initCountry]="account?.defaultCountryCode"
              formControlName="phone_number"
            >
            </shared-ui-phone-number-picker>
            <mat-hint *ngIf="picker.exampleNumber"
              ><span class="mr-1" i18n>eg :</span
              >{{ picker.exampleNumber }}</mat-hint
            >

            <mat-error></mat-error>
          </mat-form-field>
          <!-- <mat-chip-listbox formControlName="availabilities" aria-label="Phone number availabilities">
            @for (availability of availabilities; track availability) {
            <mat-chip-option>{{availability.value}}</mat-chip-option>
            }
          </mat-chip-listbox> -->
          <mat-form-field class="flex-auto">
            <mat-label i18n>remarks</mat-label>
            <input type="text" matInput formControlName="remarks" />
          </mat-form-field>
          <mat-form-field>
            <mat-label i18n>availabilities</mat-label>
            <mat-select multiple formControlName="availabilities">
              <mat-option [value]="'WHATSAPP'">WhatsApp</mat-option>
              <mat-option [value]="'VIBER'">Viber</mat-option>
            </mat-select>
          </mat-form-field>
          <button
            type="button"
            (click)="removePhoneNumberCtrl(i)"
            mat-flat-button
          >
            <span i18n>remove</span>
          </button>
        </form>
      </section>
    </ui-layout-container>

    <ui-layout-container level="2" [title]="propertiesTitle">
      <button
        (click)="addProperyCtrl()"
        ngProjectAs="actions"
        mat-stroked-button
      >
        <mat-icon>add_circle</mat-icon>
        <span i18n>add property</span>
      </button>
      <lib-inline-form-array
        [init]="initPublicProperties()"
        [min]="0"
        [extra]="0"
        [formBuildFn]="createProperty"
        controlKey="public_properties"
      >
        <ng-template let-index="index" #fields>
          <patient-property-inline-form
            [index]="index"
            formArrayKey="public_properties"
          ></patient-property-inline-form>
        </ng-template>
      </lib-inline-form-array>
    </ui-layout-container>
  }
  <ui-layout-container level="2" title="remarks" i18n-title>
    <mat-form-field class="w-full">
      <textarea
        placeholder="type your remarks"
        i18n-placeholder
        matInput
        type="text"
        formControlName="remarks"
      >
      </textarea>
    </mat-form-field>
  </ui-layout-container>
</form>
