import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AccountLocation } from '../../../../../shared/data-access/graphql/src';
import { BaseEntityDetailsComponent } from '../../../../../shared/feature-entity/src';
import { KeyValueComponent } from '../../../../../shared/ui-layout/src';
import { DurationPipe, TimePipe } from '../../../../../shared/util-time/src';

@Component({
    selector: 'lib-location-details',
    imports: [CommonModule, KeyValueComponent, TimePipe, DurationPipe],
    templateUrl: './location-details.component.html',
    styleUrl: './location-details.component.scss'
})
export class LocationDetailsComponent extends BaseEntityDetailsComponent<AccountLocation> {}
