import { TypePolicy } from '@apollo/client/cache/inmemory/policies';

import { AppointmentStatus } from '@doctorus-front-end-monorepo/shared-util';
import { gql } from 'apollo-angular';
import { compareAsc, compareDesc } from 'date-fns';
import {
  Account,
  AccountFieldsFragmentDoc,
  Appointment,
  AppointmentMinimumFieldsFragmentDoc,
  MedicalNotesFieldsFragmentDoc,
  Patient,
  PatientPayment,
} from '../../generated';
import { ALL_DOCUMENTS_FIELD } from './fields';

export const patientTypePolicy: TypePolicy = {
  fields: {
    allDocuments: ALL_DOCUMENTS_FIELD,
    missingMeasureChecks: {
      // Field policy for the isInCart field
      read(
        obj,
        { variables, field, args, cache, toReference, readField, storage },
      ) {
        const patient = cache.readFragment<Pick<Patient, 'measures'>>({
          id: `Patient:${variables!['id']}`,
          fragmentName: 'PatientMeasuresFrag',
          fragment: gql`
            ${MedicalNotesFieldsFragmentDoc}
            fragment PatientMeasuresFrag on Patient {
              measures {
                ...MeasureFields
              }
            }
          `,
        });
        const account = cache.readFragment<Pick<Account, 'measureModels'>>({
          id: `Account:${localStorage.getItem('account-id')}`,
          fragmentName: 'AccountMeasureModelsFrag',
          fragment: gql`
            ${AccountFieldsFragmentDoc}
            fragment AccountMeasureModelsFrag on Account {
              measureModels {
                ...MeasureModelFields
              }
            }
          `,
        });
        const val = account?.measureModels?.filter(
          _measureModel =>
            !patient?.measures
              .map(_measure => _measure.name.toLowerCase().trim())
              .includes(_measureModel.name.toLowerCase().trim()),
        );
        return val;
      },
    },
    payment_status: {
      read(obj, { variables, field, args, cache, toReference, readField }) {
        const patient = cache.readFragment<{
          appointments: Array<Pick<Appointment, 'fees' | 'status'>>;
          payments: Array<Pick<PatientPayment, 'amount'>>;
        }>({
          id: `Patient:${variables ? variables['id'] : 'N/A'}`,
          fragment: gql`
            fragment PatientFrag on Patient {
              id
              appointments {
                status
                fees
              }
              payments {
                amount
              }
            }
          `,
        });
        return patient?.appointments && patient?.payments
          ? {
              total_due:
                patient.payments.reduce(
                  (acc: number, current) => acc + current.amount,
                  0,
                ) -
                patient.appointments.reduce(
                  (acc: number, current) => acc + (current?.fees ?? 0),
                  0,
                ),

              total_paid: patient.payments.reduce(
                (acc: number, current) => acc + current.amount,
                0,
              ),
            }
          : undefined;
      },
    },
    outdatedPendingAppointments: {
      read(obj, { variables, field, args, cache, toReference, readField }) {
        // const appointmentsRef = readField<Reference[]>('appointments');
        // const appointments = appointmentsRef?.map(ref =>
        //   cache.readFragment<Appointment>({
        //     fragment: AppointmentMinimumFieldsFragmentDoc,
        //     id: cache.identify(ref),
        //   }),
        // );
        // console.warn(appointments);
        // return (
        //   appointments?.filter(
        //     _app =>
        //       _app?.status === AppointmentStatus.PENDING &&
        //       isBefore(_app.end ?? new Date(), new Date()),
        //   ) ?? []
        // );
        return [];
      },
    },
    nextAppointment: {
      read(obj, { variables, field, args, cache, toReference, readField }) {
        const patient = cache.readFragment<{
          appointments: Array<Appointment>;
          payments: Array<Pick<PatientPayment, 'amount'>>;
        }>({
          id: `Patient:${variables!['id']}`,
          fragment: gql`
            fragment PatientFrag on Patient {
              id
              appointments {
                ...AppointmentMinimumFields
              }
            }
            ${AppointmentMinimumFieldsFragmentDoc}
          `,
        });
        return (
          patient?.appointments
            .filter(app => app.status === AppointmentStatus.DONE)
            .sort((a, b) => compareAsc(a.start, b.start))
            .shift() ?? null
        );
      },
    },
    lastAppointment: {
      read(obj, { variables, field, args, cache, toReference, readField }) {
        const appointments = readField<Appointment[]>({
          fieldName: 'appointments',
        });
        return appointments
          ? appointments
              .filter(x => x.status === AppointmentStatus.DONE)
              .sort((a, b) => compareDesc(a.start, b.end))
              .shift()
          : null;
      },
    },
  },
};
