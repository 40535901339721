<ui-layout-container border="false" [title]="title()" i18n-title>
    <button [routerLink]="['new']" ngProjectAs="actions"  mat-flat-button>
        <span i18n>new care plan</span>
      </button>

<div class="flex flex-col gap-1">
  <ui-layout-container title="filter by" paddingLevel="0" border="false" level="2">
    <div ngProjectAs="actions">
      <button (click)="clearFilters()"  mat-button><span>clear filters</span></button>

    </div>
    <section class="flex flex-wrap gap-2">
      <mat-form-field class="w-fit">
        <mat-label>contributors</mat-label>
        <mat-select multiple="true" [value]="contributors()" (selectionChange)="contributors.set($event.value)">
          @for (item of account?.memberships; track $index) {
            <mat-option [value]="item.user.sub">{{item.user | humanName}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field class="w-fit">
        <mat-label i18n>start date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input (dateChange)="startDateFrom.set($event.value)" matStartDate placeholder="Start date">
          <input (dateChange)="startDateTo.set($event.value)"  matEndDate placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </section>



  </ui-layout-container>

  <mat-paginator [length]="carePlans()?.count" class="self-end" hidePageSize="true" (page)="page.set($event.pageIndex)" [pageIndex]="page()" pageSize="20" aria-label="Select page of care plans"></mat-paginator>
  @if(isLoading() === false) {
      <table matSort (matSortChange)="onSort($event)" mat-table [dataSource]="carePlans()?.results" class="w-full">

        <ng-container matColumnDef="name">
          <th mat-header-cell i18n *matHeaderCellDef>name</th>
          <td mat-cell *matCellDef="let element"><a class="anchor" [routerLink]="[element.id]">{{element.name}}</a></td>
        </ng-container>
      
        <ng-container matColumnDef="patient">
          <th mat-header-cell *matHeaderCellDef>patient</th>
          <td mat-cell *matCellDef="let element"><a class="anchor" [routerLink]="rhns.getPatientRoute(element.patient.id)">{{element.patient |humanName}}</a></td>
        </ng-container>
      
        <ng-container matColumnDef="start">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>start</th>
          <td mat-cell *matCellDef="let element">{{element.start | date}}</td>
        </ng-container>
      
        <ng-container matColumnDef="end">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>end</th>
          <td mat-cell *matCellDef="let element">{{element.end|date}}</td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            <ui-layout-empty-state>
              <span i18n>no care plans</span>
            </ui-layout-empty-state>
      </td>
        </tr>
      
          </table>


  }@else { 
    <ui-layout-loading></ui-layout-loading>

  }

</div>

</ui-layout-container>