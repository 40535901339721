import { CommonModule } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTimepickerModule } from '@angular/material/timepicker';
import { tz } from '@date-fns/tz';
import { startOfToday, startOfTomorrow } from 'date-fns';
import { isNil } from 'lodash';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { rxResource } from '@angular/core/rxjs-interop';
import { BaseEntityFormComponent } from '@doctorus-front-end-monorepo/feature-entity';
import { CustomMatSelectSearchFieldComponent } from '@doctorus-front-end-monorepo/generic-mat-select-search';
import {
  AccountDataService,
  CarePlan,
  GetAllPatientsGQL,
  MinimumPatientFieldsFragment,
  Patient,
} from '@doctorus-front-end-monorepo/graphql';

import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';
import { map, Subscription } from 'rxjs';

@Component({
  selector: 'lib-care-plan-write',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatDatepickerModule,
    MatTimepickerModule,
    UiMatFormFieldErrorDisplayerDirective,
    CustomMatSelectSearchFieldComponent
],
  templateUrl: './care-plan-write.component.html',
  styleUrl: './care-plan-write.component.scss',
})
export class CarePlanWriteComponent extends BaseEntityFormComponent<
  CarePlan,
  { patient?: Patient }
> {
  patient = computed(() => this.extra()?.patient);
  subscription = new Subscription();
  hasPatientField = computed<boolean>(() => isNil(this.patient()?.id));
  patientFilterCtrl = new FormControl();
  patientsLoading = signal(false);
  filtredPatients = signal<Array<MinimumPatientFieldsFragment>>([]);
  getAllPatientsGQL = inject(GetAllPatientsGQL);
  searchTerm = signal('');
  account = inject(AccountDataService).account;
  patientSearch = signal<string>('');
  users = this.account?.memberships?.map(x => x.user) ?? [];
  patientsRx = rxResource({
    request: () => ({
      search: this.searchTerm(),
      init: this.obj()?.patient?.id ?? this.patient()?.id,
    }),
    loader: param =>
      this.getAllPatientsGQL
        .fetch({
          payload: {
            search: param.request.search,
            ...(param.request.init && { id__in: [param.request.init] }),
          },
        })
        .pipe(map(x => x.data.getPatients.results)),
  });

  override initFormFun = () =>
    this.fb.group({
      id: [this.obj()?.id],
      name: [this.obj()?.name ?? this.queryParams()?.name, Validators.required],
      start: [
        this.obj()?.start
          ? new Date(this.obj()?.start)
          : startOfToday({
              in: tz(Intl.DateTimeFormat().resolvedOptions().timeZone),
            }),
        Validators.required,
      ],
      end: [
        this.obj()?.end
          ? new Date(this.obj()?.end)
          : startOfTomorrow({
              in: tz(Intl.DateTimeFormat().resolvedOptions().timeZone),
            }),
        Validators.required,
      ],
      frequency: [
        this.obj()?.frequency ?? this.queryParams()?.frequency,
        [Validators.required, Validators.max(20), Validators.min(1)],
      ],
      location: [
        this.obj()?.location?.id ??
          this.account?.appointmentDefaultLocation?.id ??
          this.account?.locations?.at(0)?.id,
        Validators.required,
      ],
      patient: [
        this.obj()?.patient?.id ?? this.patient()?.id,
        Validators.required,
      ],
      contributors: [this.obj()?.contributors?.map(x => x.sub)],
      notes: [this.obj()?.notes ?? this.queryParams()?.notes],
    });
}
