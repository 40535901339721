/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @typescript-eslint/no-empty-function */
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { SelectionModel } from '@angular/cdk/collections';
import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  Component,
  computed,
  DoCheck,
  ElementRef,
  HostBinding,
  inject,
  input,
  Input,
  linkedSignal,
  OnDestroy,
  Optional,
  Self,
} from '@angular/core';
import { outputFromObservable, toSignal } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  FormGroupDirective,
  FormsModule,
  NgControl,
  NgForm,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import {
  MatFormFieldControl,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { HumanNamePipe } from '@doctorus-front-end-monorepo/shared-util';
import { isEqual, isNil } from 'lodash';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  debounceTime,
  filter,
  map,
  Observable,
  of,
  Subject,
  Subscription,
} from 'rxjs';

type TypeMatchingKeys<T, V> = {
  [K in keyof T]: T[K] extends V ? K : never;
}[keyof T];

@Component({
  selector: 'lib-custom-mat-search-select-field',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatAutocompleteModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    FormsModule,
  ],
  templateUrl: './custom-mat-search-select-field.component.html',
  standalone: true,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: CustomMatSelectSearchFieldComponent,
    },
  ],
  host: {
    '[class.floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
  styleUrl: './custom-mat-search-select-field.component.scss',
})
export class CustomMatSelectSearchFieldComponent<T>
  implements
    ControlValueAccessor,
    MatFormFieldControl<T | T[]>,
    DoCheck,
    OnDestroy
{
  valueProp = input<TypeMatchingKeys<T, symbol>>();
  allOptions = input<T[]>([]);
  loading = input(false, { transform: booleanAttribute });

  @Input({ transform: booleanAttribute }) multiple = false;
  @Input() placeholderLabel = '';
  displayWith = input<(value: T) => string>(value =>
    typeof value === 'object' ? value.toString() : 'N/A',
  );
  filterWith = input<(obj: T, term: string) => boolean>(
    (obj, term) => (obj as any) === term,
  );
  compareWith = input<(o1: T, o2: T) => boolean>();
  humanEntity = input(false, { transform: booleanAttribute });
  externalFiltering = input(false, { transform: booleanAttribute });
  compareFun = linkedSignal(() => {
    const valueProp = this.valueProp();
    return (
      this.compareWith() ??
      (valueProp ? (a: T, b: T) => a[valueProp] === b[valueProp] : isEqual)
    );
  });
  displayFunc = linkedSignal(() =>
    this.humanEntity()
      ? (a: any) => new HumanNamePipe().transform(a, 'full')
      : this.displayWith,
  );

  filterFunc = linkedSignal(() =>
    this.externalFiltering()
      ? (a: any, term: string) => true
      : this.humanEntity()
        ? (a: any, term: string) =>
            Object.entries<string>(a)
              .filter(
                ([key, value]) =>
                  ['given_name', 'family_name', 'email'].includes(key) &&
                  !!value,
              )
              .some(([key, value]) => value!.includes(term))
        : this.filterWith,
  );
  searchControl = new FormControl('');
  selectionControl = new FormControl();

  searchTerm$ = this.searchControl.valueChanges.pipe(
    debounceTime(300),

    filter(term => !!term),
  );

  searchChange = outputFromObservable(this.searchTerm$);
  selectionChange = outputFromObservable(this.selectionControl.valueChanges);
  selection$: Observable<T[]> = of([]);
  controlType = `seach-select-field-input`;
  autofilled?: boolean | undefined;
  errorState = false;
  private subscription = new Subscription();
  //userAriaDescribedBy?: string | undefined;
  disableAutomaticLabeling?: boolean | undefined;
  stateChanges = new Subject<void>();

  filtredOptions = toSignal(
    this.searchTerm$.pipe(
      map(term =>
        term && term.length > 0
          ? this.allOptions()?.filter(_opt => this.filterFunc()(_opt, term))
          : this.allOptions(),
      ),
    ),
  );

  private controlSelection = toSignal<symbol[] | symbol | null>(
    this.selectionControl.valueChanges,
  );
  selectedItems = computed(() => {
    const selection = this.controlSelection();
    const all = this.allOptions();
    if (selection && all) {
      if (Array.isArray(selection))
        return selection.map(x => this.fetchItem(x, all));
      else return [this.fetchItem(selection, all)];
    } else return [];
  });

  options = computed(
    () =>
      new SelectionModel(
        true,
        [
          ...(this.selectedItems() ?? []),
          ...((this.externalFiltering()
            ? this.allOptions()
            : this.filtredOptions()) ?? []),
        ].filter(x => !isNil(x)) as T[],
        true,
        this.compareFun(),
      ),
  );

  onChange = (_: any) => {};
  onTouched = () => {};
  @Input()
  get value(): T | T[] | null {
    return this.selectionControl.value;
  }
  set value(payload: T | T[] | null) {
    this.selectionControl.setValue(payload);
  }
  static nextId = 0;
  @HostBinding() id =
    `${this.controlType}-${CustomMatSelectSearchFieldComponent.nextId++}`;

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder!: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(req: BooleanInput) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _elementRef = inject(ElementRef);
  private _required = false;
  focused = false;
  touched = false;
  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  get empty() {
    return this.multiple && Array.isArray(this.value)
      ? this.value.length === 0
      : isNil(this.value);
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return !this.empty;
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    if (this._disabled) {
      this.searchControl.disable();
    } else {
      this.searchControl.enable();
    }
    this.stateChanges.next();
  }
  private _disabled = false;

  constructor(
    @Optional() private _parentForm: NgForm,
    @Optional() private _parentFormGroup: FormGroupDirective,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.subscription.add(
      this.selectionControl.valueChanges.subscribe(x => this.onChange(x)),
    );
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngDoCheck() {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  @Input('aria-describedby') userAriaDescribedBy!: string;
  setDescribedByIds(ids: string[]) {
    const controlElement =
      this._elementRef.nativeElement.querySelector('.input-container');
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }
  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'mat-select') {
      this._elementRef.nativeElement.querySelector('mat-select').focus();
    }
  }
  writeValue(obj: T | T[] | null): void {
    this.selectionControl?.setValue(obj);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  removeItem(item: any): void {
    if (this.multiple && Array.isArray(this.selectionControl.value)) {
      this.selectionControl.setValue(
        this.selectionControl.value.filter(el => el !== item),
      );
    } else {
      this.selectionControl.setValue(null);
    }
    this.stateChanges.next();
  }
  private fetchItem = (item: symbol, all: T[]) =>
    all.find(ops =>
      ops && typeof ops === 'object' && this.valueProp()
        ? ops[this.valueProp()!] === item
        : ops === item,
    );
  fetchValue = (item: T) =>
    typeof item === 'symbol'
      ? item
      : this.valueProp()
        ? item[this.valueProp()!]
        : (item as symbol);
  private updateErrorState() {
    const parentSubmitted =
      this._parentFormGroup?.submitted || this._parentForm?.submitted;
    const touchedOrParentSubmitted = this.touched || parentSubmitted;

    const newState =
      (this.ngControl?.invalid || this.searchControl.invalid) &&
      touchedOrParentSubmitted;

    if (this.errorState !== newState) {
      this.errorState = newState;
      this.stateChanges.next(); // Notify listeners of state changes.
    }
  }
}
