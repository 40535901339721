import { AsyncPipe, CommonModule, CurrencyPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnDestroy,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { AccountDataService } from '@doctorus-front-end-monorepo/auth';
import { AppointmentsListComponent } from '@doctorus-front-end-monorepo/feature-appointment';
import { EntityMutationService } from '@doctorus-front-end-monorepo/feature-entity';
import {
  PatientDetailsBaseComponent,
  PatientFetchService,
  PhoneNumberBoxComponent,
  WritePhoneNumberComponent,
} from '@doctorus-front-end-monorepo/feature-patient';
import {
  CorePatientFieldsFragment,
  DeletePhoneNumberGQL,
  GetPatientProfileGQL,
  PhoneNumber,
  PutPhoneNumberGQL,
} from '@doctorus-front-end-monorepo/graphql';
import {
  AgePipe,
  HumanNamePipe,
} from '@doctorus-front-end-monorepo/shared-util';
import { EntityDeleteMutationService } from '@doctorus-front-end-monorepo/ui-entity-dialog';
import {
  ContainerComponent,
  EmptyStateComponent,
  KeyValueComponent,
} from '@doctorus-front-end-monorepo/ui-layout';
import {
  CoalescePipe,
  PhoneNumberPipe,
} from '@doctorus-front-end-monorepo/util-formatting';
import { map, switchMap } from 'rxjs/operators';
import { phoneNumberConfig } from '../../../../../../libs/shared/patient/feature-patient/src/lib/patient.entity.model';
import { DurationPipe } from '@doctorus-front-end-monorepo/util-time';
import { PatientPaymentListComponent } from '../../patient-payment/components/patient-payment-list/patient-payment-list.component';

@Component({
    selector: 'doctorus-front-end-monorepo-patient-detail',
    templateUrl: './patient-detail.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./patient-detail.component.scss'],
    providers: [
        {
            provide: PatientFetchService,
            useExisting: GetPatientProfileGQL,
        },
    ],
    imports: [
        NgIf,
        RouterModule,
        CommonModule,
        MatGridListModule,
        MatCardModule,
        MatChipsModule,
        MatIconModule,
        MatButtonModule,
        CoalescePipe,
        MatTableModule,
        MatListModule,
        KeyValueComponent,
        PhoneNumberBoxComponent,
        MatTabsModule,
        MatIconModule,
        EmptyStateComponent,
        PhoneNumberPipe,
        ContainerComponent,
        HumanNamePipe,
        AppointmentsListComponent,
        PatientPaymentListComponent,
        AsyncPipe,
        AgePipe,
        DurationPipe,
        CurrencyPipe,
    ]
})
export class PatientDetailComponent
  extends PatientDetailsBaseComponent
  implements OnDestroy
{
  currency = inject(AccountDataService).account.currency;
  private getPatientProfileGQL = inject(GetPatientProfileGQL);

  phoneNumberInjector = Injector.create({
    providers: [
      {
        provide: EntityMutationService,
        useExisting: PutPhoneNumberGQL,
      },
      {
        provide: EntityDeleteMutationService,
        useExisting: DeletePhoneNumberGQL,
      },
    ],
    parent: this.injector,
  });
  patient$ = this.route.parent.paramMap.pipe(
    map(x => x.get('patId')),
    switchMap(
      id =>
        this.getPatientProfileGQL.watch({
          id: id,
        }).valueChanges,
    ),
    map(x => x.data.getPatient),
  );

  getMeaning = (amount: number) =>
    amount >= 0 ? $localize`due` : $localize`advance`;

  ngOnDestroy(): void {
    this?.subscription.unsubscribe();
  }

  writePhoneNumber(
    entity?: PhoneNumber,
    patient?: CorePatientFieldsFragment,
  ): void {
    this.sds
      .openDialogWriteForm(
        {
          cmp: WritePhoneNumberComponent,
          entity,
          entityConfig: phoneNumberConfig,
          extra: { patient },
        },
        this.phoneNumberInjector,
      )
      .subscribe();
  }

  deletePhoneNumber(entity?: PhoneNumber): void {
    this.eds
      .openEntityDeleteDialog(
        entity,
        this.phoneNumberInjector,
        phoneNumberConfig,
      )
      .subscribe();
  }
}
