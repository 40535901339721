import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MedicalHistory } from '@doctorus-front-end-monorepo/graphql';
import { RteComponent } from '@doctorus-front-end-monorepo/rte';
import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';
import { QuillEditorComponent } from 'ngx-quill';
import { BaseEntityFormComponent } from '../../../../../shared/feature-entity/src';
import { MedicalHistoryFormBuilderService } from '../medical-history-form-builder.service';

@Component({
    selector: 'medical-history-medical-history-form',
    templateUrl: './write-medical-history-form.component.html',
    styleUrls: ['./write-medical-history-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatDialogModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        UiMatFormFieldErrorDisplayerDirective,
        MatInputModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        NgFor,
        MatIconModule,
        MatOptionModule,
        QuillEditorComponent,
        RteComponent,
        NgIf,
        MatButtonModule,
    ]
})
export class WriteMedicalHistoryFormComponent extends BaseEntityFormComponent<MedicalHistory> {
  medicalhistoriesTypeProps: Set<string> =
    this.extra()?.medicalhistoriesTypeProps ?? new Set<string>();
  private fbs = inject(MedicalHistoryFormBuilderService);
  override initFormFun = () =>
    this.fbs.createForm(this.extra().patient, this.obj(), this.extra().name);
}
