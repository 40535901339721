@let appointments = appointmentsRx.value();
@let loading = appointmentsRx.isLoading();

<ui-layout-container
  border="false"
  sticky="true"
  level="2"
  paddingLevel="1"
  [title]="today | date: 'mediumDate'"
>
  <button ngProjectAs="actions" (click)="close()" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
  @if (loading) {
    <ui-layout-loading
      ><span i18n>loading today appointments ...</span></ui-layout-loading
    >
  } @else {
    <mat-nav-list>
      @if (appointments && appointments.length > 0) {
        <div class="text-gray-700 font-medium" mat-subheader i18n>
          {{ appointments.length }} appointments(s) found
        </div>
      }

      @for (appointment of appointments; track appointment.id) {
        <mat-list-item
          [routerLink]="
            rhns.getApppointmentRoute(appointment.patient, appointment.id)
          "
        >
          <div matListItemMeta>
            <doctorus-front-end-monorepo-status
              entity="appointment"
              [status]="appointment.status"
              displayMode="TEXT_ONLY"
            ></doctorus-front-end-monorepo-status>
          </div>
          <a matListItemTitle
            >{{ appointment.start | date: 'h:mm' }} ->
            {{ appointment.end | date: 'h:mm' }}</a
          >
          <p matListItemLine>
            {{ appointment.patient_info | humanName }}
          </p>
        </mat-list-item>
      } @empty {
        <ui-layout-empty-state>
          <h3 i18n>There are no appointments for today</h3>
        </ui-layout-empty-state>
      }
    </mat-nav-list>
  }
</ui-layout-container>
