import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { TaskType } from '../../../../../shared/data-access/graphql/src';
import { KeyValueComponent } from '../../../../../shared/ui-layout/src';

@Component({
  selector: 'lib-task-type-details',
  standalone: true,
  imports: [CommonModule, KeyValueComponent],
  templateUrl: './task-type-details.component.html',
  styleUrl: './task-type-details.component.scss',
})
export class TaskTypeDetailsComponent {
  obj = input.required<TaskType>();
}
