import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';
import { UiPhoneNumberPickerComponent } from '@doctorus-front-end-monorepo/ui-phone-number-picker';
import { PhoneNumberPipe } from '@doctorus-front-end-monorepo/util-formatting';

@Component({
    selector: 'doctorus-front-end-monorepo-edit-user-dialog',
    templateUrl: './edit-user-dialog.component.html',
    styleUrls: ['./edit-user-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatDialogModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        UiMatFormFieldErrorDisplayerDirective,
        MatInputModule,
        MatButtonModule,
        PhoneNumberPipe,
        CommonModule,
        UiPhoneNumberPickerComponent,
    ]
})
export class EditUserDialogComponent {
  userForm = this.fb.group({
    given_name: [this.user.given_name, Validators.required],
    family_name: [this.user.family_name, Validators.required],
    phone_number: [this.user.phone_number, Validators.required],
  });

  constructor(
    public dialogRef: MatDialogRef<EditUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public user: any,
    private fb: FormBuilder,
  ) {}
  submit(): void {
    this.userForm.markAllAsTouched();
    if (this.userForm.valid) {
      this.dialogRef.close(this.userForm.value);
    }
  }
}
