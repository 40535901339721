import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BaseEntityFormComponent } from '@doctorus-front-end-monorepo/feature-entity';
import {
  AccountDataService,
  Patient,
  PatientPayment,
} from '@doctorus-front-end-monorepo/graphql';
import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';
import { formatISO } from 'date-fns';
@Component({
  selector: 'lib-write-patient-payment-form',
  templateUrl: './write-patient-payment-form.component.html',
  styleUrls: ['./write-patient-payment-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    UiMatFormFieldErrorDisplayerDirective,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
  ],
})
export class WritePatientPaymentFormComponent extends BaseEntityFormComponent<
  PatientPayment,
  { patient?: Patient }
> {
  accountPaimentMethods =
    inject(AccountDataService).account?.account_summary.payment_methods;
  override initFormFun = () =>
    this.fb.group({
      id: [this.obj()?.id],

      amount: [
        this.obj()?.amount ?? 0,
        [Validators.required, Validators.min(0)],
      ],
      payment_method: [this.obj()?.payment_method],
      notes: [this.obj()?.notes],
      patient: [
        this.obj()?.patient ?? this.extra()?.patient?.id,
        [Validators.required],
      ],
      date: [
        this.obj()?.date ?? formatISO(new Date(), { representation: 'date' }),
        [Validators.required],
      ],
    });
}
