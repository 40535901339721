import { InjectionToken } from '@angular/core';
import { BaseEntityDetailsComponent } from './base-entity-details.component';
import { BaseEntityFormComponent } from './base-entity-form.component';
import { BaseEntityListComponent } from './base-entity-list.component';
import { EntityConfig } from './entity-config';
import { LayoutConfig } from './layout.config';

export const ENTITY_LIST_COMPONENT = new InjectionToken<
  BaseEntityListComponent<any>
>('EntityListComponent');
export const ENTITY_DETAILS_COMPONENT = new InjectionToken<
  BaseEntityDetailsComponent<any>
>('EntityDetailsComponent');
export const ENTITY_WRITE_COMPONENT = new InjectionToken<
  BaseEntityFormComponent<any>
>('EntityWriteComponent');
export const ENTITY_CONFIG = new InjectionToken<EntityConfig<any>>(
  'entityConfig',
);
export const LAYOUT_CONFIG = new InjectionToken<LayoutConfig>('layoutConfig');
