import { CommonModule, NgComponentOutlet } from '@angular/common';
import { Component, inject, signal, ViewChild } from '@angular/core';
import { isNil } from 'lodash';
import {
  debounceTime,
  finalize,
  first,
  map,
  startWith,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs';
import { EntityMutationService, IEntity } from '../../../feature-entity/src';
import { updateAllValidityMarkAllTouched } from '../../../form/util-form/src';
import { FormDialogPanelData, IFormComponent } from './slide-out-panel-types';

@Component({
  selector: 'shared-ui-form-panel-base',
  imports: [CommonModule],
  template: `<p>form-panel-base works!</p>`,
  styles: ``,
})
export abstract class FormPanelBaseComponent<
  E extends IEntity,
  C extends IFormComponent,
> {
  data!: FormDialogPanelData<E, C>;

  error: any = {};
  loading = signal(false);
  mutationService = inject(EntityMutationService);
  @ViewChild(NgComponentOutlet, { static: false })
  ngComponentOutlet!: NgComponentOutlet;

  abstract closeDialog(payload?: any): void;

  get inputs() {
    return { obj: this.data?.entity, extra: this.data?.extra };
  }

  get componentRef() {
    return this.data.cmp;
  }

  get headerTitle(): string {
    return this.data?.entityConfig
      ? this.data.entityConfig.writeTitle(isNil(this.data.entity))
      : (this.data.title ?? 'N/A');
  }

  submit(): void {
    const form = this.ngComponentOutlet['_componentRef'].instance.form;
    updateAllValidityMarkAllTouched(form);
    console.warn(form);
    form.statusChanges
      .pipe(
        tap(x => this.loading.set(true)),
        startWith(form.status),
        debounceTime(300),
        first(x => x !== 'PENDING'),
        takeWhile(x => x !== 'INVALID'),
        tap(() => console.warn(form)),
        switchMap(() => {
          const serializerFun = this.data.entityConfig?.serializerFun;
          const payload = serializerFun
            ? serializerFun(form.value)
            : form.value;
          //this.dialogRef.close(payload);
          return this.mutationService
            .mutate({
              payload: payload,
              id: this.data.entity?.id,
            })
            .pipe(
              tap(x => (this.error = x.errors)),
              map(x => x.data),
            );
        }),
        first(),
        map((_res: any) => Object.values(_res)[0]),
        tap(x => console.warn(x)),
        finalize(() => this.loading.set(false)),
      )
      .subscribe({
        next: (x: E) => this.closeDialog(x),
        error: (error: any) => {
          console.warn(error);
        },
      });
  }
}
