import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MedicalHistoryModel } from '../../../../../shared/data-access/graphql/src';
import { BaseEntityFormComponent } from '../../../../../shared/feature-entity/src';
import { UiMatFormFieldErrorDisplayerDirective } from '../../../../../shared/form/ui-form/src';

@Component({
  selector: 'lib-medical-history-model-form',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    UiMatFormFieldErrorDisplayerDirective,
    ReactiveFormsModule,
  ],
  templateUrl: './medical-history-model-form.component.html',
  styleUrl: './medical-history-model-form.component.scss',
})
export class MedicalHistoryModelFormComponent extends BaseEntityFormComponent<MedicalHistoryModel> {
  override initFormFun = () =>
    this.fb.group({
      id: [this.obj()?.id],
      type: [this.obj()?.type, Validators.required],
      order: [this.obj()?.order, Validators.min(0)],
    });
}
