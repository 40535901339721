@let patients = patientsRx.value();
@let searching = patientsRx.isLoading();

<form class="flex flex-col gap-6" [formGroup]="form">
  <mat-form-field uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>name</mat-label>
    <input type="text" formControlName="name" matInput />
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n>notes</mat-label>
    <textarea matInput formControlName="notes" cols="30" rows="2"></textarea>
  </mat-form-field>

  <mat-form-field class="col-span-full">
    <mat-label i18n>assignees</mat-label>
    <mat-icon matPrefix>person</mat-icon>
    <lib-custom-mat-search-select-field
      formControlName="contributors"
      [allOptions]="users"
      placeholder="contributors"
      multiple="true"
      humanEntity="true"
      valueProp="sub"
    ></lib-custom-mat-search-select-field>
  </mat-form-field>
  <mat-form-field class="col-span-full" uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>patient</mat-label>
    <mat-icon matPrefix>personal_injury</mat-icon>
    <mat-icon class="hover:cursor-pointer" matSuffix>person_add</mat-icon>
    <lib-custom-mat-search-select-field
      formControlName="patient"
      [allOptions]="patients"
      (searchChange)="searchTerm.set($event)"
      placeholder="select patient"
      [loading]="searching"
      multiple="false"
      externalFiltering="true"
      humanEntity="true"
      valueProp="id"
    ></lib-custom-mat-search-select-field>
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field uiFormMatFormFieldErrorDisplayer>
    <mat-icon matIconPrefix>location_on</mat-icon>
    <mat-label i18n>location</mat-label>
    <mat-select formControlName="location">
      @for (location of account?.locations; track $index) {
        <mat-option [value]="location.id">{{ location.name }}</mat-option>
      }
    </mat-select>
    <mat-error></mat-error>
  </mat-form-field>
  <h3 i18n class="first-letter:uppercase">period</h3>
  <h4 i18n class="first-letter:uppercase">from</h4>
  <div class="flex gap-2 flex-wrap">
    <mat-form-field class="flex-1">
      <mat-label i18n>start date</mat-label>
      <input
        matInput
        [matDatepicker]="datepickerStart"
        formControlName="start"
      />
      <mat-datepicker #datepickerStart />
      <mat-datepicker-toggle [for]="datepickerStart" matSuffix />
    </mat-form-field>

    <mat-form-field class="flex-1">
      <mat-label i18n>start time</mat-label>
      <input
        matInput
        [matTimepicker]="timepickerStart"
        formControlName="start"
      />
      <mat-timepicker #timepickerStart />
      <mat-timepicker-toggle [for]="timepickerStart" matSuffix />
    </mat-form-field>
  </div>
  <h4 i18n class="first-letter:uppercase">to</h4>

  <div class="flex gap-2 flex-wrap">
    <mat-form-field class="flex-1">
      <mat-label i18n>end date</mat-label>
      <input matInput [matDatepicker]="datepickerEnd" formControlName="end" />
      <mat-datepicker #datepickerEnd />
      <mat-datepicker-toggle [for]="datepickerEnd" matSuffix />
    </mat-form-field>

    <mat-form-field class="flex-1">
      <mat-label i18n>end time</mat-label>
      <input matInput [matTimepicker]="timepickerEnd" formControlName="end" />
      <mat-timepicker #timepickerEnd />
      <mat-timepicker-toggle [for]="timepickerEnd" matSuffix />
    </mat-form-field>
  </div>

  <h3 i18n class="first-letter:uppercase">frequency</h3>
  <mat-form-field uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>occurences by day</mat-label>
    <input type="number" formControlName="frequency" matInput />
    <mat-error></mat-error>
    <mat-hint i18n
      >how many times a day must the medical tasks be perfomed</mat-hint
    >
  </mat-form-field>
</form>
