import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  booleanAttribute,
  computed,
  input,
  numberAttribute,
} from '@angular/core';
enum ContainerLevel {
  PAGE = 4,
  CONTAINER = 3,
  SECTION = 2,
  ELEMENT = 1,
}
enum GutterLevel {
  NONE = 0,
  SMALL,
  MEDIUM,
  LARGE,
}
@Component({
  selector: 'ui-layout-container',
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: `./container.component.html`,
})
export class ContainerComponent {
  @Input() title!: string | null;
  @Input() description?: string | null;
  border = input(true, { transform: booleanAttribute });
  contentBorder = input(false, { transform: booleanAttribute });
  headerDivider = input(false, { transform: booleanAttribute });
  sticky = input(false, { transform: booleanAttribute });
  level = input(ContainerLevel.PAGE, { transform: numberAttribute });
  paddingLevel = input(GutterLevel.SMALL, { transform: numberAttribute });
  noHeaderMarging = input(false, {
    transform: booleanAttribute,
  });
  paddingClass = computed(() => `p-${4 * this.paddingLevel()}`);
  headerMargin = computed(() => `mb-${2 * this.level()}`);
  classes = computed<{ [klass: string]: boolean }>(() => ({
    [this.paddingClass()]: true,
    'rounded-md border-gray-200 border': this.border(),
    'h-full': true,
  }));

  headerClass = computed(() => ({
    'sticky top-0 bg-white z-10': this.sticky(),
    'border-b pb-4 border-b-gray-200': this.headerDivider(),
    [this.headerMargin()]: !this.noHeaderMarging(),
  }));

  contentClass = computed(() => ({
    'rounded-md border-gray-200 border p-4': this.contentBorder(),
    'flex flex-col gap-4': true,
  }));
}
