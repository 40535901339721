<entity-list-wrapper border="false" [objs]="objs()">
  <ui-layout-container withHeader="false">
    <main class="grid sm:grid-cols-2 gap-8 grid-cols-1">
      <ui-layout-key-value key="total" i18n-key>
        <h1>{{ paymentStatus().total_paid | accountCurrency }}</h1>
      </ui-layout-key-value>
      <ui-layout-key-value key="balance" i18n-key>
        <h1 [class.text-red-700]="paymentStatus().total_due < 0">
          {{ paymentStatus().total_due | accountCurrency }}
        </h1>
      </ui-layout-key-value>
    </main>
  </ui-layout-container>

  <table mat-table [dataSource]="objs()">
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell i18n *matHeaderCellDef>date</th>
      <td mat-cell *matCellDef="let element">
        <a class="anchor" [routerLink]="[element.id]">{{
          element.date | date
        }}</a>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef i18n>amount</th>
      <td mat-cell *matCellDef="let element">
        {{ element.amount | accountCurrency }}
      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="payment_method">
      <th mat-header-cell *matHeaderCellDef i18n>payment method</th>
      <td mat-cell *matCellDef="let element">
        {{ element.payment_method | coalesce: '-' }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</entity-list-wrapper>
