import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  BreadcrumbComponent,
  resolveBreadcrumbs,
} from '@doctorus-front-end-monorepo/breadcrumb';
import { map } from 'rxjs';

@Component({
  selector: 'doctorus-front-end-monorepo-feature-patients-viewer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterModule, BreadcrumbComponent],
  templateUrl: './feature-patients-viewer.component.html',
  styleUrl: './feature-patients-viewer.component.scss',
})
export class FeaturePatientsViewerComponent {
  router = inject(Router);
  route = inject(ActivatedRoute);

  breadcrumbs$ = this.router.events.pipe(
    map(_event => resolveBreadcrumbs(this.route)),
  );
}
