@if(form) {
  <form class="grid lg:grid-cols-12 grid-cols-6 gap-4" [formGroup]="form">
    <mat-form-field class="col-span-full" uiFormMatFormFieldErrorDisplayer>
      <mat-label i18n>patient</mat-label>
      <mat-icon matPrefix>person</mat-icon>
      <mat-icon
        (click)="openPatientForm($event)"
        class="hover:cursor-pointer"
        matSuffix
        >person_add</mat-icon
      >
      <mat-select formControlName="patient" placeholder="patient">
        <mat-option>
          <div class="fles justify-between items-center">
            <ngx-mat-select-search
              [searching]="patientsLoading"
              i18n-placeholderLabel
              placeholderLabel="search by reference, name, phone number or email"
              i18n-noEntriesFoundLabel
              noEntriesFoundLabel="no matching patient found"
              [formControl]="patientFilterCtrl"
            >
            </ngx-mat-select-search>
          </div>
        </mat-option>
        <ng-container *ngFor="let patient of filtredPatients()">
          <mat-option *ngIf="patientsLoading === false" [value]="patient.id">
            <span>{{ patient | humanName: 'full' : true }}</span
            >&nbsp;
            <span>{{ patient?.reference }}</span>
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  
    <mat-form-field
      appearance="outline"
      class="col-span-6"
      uiFormMatFormFieldErrorDisplayer
    >
      <mat-label i18n="appointment-type">type</mat-label>
      <mat-icon matPrefix>info</mat-icon>
  
      <mat-select
        (selectionChange)="updateExpectedDuration()"
        formControlName="task_type_id"
      >
        <mat-option [value]="taskType.id" *ngFor="let taskType of taskTypes">
          {{ taskType?.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  
    <mat-form-field
      appearance="outline"
      class="col-span-6"
      uiFormMatFormFieldErrorDisplayer
    >
      <mat-label i18n>location</mat-label>
      <mat-icon matPrefix>location_on</mat-icon>
      <mat-select (selectionChange)="loadParams()" formControlName="location_id">
        <mat-option [value]="location.id" *ngFor="let location of locations">
          {{ location?.name }}
        </mat-option>
      </mat-select>
      <mat-error></mat-error>
    </mat-form-field>
  
    <h3 class="col-span-full first-letter:uppercase" i18n>
      appointment schedules
    </h3>
    <mat-form-field class="sm:col-span-3 col-span-full">
      <mat-label i18n>choose a date</mat-label>
      <input
        (change)="loadParams()"
        #dateInput
        type="date"
        [formControl]="appointmentDay"
        matInput
      />
      <mat-icon (click)="dateInput.showPicker()" matSuffix
        >calendar_month</mat-icon
      >
    </mat-form-field>
    <mat-form-field
      class="sm:col-span-3 col-span-full"
      appearance="outline"
      uiFormMatFormFieldErrorDisplayer
    >
      <mat-label i18n>expected duration</mat-label>
      <mat-icon matPrefix>timer</mat-icon>
  
      <time-duration-input
        (valueChange)="loadParams()"
        [formControl]="expectedDurationCtrl"
      >
      </time-duration-input>
      <mat-error></mat-error>
    </mat-form-field>
    <mat-form-field
      class="col-span-6"
      appearance="outline"
      uiFormMatFormFieldErrorDisplayer
    >
      <mat-label i18n>time zone</mat-label>
      <mat-icon matPrefix>language</mat-icon>
    <mat-select         [compareWith]="compareStr"
    (change)="loadParams()"
    formControlName="timezone">
      <mat-option [value]="_timezone" *ngFor="let _timezone of timezones">{{ _timezone }}</mat-option>
    </mat-select>
      <!-- <mtx-select
        [compareWith]="compareStr"
        (change)="loadParams()"
        formControlName="timezone"
      >
        <mtx-option [value]="_timezone" *ngFor="let _timezone of timezones">
          {{ _timezone }}
        </mtx-option>
      </mtx-select> -->
      <mat-error></mat-error>
    </mat-form-field>
    <appointment-slot-picker
      class="col-span-full"
      [formControl]="slotSelectorCtr"
      [offset]="offset"
      [params]="slotFetchParams"
    ></appointment-slot-picker>
  </form>
}
