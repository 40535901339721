<form class="flex flex-col gap-4" [formGroup]="form">
  <ui-layout-container title="appointment">
    <mat-form-field uiFormMatFormFieldErrorDisplayer class="max-w-sm w-full">
      <mat-label i18n>default time zone</mat-label>

      <mat-select formControlName="appointmentDefaultTimeZone">
        <mat-option [value]="timeZone" *ngFor="let timeZone of timeZones">{{timeZone}} ({{timeZone |
          timezoneOffset}})</mat-option>
      </mat-select>
      <mat-error></mat-error>
    </mat-form-field>

    <mat-form-field uiFormMatFormFieldErrorDisplayer class="max-w-sm w-full">
      <mat-label i18n>default location</mat-label>

      <mat-select formControlName="appointmentDefaultLocation">
        <mat-option [value]="location.id" *ngFor="let location of obj()?.locations">{{location.name}}</mat-option>
      </mat-select>
      <mat-error></mat-error>

    </mat-form-field>

    <mat-form-field uiFormMatFormFieldErrorDisplayer class="max-w-sm w-full">
      <mat-label i18n>default type</mat-label>

      <mat-select formControlName="appointmentDefaultTaskType">
        <mat-option [value]="taskType.id" *ngFor="let taskType of obj()?.taskTypes">{{taskType.name}}</mat-option>
      </mat-select>
      <mat-error></mat-error>

    </mat-form-field>
  </ui-layout-container>
  <ui-layout-container title="patient">
    <mat-form-field uiFormMatFormFieldErrorDisplayer class="max-w-sm w-full">
      <mat-label i18n>default country code</mat-label>

      <mat-select formControlName="defaultCountryCode">
        <mat-option [value]="country.iso2" *ngFor="let country of countries">{{country.name}}
          ({{country.phone}})</mat-option>
      </mat-select>
      <mat-error></mat-error>
    </mat-form-field>
  </ui-layout-container>

  <ui-layout-container title="document">
    <mat-form-field uiFormMatFormFieldErrorDisplayer class="max-w-sm w-full">
      <mat-label i18n>default print layout</mat-label>

      <mat-select formControlName="defaultPrintLayout">
        <mat-option [value]="printLayout.id" *ngFor="let printLayout of obj()?.printLayouts">
          {{printLayout.name}}</mat-option>
      </mat-select>
      <mat-error></mat-error>
    </mat-form-field>
    <mat-form-field uiFormMatFormFieldErrorDisplayer class="max-w-sm w-full">
      <mat-label i18n>default print layout</mat-label>

      <mat-select formControlName="defaultDocumentModel">
        <mat-option [value]="documentModel.id"
          *ngFor="let documentModel of obj()?.documentModels">{{documentModel.name}}
          {{documentModel.name}}</mat-option>
      </mat-select>
      <mat-error></mat-error>
    </mat-form-field>
  </ui-layout-container>


</form>