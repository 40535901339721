import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { LAYOUT_CONFIG } from './tokens';

@Component({
  standalone: true,
  imports: [CommonModule],
  template: ``,
  styles: ``,
})
export class BaseWrapperComponent {
  layoutConfig = inject(LAYOUT_CONFIG, { optional: true });
  withBorder = this.layoutConfig?.withBorder ?? true;
}
