@if (patient$ | async; as patient) {
<ui-layout-container level="4" [border]="false" [title]="patient | humanName:'full':true">
  @if (patient.remarks) {
  <p ngProjectAs="description">{{patient.remarks}}</p>
  }
  <div ngProjectAs="actions"> <button mat-flat-button
      [routerLink]="['medical-record',{outlets:{center:['summary']}}]"><span i18n>medical
        record</span></button>

  </div>
  <main class="flex flex-col gap-6">

    <ui-layout-container i18n-title title="profile informations">

      <div ngProjectAs="actions"> <button [routerLink]="['edit']" routerLinkActive="router-link-active"
          mat-icon-button><mat-icon i18n>edit
          </mat-icon></button>
      </div>
      <section class="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">
        <ui-layout-key-value key="family name" i18n-key>
          {{patient.family_name}}
        </ui-layout-key-value>
        <ui-layout-key-value key="given name" i18n-key>
          {{patient.given_name}}
        </ui-layout-key-value>
        <ui-layout-key-value key="birthdate" i18n-key>
          @if(patient.birthdate) {
          {{patient.birthdate|date}} ({{patient.birthdate | age |duration}})

          }@else {-}
        </ui-layout-key-value>
        <ui-layout-key-value key="reference" i18n-key>
          {{patient.reference | coalesce:'-'}}

        </ui-layout-key-value>

        <ui-layout-key-value key="email" i18n-key>
          @if (patient.email) {
          <a class="break-words" [href]="'mailto:'+patient.email">{{ patient.email }}</a>
          }@else {-}
        </ui-layout-key-value>

        <ui-layout-key-value key="contacts" i18n-key>
          @if (patient.phone_numbers) {
          <div class="flex flex-col gap-1">
            @for (phoneNumber of patient.phone_numbers; track $index) {
            <a class="anchor" [href]="phoneNumber.phone_number|phoneNumber:'RFC3966'">{{
              phoneNumber.phone_number|phoneNumber
              }}</a> }
          </div> }
          @else {-}
        </ui-layout-key-value>

        <ui-layout-key-value key="balance" i18n-key>
          <span class="font-medium" [class.text-green-800]="patient?.payment_status?.total_due === 0"
            [class.text-cyan-500]="patient?.payment_status?.total_due > 0"
            [class.text-red-800]="patient?.payment_status?.total_due < 0">
            {{
            patient?.payment_status?.total_due |
            currency : currency : 'symbol' : '0.0-0' }}
          </span>

        </ui-layout-key-value>

        @for (property of patient.public_properties; track $index) {
        <ui-layout-key-value [key]="property.key" i18n-key>
          {{property.value}}
        </ui-layout-key-value>
        }
      </section>


    </ui-layout-container>

    <mat-tab-group dynamicHeight="true" mat-stretch-tabs="false">
      <mat-tab label="appointments" i18n-label>
        <ui-layout-container level="3" i18n-title title="appointments">

          <div ngProjectAs="actions"> <button (click)="appointments.writeAppointment()" mat-stroked-button><span
                i18n>new
                appointment</span></button>
          </div>
          <appointment-appointments-list class="max-h-full" #appointments="appointmentsList" [patient]="patient"
            [appointments]="patient.appointments" mode="patient-board"></appointment-appointments-list>


        </ui-layout-container>
      </mat-tab>
      <mat-tab label="payments" i18n-label><ui-layout-container level="3" i18n-title title="payments">
          <div ngProjectAs="actions"> <button (click)="payments.onWrite()" mat-stroked-button><span i18n>new
                payment</span></button>
          </div>
          <doctorus-front-end-monorepo-patient-payment-list #payments="paymentsList" [patient]="patient"
            [entities]="patient.payments"></doctorus-front-end-monorepo-patient-payment-list>


        </ui-layout-container></mat-tab>
      <mat-tab label="contacts" i18n-label>
        <ui-layout-container level="3" [title]="'phone numbers '+'('+patient.phone_numbers.length+')'" i18n-title>
          <div ngProjectAs="actions"> <button (click)="writePhoneNumber(undefined,patient)"
              mat-icon-button><mat-icon>add_circle</mat-icon></button>
          </div>
          @if (patient.phone_numbers.length > 0) {
          <ul class="flex flex-col gap-4">
            @for (phone_number of patient.phone_numbers; track $index;let first=$first ,last=$last) {
            <li class="flex gap-2 flex-wrap items-start">
              <div>
                <a class="anchor font-medium" [href]="phone_number.phone_number|phoneNumber:'RFC3966'">{{
                  phone_number.phone_number|phoneNumber
                  }}</a>
                @if (phone_number.remarks) {
                <p class="text-base">{{phone_number.remarks}}</p>
                }
              </div>

              @if (phone_number.availabilities) {
              <mat-chip-set class="mat-chip-sm text-sm">
                @for (availability of phone_number.availabilities; track $index) {
                <mat-chip>{{availability}}</mat-chip>
                }

              </mat-chip-set>
              }
              <div class="flex gap-1 ml-auto">
                <button mat-icon-button (click)="deletePhoneNumber(phone_number)"><mat-icon>delete</mat-icon></button>

                <button (click)="writePhoneNumber(phone_number)" mat-icon-button><mat-icon>edit</mat-icon></button>

              </div>



            </li>
            @if (!last) {
            <mat-divider></mat-divider>

            }
            }
          </ul>
          }@else {
          <ui-layout-empty-state>
            <span i18n class="first-letter:uppercase">No phone numbers</span>
            <button ngProjectAs="actions" (click)="writePhoneNumber(undefined,patient)" mat-stroked-button><span
                i18n>new
                phonenumber
              </span></button>
          </ui-layout-empty-state>
          }

        </ui-layout-container>
      </mat-tab>
    </mat-tab-group>



    <!-- <ui-layout-container i18n-title title="appointments">

      <div ngProjectAs="actions"> <button (click)="appointments.writeAppointment()" mat-stroked-button><span i18n>new
            appointment</span></button>
      </div>
      <appointment-appointments-list class="max-h-full" #appointments="appointmentsList" [patient]="patient"
        [appointments]="patient.appointments" mode="patient-board"></appointment-appointments-list>


    </ui-layout-container>
    <ui-layout-container i18n-title title="payments">
      <p ngProjectAs="description">
        <mat-chip-set>
          <mat-chip>
            <span class="first-letter:uppercase">{{getMeaning(patient?.payment_status?.total_due)}}: {{
              math.abs(patient?.payment_status?.total_due) |
              currency : currency : 'symbol' : '0.0-0' }}
            </span>


          </mat-chip>
          <mat-chip> <span class="first-letter:uppercase">paid: {{ patient?.payment_status?.total_paid | currency :
              currency : 'symbol' : '0.0-0' }}
            </span></mat-chip>
        </mat-chip-set>
      </p>
      <div ngProjectAs="actions"> <button (click)="payments.onWrite()" mat-stroked-button><span i18n>new
            payment</span></button>
      </div>
      <doctorus-front-end-monorepo-patient-payment-list #payments="paymentsList" [patient]="patient"
        [entities]="patient.payments"></doctorus-front-end-monorepo-patient-payment-list>


    </ui-layout-container> -->


  </main>

</ui-layout-container>


}