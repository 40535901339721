<table mat-table class="bg-white" [dataSource]="objs()">
  <!-- User name Definition -->
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef i18n>name</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <a class="anchor" [routerLink]="row.id">{{ row.name }}</a>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="order">
    <mat-header-cell *matHeaderCellDef i18n>order</mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.order }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="unit">
    <mat-header-cell *matHeaderCellDef i18n>unit</mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.unit }} </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: columns"></mat-row>
</table>
