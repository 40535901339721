<amplify-authenticator
  [formFields]="formFields"
  [signUpAttributes]="['email', 'phone_number', 'given_name', 'family_name']"
  usernameAlias="email"
  [loginMechanisms]="['email']"
>
  <ng-template amplifySlot="header">
    <div style="padding: var(--amplify-space-large); text-align: center">
      <img
        class="amplify-image w-28"
        alt="Amplify logo"
        [src]="'assets/img/log-emblem.png'"
      />
    </div>
  </ng-template>

  <ng-template amplifySlot="sign-in-header">
    <h2
      class="text-center"
      style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)"
    >
      Login to the Medical Space
    </h2>
  </ng-template>
  <ng-template
    amplifySlot="authenticated"
    let-user="user"
    let-signOut="signOut"
  >
    <doctorus-front-end-monorepo-upper-menu
      class="fixed top-0 w-full z-30"
    ></doctorus-front-end-monorepo-upper-menu>
    <div class="pt-14 h-screen"><router-outlet></router-outlet></div>
  </ng-template>
</amplify-authenticator>
