<form [formGroup]="formGroup">
  <div class="flex flex-wrap gap-6">
    <mat-form-field uiFormMatFormFieldErrorDisplayer subscriptSizing="dynamic" class="flex-1">
      <mat-label i18n>from</mat-label>
      <mat-select i18n-placeholder placeholder="select starting time" [formControlName]="0">
        <mat-option [value]="time" *ngFor="let time of dayTimes">{{time |
          time}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field uiFormMatFormFieldErrorDisplayer subscriptSizing="dynamic" class="flex-1">
      <mat-label i18n>to</mat-label>

      <mat-select i18n-placeholder placeholder="select end time" [formControlName]="1">
        <mat-option [value]="time" *ngFor="let time of dayTimes">{{time |
          time}}</mat-option>
      </mat-select>
    </mat-form-field>

  </div>
  @if (formGroup.errors; as errors) {
  <p class="text-red-700">
    <span *ngFor="let error of errors|keyvalue"> {{error.value}} </span>
  </p>

  }
</form>
