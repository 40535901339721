import { CommonModule, NgFor } from '@angular/common';
import {
  ApplicationRef,
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnInit,
  inject,
  signal,
} from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { DurationInputComponent } from '@doctorus-front-end-monorepo/duration-input';
import {
  BaseEntityFormComponent,
  EntityConfig,
  IEntity,
  PutEntityService,
} from '@doctorus-front-end-monorepo/feature-entity';
import { CustomMatSelectSearchFieldComponent } from '@doctorus-front-end-monorepo/generic-mat-select-search';
import {
  AccountDataService,
  AccountLocation,
  Appointment,
  CreatePatientGQL,
  GetAllPatientsGQL,
  Patient,
  QueryGetAvailableSlotsArgs,
} from '@doctorus-front-end-monorepo/graphql';
import {
  IFormComponent,
  SlideOutPanelService,
} from '@doctorus-front-end-monorepo/slide-out-panel';
import { MtxSelectModule } from '@ng-matero/extensions/select';
import * as dateFns from 'date-fns';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { Observable, Subscription, filter, map, startWith, tap } from 'rxjs';
import { Temporal } from 'temporal-polyfill';
import { DatesComponent } from '../../dates/dates.component';
import { AppointmentInputBuilderService } from '../../services/appointment-form-builder.service';
import { AppointmentNewPatientFormComponent } from '../appointment-new-patient-form/appointment-new-patient-form.component';
import { SlotPickerComponent } from '../slot-picker/slot-picker.component';

@Component({
  selector: 'appointment-write-appointment-form',
  templateUrl: './write-appointment-form.component.html',
  styleUrls: ['./write-appointment-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatButtonModule,
    MatRadioModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    DatesComponent,
    MtxSelectModule,
    MatProgressSpinnerModule,
    NgFor,
    SlotPickerComponent,
    NgxMatSelectSearchModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatDividerModule,
    DurationInputComponent,
    CustomMatSelectSearchFieldComponent,
  ],
})
export class WriteAppointmentFormComponent
  extends BaseEntityFormComponent<Appointment>
  implements OnInit
{
  slideOutPanelService = inject(SlideOutPanelService);
  getAllPatientsGQL = inject(GetAllPatientsGQL);
  cdr = inject(ApplicationRef);
  ads = inject(AccountDataService);
  fbs = inject(AppointmentInputBuilderService);
  accountUsers$ = this.ads.currentAccount$.pipe(
    map(x => x?.memberships?.map(x => x.user)),
  );

  patientMutationInjector = Injector.create({
    parent: this.cdr.injector,
    providers: [{ provide: PutEntityService, useClass: CreatePatientGQL }],
  });
  locations = this.ads.account?.locations;
  taskTypes = this.ads.account?.taskTypes;

  selectedLocation$?: Observable<AccountLocation | undefined>;
  slotSelectorCtr = new FormControl<{ start: string; end: string } | null>(
    null,
  );
  override initFormFun = () =>
    this.fbs.createForm(
      this.extra()?.patient,
      this.obj(),
      this.ads.account?.appointmentDefaultTimeZone ??
        Intl.DateTimeFormat().resolvedOptions().timeZone,
    );

  timezones = Intl.supportedValuesOf('timeZone');
  expectedDurationCtrl = new FormControl('PT30M');
  appointmentDayCtrl = new FormControl(
    dateFns.formatISO(new Date(), {
      representation: 'date',
    }),
  );
  searchTerm = signal<string | null>(null);
  patientRessource = rxResource({
    request: () => ({
      searchTerm: this.searchTerm,
      initPatient: this.obj()?.patient_info ?? this.extra()?.patient,
    }),
    loader: param =>
      this.getAllPatientsGQL
        .fetch({
          payload: {
            search: param.request.searchTerm(),
            commons: { page_size: 15 },
          },
        })
        .pipe(
          map(res =>
            res.data.getPatients.results?.concat(
              param.request.initPatient ? [param.request.initPatient] : [],
            ),
          ),
        ),
  });

  initData: any;
  loading = false;
  subscription = new Subscription();
  slotFetchParams: QueryGetAvailableSlotsArgs | null = null;
  override ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.slotSelectorCtr.valueChanges
        .pipe(
          filter(x => !!x),
          tap(console.warn),
        )
        .subscribe(x => this.form.patchValue(x)),
    );
    if (this.extra()?.date) {
      this.appointmentDayCtrl.setValue(
        dateFns.formatISO(this.extra()?.date, { representation: 'date' }),
      );
    }

    if (this.obj()) {
      this.slotSelectorCtr.setValue(this.obj()!, {
        emitEvent: false,
      });
      this.appointmentDayCtrl.setValue(
        dateFns.formatISO(this.obj()?.start, { representation: 'date' }),
      );
      const start = Temporal.Instant.from(this.obj()?.start);
      const end = Temporal.Instant.from(this.obj()?.end);
      this.expectedDurationCtrl.setValue(
        start
          .until(end, {
            largestUnit: 'hours',
            smallestUnit: 'minutes',
          })
          .toString(),
      );
    }

    this.selectedLocation$ = this.form.get('location_id')?.valueChanges.pipe(
      startWith(this.form.get('location_id')?.value),
      map(id => this.locations?.find(_locId => _locId.id === id)),
    );
  }

  displayFn(patient: Patient): string {
    return patient && `${patient.given_name} ${patient.family_name}`;
  }

  updateExpectedDuration(): void {
    const taskType = this.taskTypes?.find(
      x => x.id === this.form.controls['task_type_id']?.value,
    );
    if (taskType && taskType.default_duration) {
      this.expectedDurationCtrl.setValue(taskType.default_duration);
    }
  }
  openPatientForm(event: MouseEvent): void {
    this.slideOutPanelService
      .openSlideOutEntityWriteForm<IEntity, IFormComponent, Patient>(
        {
          cmp: AppointmentNewPatientFormComponent,
          entityConfig: new EntityConfig({
            feature: 'patient',
          }),
        },
        this.patientMutationInjector,
      )
      .subscribe(_newPatient => {
        this.form.get('patient')?.setValue(_newPatient?.id);
      });
    event.stopPropagation();
  }

  compareStr = (a: string, b: string) =>
    a.toLowerCase().trim() === b.toLowerCase().trim();
}
