import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  booleanAttribute,
  computed,
  inject,
  input,
  numberAttribute,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
enum ContainerLevel {
  PAGE = 4,
  CONTAINER = 3,
  SECTION = 2,
  ELEMENT = 1,
}
enum GutterLevel {
  NONE = 0,
  SMALL,
  MEDIUM,
  LARGE,
}
const LEVEL_CLASS_MAP: Record<ContainerLevel, { title: string }> = {
  [ContainerLevel.PAGE]: { title: 'text-2xl font-bold ' },
  [ContainerLevel.CONTAINER]: { title: 'text-xl font-semibold ' },

  [ContainerLevel.SECTION]: { title: 'text-lg font-medium ' },

  [ContainerLevel.ELEMENT]: { title: 'text-base font-medium' },
};
@Component({
  selector: 'ui-layout-container',
  imports: [CommonModule, RouterModule, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: `./container.component.html`,
})
export class ContainerComponent {
  @Input() title?: string | null;
  @Input() description?: string | null;
  @Input() link?: any[];
  @Input() matIcon?: string;
  border = input(true, { transform: booleanAttribute });
  withHeader = input(true, { transform: booleanAttribute });
  contentBorder = input(false, { transform: booleanAttribute });
  headerDivider = input(false, { transform: booleanAttribute });
  sticky = input(false, { transform: booleanAttribute });
  level = input(ContainerLevel.PAGE, { transform: numberAttribute });
  paddingLevel = input(GutterLevel.SMALL, { transform: numberAttribute });
  noHeaderMarging = input(false, {
    transform: booleanAttribute,
  });
  paddingClass = computed(() => `p-${4 * this.paddingLevel()}`);
  headerMargin = computed(() => `mb-${2 * this.level()}`);
  dismissible = input(false, { transform: booleanAttribute });
  classes = computed<{ [klass: string]: boolean }>(() => ({
    [this.paddingClass()]: true,
    'rounded-md border-gray-200 border': this.border(),
    'h-full flex flex-col': true,
  }));

  headerClass = computed(() => ({
    'sticky top-0 bg-white z-10': this.sticky(),
    'border-b pb-4 border-b-gray-200': this.headerDivider(),
    [this.headerMargin()]: !this.noHeaderMarging(),
  }));

  contentClass = computed(() => ({
    'rounded-md border-gray-200 border p-4': this.contentBorder(),
    'flex flex-col gap-4': true,
  }));
  private host = inject(ElementRef);

  titleClass = computed(
    () =>
      `${LEVEL_CLASS_MAP[this.level() as ContainerLevel].title} first-letter:uppercase anchor`,
  );
  dismiss() {
    this.host.nativeElement.remove();
  }
}
