import { ValidationErrors } from '@angular/forms';

const messages = new Map<
  string,
  { message: string; validatorErrorsKey?: string[] }
>([
  ['required', { message: $localize`this field is required` }],
  ['email', { message: $localize`enter a valid email` }],
  ['slotRequired', { message: 'you have to select one slot time' }],
  [
    'invalidWorkingHours',
    {
      message:
        'invalid working hours open time should be before or the same as close time',
    },
  ],
  [
    'minlength',
    {
      message: 'Password must be at least {0} characters long',
      validatorErrorsKey: ['requiredLength'],
    },
  ],
  [
    'maxlength',
    {
      message: 'Password cannot be more than {0} characters long',
      validatorErrorsKey: ['requiredLength'],
    },
  ],
  ['max', { message: `maximum value is {0}`, validatorErrorsKey: ['max'] }],
  ['min', { message: `minimum value is {0}`, validatorErrorsKey: ['min'] }],
]);

export const FORM_ERROR_MSG_MAP = new Map<
  string,
  string | ((val: any) => string)
>()
  .set('required', $localize`this field is required`)
  .set('email', $localize`enter a valid email`)
  .set('notEqualPasswords', $localize`passwords are not equals`)
  .set('invalidMedication', $localize`please enter a valid medication`)
  .set('invalidPatient', $localize`please enter a valid patient`)
  .set(
    'patientPhoneNumberExists',
    $localize`patient with this phone number already exists in this office`,
  )
  .set(
    'patientEmailExists',
    $localize`patient with this email already exists in this office`,
  )
  .set('slotRequired', $localize`you have to select one slot time`)
  .set(
    'staffUserNameExists',
    $localize`staff member with this email already exists`,
  )
  .set(
    'StaffphoneNumberExists',
    $localize`staff member with this phone number already exists`,
  )
  .set('unsuccessiveTeeth', $localize`unsuccessive teeth`)
  .set(
    'invalidWorkingHours',
    $localize`invalid working hours open time should be before or the same as close time`,
  )
  .set('passwordNotConfirmed', $localize`passwords are not the same`)
  .set('min', (val: any) => $localize`minimum value is ${val['min']}`)
  .set('max', (val: any) => $localize`maximum value is ${val['max']}`)
  .set(
    'phoneNumberPattern',
    (val: { pattern: number[]; max: number; min: number }) =>
      $localize`invalid phone number`,
  )
  .set('INVALID_COUNTRY', $localize`invalid country code`)
  .set('NOT_A_NUMBER', $localize`invalid phone number, not a number`)
  .set('TOO_SHORT', $localize`too short`)
  .set('TOO_LONG', $localize`too long`)
  .set('INVALID_LENGTH', $localize`invalid length`)
  .set(
    'existantMembership',
    $localize`this user is already a member of this account`,
  );

export const getValidatorErrorMessage = (
  validatorName: string,
  validatorErrors?: ValidationErrors,
): string | undefined => {
  let args = messages
    .get(validatorName)
    ?.validatorErrorsKey?.map(name => validatorErrors?.[name]);
  return args
    ? stringFormat(messages.get(validatorName)?.message, ...args)
    : messages.get(validatorName)?.message;
};

function stringFormat(template: string | undefined, ...args: any[]) {
  if (template) {
    return template.replace(/{(\d+)}/g, (match, index) => {
      return typeof args[index] !== 'undefined' ? args[index] : match;
    });
  }
  return undefined;
}
