import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lib-feature-contact',
  imports: [CommonModule],
  templateUrl: './feature-contact.component.html',
  styleUrl: './feature-contact.component.css',
})
export class FeatureContactComponent {}
