<ui-layout-container level="3" [title]="(appointment().start|date)">
  <span class="text-sm text-gray-700" ngProjectAs="description">
    {{appointment().start |date:'shortTime'}} ->
    {{appointment().end
    |date:'shortTime'}}
  </span>
  <div class="flex" ngProjectAs="actions"> <button (click)="deleteAppointment()" color="warn"
      mat-icon-button><mat-icon>delete</mat-icon></button>
    <button (click)="editAppointment()" mat-icon-button><mat-icon>edit
      </mat-icon></button>
    <button (click)="deselect.emit()" mat-icon-button><mat-icon>close</mat-icon></button>
  </div>
  <main class="flex flex-col gap-4">

    <mat-chip-set>
      @if(appointment().task_type) {
      <mat-chip>
        <p matChipAvatar class="w-3 h-3 rounded-full" [style.background-color]="appointment().task_type?.color"></p>
        <p class="chip-text">{{appointment().task_type?.name}}</p>


      </mat-chip>
      }
      @if(appointment().location) {
      <mat-chip>
        <mat-icon matChipAvatar>location_on</mat-icon>
        {{appointment().location?.name}}


      </mat-chip>
      }
      <appointment-status-picker (statusChange)="setStatus($event)" [status]="appointment().status">
        @if (appointment().status|resolveStatusConfig:'appointment' ;as status) {
        <mat-chip-row [editable]="true">
          <mat-icon matChipAvatar [style.color]="status.color">{{status.svgIcon}}</mat-icon> <span
            class="first-letter:uppercase">{{status.label}}</span>

        </mat-chip-row>
        }
      </appointment-status-picker>
      <mat-chip-row (click)="setFees()" [editable]="true">
        <mat-icon matChipAvatar>payments</mat-icon>
        {{appointment().fees | coalesce:'-'}}


      </mat-chip-row>
    </mat-chip-set>

    <ui-layout-container [border]="false" [level]="3" paddingLevel="" i18n-title title="medical notes">
      <span ngProjectAs="description">
        {{appointment().description}}
      </span>
      <div ngProjectAs="actions"> <button (click)="editMedicalNote()" mat-stroked-button><span i18n>edit
          </span></button>
      </div>

      <quill-view [content]="appointment().note | coalesce:'-'" format="html" theme="snow"></quill-view>
    </ui-layout-container>
  </main>

</ui-layout-container>