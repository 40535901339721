import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { AccountDataService } from '../../../../../shared/auth/src';
import { UpdateAccountOwnerGQL } from '../../../../../shared/data-access/graphql/src';
import { HumanNamePipe } from '../../../../../shared/shared-util/src';

@Component({
    selector: 'account-settings-update-account-owner',
    imports: [
        CommonModule,
        MatSelectModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatDialogModule,
        HumanNamePipe,
    ],
    template: `<h2 matDialogTitle i18n>update account owner</h2>
    <main matDialogContent>
      <h3 class="text-gray-700 font-medium mb-4">
        current owner : {{ (account$ | async)?.owner | humanName: 'full' }}
      </h3>
      <mat-form-field class="w-full">
        <mat-label i18n>owner</mat-label>

        <mat-select [formControl]="ownerCtrl">
          @for (member of (account$ | async)?.memberships; track member) {
            <mat-option
              [disabled]="member.user.sub === (account$ | async)?.owner?.sub"
              [value]="member.user.sub"
              >{{ member.user | humanName: 'full' }}:{{
                member.user.email
              }}</mat-option
            >
          }
        </mat-select>
      </mat-form-field>
    </main>

    <footer class="flex gap-4" matDialogActions>
      <button mat-stroked-button mat-dialog-close>
        <span i18n>delete</span>
      </button>
      <button
        [disabled]="!ownerCtrl.valid"
        mat-stroked-button
        (click)="submit()"
        type="submit"
      >
        <span i18n>update</span>
      </button>
    </footer>`,
    styles: ``
})
export class UpdateAccountOwnerComponent {
  ownerCtrl = new FormControl('', Validators.required);
  account$ = inject(AccountDataService).currentAccount$;
  private updateOwnerGql = inject(UpdateAccountOwnerGQL);
  private matDialogRef = inject(MatDialogRef);

  submit(): void {
    this.updateOwnerGql
      .mutate({ userId: this.ownerCtrl.value! })
      .subscribe(res => this.matDialogRef.close(res.data?.updateAccountOwner));
  }
}
