/* eslint-disable @typescript-eslint/no-empty-interface */
import { EntityConfig } from '@doctorus-front-end-monorepo/feature-entity';
import { GeneratedDocument } from '@doctorus-front-end-monorepo/graphql';

export const generatedDocumentConfig: EntityConfig<GeneratedDocument, any> =
  new EntityConfig({
    //baseUri: 'medical_note/generatedDocuments/',
    feature: $localize`generated document`,
    toString: (document: GeneratedDocument) => `${document.title}`,
  });
