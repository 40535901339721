import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
    selector: 'shared-ui-field-set',
    imports: [CommonModule],
    templateUrl: './field-set.component.html',
    styleUrl: './field-set.component.scss'
})
export class FieldSetComponent {}
