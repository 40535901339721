import { Injectable, inject } from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationSkipped,
  PRIMARY_OUTLET,
  Router,
} from '@angular/router';
import * as Handlebars from 'handlebars';
import {
  Observable,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs';
import { Breadcrumb } from '../types';

@Injectable()
export class BreadcrumbService {
  private router = inject(Router);
  breadcrumbs$!: Observable<Breadcrumb[]>;

  constructor(private route: ActivatedRoute) {
    this.breadcrumbs$ = this.router.events.pipe(
      filter(
        event =>
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationSkipped,
      ),
      distinctUntilChanged(),
      debounceTime(300),
      map(() => this.resolveBreadcrumbs(this.route, 'primary')),
    );
  }

  private resolveBreadcrumbs = (
    route: ActivatedRoute,
    group?: string,
    breadcrumbs: Breadcrumb[] = [],
    link: any[] = [],
    url = '',
  ): Breadcrumb[] => {
    const ROUTE_DATA_BREADCRUMB_DEF_KEY = 'breadcrumbChunck';
    const ROUTE_DATA_BREADCRUMB_GROUP_KEY = 'breadcrumbGroup';
    const children: ActivatedRoute[] = route.children;
    if (children.length === 0 || route.outlet !== PRIMARY_OUTLET) {
      return breadcrumbs;
    }

    for (const child of children) {
      const _childLink = [...link, ...child.snapshot.url.map(x => x.path)];

      const name = child.snapshot.data[ROUTE_DATA_BREADCRUMB_DEF_KEY]?.name;
      if (name) {
        const compiledName = Handlebars.compile(name, {})(
          child.snapshot.data,
          {},
        );
        const breadcrumb: Breadcrumb = {
          name: compiledName,
          group:
            child.snapshot.data[ROUTE_DATA_BREADCRUMB_DEF_KEY]?.group ??
            PRIMARY_OUTLET,
          params: child.snapshot.params,
          queryParams: child.snapshot.queryParams,
          link: _childLink,
          url: this.router.url,
        };
        if (breadcrumb.name) {
          breadcrumbs.push(breadcrumb);
        }
      }

      return this.resolveBreadcrumbs(child, group, breadcrumbs, _childLink, '');
    }
    return breadcrumbs;
  };
}
