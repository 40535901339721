@if(obj().patientPropertyModels;as models) {
@if(models.length > 0) {
<ul class="flex flex-col gap-4">
  @for (item of models; track $index ;let last = $last) {
  <li class="flex flex-wrap gap-4 items-center">
    <h3>{{item.key}}</h3>
    <mat-chip-set class="dense-chip">
      @if(item.is_medical) {
      <mat-chip>
        <mat-icon matChipAvatar>medical_information</mat-icon>
        <span i18n>medical</span></mat-chip>
      }
      @if(item.is_required) {

      <mat-chip>
        <mat-icon matChipAvatar>asterisk</mat-icon>

        <span i18n>required</span></mat-chip>
      }
    </mat-chip-set>



  </li>

  }
</ul>
}@else {
<ui-layout-empty-state>
  <span i18n>no property models</span>
  <button [routerLink]="['edit']" ngProjectAs="actions" mat-stroked-button>
    <span i18n>add property models</span>
  </button>
</ui-layout-empty-state>
}

}