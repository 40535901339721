import {
  AsyncPipe,
  CommonModule,
  KeyValuePipe,
  NgFor,
  NgPlural,
  NgPluralCase,
} from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  Maybe,
  Prescription,
  Treatment,
} from '@doctorus-front-end-monorepo/graphql';
import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BaseEntityFormComponent } from '../../../../../shared/feature-entity/src';
import { InlineFormArrayComponent } from '../../../../../shared/form/inline-form-array/src';
import {
  ContainerComponent,
  FormContainerComponent,
} from '../../../../../shared/ui-layout/src';
import { PrescriptionFormBuilderService } from '../prescription-form-builder.service';
import { TreatmentFormComponent } from '../treatment-form/treatment-form.component';
import { TreatmentGroupComponent } from '../treatment-group/treatment-group.component';
@Component({
    selector: 'prescription-write-prescription-form',
    imports: [
        MatDialogModule,
        ReactiveFormsModule,
        CommonModule,
        MatFormFieldModule,
        UiMatFormFieldErrorDisplayerDirective,
        MatInputModule,
        MatDatepickerModule,
        NgxMatSelectSearchModule,
        TreatmentGroupComponent,
        InlineFormArrayComponent,
        ContainerComponent,
        MatSelectModule,
        MatOptionModule,
        NgFor,
        NgPlural,
        NgPluralCase,
        MatButtonModule,
        MatIconModule,
        MatDividerModule,
        MatAutocompleteModule,
        FormContainerComponent,
        AsyncPipe,
        TreatmentFormComponent,
        KeyValuePipe,
    ],
    templateUrl: './write-prescription-form.component.html',
    styleUrl: './write-prescription-form.component.scss'
})
export class WritePrescriptionFormComponent extends BaseEntityFormComponent<Prescription> {
  //@Input() obj?: Prescription;
  patient = computed(() => this.extra()?.patient);
  medicationLoading = false;

  //prescriptionModels$ = this.pmds.entities;
  prescriptionModelCtl = new FormControl();
  medicationFilterCtrl = new FormControl();

  private fbs = inject(PrescriptionFormBuilderService);
  override initFormFun = () =>
    this.fbs.createPrecriptionForm(this.patient(), this.obj());

  get treatmentsContainerTitle(): string {
    return $localize`treatments(${(this.form.get('treatment_set') as FormArray<FormControl>).length})`;
  }

  addTreatment(treatment?: Treatment): void {
    (this.form.get('treatment_set') as FormArray<FormGroup>).push(
      this.fbs.treatmentForm(),
    );
  }

  createTreatment = (payload?: Maybe<Treatment>) =>
    new FormGroup({
      name: new FormControl(payload?.name, Validators.required),
      note: new FormControl(payload?.note),
    });
}
