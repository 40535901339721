import { Pipe, PipeTransform } from '@angular/core';
import { isAnImage } from '../utils';

@Pipe({
  name: 'isImage',
  standalone: true,
})
export class IsImagePipe implements PipeTransform {
  transform(value: File | Blob | string): boolean {
    return isAnImage(value);
  }
}
