import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import {
  BaseEntityFormComponent,
  IEntity,
} from '../../../../feature-entity/src';
import { FormPanelBaseComponent } from '../form-panel-base.component';

@Component({
  selector: 'shared-ui-mat-dialog-form-panel',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  templateUrl: './mat-dialog-form-panel.component.html',
  styleUrl: './mat-dialog-form-panel.component.scss',
})
export class MatDialogFormPanelComponent<
  E extends IEntity,
  C extends BaseEntityFormComponent<E>,
  R = E,
> extends FormPanelBaseComponent<E, C> {}
