import { Pipe, PipeTransform } from '@angular/core';
import { Appointment } from '@doctorus-front-end-monorepo/graphql';
import { CalendarEvent } from '../types';
import { toCalendatEvent } from '../util-scheduler';

@Pipe({
  name: 'toEvent',
  standalone: true,
})
export class ToEventPipe implements PipeTransform {
  transform(appointments: Appointment[] | null | undefined): CalendarEvent[] {
    return appointments?.map(appointment => toCalendatEvent(appointment)) ?? [];
  }
}
