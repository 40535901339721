import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { PrintLayout } from '../../../../../shared/data-access/graphql/src';
import { BaseEntityListComponent } from '../../../../../shared/feature-entity/src';

@Component({
  selector: 'lib-ui-print-layout',
  standalone: true,
  imports: [CommonModule, RouterModule, MatTableModule],
  templateUrl: './ui-print-layout.component.html',
  styleUrl: './ui-print-layout.component.css',
})
export class UiPrintLayoutComponent extends BaseEntityListComponent<PrintLayout> {}
