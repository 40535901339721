<main class="flex max-w-full gap-4">
  <div class="original-container flex-col gap-2">


    @if (title) {
    <h3 class="mb-2">{{title}}</h3>
    }
    @if (content) {
    <p class="text-gray-700 text-sm italic mb-2">{{content}}</p>

    }

    <a href=""></a>

    <div class=" relative">
      <img class="max-h-full overflow-y-auto" (load)="updateRatios()" (mousemove)="updateParams($event)" [src]="url"
        #img>
      <div (mousemove)="updateParams($event)" #lens [style.left]="zoomParams.lensLeft" [style.top]="zoomParams.lensTop"
        class=" border-black lens w-24 h-24 absolute z-10 bg-white opacity-50">
      </div>
    </div>
  </div>

  <div #res [style.backgroundSize]="resultBgSize" [style.backgroundPosition]="zoomParams.resultBgPos"
    class="res-container" [style.backgroundImage]="resultBgUrl">
  </div>
</main>