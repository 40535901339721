import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MedicalHistoryModel } from '../../../../../shared/data-access/graphql/src';
import { BaseEntityDetailsComponent } from '../../../../../shared/feature-entity/src';
import { KeyValueComponent } from '../../../../../shared/ui-layout/src';

@Component({
  selector: 'lib-medical-history-model-details',
  standalone: true,
  imports: [CommonModule, KeyValueComponent],
  templateUrl: './medical-history-model-details.component.html',
  styleUrl: './medical-history-model-details.component.scss',
})
export class MedicalHistoryModelDetailsComponent extends BaseEntityDetailsComponent<MedicalHistoryModel> {}
