import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthTokens } from 'aws-amplify/auth';
import { catchError, from, switchMap } from 'rxjs';
import { AuthService } from '../auth-service/auth.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authToken = inject(AuthService).fetchTokens();
  return from(authToken).pipe(
    switchMap((token: AuthTokens | undefined) => {
      // switchMap() is used instead of map().

      const with_auth_request = req.clone({
        setHeaders: {
          ...(token?.accessToken && {
            Authorization: `Bearer ${token.accessToken}`,
          }),
        },
      });
      return next(with_auth_request);
    }),
    catchError(err => {
      console.warn('Error ', err);
      return next(req);
    }),
  );
};
