import { IEntity } from './types';

interface RealTimeDataChangeConfig {
  soundNotify: boolean;
  visualNotify: boolean;
  visualDuration?: number;
}
export class EntityConfig<T extends IEntity = any, F = any, A = any> {
  constructor(args: Partial<EntityConfig<T, F, A>>) {
    Object.assign(this, {
      ...args,
      deletable: args.deletable ?? true,
      toString: args.toString ?? (() => args.feature),
      plural: args.plural ?? args.feature + 's',
      // ...(args.graphQl &&
      //   args.graphQl.type && { graphQl: new GraphQlConfig(args.graphQl) }),
      messages: args.messages ?? {
        title: {
          new: $localize`:@@default.message.title.new:New ${args.feature}`,
          edit: $localize`:@@default.message.title.edit:Edit ${args.feature}`,
          delete: $localize`:@@default.message.title.delete:Delete ${args.feature}`,
        },
        action: {
          created: {
            success: $localize`:@@default.message.created.success:New ${args.feature} created`,
          },
          updated: {
            success: $localize`:@@default.message.updated.success:${args.feature} updated`,
          },
          deleted: {
            confirmation: $localize`:@@default.message.deleted.confirmation:Do you really want to delete this ${args.feature}`,
            success: $localize`:@@default.message.deleted.success:${args.feature} was deleted`,
          },
        },
      },
    });
  }
  base!: string;
  deletable!: boolean;
  feature!: string;
  plural?: string;
  //graphQl?: GraphQlConfig<T, F, any> | undefined;
  toString?: ((entity?: any) => string) | string | undefined;
  description?: ((entity?: any) => string) | string | undefined;

  serializerFun!: (arg: any) => any;
  //deserializerFun!: (arg: A) => any;
  realTimeDataChangeConfig?: RealTimeDataChangeConfig | undefined;
  messages?:
    | Partial<{
        title: Partial<Record<'new' | 'edit' | 'delete', string>>;
        action: Partial<
          Record<
            'created' | 'deleted' | 'updated',
            Partial<{
              buttonLabel: string;
              success: string;
              fail: string;
              confirmation: string;
            }>
          >
        >;
      }>
    | undefined;

  writeTitle = (create = false): string =>
    (create ? this.messages?.title?.new : this.messages?.title?.edit) ?? '';
  deleteTitle = (create = false): string =>
    (create ? this.messages?.title?.new : this.messages?.title?.edit) ?? '';
  onPutSuccessMsg = (update = false): string =>
    (update
      ? this.messages?.action?.updated?.success
      : this.messages?.action?.created?.success) ??
    $localize`successfully ${update ? 'updated' : 'created'} ${this.feature}`;
  onDeleteSucessMsg = (entity: any): string =>
    this.messages?.action?.deleted?.success ??
    $localize`${
      this.toString &&
      (this.toString instanceof Function
        ? this.toString(entity)
        : this.toString)
    } successfully deleted`;
}
