@if (breadcrumbs$ | async; as breadcrumbs) {
  @if (breadcrumbs.length > 1) {
    <div class="p-4 pt-5 border-b border-b-gray-200">
      <lib-layout-breadcrumb
        [breadcrumbs]="breadcrumbs"
      ></lib-layout-breadcrumb>
    </div>
  }
}
<router-outlet></router-outlet>
