import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  EntityDeleteMutationService,
  EntityDialogService,
  PutEntityService,
} from '@doctorus-front-end-monorepo/feature-entity';
import {
  DeleteObservationGQL,
  Measure,
  Observation,
  PutObservationGQL,
} from '@doctorus-front-end-monorepo/graphql';
import { SlideOutPanelService } from '@doctorus-front-end-monorepo/slide-out-panel';
import { observationConfig } from '../utils';
import { WriteObservationDialogComponent } from '../write-observation-dialog/write-observation-dialog.component';

@Component({
  selector: 'medical-measure-observation-box',
  imports: [CommonModule, MatIconModule, MatButtonModule],
  providers: [
    {
      provide: PutEntityService,
      useExisting: PutObservationGQL,
    },
    {
      provide: EntityDeleteMutationService,
      useExisting: DeleteObservationGQL,
    },
  ],
  templateUrl: './observation-box.component.html',
  styleUrls: ['./observation-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObservationBoxComponent {
  @Input() observation!: Observation;
  @Input() measure!: Measure;
  @Input() mode: 'timeline' | 'large' = 'large';
  private sds = inject(SlideOutPanelService);
  private eds = inject(EntityDialogService);
  private injector = inject(Injector);
  delete(): void {
    this.eds
      .openEntityDeleteDialog(
        this.observation,
        this.injector,
        observationConfig,
      )
      .subscribe();
  }
  edit(): void {
    this.sds
      .openSlideOutEntityWriteForm(
        {
          cmp: WriteObservationDialogComponent,
          entity: this.observation,
          entityConfig: observationConfig,
          extra: {
            measure: this.measure,
          },
        },
        this.injector,
      )
      .subscribe();
  }
}
