import { FieldPolicy, FieldReadFunction } from '@apollo/client/cache';
import { gql } from 'apollo-angular';
import { compareDesc } from 'date-fns';
import {
  MedicalDocumentType,
  MedicalNotesFieldsFragmentDoc,
  Patient,
} from '../../generated';
import { toGenericDocuments } from './utils';

export const ALL_DOCUMENTS_FIELD: FieldPolicy<any> | FieldReadFunction<any> = {
  // Field policy for the isInCart field
  read(
    obj,
    { variables, field, args, cache, toReference, readField, storage },
  ) {
    const patient = cache.readFragment<
      Pick<
        Patient,
        'prescriptions' | 'upload_documents' | 'generated_documents'
      >
    >({
      id: `Patient:${variables!['id']}`,
      fragmentName: 'PatientDocumentsFrag',
      fragment: gql`
        ${MedicalNotesFieldsFragmentDoc}
        fragment PatientDocumentsFrag on Patient {
          ...MedicalNotesFields
        }
      `,
    });

    return [
      ...toGenericDocuments(
        MedicalDocumentType.Uploaded,
        patient?.upload_documents ?? [],
      ),
      ...toGenericDocuments(
        MedicalDocumentType.Generated,
        patient?.generated_documents ?? [],
      ),
      ...toGenericDocuments(
        MedicalDocumentType.Prescription,
        patient?.prescriptions ?? [],
      ),
    ].sort((a, b) => compareDesc(new Date(a.date), new Date(b.date)));
  },
};
