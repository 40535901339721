<div role="group" class="duration-input-container" [formGroup]="parts" [attr.aria-labelledby]="_formField?.getLabelId()"
  (focusin)="onFocusIn()" (focusout)="onFocusOut($event)">
  <input id="hours" (keyup.arrowright)="autoFocusNext(parts.controls.hours,minutes)"
    (keyup.arrowleft)="autoFocusNext(parts.controls.hours,minutes)"
    (keyup.backspace)="_eraseInput(parts.controls.hours)" [maxlength]="2" placeholder="--"
    class="duration-input-element" formControlName="hours" type="number" aria-label="Duration hours"
    (input)="_handleInput(parts.controls.hours,minutes)" #hours />
  <label for="hours" class="duration-input-spacer" i18n>:</label>
  <input id="minutes" (keyup.arrowright)="autoFocusNext(parts.controls.minutes,hours)"
    (keyup.arrowleft)="autoFocusNext(parts.controls.minutes,hours)" [max]="59" [maxlength]="2" placeholder="--"
    class="duration-input-element" formControlName="minutes" type="number" aria-label="duration minutes"
    (input)="_handleInput(parts.controls.minutes)" (keyup.backspace)="_eraseInput(parts.controls.minutes)" #minutes />
  <!-- <label for="minutes" class="duration-input-spacer" i18n>minutes</label> -->
</div>