@if(entities|arrayExist) {

<ul class="flex flex-col gap-3">
  @for (item of entities; track $index;let last=$last) {
  <li class="flex justify-between">
    <div>
      <p class="text-base font-medium ">
        {{item.amount|accountCurrency}}
      </p>
      <p class="text-gray-800"> {{item.date|date}}</p>
    </div>

    <button [mat-menu-trigger-for]="appMenu" [matMenuTriggerData]="{item}"
      mat-icon-button><mat-icon>more_vert</mat-icon></button>
  </li>
  @if(!(last||entities.length === 1)){
  <mat-divider></mat-divider>
  }
  }
</ul>
<mat-menu #appMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="onWrite(item)" mode="write">
      <mat-icon>edit</mat-icon>
      <span i18n>edit</span>
    </button>
    <button mat-menu-item (click)="onDelete(item)">
      <mat-icon>delete</mat-icon>
      <span i18n>delete</span>
    </button>
  </ng-template>
</mat-menu>
}@else {

<ui-layout-empty-state>
  <span i18n class="first-letter:uppercase">no payments</span>
  <button ngProjectAs="actions" (click)="onWrite()" mat-stroked-button><span i18n>add payment
    </span></button>
</ui-layout-empty-state>

}