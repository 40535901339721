<form class="flex flex-col gap-4" matDialogContent [formGroup]="form">
  <mat-form-field>
    <mat-label i18n>date</mat-label>
    <input formControlName="date" matInput [matDatepicker]="picker">
    <mat-hint>DD/MM/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <mat-form-field uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>value</mat-label>
    <input type="number" formControlName="value" matInput>
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n>note</mat-label>
    <textarea matInput formControlName="note" cols="30" rows="5"></textarea>
  </mat-form-field>
</form>