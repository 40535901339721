@let patients = patientRessource.value();
@let isLoading = patientRessource.isLoading();
@if (form) {
  <form class="grid lg:grid-cols-12 grid-cols-6 gap-4" [formGroup]="form">
    <mat-form-field class="col-span-full">
      <mat-label i18n>assignees</mat-label>
      <mat-icon matPrefix>person</mat-icon>
      <lib-custom-mat-search-select-field
        formControlName="assignees"
        [allOptions]="accountUsers$ | async"
        placeholder="search assignees"
        multiple="true"
        humanEntity="true"
        valueProp="sub"
      ></lib-custom-mat-search-select-field>
    </mat-form-field>
    <mat-form-field class="col-span-full" uiFormMatFormFieldErrorDisplayer>
      <mat-label i18n>patient</mat-label>
      <mat-icon matPrefix>personal_injury</mat-icon>
      <mat-icon
        (click)="openPatientForm($event)"
        class="hover:cursor-pointer"
        matSuffix
        >person_add</mat-icon
      >
      <lib-custom-mat-search-select-field
        formControlName="patient"
        [allOptions]="patients"
        [loading]="isLoading"
        (searchChange)="searchTerm.set($event)"
        placeholder="select patient"
        multiple="false"
        externalFiltering="true"
        humanEntity="true"
        valueProp="id"
      ></lib-custom-mat-search-select-field>
      <mat-error></mat-error>
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      class="col-span-6"
      uiFormMatFormFieldErrorDisplayer
    >
      <mat-label i18n="appointment-type">type</mat-label>
      <mat-icon matPrefix>info</mat-icon>

      <mat-select
        (selectionChange)="updateExpectedDuration()"
        formControlName="task_type_id"
      >
        <mat-option [value]="taskType.id" *ngFor="let taskType of taskTypes">
          {{ taskType?.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="col-span-6"
      uiFormMatFormFieldErrorDisplayer
    >
      <mat-label i18n>location</mat-label>
      <mat-icon matPrefix>location_on</mat-icon>
      <mat-select formControlName="location_id">
        <mat-option [value]="location.id" *ngFor="let location of locations">
          {{ location?.name }}
        </mat-option>
      </mat-select>
      <mat-error></mat-error>
    </mat-form-field>

    <div class="col-span-full flex flex-wrap gap-4 items-center" i18n>
      <h3>appointment schedules</h3>
      @if (obj(); as app) {
        <p><appointment-dates [appointment]="app"></appointment-dates></p>
      }
    </div>
    <mat-form-field class="sm:col-span-3 col-span-full">
      <mat-label i18n>choose a date</mat-label>
      <input
        #dateInput
        type="date"
        [formControl]="appointmentDayCtrl"
        matInput
      />
      <mat-icon (click)="dateInput.showPicker()" matSuffix
        >calendar_month</mat-icon
      >
    </mat-form-field>
    <mat-form-field
      class="sm:col-span-3 col-span-full"
      appearance="outline"
      uiFormMatFormFieldErrorDisplayer
    >
      <mat-label i18n>expected duration</mat-label>
      <mat-icon matPrefix>timer</mat-icon>

      <time-duration-input #durationInput [formControl]="expectedDurationCtrl">
      </time-duration-input>
      <mat-error></mat-error>
    </mat-form-field>
    <mat-form-field
      class="col-span-6"
      appearance="outline"
      uiFormMatFormFieldErrorDisplayer
    >
      <mat-label i18n>time zone</mat-label>
      <mat-icon matPrefix>language</mat-icon>
      <mat-select
        #timeZoneInput
        [compareWith]="compareStr"
        formControlName="timezone"
      >
        <mat-option [value]="_timezone" *ngFor="let _timezone of timezones">{{
          _timezone
        }}</mat-option>
      </mat-select>
      <mat-error></mat-error>
    </mat-form-field>
    <appointment-slot-picker
      class="col-span-full"
      [formControl]="slotSelectorCtr"
      [duration]="durationInput.value"
      [appointment]="obj()?.id"
      [timezone]="timeZoneInput.value"
      [date]="dateInput.value"
      [location]="selectedLocation$ | async"
    ></appointment-slot-picker>
  </form>
}
