@if (patient(); as patient) {
  @if (feeds().length > 0) {
    <ui-layout-container withHeader="false" dismissible="true">
      <div class="flex flex-wrap gap-4">
        @for (feed of feeds(); track $index; let idx = $index, last = $last) {
          <ui-layout-feedback
            removable="true"
            withIcon="false"
            fullWidth="false"
            [type]="feed.type"
            [pattern]="2"
            [message]="feed.message"
          ></ui-layout-feedback>
        }
      </div>
    </ui-layout-container>
  }

  <ui-layout-container level="2">
    <main class="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-4">
      <ui-layout-key-value key="latest appointment" i18n-key>
        @if (lastAppointment(); as lastAppointment) {
          <a
            class="anchor"
            [routerLink]="['..', 'appointments', lastAppointment.id]"
            >{{ lastAppointment.start | date }} (
            {{ lastAppointment.task_type?.name }} )</a
          >
        } @else {
          -
        }
      </ui-layout-key-value>

      <ui-layout-key-value key="next appointment" i18n-key>
        @if (nextAppointment(); as nextAppointment) {
          <a
            class="anchor"
            [routerLink]="['..', 'appointments', nextAppointment.id]"
            >{{ nextAppointment.start | date }} (
            {{ nextAppointment.task_type?.name }} )</a
          >
        } @else {
          -
        }
      </ui-layout-key-value>
      <ui-layout-key-value key="balance" i18n-key>
        <a
          [class.text-green-800]="paymentStatus().total_due === 0"
          [class.text-cyan-500]="paymentStatus().total_due > 0"
          [class.text-red-800]="paymentStatus().total_due < 0"
        >
          {{ paymentStatus().total_due | accountCurrency }}</a
        >
      </ui-layout-key-value>
    </main>
  </ui-layout-container>

  <ui-layout-container level="2" title="profile informations">
    <div ngProjectAs="actions">
      <button
        [routerLink]="['..', 'edit']"
        [queryParams]="{ obj: patient }"
        mat-icon-button
      >
        <mat-icon>edit</mat-icon>
      </button>
    </div>
    <section class="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-4">
      <ui-layout-key-value key="family name" i18n-key>
        {{ patient.family_name }}
      </ui-layout-key-value>
      <ui-layout-key-value key="given name" i18n-key>
        {{ patient.given_name }}
      </ui-layout-key-value>
      <ui-layout-key-value key="birthdate" i18n-key>
        @if (patient.birthdate) {
          {{ patient.birthdate | date }} ({{
            patient.birthdate | age | duration
          }})
        } @else {
          -
        }
      </ui-layout-key-value>
      <ui-layout-key-value key="reference" i18n-key>
        {{ patient.reference | coalesce: '-' }}
      </ui-layout-key-value>

      <ui-layout-key-value key="email" i18n-key>
        @if (patient.email) {
          <a class="break-words" [href]="'mailto:' + patient.email">{{
            patient.email
          }}</a>
        } @else {
          -
        }
      </ui-layout-key-value>

      <ui-layout-key-value key="contacts" i18n-key>
        @if (patient.phone_numbers) {
          <div class="flex flex-col gap-1">
            @for (phoneNumber of patient.phone_numbers; track $index) {
              <a
                class="anchor"
                [href]="phoneNumber.phone_number | phoneNumber: 'RFC3966'"
                >{{ phoneNumber.phone_number | phoneNumber }}</a
              >
            }
          </div>
        } @else {
          -
        }
      </ui-layout-key-value>
      @if (patient.remarks) {
        <ui-layout-key-value class="col-span-full" key="remarks" i18n-key>
          {{ patient.remarks }}
        </ui-layout-key-value>
      }
    </section>
  </ui-layout-container>

  <ui-layout-container level="2" title="public properties">
    <div ngProjectAs="actions">
      <button (click)="editProperties(false, patient)" mat-icon-button>
        <mat-icon>edit</mat-icon>
      </button>
    </div>
    <main class="grid grid-cols-4 gap-4">
      @for (property of patient.public_properties; track $index) {
        <ui-layout-key-value applyCase="false" [key]="property.key" i18n-key>
          {{ property.value }}
        </ui-layout-key-value>
      } @empty {
        <ui-layout-empty-state class="col-span-full">
          <h3 class="first-letter:uppercase" i18n>
            there's no public properties for this patient
          </h3>
        </ui-layout-empty-state>
      }
    </main>
  </ui-layout-container>
  @if (isDoctor$ | async) {
    <ui-layout-container level="2" title="medical properties">
      <div ngProjectAs="actions">
        <button (click)="editProperties(true, patient)" mat-icon-button>
          <mat-icon>edit</mat-icon>
        </button>
      </div>
      <main class="grid grid-cols-4 gap-4">
        @for (property of patient.medical_properties; track $index) {
          <ui-layout-key-value applyCase="false" [key]="property.key" i18n-key>
            {{ property.value }}
          </ui-layout-key-value>
        } @empty {
          <ui-layout-empty-state class="col-span-full">
            <h3 class="first-letter:uppercase" i18n>
              there's no medical properties for this patient
            </h3>
          </ui-layout-empty-state>
        }
      </main>
    </ui-layout-container>
  }

  <section
    class="p-6 error-container rounded-md flex flex-wrap justify-between"
  >
    <section>
      <h3 class="capitalize" i18n>delete patient</h3>
      <p i18n>this will deactivate this patient profile</p>
    </section>
    <button (click)="deletePatient()" class="red-button" mat-flat-button>
      <span i18n>delete</span>
    </button>
  </section>
}
