@if (authUser$ | async; as authUser) {
<h1 class="capitalize" i18n>staff dashboard</h1>

<ui-layout-container title="profile informations">
  <button ngProjectAs="actions" (click)="editProfileDialog(authUser)" mat-stroked-button>
    <span i18n>edit</span>
  </button>
  <mat-grid-list class="h-44" cols="3" rowHeight="fit">
    <mat-grid-tile> <ui-layout-key-value key="family name" i18n-key>
        {{authUser.family_name}}
      </ui-layout-key-value></mat-grid-tile>
    <mat-grid-tile> <ui-layout-key-value key="given name" i18n-key>
        {{authUser.given_name}}
      </ui-layout-key-value></mat-grid-tile>
    <mat-grid-tile> <ui-layout-key-value key="email" i18n-key>
        <a [href]="'mailto:'+authUser.email">{{ authUser.email }}</a>
      </ui-layout-key-value></mat-grid-tile>
    <mat-grid-tile> <ui-layout-key-value key="phone number" i18n-key>
        {{authUser.phone_number}}

      </ui-layout-key-value></mat-grid-tile>
  </mat-grid-list>
</ui-layout-container>

<ui-layout-container [title]="'medical accounts' + '('+(memberships$ |async)?.length+')'">
  <button ngProjectAs="actions" (click)="openNewOfficeDialog()" mat-stroked-button>
    <span i18n>new medical account</span>
  </button>
  <mat-nav-list>
    <a mat-list-item [routerLink]="['/', membership?.account?.id]" *ngFor="let membership of (memberships$ |async)">
      <div matListItemTitle>{{membership?.account?.alias}}</div>
      <div matListItemLine>{{membership?.roles?.join(' - ')}}</div>
    </a>
  </mat-nav-list>
</ui-layout-container>
}
<!-- <ng-container *ngIf="(authUser$ | async) as authUser">

  <section class="gap-4 flex flex-col border border-gray-200 rounded-md mt-6 mb-8 p-8">
    <section class=" flex sm:flex-row flex-col items-center gap-12">
      <img class="rounded-full w-28" authAvatarLoader [user]="$any(authUser)" />
      <section class="flex flex-grow items-center gap-x-24 gap-y-10 flex-wrap">
        <article class="record">
          <p i18n>email</p>
          <p>
            {{ authUser?.email }}
          </p>
        </article>
        <article class="record">
          <p i18n>phone number</p>
          <p>
            {{ authUser?.phone_number | phoneNumber}}
          </p>
        </article>
        <article class="record">
          <p i18n>given name</p>
          <p class="first-letter:uppercase">
            {{ authUser.given_name}}
          </p>
        </article>
        <article class="record">
          <p i18n>family name</p>
          <p class="first-letter:uppercase">
            {{ authUser.family_name}}
          </p>
        </article>


        <button (click)="editProfileDialog(authUser)" color="primary" mat-flat-button>
          <mat-icon>edit</mat-icon>
          <span i18n>profile modification</span>
        </button>
      </section>
    </section>

  </section>
  <section class="border border-gray-200 rounded-md p-8">
    <header class="mb-6 gap-6 flex justify-between flex-wrap">
      <h2 class="first-letter:uppercase" i18n>medical accounts list</h2>
      <button (click)="openNewOfficeDialog()" color="primary" mat-raised-button>
        <mat-icon>add</mat-icon>
        <span i18n>create a medical account</span>
      </button>
    </header>
    <mat-nav-list>
      <a mat-list-item [routerLink]="['/', membership?.account?.id]" *ngFor="let membership of (memberships$ |async)">
        <div matListItemTitle>{{membership?.account?.alias}}</div>
        <div matListItemLine>{{membership?.roles?.join(' - ')}}</div>
      </a>
    </mat-nav-list>

    <ng-template #noSubscription>
      <div class="mx-auto flex-col items-center flex">
        <mat-icon inline="true" class="max-w-xs mb-8" svgIcon="new-office"></mat-icon>

        <p class="first-letter:uppercase max-w-sm font-medium text-gray-600 text-center justify-center" i18n>you are not
          a
          member of any
          medical account
          you create your own or ask a medical account manager to invite you</p>
        <button mat-flat-button color="primary" class="mt-6" (click)="openNewOfficeDialog()">
          <mat-icon class="mr-4">add</mat-icon>
          <span i18n class="capitalize">create a medical account</span>
        </button>
      </div>
    </ng-template>
  </section>
</ng-container> -->