@if (isCompact$ | async) {
  <div class="pb-14">
    <router-outlet></router-outlet>
  </div>
  <mat-toolbar class="fixed bottom-0 z-30 flex justify-between">
    @for (item of navLinks; track $index) {
      <button
        [routerLink]="item.routerLink"
        class="flex justify-center"
        mat-button
      >
        <mat-icon class="m-auto">{{ item.svgIcon }}</mat-icon>
      </button>
    }
  </mat-toolbar>
} @else {
  <mat-sidenav-container>
    <mat-sidenav
      mode="side"
      fixedTopGap="56"
      class="w-24"
      fixedInViewport
      opened
    >
      <nav class="flex flex-col items-center gap-4 pt-4">
        @for (link of navLinks; track $index) {
          <button
            *showForRoles="link.requiredRoles"
            [matTooltip]="link.name"
            mat-button
            matTooltipPosition="right"
            class="nav-button"
            [routerLink]="link.routerLink"
            [routerLinkActive]="['bg-blue-200', 'hover:bg-blue-200']"
          >
            <mat-icon class="m-0">{{ link.svgIcon }}</mat-icon>
          </button>
        }
      </nav>
    </mat-sidenav>

    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
}
