import { Dialog } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';

import { Injectable, Injector, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  BaseEntityFormComponent,
  IEntity,
} from '@doctorus-front-end-monorepo/feature-entity';
import { filter, tap } from 'rxjs';
import { MatDialogFormPanelComponent } from './mat-dialog-form-panel/mat-dialog-form-panel.component';
import { SlideOutFormPanelComponent } from './slide-out-form-panel/slide-out-form-panel.component';
import { FormDialogPanelData, IFormComponent } from './slide-out-panel-types';

@Injectable({
  providedIn: 'root',
})
export class SlideOutPanelService {
  private dialog = inject(Dialog);
  private matDialog = inject(MatDialog);
  private overlay = inject(Overlay);
  private snackBar = inject(MatSnackBar);

  // open<T, C, R>(title: string, cmp: C, data: T) {
  //   return this.dialog.open<R>(SlideOutPanelComponent<T, C>, {
  //     width: 'calc(100vw - 148px)',
  //     height: '100vh',
  //     hasBackdrop: true,
  //     panelClass: ['rounded-md'],
  //     positionStrategy: this.overlay.position().global().top('0').right('0'),
  //     data: {
  //       title,
  //       data,
  //       cmp,
  //     },
  //   });
  // }
  openSlideOutEntityWriteForm<E extends IEntity, C extends IFormComponent, R>(
    data: FormDialogPanelData<E>,
    injector: Injector,
  ) {
    return this.dialog
      .open<R>(SlideOutFormPanelComponent<E, C>, {
        width: '100vw',
        maxWidth: 1280,
        height: '100vh',
        hasBackdrop: true,
        panelClass: ['rounded-md'],
        positionStrategy: this.overlay.position().global().top('0').right('0'),
        data,
        injector,
      })
      .closed.pipe(
        filter(x => (x ? true : false)),
        tap(x =>
          this.snackBar.open(
            data.entityConfig?.onPutSuccessMsg(data.entity ? true : false) ??
              'Action performed',
          ),
        ),
      );
  }

  openDialogWriteForm<
    E extends IEntity,
    C extends BaseEntityFormComponent<E>,
    R,
  >(data: FormDialogPanelData<E>, injector: Injector) {
    return this.matDialog
      .open<any, any, R>(MatDialogFormPanelComponent<E, C>, {
        width: '420px',
        hasBackdrop: true,
        data,
        injector,
      })
      .afterClosed()
      .pipe(
        tap(console.warn),
        filter(x => (x ? true : false)),
        tap(x =>
          this.snackBar.open(
            data.entityConfig?.onPutSuccessMsg(data.entity ? true : false) ??
              'Action performed',
          ),
        ),
      );
  }
}
