import { CommonModule, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';
import { UiPhoneNumberPickerComponent } from '@doctorus-front-end-monorepo/ui-phone-number-picker';
import {
  ICountry,
  TCountryCode,
  countries,
  getCountryData,
  getEmojiFlag,
} from 'countries-list';
import { Country } from '../../../models/utils/util.type';

@Component({
  selector: 'doctorus-front-end-monorepo-new-office-dialog',
  templateUrl: './new-office-dialog.component.html',
  styleUrls: ['./new-office-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    UiMatFormFieldErrorDisplayerDirective,
    MatInputModule,
    MatSelectModule,
    NgIf,
    MatIconModule,
    CommonModule,
    NgFor,
    MatOptionModule,
    UiPhoneNumberPickerComponent,
    MatButtonModule,
    TitleCasePipe,
  ],
})
export class NewOfficeDialogComponent {
  countries = countries;
  officeForm = this.fb.group({
    alias: ['', Validators.required],
    country: ['TN', Validators.required],
  });
  constructor(
    public dialogRef: MatDialogRef<NewOfficeDialogComponent>,
    private fb: FormBuilder
  ) {}
  countryCompare = (a: Country, b: Country) =>
    a?.name?.toLowerCase() === b?.name?.toLowerCase();

  submit(): void {
    this.officeForm.markAllAsTouched();
    if (this.officeForm.valid) {
      this.dialogRef.close(this.officeForm.value);
    }
  }

  getFlag(code: string | ICountry) {
    return getEmojiFlag(code as TCountryCode);
  }
  getCountry(code: string | ICountry) {
    return getCountryData(code as TCountryCode);
  }
}
