import { AppointmentEventFieldsFragment } from '@doctorus-front-end-monorepo/graphql';
import { HumanNamePipe } from '@doctorus-front-end-monorepo/shared-util';
import * as dateFns from 'date-fns';
import { CalendarEvent } from './types';

function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export const toCalendatEvent = (
  appointment: AppointmentEventFieldsFragment,
) => {
  const rgb = hexToRgb(appointment.task_type?.color ?? '#e3e8e7');
  return {
    id: appointment.id,
    start: new Date(appointment.start),
    end: new Date(appointment.end),
    borderColor: appointment.task_type?.color ?? 'white',
    backgroundColor: `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, 0.5)`,
    title: new HumanNamePipe().transform(appointment?.patient_info),
    extendedProps: {
      ...(appointment.location && { location: appointment.location }),
      ...(appointment.task_type && { task_type: appointment.task_type }),
      patient_info: appointment.patient_info,
      patient: appointment.patient,
      timezone: appointment.timezone,
      status: appointment.status,
    },
  };
};

export const fromEventCalendar = (event: CalendarEvent) => ({
  id: event.id,
  start: dateFns.formatISO(event.start),
  end: dateFns.formatISO(event.end),
  ...event.extendedProps,
});
