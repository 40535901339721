// import { MedicalHistory, MedicalHistoryModel } from '../../generated';
// import { MedicalHistoryDefaultEntry, MedicalNoteEntry } from './types';

import {
  GeneratedDocument,
  MedicalDocumentType,
  Prescription,
  UploadDocument,
} from '../../generated';

export type AllCheckType<T, C> = {
  obj?: T;
  order?: number;
  name: any;
  checked: boolean;
  model?: C;
};

export const allChecks = <T, C extends { order?: number | null }>(
  checked: T[],
  toBeChecked: C[],
  checkIdProp: keyof T,
  toBeCheckedIdProp: keyof C,
): Array<AllCheckType<T, C>> =>
  [
    ...checked.map(x => ({
      obj: x,
      order:
        toBeChecked.find(
          d => (d[toBeCheckedIdProp] as symbol) === x[checkIdProp],
        )?.order ?? 999,
      name: x[checkIdProp],
      checked: true,
    })),
    ...toBeChecked
      .filter(
        x =>
          !checked
            .map(_ch => _ch[checkIdProp] as symbol)
            .includes(x[toBeCheckedIdProp] as symbol),
      )
      .map(x => ({
        model: x,
        order: x.order ?? 999,
        name: x[toBeCheckedIdProp],
        checked: false,
      })),
  ].sort((a, b) => a.order - b.order);

// export const toMedicalHistoriesMap = (
//   medicalHistories: MedicalHistory[],
//   defaultTypes: MedicalHistoryModel[],
// ): MedicalHistoryEntry[] => {
//   return [
//     ...Array.from(new Set(medicalHistories.map(x => x.name))).map(name => ({
//       entries: medicalHistories.filter(x => x.name === name),
//       name: name,
//       checked: true,
//       order: defaultTypes.find(d => d.type === name)?.order ?? 999,
//       obj: null,
//     })),
//     ...defaultTypes
//       .filter(d => !medicalHistories.map(x => x.name).includes(d.type))
//       .map(x => ({
//         obj: x,
//         name: x.type,
//         entries: [],
//         checked: false,
//         order: x.order ?? 999,
//       })),
//   ].sort((a, b) => a.order - b.order);
// };

// export const toMeasureSurvey = (
//   medicalMeasures: Measure[],
//   measureModels: MeasureModel[],
// ): MeasureEntry[] => {
//   return [
//     ...medicalMeasures.map(measure => ({
//       obj: measure,
//       model: null,
//       name: measure.name,
//       checked: true,
//       order: measureModels.find(d => d.name === measure.name)?.order ?? 999,
//     })),
//     ...measureModels
//       .filter(d => !medicalMeasures.map(x => x.name).includes(d.name))
//       .map(x => ({
//         model: x,
//         obj: null,
//         name: x.name,
//         checked: false,
//         order: x.order ?? 999,
//       })),
//   ].sort((a, b) => a.order - b.order);
// };

export const toGenericDocuments = <
  T extends Prescription | UploadDocument | GeneratedDocument,
>(
  type: MedicalDocumentType,
  documents: T[],
) =>
  documents.map(x => ({
    id: x.id,
    type: type,
    date: x.date,
    media: (x as any)?.multi_sizes_urls?.M ?? '',
    name:
      (x as any)['title'] ??
      (x as any)['name'] ??
      (type === MedicalDocumentType.Prescription
        ? $localize`Prescription`
        : null),
    content:
      (x as any)['content'] ??
      (x as any)['description'] ??
      (type === MedicalDocumentType.Prescription
        ? (x as Prescription).treatment_set?.map(x => x.name)?.join(' , ')
        : null),
  }));
