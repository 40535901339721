import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import {
  ControlContainer,
  FormArray,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {
  Observable,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs';
import {
  Medication,
  SearchMedicationsGQL,
} from '../../../../../shared/data-access/graphql/src';

@Component({
    selector: 'prescription-treatment-form',
    viewProviders: [
        {
            provide: ControlContainer,
            useFactory: () => inject(ControlContainer, { skipSelf: true }),
        },
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatIconModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatInputModule,
    ],
    templateUrl: './treatment-form.component.html',
    styleUrl: './treatment-form.component.scss'
})
export class TreatmentFormComponent implements OnInit {
  ngOnInit(): void {
    if (this.formGroup.get('name')) {
      this.filtredMedications$ = this.formGroup.get('name')!.valueChanges.pipe(
        tap(console.warn),
        filter(val => val && val.length > 2),
        map(x => x.toLowerCase()),
        distinctUntilChanged(),
        debounceTime(300),
        switchMap(val =>
          this.searchMedicationsGql.fetch({
            search: val,
          }),
        ),
        map(
          res =>
            res.data.searchMedications?.map(x => this.medicaDisplayFunc(x)) ??
            [],
        ),
      );
    }
  }
  @Input({ required: true }) index!: number;
  @Input({ required: true }) formArrayKey!: string;
  parentContainer = inject(ControlContainer);

  get formGroup() {
    return (
      this.parentContainer.control?.get(this.formArrayKey) as FormArray
    )?.at(this.index) as FormGroup;
  }
  private searchMedicationsGql = inject(SearchMedicationsGQL);

  filtredMedications$!: Observable<string[]>;

  medicaDisplayFunc = (a: Medication) =>
    a ? `${a.label} ${a.dose ? ` ( ${a.dose.toLowerCase()} )` : ''}` : '';
}
