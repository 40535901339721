<div class="flex flex-col px-4  p-6">

  @if ((breadcrumbs$|async);as breadcrumbs ) {
  @if(breadcrumbs.length > 1) {
  <ui-layout-breadcrumb class="mb-4" [breadcrumbs]="breadcrumbs"></ui-layout-breadcrumb>

  }


  }

  <router-outlet></router-outlet>

</div>