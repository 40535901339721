import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
    selector: 'entity-base-entity-list',
    imports: [CommonModule],
    template: `<p>base-entity-list works!</p>`,
    styles: ``
})
export class BaseEntityListComponent<T> {
  objs = input.required<Array<T>>();
}
