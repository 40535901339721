<div class="h-full" [ngSwitch]="arg.view.type">
  <ng-container *ngSwitchCase="'timeGridDay'">
    <div class="flex gap-1 pl-1  h-full items-center  text-xs  text-black"
      [style.border-left-color]="arg.event.extendedProps.task_type?.color">
      <span>{{arg.event.start
        |date:'H:mm':offset }}</span><span>{{arg.event.extendedProps.patient_info | humanName:'full'
        }}</span><span class="ml-2 flex items-center"><mat-icon
          [inline]="true">location_on</mat-icon>{{arg.event.extendedProps.location?.name}}</span>
    </div>

  </ng-container>
  <ng-container *ngSwitchCase="'dayGridMonth'">
    <div class="flex gap-1 pl-1 items-center text-xs text-black">
      <span class="w-2 h-2 rounded-full" [style.background-color]="arg.event.extendedProps.task_type?.color"></span>
      <span>{{arg.event.start
        |date:'H:mm':offset}}</span>
      <span *ngIf="isLarge$|async" class=" truncate">{{arg.event.extendedProps.patient_info | humanName
        }}</span>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'timeGridWeek'">
    <div class="flex gap-1 pl-1 items-center  h-full text-black">
      <span>{{arg.event.start
        |date:'H:mm':offset}}</span><span *ngIf="isLarge$|async">{{arg.event.extendedProps.patient_info | humanName
        }}</span>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault="">
    <div class="flex gap-1 pl-1 items-center overflow-x-hidden text-black">
      <span>{{arg.event.extendedProps.patient_info | humanName:'full'
        }}</span><span class="ml-2 flex items-center"><mat-icon
          [inline]="true">location_on</mat-icon>{{arg.event.extendedProps.location?.name}}</span>
    </div>
  </ng-container>
</div>
