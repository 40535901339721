import { Route } from '@angular/router';
import { BreadcrumbRouteData } from '../../../../shared/breadcrumb/src/lib/types';
import {
  DeleteUploadDocumentGQL,
  PutUploadDocumentGQL,
  resolveMedicalRecordItem,
} from '../../../../shared/data-access/graphql/src';
import {
  DetailsWrapperComponent,
  ENTITY_CONFIG,
  ENTITY_DETAILS_COMPONENT,
  ENTITY_WRITE_COMPONENT,
  EntityMutationService,
  WriteWrapperComponent,
} from '../../../../shared/feature-entity/src';
import { EntityDeleteMutationService } from '../../../../shared/ui-entity-dialog/src';
import { UploadDocumentBoxComponent } from './upload-document-box/upload-document-box.component';
import { uploadDocumentConfig } from './utils';
import { WriteUploadDocumentComponent } from './write-upload-document/write-upload-document.component';

export const featureUploadedDocumentRoutes: Route = {
  path: 'upload-document',
  data: {
    breadcrumbChunck: {
      name: null,
    } as BreadcrumbRouteData,
  },
  providers: [
    {
      provide: ENTITY_CONFIG,
      useValue: uploadDocumentConfig,
    },
    {
      provide: EntityDeleteMutationService,
      useClass: DeleteUploadDocumentGQL,
    },
    {
      provide: EntityMutationService,
      useClass: PutUploadDocumentGQL,
    },
    {
      provide: ENTITY_DETAILS_COMPONENT,
      useValue: UploadDocumentBoxComponent,
    },
    {
      provide: ENTITY_WRITE_COMPONENT,
      useValue: WriteUploadDocumentComponent,
    },
  ],
  children: [
    {
      path: 'new',
      component: WriteWrapperComponent,
      data: {
        successRedirect: ['../..'],
        cancelRedirect: ['../..'],
        obj: null,
        breadcrumbChunck: {
          name: $localize`new`,
        } as BreadcrumbRouteData,
      },
    },
    {
      path: ':id',
      resolve: {
        obj: resolveMedicalRecordItem('upload_documents'),
      },
      data: {
        breadcrumbChunck: {
          name: $localize`uploaded document:{{obj.id}}`,
        } as BreadcrumbRouteData,
      },
      children: [
        {
          path: '',
          component: DetailsWrapperComponent,
          data: {
            deleteRedirect: ['../..'],

            breadcrumbChunck: {
              name: null,
            } as BreadcrumbRouteData,
          },
        },
        {
          path: 'edit',
          component: WriteWrapperComponent,
          data: {
            breadcrumbChunck: {
              name: $localize`edit`,
            } as BreadcrumbRouteData,
          },
        },
      ],
    },
  ],
};
