<main cass="tlext-sm" mat-dialog-content>
  <div class="flex justify-between items-center text-black">
    <h3>{{headerStr}}</h3>
    <button mat-dialog-close mat-icon-button><mat-icon>close</mat-icon></button>
  </div>
  <p i18n class="text-black">{{ confirmMsg }}</p>
</main>
<div align="end" mat-dialog-actions>
  <button matDialogClose class="mr-2" mat-button>
    <span i18n>cancel</span>
  </button>
  <button (click)="delete()" color="warn" mat-flat-button>
    <span i18n>{{buttonLabel}}</span>
  </button>
</div>
