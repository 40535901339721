import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
  inject,
  model,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ShowForMultiLocationsDirective } from '@doctorus-front-end-monorepo/auth';
import {
  EntityConfig,
  EntityMutationService,
} from '@doctorus-front-end-monorepo/feature-entity';
import {
  AppointmentPublicFieldsFragment,
  CorePatientFieldsFragment,
  DeleteAppointmentGQL,
  PutAppointmentGQL,
  SetAppointmentFeesGQL,
  SetAppointmentMedicalNotesGQL,
  SetAppointmentStatusGQL,
} from '@doctorus-front-end-monorepo/graphql';
import { AppointmentStatus } from '@doctorus-front-end-monorepo/shared-type';
import { ResolveStatusConfigPipe } from '@doctorus-front-end-monorepo/shared-util';
import { SlideOutPanelService } from '@doctorus-front-end-monorepo/slide-out-panel';
import {
  EntityDeleteMutationService,
  EntityDialogService,
} from '@doctorus-front-end-monorepo/ui-entity-dialog';
import {
  ContainerComponent,
  GroupHeaderComponent,
  KeyValueComponent,
} from '@doctorus-front-end-monorepo/ui-layout';
import { CoalescePipe } from '@doctorus-front-end-monorepo/util-formatting';
import { orderBy } from 'lodash';
import { QuillEditorComponent, QuillViewComponent } from 'ngx-quill';
import { appointmentConfig } from '../appointment-entity.model';
import { FeesDialogComponent } from '../fees-dialog/fees-dialog.component';
import { WriteAppointmentFormComponent } from '../forms/write-appointment-form/write-appointment-form.component';
import { WriteMedicaNoteFromComponent } from '../forms/write-medica-note-from/write-medica-note-from.component';
import { StatusPickerComponent } from '../status-picker/status-picker.component';
@Component({
    selector: 'appointment-medical-record-appointment-details',
    imports: [
        CommonModule,
        StatusPickerComponent,
        MatButtonModule,
        CoalescePipe,
        MatIconModule,
        ResolveStatusConfigPipe,
        GroupHeaderComponent,
        ContainerComponent,
        MatFormFieldModule,
        MatDividerModule,
        MatSelectModule,
        ReactiveFormsModule,
        QuillEditorComponent,
        KeyValueComponent,
        MatChipsModule,
        MatInputModule,
        ShowForMultiLocationsDirective,
        QuillViewComponent,
        CoalescePipe,
    ],
    templateUrl: './medical-record-appointment-details.component.html',
    styleUrls: ['./medical-record-appointment-details.component.scss'],
    providers: [
        {
            provide: EntityDeleteMutationService,
            useClass: DeleteAppointmentGQL,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MedicalRecordAppointmentDetailsComponent {
  @Input({ required: true }) patient!: CorePatientFieldsFragment;
  appointment = model.required<
    AppointmentPublicFieldsFragment &
      Partial<{
        note: string;
        description: string;
      }>
  >();
  @Output() deselect = new EventEmitter();

  private injector = inject(Injector);
  private eds = inject(SlideOutPanelService);
  private dialogService = inject(EntityDialogService);
  private setStatusGql = inject(SetAppointmentStatusGQL);
  private medicalNoteInjector = Injector.create({
    parent: this.injector,
    providers: [
      {
        provide: EntityMutationService,
        useClass: SetAppointmentMedicalNotesGQL,
      },
    ],
  });
  private appointmentFeesInjector = Injector.create({
    parent: this.injector,
    providers: [
      {
        provide: EntityMutationService,
        useClass: SetAppointmentFeesGQL,
      },
    ],
  });
  private appointmentDetailsInjector = Injector.create({
    parent: this.injector,
    providers: [
      {
        provide: EntityMutationService,
        useClass: PutAppointmentGQL,
      },
    ],
  });
  editMedicalNote(): void {
    this.eds
      .openDialogWriteForm(
        {
          cmp: WriteMedicaNoteFromComponent,
          entity: this.appointment(),
          entityConfig: new EntityConfig({
            feature: $localize`medical note`,
          }),
        },
        this.medicalNoteInjector,
      )
      .subscribe();
  }
  setStatus(status: AppointmentStatus | undefined): void {
    if (status) {
      this.setStatusGql
        .mutate({
          payload: {
            id: this.appointment().id,
            status,
          },
        })
        .subscribe();
    }

    //this.eds.openEntityWriteForm({});
  }
  setFees(): void {
    const suggestions = [60, 100, 200, 55];
    if (this.appointment().task_type?.default_fees) {
      suggestions.unshift(this.appointment().task_type?.default_fees!);
    }

    this.eds
      .openDialogWriteForm(
        {
          cmp: FeesDialogComponent,
          entity: this.appointment(),
          entityConfig: new EntityConfig({
            feature: $localize`appointment fees`,
          }),
          extra: {
            suggestions: orderBy(suggestions, {}, 'asc'),
          },
        },
        this.appointmentFeesInjector,
      )
      .subscribe();
  }
  editAppointment(): void {
    this.eds
      .openSlideOutEntityWriteForm<any, any, any>(
        {
          cmp: WriteAppointmentFormComponent,
          entity: this.appointment(),
          entityConfig: appointmentConfig,
          extra: {
            patient: this.patient,
          },
        },
        this.appointmentDetailsInjector,
      )
      .subscribe(x => {});
  }
  deleteAppointment(): void {
    this.dialogService
      .openEntityDeleteDialog(
        this.appointment,
        this.injector,
        appointmentConfig,
      )
      .subscribe();
  }
}
