import { CommonModule } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTimepickerModule } from '@angular/material/timepicker';
import { tz } from '@date-fns/tz';
import { startOfToday, startOfTomorrow } from 'date-fns';
import { isNil } from 'lodash';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import {
  debounceTime,
  filter,
  finalize,
  map,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';
import { AccountDataService } from '../../../../../shared/auth/src';
import {
  CarePlan,
  CorePatientFieldsFragment,
  GetPatientsGQL,
  Patient,
} from '../../../../../shared/data-access/graphql/src';
import { BaseEntityFormComponent } from '../../../../../shared/feature-entity/src';
import { UiMatFormFieldErrorDisplayerDirective } from '../../../../../shared/form/ui-form/src';
import { HumanNamePipe } from '../../../../../shared/shared-util/src';

@Component({
  selector: 'lib-care-plan-write',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatDatepickerModule,
    MatTimepickerModule,
    HumanNamePipe,
    UiMatFormFieldErrorDisplayerDirective,
  ],
  templateUrl: './care-plan-write.component.html',
  styleUrl: './care-plan-write.component.scss',
})
export class CarePlanWriteComponent extends BaseEntityFormComponent<
  CarePlan,
  { patient?: Patient }
> {
  patient = computed(() => this.extra()?.patient);
  subscription = new Subscription();
  hasPatientField = computed<boolean>(() => isNil(this.patient()?.id));
  patientFilterCtrl = new FormControl();
  patientsLoading = signal(false);
  filtredPatients = signal<Array<CorePatientFieldsFragment>>([]);
  getAllPatientsGQL = inject(GetPatientsGQL);

  account = inject(AccountDataService).account;
  override ngOnInit(): void {
    super.ngOnInit();

    this.subscription.add(
      this.patientFilterCtrl.valueChanges
        .pipe(
          filter(x => (x ? true : false)),
          tap(() => this.patientsLoading.set(true)),
          debounceTime(200),
          switchMap(x =>
            this.getAllPatientsGQL
              .fetch({
                payload: { page_size: 500, search: x?.toString() ?? '' },
              })
              .pipe(finalize(() => this.patientsLoading.set(false))),
          ),
          map(res => res.data.getPatients?.results),
        )
        .subscribe(x => this.filtredPatients.set(x ?? [])),
    );
    this.filtredPatients.set([this.obj()?.patient].filter(x => !isNil(x)));
  }
  override initFormFun = () =>
    this.fb.group({
      id: [this.obj()?.id],
      name: [this.obj()?.name ?? this.queryParams()?.name, Validators.required],
      start: [
        this.obj()?.start
          ? new Date(this.obj()?.start)
          : startOfToday({
              in: tz(Intl.DateTimeFormat().resolvedOptions().timeZone),
            }),
        Validators.required,
      ],
      end: [
        this.obj()?.end
          ? new Date(this.obj()?.end)
          : startOfTomorrow({
              in: tz(Intl.DateTimeFormat().resolvedOptions().timeZone),
            }),
        Validators.required,
      ],
      frequency: [
        this.obj()?.frequency ?? this.queryParams()?.frequency,
        [Validators.required, Validators.max(20), Validators.min(1)],
      ],
      location: [
        this.obj()?.location?.id ??
          this.account?.appointmentDefaultLocation?.id ??
          this.account?.locations?.at(0)?.id,
        Validators.required,
      ],
      patient: [
        this.obj()?.patient?.id ?? this.patient()?.id,
        Validators.required,
      ],
      contributors: [this.obj()?.contributors?.map(x => x.sub)],
      notes: [this.obj()?.notes ?? this.queryParams()?.notes],
    });
}
