import {
  CommonModule,
  DatePipe,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  TitleCasePipe,
} from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import {
  BaseEntityDetailsComponent,
  EntityMutationService,
} from '@doctorus-front-end-monorepo/feature-entity';
import {
  DeleteGeneratedDocumentGQL,
  DocumentType,
  GeneratedDocument,
  PutGeneratedDocumentGQL,
} from '@doctorus-front-end-monorepo/graphql';
import { EntityDeleteMutationService } from '@doctorus-front-end-monorepo/ui-entity-dialog';
import { PdfConfigDialogService } from '@doctorus-front-end-monorepo/util-document';
import { QuillViewComponent } from 'ngx-quill';
import {
  ContainerComponent,
  KeyValueComponent,
} from '../../../../../shared/ui-layout/src';
import { CoalescePipe } from '../../../../../shared/util-formatting/src';

@Component({
  selector: 'generated-document-generated-document-box',
  templateUrl: './generated-document-box.component.html',
  styleUrls: ['./generated-document-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: EntityMutationService,
      useClass: PutGeneratedDocumentGQL,
    },
    {
      provide: EntityDeleteMutationService,
      useClass: DeleteGeneratedDocumentGQL,
    },
  ],
  standalone: true,
  imports: [
    NgIf,
    NgSwitch,
    NgSwitchCase,
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    QuillViewComponent,
    MatListModule,
    RouterLink,
    ContainerComponent,
    KeyValueComponent,
    MatMenuModule,

    TitleCasePipe,
    DatePipe,
    CoalescePipe,
  ],
})
export class GeneratedDocumentBoxComponent extends BaseEntityDetailsComponent<GeneratedDocument> {
  private pcds = inject(PdfConfigDialogService);

  getPdf(): void {
    this.pcds
      .openPDFConfigDialog(this.obj().id, DocumentType.GeneratedDocument)
      .subscribe();
  }
}
