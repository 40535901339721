import { Pipe, PipeTransform } from '@angular/core';
import { fileIcon } from '../utils';

@Pipe({
  name: 'contentTypeIcon',
  standalone: true,
})
export class ContentTypeIconPipe implements PipeTransform {
  transform(src: string | File | Blob) {
    return fileIcon(src);
  }
}
