import { CommonModule } from '@angular/common';
import { Component, computed } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MeasureEntry } from '../../../../../shared/data-access/graphql/src';
import { BaseEntityListComponent } from '../../../../../shared/feature-entity/src';
import {
  ContainerComponent,
  FeedbackComponent,
} from '../../../../../shared/ui-layout/src';
import { CoalescePipe } from '../../../../../shared/util-formatting/src';
import { MeasureGraphComponent } from '../measure-graph/measure-graph.component';
import { MeasureObservationsSummaryComponent } from '../measure-observations-summary.component';

@Component({
  selector: 'medical-measure-feature-medical-measure',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatDividerModule,
    RouterModule,
    MatButtonModule,
    MeasureGraphComponent,
    ContainerComponent,
    MeasureObservationsSummaryComponent,
    FeedbackComponent,
    CoalescePipe,
  ],
  templateUrl: './feature-medical-measure.component.html',
  styleUrl: './feature-medical-measure.component.scss',
})
export class FeatureMedicalMeasureComponent extends BaseEntityListComponent<MeasureEntry> {
  patientCurrentMeasure = computed(() => this.objs().filter(el => el.checked));
}
