import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '@doctorus-front-end-monorepo/auth';
import { BreadcrumbRouteData } from '@doctorus-front-end-monorepo/breadcrumb';
import {
  DetailsWrapperComponent,
  ENTITY_CONFIG,
  ENTITY_DETAILS_COMPONENT,
  ENTITY_WRITE_COMPONENT,
  EntityDeleteMutationService,
  LAYOUT_CONFIG,
  LayoutConfig,
  PutEntityService,
  WriteWrapperComponent,
} from '@doctorus-front-end-monorepo/feature-entity';
import {
  DeleteAppointmentGQL,
  GetAppointmentGQL,
  PutPatientAppointmentGQL,
} from '@doctorus-front-end-monorepo/graphql';
import { map } from 'rxjs';
import { AppointmentFullListComponent } from './appointment-full-list/appointment-full-list.component';
import { appointmentConfig } from './entity-config';
import { WriteAppointmentFormComponent } from './forms/write-appointment-form/write-appointment-form.component';
import { MedicalRecordAppointmentDetailsComponent } from './medical-record-appointment-details/medical-record-appointment-details.component';

const resolveAppointment = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) =>
  inject(GetAppointmentGQL)
    .fetch({
      id: route.params['id'],
      withMedicalNotes: inject(AuthService).isAuthUserDoctor,
    })
    .pipe(map(x => x.data.getAppointment));

export const featureAppointmentRoutes: Route = {
  path: 'appointments',
  title: 'appointment:list',
  children: [
    {
      path: '',
      providers: [
        {
          provide: ENTITY_CONFIG,
          useValue: appointmentConfig,
        },
        {
          provide: LAYOUT_CONFIG,
          useValue: { withBorder: false } as LayoutConfig,
        },
        {
          provide: EntityDeleteMutationService,
          useClass: DeleteAppointmentGQL,
        },
        {
          provide: PutEntityService,
          useClass: PutPatientAppointmentGQL,
        },
        {
          provide: ENTITY_DETAILS_COMPONENT,
          useValue: MedicalRecordAppointmentDetailsComponent,
        },
        {
          provide: ENTITY_WRITE_COMPONENT,
          useValue: WriteAppointmentFormComponent,
        },
      ],
      data: {
        breadcrumbChunck: {
          name: $localize`appointments`,
        },
      },
      children: [
        {
          providers: [
            {
              provide: LAYOUT_CONFIG,
              useValue: { withBorder: false } as LayoutConfig,
            },
          ],
          path: '',
          component: AppointmentFullListComponent,
          data: {
            breadcrumbChunck: {
              name: null,
            },
          },
        },
        {
          path: 'new',
          title: 'appointment:create',

          component: WriteWrapperComponent,
          data: {
            obj: null,
            breadcrumbChunck: {
              name: $localize`new`,
            } as BreadcrumbRouteData,
          },
        },
        {
          path: ':id',
          runGuardsAndResolvers: 'always',

          resolve: {
            obj: resolveAppointment,
          },
          data: {
            breadcrumbChunck: {
              name: $localize`appointment:{{obj.id}}`,
            } as BreadcrumbRouteData,
          },
          children: [
            {
              path: '',
              title: 'appointment:details',

              component: DetailsWrapperComponent,
              data: {
                deleteRedirect: ['..'],

                breadcrumbChunck: {
                  name: null,
                } as BreadcrumbRouteData,
              },
            },
            {
              path: 'edit',
              title: 'appointment:edit',
              component: WriteWrapperComponent,
              data: {
                breadcrumbChunck: {
                  name: $localize`edit`,
                } as BreadcrumbRouteData,
              },
            },
          ],
        },
      ],
    },
  ],
  data: {
    breadcrumbChunck: { name: null },
  },
};
