import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  input,
  numberAttribute,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { EnumDisplayConfig, STATUS_CONF, StatusConf } from '../types';
type StatusDisplayMode = 'ICON_ONLY' | 'TEXT_ONLY' | 'BOTH';
@Component({
  selector: 'doctorus-front-end-monorepo-status',
  imports: [CommonModule, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './status.component.html',
  styleUrl: './status.component.scss',
})
export class StatusComponent<K extends keyof StatusConf> {
  clickable = input(false, { transform: booleanAttribute });
  displayMode = input<StatusDisplayMode>('BOTH');
  entity = input.required<K>();
  status = input.required({ transform: numberAttribute });
  withBorder = input(true, { transform: booleanAttribute });

  statusDef = computed(
    () => STATUS_CONF[this.entity()][this.status()] as EnumDisplayConfig,
  );

  displayIcon = computed(
    () => this.displayMode() === 'BOTH' || this.displayMode() === 'ICON_ONLY',
  );
  displayText = computed(
    () => this.displayMode() === 'BOTH' || this.displayMode() === 'TEXT_ONLY',
  );

  @HostBinding('class')
  public get classAttribute() {
    return [
      {
        condition: this.withBorder(),
        class: 'px-2 py-1 border-2',
      },
      {
        condition: this.clickable(),
        class: 'hover:bg-gray-100 hover:cursor-pointer',
      },
      {
        condition: true,
        class:
          'flex items-center gap-2  rounded-full w-fit text-sm font-medium',
      },
    ]
      .filter(x => x.condition)
      .map(x => x.class)
      .join(' ');
  }

  @HostBinding('style.border-color')
  public get borderColor(): string {
    return this.statusDef().color;
  }
  @HostBinding('style.color')
  public get textColor(): string {
    return this.statusDef().color;
  }
}
