import { ErrorHandler, Injectable } from '@angular/core';
declare function cwr(operation: string, payload: any): void;

@Injectable({
  providedIn: 'root',
})
export class CustomErrorHandlerService implements ErrorHandler {
  handleError(error: Error): void {
    console.error(error);
    cwr('recordError', error);
  }
}
