<ui-layout-container border="false" [contentBorder]="withBorder" paddingLevel="0" level="4" [title]="title">

  <div ngProjectAs="actions"> <button [routerLink]="['new']"
      mat-stroked-button><span>{{createActionTitle}}</span></button>
  </div>
  <!-- <ng-template (attached)="setAttachedForm($event)" [cdkPortalOutlet]="portalComponent"></ng-template> -->
  <ng-container [ngComponentOutletInputs]="componentInputs()" [ngComponentOutlet]="component"></ng-container>
  @if (objs().length < 1) { <ui-layout-empty-state>
    {{emptyStateContent}}
    </ui-layout-empty-state>
    }

</ui-layout-container>