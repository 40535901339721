//mime.define({ 'image/jpeg': ['jpeg', 'jpg'] }, true);
import mimeDb from 'mime-db';

const mimiType = Object.entries(mimeDb);
export const findExt = (file: string | File | Blob) => {
  if (typeof file === 'string') {
    return (file.split('?')[0] ?? file ?? '').split('.').pop();
  } else {
    const entry = mimiType.find(
      x => x[0].toLowerCase() === file.type.toLowerCase(),
    );
    return entry?.[1].extensions?.at(0);
  }
};

/* extract Mime Type from url blob or file*/
export const contentType = (media: string | File | Blob) => {
  if (typeof media === 'string') {
    const entry = mimiType.find(x =>
      x[1]?.extensions?.includes(findExt(media) ?? ''),
    );
    return entry ? entry[0] : '';
  } else {
    return media.type;
  }
};

export const isImageType = (type: string): boolean =>
  type ? type?.split('/')[0] === 'image' : false;

export const isAnImage = (payload: string | File | Blob) =>
  isImageType(contentType(payload));
///(jpg|jpeg|png|webp|avif|gif|svg|jfif)((\?.*)$|$)/.test(url ?? '');
export const fileIcon = (url: string | File | Blob): string =>
  findIcon(contentType(url));

const findIcon = (mimeType: string): string =>
  CONTENT_TYPE_DESCRIPTION_MAP.get(mimeType ?? 'default') ?? 'file';

const CONTENT_TYPE_DESCRIPTION_MAP = new Map<string, string>()
  .set('application/msword', 'word')
  .set(
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'word',
  )
  .set(
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'word',
  )
  .set('application/vnd.ms-word.document.macroEnabled.12', 'word')
  .set('application/vnd.ms-word.template.macroEnabled.12', 'word')
  .set('vnd.ms-excel', 'excel')
  .set('application/vnd.ms-excel', 'excel')
  .set(
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'excel',
  )
  .set(
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'excel',
  )
  .set('application/vnd.ms-excel.sheet.macroEnabled.12', 'excel')
  .set('application/vnd.ms-excel.addin.macroEnabled.12', 'excel')
  .set('application/pdf', 'pdf')

  .set('text/plain', 'text')
  .set('default', 'file')
  .set('', 'file');

export const PAPER_FORMATS: Record<'value' | 'dimension', string>[] = [
  {
    value: 'A0',
    dimension: '84,1cm x 118,8cm',
  },
  {
    value: 'A1',
    dimension: '59,4cm x 84,1cm',
  },
  {
    value: 'A2',
    dimension: '42cm x 59,4cm',
  },
  {
    value: 'A3',
    dimension: '29,7cm x 42cm',
  },
  {
    value: 'A4',
    dimension: '21cm x 29,7cm',
  },
  {
    value: 'A5',
    dimension: '14,8cm x 21cm',
  },
  {
    value: 'A6',
    dimension: '10,5cm x 14,8cm',
  },
  {
    value: 'A7',
    dimension: '7,4cm x 10,5cm',
  },
  {
    value: 'A8',
    dimension: '5,2cm x 7,4cm',
  },
];

export const b64toBlob = (b64Data: string, contentType = '') => {
  const url = `data:${contentType};${b64Data}`;
  return fetch(url).then(res => res.blob());
};
