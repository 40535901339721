<mat-table [dataSource]="objs()">
  <!-- User name Definition -->
  <ng-container matColumnDef="title">
    <mat-header-cell *matHeaderCellDef i18n>title</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <a class="anchor" [routerLink]="row.id">{{row.name}}</a>
    </mat-cell>
  </ng-container>

  <!-- Age Definition -->
  <ng-container matColumnDef="working_periods">
    <mat-header-cell *matHeaderCellDef>working periods</mat-header-cell>
    <mat-cell *matCellDef="let row">{{getWorkingHours(row.working_periods)}}
    </mat-cell>
  </ng-container>

  <!-- Title Definition -->
  <ng-container matColumnDef="slot_interval">
    <mat-header-cell *matHeaderCellDef>slot interval</mat-header-cell>
    <mat-cell *matCellDef="let row"> {{getSlotIntervals(row.slot_interval)}}
    </mat-cell>
  </ng-container>

  <!-- Header and Row Declarations -->
  <mat-header-row *matHeaderRowDef="['title', 'working_periods', 'slot_interval']"></mat-header-row>
  <mat-row *matRowDef="let row; columns: ['title', 'working_periods', 'slot_interval']"></mat-row>
</mat-table>
