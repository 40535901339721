import { Pipe, PipeTransform } from '@angular/core';
import { parseDuration } from '@doctorus-front-end-monorepo/util-formatting';
import * as dateFns from 'date-fns';
import { fr } from 'date-fns/locale';

@Pipe({
  name: 'duration',
  standalone: true,
})
export class DurationPipe implements PipeTransform {
  transform(
    isoDuration: string | dateFns.Duration | null | undefined,
    format?: Array<keyof dateFns.Duration>,
  ): string {
    return isoDuration
      ? dateFns.formatDuration(
          typeof isoDuration === 'string'
            ? parseDuration(isoDuration)
            : isoDuration,
          {
            format: format,
            locale: fr,
          },
        )
      : '';
  }
}
