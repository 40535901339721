import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { Account } from '../../../../../shared/data-access/graphql/src';
import { BaseEntityDetailsComponent } from '../../../../../shared/feature-entity/src';
import { EmptyStateComponent } from '../../../../../shared/ui-layout/src';

@Component({
    selector: 'lib-ui-patient-properties',
    imports: [
        CommonModule,
        MatDividerModule,
        MatChipsModule,
        EmptyStateComponent,
        MatIconModule,
        MatButtonModule,
        RouterModule,
    ],
    templateUrl: './ui-patient-properties.component.html',
    styleUrl: './ui-patient-properties.component.scss'
})
export class UiPatientPropertiesComponent extends BaseEntityDetailsComponent<Account> {}
