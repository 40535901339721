import { NgFor, TitleCasePipe } from '@angular/common';
import { booleanAttribute, Component, input, model } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {
  AppointmentStatus,
  EnumDisplayConfig,
  STATUS_CONF,
} from '@doctorus-front-end-monorepo/shared-util';

@Component({
  selector: 'appointment-status-picker',
  templateUrl: './status-picker.component.html',
  styleUrls: ['./status-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: StatusPickerComponent,
    },
  ],
  imports: [
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    NgFor,
    TitleCasePipe,
  ],
})
export class StatusPickerComponent {
  status = model<AppointmentStatus>(AppointmentStatus.PENDING);
  disabled = input(false, { transform: booleanAttribute });

  statues = Object.entries(STATUS_CONF.appointment) as unknown as Array<
    [AppointmentStatus, EnumDisplayConfig]
  >;

  updateStatus(status: AppointmentStatus): void {
    if (status !== this.status()) {
      this.status.set(status);
    }
  }
}
