import {
  AsyncPipe,
  CommonModule,
  NgFor,
  NgIf,
  TitleCasePipe,
} from '@angular/common';
import {
  Component,
  Injector,
  OnDestroy,
  computed,
  inject,
  input,
  model,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import {
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import {
  AppointmentsListComponent,
  MedicalRecordAppointmentDetailsComponent,
} from '@doctorus-front-end-monorepo/feature-appointment';
import {
  EntityConfig,
  EntityMutationService,
} from '@doctorus-front-end-monorepo/feature-entity';
import { MedicalHistoryListComponent } from '@doctorus-front-end-monorepo/feature-medical-history';
import {
  PatientDetailsBaseComponent,
  PatientFetchService,
  WritePatientPropertiesComponent,
} from '@doctorus-front-end-monorepo/feature-patient';
import {
  Account,
  AppointmentPublicFieldsFragment,
  GetPatientMedicalRecordGQL,
  Patient,
  PutPatientMedicalPropertiesGQL,
} from '@doctorus-front-end-monorepo/graphql';
import {
  ContainerComponent,
  EmptyStateComponent,
  KeyValueComponent,
  NavLink,
} from '@doctorus-front-end-monorepo/ui-layout';
import { Observable, filter, map, switchMap, tap } from 'rxjs';
import { AgePipe, HumanNamePipe } from '@doctorus-front-end-monorepo/shared-util';
import { CoalescePipe } from '@doctorus-front-end-monorepo/util-formatting';
import { DurationPipe } from '@doctorus-front-end-monorepo/util-time';
import { MedicalDocumentsListComponent } from '../../../medical-documents-list/medical-documents-list.component';
import { PatientPaymentListComponent } from '../../../patient-payment/components/patient-payment-list/patient-payment-list.component';
import { AdjustableContainerComponent } from '../../../shared/components/adjustable-container/adjustable-container.component';
import { MedicalTimelineComponent } from '../medical-timeline/medical-timeline.component';

@Component({
    selector: 'doctorus-front-end-monorepo-medical-record',
    templateUrl: './medical-record.component.html',
    styleUrls: ['./medical-record.component.scss'],
    providers: [
        {
            provide: PatientFetchService,
            useExisting: GetPatientMedicalRecordGQL,
        },
    ],
    imports: [
        NgIf,
        CommonModule,
        MatExpansionModule,
        AdjustableContainerComponent,
        HumanNamePipe,
        MatDividerModule,
        ContainerComponent,
        CoalescePipe,
        MedicalHistoryListComponent,
        MatTabsModule,
        AppointmentsListComponent,
        PatientPaymentListComponent,
        EmptyStateComponent,
        KeyValueComponent,
        MedicalRecordAppointmentDetailsComponent,
        MedicalTimelineComponent,
        MedicalDocumentsListComponent,
        MatIconModule,
        AgePipe,
        DurationPipe,
        RouterLink,
        MatButtonModule,
        RouterOutlet,
        NgFor,
        RouterLinkActive,
        AsyncPipe,
        TitleCasePipe,
    ]
})
export class MedicalRecordComponent
  extends PatientDetailsBaseComponent
  implements OnDestroy
{
  account = input<Account>();
  initAppointmentId = model<string>();
  loading = signal(false);
  detailsActive$: Observable<boolean>;
  outletHeight = 350;

  links: NavLink[] = [
    {
      name: $localize`summary`,
      routerLink: ['summary'],
      active: true,
    },
    {
      name: $localize`documents`,
      routerLink: ['documents'],
      active: true,
    },
    {
      name: $localize`measures`,
      routerLink: ['measures'],
      active: true,
    },
    {
      name: $localize`care plans`,
      routerLink: ['care-plans'],
      active: true,
    },
    {
      name: $localize`medical notes`,
      routerLink: ['medical-notes'],
      active: true,
    },
  ];
  medicalPropertiesInjector = Injector.create({
    providers: [
      {
        provide: EntityMutationService,
        useClass: PutPatientMedicalPropertiesGQL,
      },
    ],
    parent: this.injector,
  });
  selectedAppointment = signal<AppointmentPublicFieldsFragment | undefined>(
    undefined,
  );
  private router = inject(Router);
  private getPatientMedicalRecordGQL = inject(GetPatientMedicalRecordGQL);
  patient$ = this.route.paramMap.pipe(
    map(x => x.get('patId')),
    filter(id => (id ? true : false)),
    switchMap(
      id =>
        this.getPatientMedicalRecordGQL.watch(
          { id },
          { notifyOnNetworkStatusChange: true },
        ).valueChanges,
    ),
    tap(x => {
      this.loading.set(x.loading);
    }),
    filter(x => x.loading === false),
    tap(x => {
      this.selectAppointment(
        x.data.getPatient.appointments.find(
          x =>
            x.id === (this.selectedAppointment()?.id ?? this.initAppointmentId),
        ),
      );
    }),
    map(x => x.data.getPatient),
  );

  uncheckedMedicalHistory = computed(
    () =>
      this.patient().medicalHistoriesSurvey.filter(el => !el.checked).length >
      0,
  );

  get navContainerStyle(): string {
    return this.selectedAppointment()
      ? `calc( 100% - ${this.outletHeight + 20}px)`
      : '100%';
  }

  selectAppointment(appointment?: AppointmentPublicFieldsFragment): void {
    this.selectedAppointment.set(appointment);
    if (!appointment) {
      this.router.navigate(['.'], {
        queryParams: { initAppointmentId: null },
        relativeTo: this.route,
      });
    }
  }
  ngOnDestroy(): void {
    this?.subscription.unsubscribe();
  }

  initMedicalProperties = computed(() =>
    this.account()
      ?.patientPropertyModels?.filter(
        _prop =>
          _prop.is_medical &&
          !this.patient()
            ?.medical_properties?.map(x => x.key.toLowerCase())
            .includes(_prop.key.toLowerCase()),
      )
      .map(_prop => ({
        key: _prop.key,
        value: null,
      }))
      .concat(this.patient()?.medical_properties ?? []),
  );

  editMedicalProperties(
    patient: Pick<Patient, 'id' | 'medical_properties'>,
  ): void {
    this.sds
      .openSlideOutEntityWriteForm(
        {
          cmp: WritePatientPropertiesComponent,
          entity: patient,
          extra: {
            init: this.initMedicalProperties(),
          },
          entityConfig: new EntityConfig({
            feature: $localize`patient medical properties`,
            messages: {
              title: {
                edit: $localize`update patient medical properties`,
              },
              action: {
                updated: {
                  success: $localize`patient medical properties sucessufull updated`,
                },
              },
            },
          }),
        },
        this.medicalPropertiesInjector,
      )
      .subscribe();
  }
}
