import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import {
  MedicalHistory,
  MedicalHistoryStatusInput,
} from '@doctorus-front-end-monorepo/graphql';
import { MedicalHistoryStatus } from '@doctorus-front-end-monorepo/shared-util';
import { QuillViewComponent } from 'ngx-quill';

@Component({
  selector: 'medical-history-medical-history-status-dialog',
  templateUrl: './medical-history-status-dialog.component.html',
  styleUrls: ['./medical-history-status-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatDialogModule, QuillViewComponent, MatButtonModule],
})
export class MedicalHistoryStatusDialogComponent {
  get expiredStatus() {
    return this.data.medicalHistory.status === MedicalHistoryStatus.EXPIRED;
  }
  action = this.expiredStatus ? $localize`enable` : $localize`disable`;
  payload: MedicalHistoryStatusInput = {
    id: this.data.medicalHistory.id,
    status: this.expiredStatus
      ? MedicalHistoryStatus.CURRENT
      : MedicalHistoryStatus.EXPIRED,
  };
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { medicalHistory: MedicalHistory },
  ) {}
}
