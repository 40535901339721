@if (form) {
<form class="flex flex-col gap-6" [formGroup]="form">
  <mat-form-field uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>name</mat-label>
    <input formControlName="name" matInput type="text">
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n>fees</mat-label>
    <input min="0" step="5" formControlName="default_fees" matInput type="number">
    <mat-icon matPrefix>payments</mat-icon>
  </mat-form-field>
  <mat-form-field appearance="outline" uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>duration</mat-label>
    <mat-icon matPrefix>timer</mat-icon>

    <mat-select formControlName="default_duration">
      <mat-option [value]="duration.toString()" *ngFor="let duration of durations">
        {{ duration |duration}}
      </mat-option>
    </mat-select>
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline" uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>color</mat-label>
    <mat-icon matPrefix>palette</mat-icon>
    <input matInput type="color" formControlName="color">
    <mat-error></mat-error>
  </mat-form-field>
</form>
}