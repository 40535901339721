import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { Account } from '../../../../../../shared/data-access/graphql/src';
import { BaseEntityDetailsComponent } from '../../../../../../shared/feature-entity/src';
import {
  ContainerComponent,
  GroupHeaderComponent,
  KeyValueComponent,
  ViewToggleContainerComponent,
  ViewToggleDirective,
} from '../../../../../../shared/ui-layout/src';
import { CountryInfoPipe } from '../../../../../../shared/util-country/src';
import { CoalescePipe } from '../../../../../../shared/util-formatting/src';
import { TimezoneOffsetPipe } from '../../../../../../shared/util-time/src';

@Component({
  selector: 'account-settings-forms-preferences',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    ReactiveFormsModule,
    ContainerComponent,
    ViewToggleContainerComponent,
    ViewToggleDirective,
    CoalescePipe,
    MatFormFieldModule,
    CountryInfoPipe,
    TimezoneOffsetPipe,
    KeyValueComponent,
    GroupHeaderComponent,
    MatSelectModule,
    MatGridListModule,
  ],
  templateUrl: './forms-preferences.component.html',
  styleUrl: './forms-preferences.component.scss',
})
export class FormsPreferencesComponent extends BaseEntityDetailsComponent<Account> {
  // account = model.required<Account>();
  // timeZones = Intl.supportedValuesOf('timeZone');
  // updateAccountSettingsGql = inject(UpdatePreferencesGQL);
  // matSnackBar = inject(MatSnackBar);
  // fb = inject(FormBuilder);
  // form = this.fb.group({
  //   defaultTimeZone: ['', [Validators.required]],
  // });
  // constructor() {
  //   this.account$
  //     .pipe(first())
  //     .pipe(
  //       tap(x => {
  //         console.warn(x);
  //         this.form.patchValue(
  //           {
  //             defaultTimeZone: x?,
  //           },
  //           { emitEvent: false },
  //         );
  //       }),
  //       switchMap(x =>
  //         this.form.valueChanges.pipe(
  //           debounceTime(200),
  //           switchMap(x =>
  //             this.updateAccountSettingsGql.mutate({
  //               payload: x,
  //             }),
  //           ),
  //           tap(() =>
  //             this.matSnackBar.open(
  //               $localize`appointment preferences updated`,
  //               undefined,
  //               {
  //                 duration: 5000,
  //               },
  //             ),
  //           ),
  //         ),
  //       ),
  //     )
  //     .subscribe();
  // }
}
