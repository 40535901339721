import {
  Directive,
  OnDestroy,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountDataService } from './account-data.service';

@Directive({
  selector: '[authShowForMultiLocations]',
  standalone: true
})
export class ShowForMultiLocationsDirective implements OnDestroy {
  private hasView = false;
  private subscription = new Subscription();
  constructor(
    private ads: AccountDataService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
    this.subscription.add(
      this.ads.hasMultipleLocations$.subscribe((x) => {
        if (x && !this.hasView) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
        } else if (!x && this.hasView) {
          this.viewContainer.clear();
          this.hasView = false;
        }
      })
    );
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
