<mat-sidenav-container class="h-full">
  @if(largeSideNav$|async) {
  <mat-sidenav [opened]="sideMenuOpen|async" mode="over" fixedTopGap="64" fixedInViewport>
    <header class="flex justify-between p-2 py-4 items-start">
      <div>
        <h2 class="truncate">{{account().alias}}</h2>
        <!-- <mat-chip-set class="mat-chip-xs text-xs">
          @for (role of roles; track $index) {
          <mat-chip>{{role}}</mat-chip>
          }
        </mat-chip-set> -->

      </div>
      <button (click)="toggle()" mat-icon-button><mat-icon>close</mat-icon></button>

    </header>
    <mat-divider></mat-divider>
    <nav class="flex flex-col items-center  gap-4">
      @for (link of navLinks; track $index) {
      <a *showForRoles="link.requiredRoles" [matTooltip]="link.name" matTooltipPosition="right"
        class="w-full rounded-sm hover:bg-gray-100 flex gap-4 p-2" [routerLink]="link.routerLink"
        [routerLinkActive]="['bg-gray-200','hover:bg-gray-200']">
        <mat-icon>{{link.svgIcon}}</mat-icon>
        <span>{{link.name|titlecase}}</span>
      </a>
      }
    </nav>


  </mat-sidenav>
  }
  @else {
  <mat-sidenav mode="side" fixedTopGap="64" class="w-24 border-r-gray-100 border-r bg-gray-50" fixedInViewport opened>
    <nav class="flex flex-col items-center  gap-4 pt-4 ">
      @for (link of navLinks; track $index) {
      <a *showForRoles="link.requiredRoles" [matTooltip]="link.name" matTooltipPosition="right"
        class="w-full rounded-full hover:bg-blue-100 flex justify-center py-2" [routerLink]="link.routerLink"
        [routerLinkActive]="['bg-blue-200','hover:bg-blue-200']">
        <mat-icon>{{link.svgIcon}}</mat-icon>
      </a>
      }
    </nav>


  </mat-sidenav>
  }

  <mat-sidenav-content>

    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
