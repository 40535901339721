import { CommonModule } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterModule } from '@angular/router';
import {
  GetPatientsByMedicalNotesFilterGQL,
  PatientsMedicalNotesFilterInput,
} from '@doctorus-front-end-monorepo/graphql';
import { HumanNamePipe } from '@doctorus-front-end-monorepo/shared-util';
import {
  EmptyStateComponent,
  LoadingComponent,
} from '@doctorus-front-end-monorepo/ui-layout';
import { first, map, of, tap } from 'rxjs';
import { PatientsListBaseComponent } from '../patient-list.base.component';

@Component({
  selector: 'doctorus-front-end-monorepo-patients-notes-explorer',
  imports: [
    CommonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    HumanNamePipe,
    LoadingComponent,
    ReactiveFormsModule,
    MatPaginatorModule,
    EmptyStateComponent,
    RouterModule,
  ],
  templateUrl: './patients-notes-explorer.component.html',
  styleUrl: './patients-notes-explorer.component.scss',
})
export class PatientsNotesExplorerComponent extends PatientsListBaseComponent {
  queryParams = computed<PatientsMedicalNotesFilterInput>(() => ({
    commons: {
      page_size: this.pageSize(),
      ...(!!this.pageIndex() && { page: this.pageIndex() + 1 }),
    },
    medical_notes__in: this.searchTerm(),
  }));

  patientListGql = inject(GetPatientsByMedicalNotesFilterGQL);
  patientsRessource = rxResource({
    request: this.queryParams,
    loader: params =>
      this.searchTerm()
        ? this.patientListGql
            .fetch(
              {
                payload: params.request,
              },
              { fetchPolicy: this.fetchPolicy() },
            )
            .pipe(
              first(),
              tap(res => {
                this.count.set(
                  res.data.getPatientsByMedicalNotesFilter?.count ?? 0,
                );
                this.fetchPolicy.set('cache-first');
              }),
              map(res => res.data.getPatientsByMedicalNotesFilter?.results),
            )
        : of([]),
  });
}
