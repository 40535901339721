<form [formGroup]="form">
  <mat-form-field class="w-full" uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>appointment fees</mat-label>
    <input
      #input
      type="number"
      min="0"
      [step]="10"
      matInput
      formControlName="fees"
    />
    <mat-error></mat-error>
    <mat-icon (click)="clear()" matSuffix>close</mat-icon>
  </mat-form-field>
  <mat-chip-listbox>
    <mat-chip-option
      (selectionChange)="updateTextField($event)"
      [value]="option"
      *ngFor="let option of extra().suggestions"
      >{{ option }}</mat-chip-option
    >
    <mat-chip-option
      i18n
      (selectionChange)="updateTextField($event)"
      [value]="0"
      >free</mat-chip-option
    >
  </mat-chip-listbox>
</form>
