import { Pipe, PipeTransform } from '@angular/core';
import { parseDuration } from '@doctorus-front-end-monorepo/util-formatting';
import { Duration } from '@fullcalendar/core';
import * as dateFns from 'date-fns';
import { fr } from 'date-fns/locale';

@Pipe({
  name: 'duration',
  standalone: true
})
export class DurationPipe implements PipeTransform {
  transform(isoDuration: string|Duration): string {
    return dateFns.formatDuration(typeof isoDuration ==='string' ? parseDuration(isoDuration):isoDuration, {
      locale: fr
    });
  }
}
