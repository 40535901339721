<ng-template #navContent>
  @if (isDoctor) {
    @if (currentMedicalHistory().size > 0) {
      <dl class="p-4 border-b border-b-gray-200">
        @for (item of currentMedicalHistory() | keyvalue; track $index) {
          <dt class="text-gray-800 font-medium">{{ item.key }}</dt>
          <ul class="text-gray-700">
            @for (entry of item.value; track $index) {
              <li>
                <quill-view-html [content]="entry.content"></quill-view-html>
              </li>
            }
          </ul>
        }
      </dl>
    }
  }
  <ui-layout-nav-tree
    [authUserRoles]="roles$ | async"
    [navLinks]="generalRoutes()"
  ></ui-layout-nav-tree>
</ng-template>

@if (!patient()) {
  <ui-layout-loading></ui-layout-loading>
} @else {
  @if (isLarge$ | async) {
    <mat-sidenav-container>
      <mat-sidenav
        class="max-w-xs border-r border-r-gray-200"
        mode="side"
        opened
        fixedInViewport
        fixedTopGap="116"
      >
        <h2
          [routerLink]="['.']"
          class="p-4 border-b border-b-gray-200 hover:cursor-pointer"
        >
          {{ patient() | humanName: 'full' : true }}
        </h2>
        <!-- @if (feeds().length > 0) {
          <div class="px-2 flex p-2 gap-2 flex-col border-b border-b-gray-200">
            @for (
              feed of feeds();
              track $index;
              let idx = $index, last = $last
            ) {
              <ui-layout-feedback
                removable="true"
                withIcon="false"
                [type]="feed.type"
                [pattern]="2"
                [message]="feed.message"
              ></ui-layout-feedback>
            }
          </div>
        } -->
        <ng-container [ngTemplateOutlet]="navContent"></ng-container>
      </mat-sidenav>
      <mat-sidenav-content>
        <div class="container pt-4 sidenav-content">
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  } @else {
    <mat-toolbar>
      <button (click)="toggleBottomSheet()" mat-icon-button>
        <mat-icon>menu</mat-icon>
      </button>

      <a [routerLink]="['.']" class="truncate">
        {{ patient() | humanName: 'full' : true }}
      </a>
    </mat-toolbar>

    <!-- @if (feeds().length > 0) {
      <div class="px-2 flex p-2 gap-2 flex-col border-b border-b-gray-200">
        @for (feed of feeds(); track $index) {
          <ui-layout-feedback
            removable="true"
            withIcon="false"
            [type]="feed.type"
            [pattern]="2"
            [message]="feed.message"
          ></ui-layout-feedback>
        }
      </div>
    } -->
    <main class="container">
      <router-outlet></router-outlet>
    </main>
  }
}
