import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'titleCaseFirst',
    standalone: true,
})
export class TitleCaseFirstPipe implements PipeTransform {
  transform(value: string | null | undefined): string | null | undefined {
    if (!value) return value;
    return value[0].toUpperCase() + value.substring(1).toLowerCase();
  }
}
