<form class="max-w-screen-md flex flex-col gap-4" [formGroup]="form">
  <mat-form-field uiFormMatFormFieldErrorDisplayer class="w-full">
    <mat-label i18n>type</mat-label>
    <input type="text" matInput formControlName="type">
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label i18n>order</mat-label>
    <input type="number" min="0" matInput formControlName="order">
    <mat-error></mat-error>
    <mat-hint i18n>medical history ordering in the patient medical record</mat-hint>
  </mat-form-field>
</form>
