import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PrintLayout } from '@doctorus-front-end-monorepo/graphql';

@Injectable({
  providedIn: 'root',
})
export class PdfConfigFormBuilderService {
  constructor(private fb: FormBuilder) {}
  createForm = (payload?: PrintLayout): FormGroup =>
    this.fb.group({
      top_margin: [
        payload?.topMargin ?? 3,
        [Validators.required, Validators.max(10), Validators.min(0)],
      ],
      bottom_margin: [
        payload?.bottomMargin ?? 3,
        [Validators.required, Validators.max(10), Validators.min(0)],
      ],
      right_margin: [
        payload?.rightMargin ?? 3,
        [Validators.required, Validators.max(10), Validators.min(0)],
      ],
      left_margin: [
        payload?.leftMargin ?? 3,
        [Validators.required, Validators.max(10), Validators.min(0)],
      ],
      paper_format: [payload?.paperFormat ?? 'A5', Validators.required],
    });
}
