<ui-layout-container title="general settings" level="4" i18n-title>
  <div ngProjectAs="actions"> <button (click)="updateSettings()" mat-stroked-button><span i18n>edit</span></button>
  </div>

  <main class="grid grid-cols-2 gap-4">
    <ui-layout-key-value key="account alias" i18n-key>
      {{account().alias}}
    </ui-layout-key-value>
    <ui-layout-key-value key="currency" i18n-key>
      {{account().currency}}
    </ui-layout-key-value>
  </main>


</ui-layout-container>

<!-- <section class="rounded-md border-gray-200 p-4 border">
  <section class="flex justify-between items-center">
    <h2 class="first-letter:uppercase">account preferences</h2>
    <button mat-stroked-button><span i18n>edit</span></button>

  </section>
  <mat-grid-list cols="3" rowHeight="3:1">
    <mat-grid-tile> <ui-layout-key-value key="account alias" i18n-key>
        {{account().alias}}
      </ui-layout-key-value></mat-grid-tile>

  </mat-grid-list>

</section> -->