<form class="flex flex-col gap-6" matDialogContent [formGroup]="form">
  <mat-form-field uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>name</mat-label>
    <input type="text" formControlName="name" matInput>
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>measure unit</mat-label>
    <input type="text" formControlName="unit" matInput>
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n>description</mat-label>
    <textarea matInput formControlName="description" cols="30" rows="5"></textarea>
  </mat-form-field>
</form>
