@if ((breadcrumbs$|async);as breadcrumbs ) {
@if(breadcrumbs.length > 1) {
<ui-layout-breadcrumb class="p-4" [breadcrumbs]="breadcrumbs"></ui-layout-breadcrumb>

}
}
<div class="p-4">
  <router-outlet></router-outlet>

</div>
