import { DIALOG_DATA, DialogModule, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, Inject, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { UploadDocument } from '@doctorus-front-end-monorepo/graphql';
import { ImageZoomComponent } from '@doctorus-front-end-monorepo/util-document';
import { CoalescePipe } from '@doctorus-front-end-monorepo/util-formatting';

@Component({
    selector: 'uploaded-document-images-explorer',
    imports: [
        CommonModule,
        DialogModule,
        MatIconModule,
        MatButtonModule,
        ImageZoomComponent,
        CoalescePipe,
    ],
    templateUrl: './images-explorer.component.html',
    styleUrl: './images-explorer.component.scss'
})
export class ImagesExplorerComponent {
  selected = signal(this.data.selected);
  dialogRef = inject(DialogRef);
  constructor(
    @Inject(DIALOG_DATA)
    public data: {
      images: UploadDocument[];
      selected: UploadDocument;
    }
  ) {}
  select(image: UploadDocument) {
    this.selected.set(image);
  }

  close = () => this.dialogRef.close();
}
