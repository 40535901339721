import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import {
  MedicalHistory,
  MedicalHistoryStatusInput,
} from '@doctorus-front-end-monorepo/graphql';
import { QuillViewComponent } from 'ngx-quill';

@Component({
    selector: 'medical-history-medical-history-status-dialog',
    templateUrl: './medical-history-status-dialog.component.html',
    styleUrls: ['./medical-history-status-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatDialogModule, QuillViewComponent, MatButtonModule]
})
export class MedicalHistoryStatusDialogComponent {
  action = this.data.medicalHistory.is_current
    ? $localize`disable`
    : $localize`enable`;
  payload: MedicalHistoryStatusInput = {
    id: this.data.medicalHistory.id,
    is_current: !this.data.medicalHistory.is_current,
  };
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { medicalHistory: MedicalHistory }
  ) {}
}
