<mat-table class="bg-white" [dataSource]="objs()">
  <!-- User name Definition -->
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef i18n>name</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <a class="anchor" [routerLink]="row.id">{{row.name}}</a>
    </mat-cell>
  </ng-container>

  <!-- Age Definition -->
  <ng-container matColumnDef="documentTitle">
    <mat-header-cell *matHeaderCellDef i18n>document title</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.documentTitle}}
    </mat-cell>
  </ng-container>


  <!-- Header and Row Declarations -->
  <mat-header-row *matHeaderRowDef="fields"></mat-header-row>
  <mat-row *matRowDef="let row; columns: fields"></mat-row>
</mat-table>
