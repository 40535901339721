<h2 matDialogTitle i18n>edit profile</h2>
<form matDialogContent [formGroup]="userForm" class="flex flex-col gap-8 pt-4">
  <mat-form-field doctorusFrontEndMonorepoearance="outline" uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>first name</mat-label>
    <input matInput required autocomplete="given-name" formControlName="given_name" matInput>
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field doctorusFrontEndMonorepoearance="outline" uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>last name</mat-label>
    <input matInput required autocomplete="family-name" formControlName="family_name" matInput>
    <mat-error></mat-error>
  </mat-form-field>

  <mat-form-field class="col-span-3" uiFormMatFormFieldErrorDisplayer>
    <shared-ui-phone-number-picker #picker="phonePicker" formControlName="phone_number">
    </shared-ui-phone-number-picker>
    <mat-hint *ngIf="picker.exampleNumber"><span class="mr-1" i18n>eg
        :</span>{{picker.exampleNumber}}</mat-hint>

    <mat-error></mat-error>
  </mat-form-field>
  <!-- <mat-form-field *ngIf="offices$|async|arrayExist" appearance="outline" formGroupName="user_metadata">
        <mat-label i18n>default office</mat-label>
        <mat-select formControlName="default_account">
            <mat-option [value]="office.id" *ngFor="let office of offices$|async">
                {{office.name}}
            </mat-option>
        </mat-select>
    </mat-form-field> -->

  <footer mat-dialog-actions align="end" class="gap-4">
    <button matDialogClose mat-stroked-button>
      <span i18n>cancel</span>
    </button>
    <button type="submit" (click)="submit()" color="primary" mat-flat-button>
      <span i18n>edit</span>
    </button>

  </footer>
