import { Pipe, PipeTransform } from '@angular/core';
import { ArrayFilterElement } from './types';

@Pipe({
  name: 'arrayFind',
  pure: true,
  standalone: true
})
export class ArrayFindPipe implements PipeTransform {
  transform<T>(
    array: any[] | null | undefined,
    filters: ArrayFilterElement[]
  ): any | undefined {
    return array?.find((val) =>
      filters.every((filter) => {
        const props = filter.prop.split('.');
        const compareFn = filter.compareFn ?? ((a, b) => a === b);
        const _val = props
          ? props.reduce((acc: any, current: string) => acc[current], val)
          : val;
        return compareFn(_val, filter.value);
      })
    );
  }
}
