<main class="grid grid-cols-3 gap-4">
  <ui-layout-key-value key="category" i18n-key>
    {{ obj().name }}
  </ui-layout-key-value>
  <ui-layout-key-value key="date" i18n-key>
    {{ obj().date | date }}
  </ui-layout-key-value>
  <ui-layout-key-value key="status" i18n-key>
    <doctorus-front-end-monorepo-status
      entity="medicalHistory"
      [status]="obj().status"
    ></doctorus-front-end-monorepo-status>
  </ui-layout-key-value>

  <ui-layout-key-value key="description" i18n-key>
    <quill-view-html [content]="obj().content"></quill-view-html>
  </ui-layout-key-value>
</main>
