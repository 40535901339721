<ui-layout-container border="false" level=" 1" title="membership">
  <ui-layout-container level="2" title="ownership">
    <button
      (click)="updateOwnership()"
      mat-stroked-button
      ngProjectAs="actions"
    >
      <span i18n>edit</span>
    </button>
    <ui-layout-key-value key="account owner" i18n-key>
      <div class="flex">
        <span>{{ account().owner | humanName: 'full' }}</span>
        <span>({{ account().owner.email }})</span>
      </div>
    </ui-layout-key-value>
  </ui-layout-container>

  <ui-layout-container level="2" title="members list" i18n-title>
    <button
      ngProjectAs="actions"
      mat-stroked-button
      (click)="createMembership()"
    >
      <span i18n>new membership</span>
    </button>

    <ul class="grid grid-cols-1 gap-12">
      @for (
        membership of account().memberships;
        track $index;
        let last = $last
      ) {
        <li class="flex gap-4 flex-col">
          <div class="flex gap-4 items-center">
            <img
              class="w-14 h-14 rounded-full"
              doctorusFrontEndMonorepoAuthAvatarLoader
              [user]="$any(membership.user)"
            />
            <div>
              <h3>{{ membership.user | humanName: 'full' }}</h3>
              <a
                class="break-words anchor text-gray-700"
                [href]="'mailto:' + membership.user?.email"
                >{{ membership.user.email }}</a
              >
            </div>
            <button
              [matMenuTriggerData]="{ membership }"
              [matMenuTriggerFor]="menu"
              class="ml-auto"
              mat-icon-button
            >
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>

          <mat-chip-set>
            @if (account().owner.sub === membership.user.sub) {
              <mat-chip class="uppercase" i18n>owner</mat-chip>
            }
            @for (item of membership.roles; track $index) {
              <mat-chip>{{ item }}</mat-chip>
            }
          </mat-chip-set>

          <!-- <div class="flex gap-2 self-start">
          <button (click)="deleteMembership(membership)" mat-stroked-button><span>delete</span></button>
          <button (click)="editMembership(membership)" mat-stroked-button><span>edit</span></button>
        </div> -->
        </li>
      }
    </ul>
  </ui-layout-container>
</ui-layout-container>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-membership="membership">
    <button (click)="deleteMembership(membership)" mat-menu-item>
      <mat-icon>delete</mat-icon><span i18n>delete</span>
    </button>
    <button (click)="editMembership(membership)" mat-menu-item>
      <mat-icon>edit</mat-icon><span i18n>edit</span>
    </button>
  </ng-template>
</mat-menu>
