@let appointments = appointmentsRessource.value();
@let isLoading = appointmentsRessource.isLoading();
<mat-toolbar>
  <mat-toolbar-row class="flex justify-between">
    <section class="flex items-center gap-2">
      <button mat-icon-button (click)="drawer.toggle()">
        <mat-icon
          [matBadge]="schedulerFilterCount()"
          [matBadgeHidden]="schedulerFilterCount() === 0"
          matBadgecolor="tertiary"
          >filter_alt</mat-icon
        >
      </button>

      <button mat-icon-button (click)="today()">
        <mat-icon>today</mat-icon>
      </button>
      <ng-container>
        <button
          *ngIf="(isSmall$ | async) === false"
          (click)="navigate('back')"
          mat-icon-button
        >
          <mat-icon>navigate_before</mat-icon>
        </button>
        <span>{{
          selectedDate() | date: calendarView().dateFormat | uppercase
        }}</span>
        <button
          *ngIf="(isSmall$ | async) === false"
          (click)="navigate('next')"
          mat-icon-button
        >
          <mat-icon>navigate_next</mat-icon>
        </button>
      </ng-container>
    </section>
    <section class="ml-auto flex items-center">
      <ng-container *ngIf="isSmall$ | async; else inline">
        <button [matMenuTriggerFor]="calendarViewMenu" mat-button>
          <mat-icon>{{ calendarView().matIcon }}</mat-icon>
          <span>{{ calendarView().shortLabel | titlecase }}</span>
        </button>
        <mat-menu #calendarViewMenu="matMenu">
          <button
            mat-menu-item
            *ngFor="let view of calendarViews"
            (click)="switchView(view)"
          >
            <mat-icon>{{ view.matIcon }}</mat-icon>
            <span>{{ view.label | titlecase }}</span>
          </button>
        </mat-menu>
      </ng-container>

      <button (click)="writeAppointment()" class="ml-4" mat-mini-fab>
        <mat-icon>edit_calendar</mat-icon>
      </button>
    </section>
  </mat-toolbar-row>
  <ng-template #inline>
    <section class="flex items-center">
      <button
        mat-button
        *ngFor="let view of calendarViews"
        (click)="switchView(view)"
      >
        <mat-icon>{{ view.matIcon }}</mat-icon>
        <span>{{
          ((isSmall$ | async) ? view.shortLabel : view.label) | titlecase
        }}</span>
      </button>
    </section>
  </ng-template>
</mat-toolbar>
<mat-drawer-container>
  <mat-drawer
    [opened]="(isSmall$ | async) === false"
    [(opened)]="opened"
    class="w-full max-w-xs pr-4 bg-white"
    [mode]="(isSmall$ | async) ? 'over' : 'side'"
    #drawer
  >
    <medical-space-web-scheduler-filter
      [(filterChanged)]="schedulerFilter"
      (dateChanged)="changeDate($event)"
      [selectedDate]="selectedDate()"
    ></medical-space-web-scheduler-filter>
  </mat-drawer>
  <mat-drawer-content
    appGestureEventExtension
    (swipeleft)="navigate('back')"
    (swiperight)="navigate('next')"
  >
    @if (isLoading) {
      <mat-progress-bar
        class="absolute top-0"
        mode="indeterminate"
      ></mat-progress-bar>
    }

    <medical-space-web-calendar
      (eventUiEdit)="putEvent($event)"
      (eventClick)="openEventDetails($event)"
      (dateClick)="openAppointmentWriteForm($event)"
      #calendar="calendar"
      [date]="selectedDate()"
      (dateChange)="toDate($event)"
      [(view)]="calendarView"
      [appointments]="appointments"
    ></medical-space-web-calendar>
  </mat-drawer-content>
</mat-drawer-container>
