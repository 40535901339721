import * as i0 from '@angular/core';
import { EventEmitter, Component, Input, Output, NgModule } from '@angular/core';
import { dispatchEditorEvents, appendEditor, openEditor, overlayEditor } from '@pqina/pintura';
const _c0 = ["*"];
class PinturaEditorAbstractComponent {
  constructor(element, zone) {
    this.unsubs = [];
    // A shortcut to assign multiple props at once
    this.options = undefined;
    // Events
    this.init = new EventEmitter();
    this.loadstart = new EventEmitter();
    this.loadabort = new EventEmitter();
    this.loaderror = new EventEmitter();
    this.loadprogress = new EventEmitter();
    this.load = new EventEmitter();
    this.processstart = new EventEmitter();
    this.processabort = new EventEmitter();
    this.processerror = new EventEmitter();
    this.processprogress = new EventEmitter();
    this.process = new EventEmitter();
    this.update = new EventEmitter();
    this.undo = new EventEmitter();
    this.redo = new EventEmitter();
    this.revert = new EventEmitter();
    this.writehistory = new EventEmitter();
    this.destroy = new EventEmitter();
    this.show = new EventEmitter();
    this.hide = new EventEmitter();
    this.close = new EventEmitter();
    this.ready = new EventEmitter();
    this.loadpreview = new EventEmitter();
    this.addshape = new EventEmitter();
    this.selectshape = new EventEmitter();
    this.updateshape = new EventEmitter();
    this.removeshape = new EventEmitter();
    this.markuptap = new EventEmitter();
    this.markupzoom = new EventEmitter();
    this.markuppan = new EventEmitter();
    this.zoom = new EventEmitter();
    this.pan = new EventEmitter();
    this.selectstyle = new EventEmitter();
    this.selectutil = new EventEmitter();
    this.selectcontrol = new EventEmitter();
    // EventHandlerNonNull
    this.routeEvent = e => {
      // @ts-ignore
      const emitter = this[e.type.split(":")[1]];
      if (!emitter) return;
      emitter.emit(e.detail);
    };
    this._initialChanges = {};
    this.element = element;
    this.zone = zone;
  }
  get history() {
    return this.editor?.history;
  }
  get imageSize() {
    return this.editor?.imageSize;
  }
  get imageAspectRatio() {
    return this.editor?.imageAspectRatio;
  }
  get imageCropSize() {
    return this.editor?.imageCropSize;
  }
  get imageCropRectAspectRatio() {
    return this.editor?.imageCropRectAspectRatio;
  }
  get imageFile() {
    return this.editor?.imageFile;
  }
  get imageLoadState() {
    return this.editor?.imageLoadState;
  }
  get imageProcessState() {
    return this.editor?.imageProcessState;
  }
  get imageRotationRange() {
    return this.editor?.imageRotationRange;
  }
  // map functions
  loadImage(src, options) {
    return this.editor?.loadImage(src, options);
  }
  editImage(src, options) {
    return this.editor?.editImage(src, options);
  }
  updateImage(src) {
    return this.editor?.updateImage(src);
  }
  updateImagePreview(src) {
    return this.editor?.updateImagePreview(src);
  }
  abortLoadImage() {
    return this.editor?.abortLoadImage();
  }
  removeImage() {
    return this.editor?.removeImage();
  }
  processImage(src, options) {
    return this.editor?.processImage(src, options);
  }
  abortProcessImage() {
    return this.editor?.abortProcessImage();
  }
  closeEditor() {
    return this.editor?.close();
  }
  initEditor(element, props) {
    // @ts-ignore
    return;
  }
  ngAfterViewInit() {
    this.element.nativeElement.classList.add("PinturaRootWrapper");
    // will block angular from listening to events inside the editor
    this.zone.runOutsideAngular(() => {
      // create editor instance
      this.editor = this.initEditor(this.element.nativeElement, Object.assign({},
      // deprecated options object
      this.options,
      // new changes object
      this._initialChanges));
      // route events to component native element
      this.unsubs = dispatchEditorEvents(this.editor, this.element.nativeElement);
    });
    // route events
    Object.keys(this)
    // @ts-ignore
    .filter(key => this[key] instanceof EventEmitter).forEach(key => {
      this.element.nativeElement.addEventListener(`pintura:${key}`, this.routeEvent);
    });
  }
  ngOnChanges(changes) {
    // turn into options object ready to be assigned to editor
    const options = Object.entries(changes).reduce((options, [prop, change]) => {
      // @ts-ignore
      options[prop] = change.currentValue;
      return options;
    }, {});
    // no editor yet, let's store the object for when the editor loads
    if (!this.editor) {
      this._initialChanges = options;
      return;
    }
    // an editor is active, let's assign the options to the editor
    Object.assign(this.editor,
    // old options object
    this.options,
    // new merged changes
    options);
  }
  ngOnDestroy() {
    this._initialChanges = {};
    if (!this.editor) return;
    this.editor.destroy();
    // unsubscribe
    this.unsubs.forEach(unsub => unsub());
    this.unsubs = [];
    // unroute events
    Object.keys(this)
    // @ts-ignore
    .filter(key => this[key] instanceof EventEmitter).forEach(key => {
      this.element.nativeElement.removeEventListener(`pintura:${key}`, this.routeEvent);
    });
    this.editor = undefined;
  }
  ngOnInit() {
    // Do nothing
  }
}
PinturaEditorAbstractComponent.ɵfac = function PinturaEditorAbstractComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PinturaEditorAbstractComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
PinturaEditorAbstractComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PinturaEditorAbstractComponent,
  selectors: [["ng-component"]],
  inputs: {
    options: "options",
    id: "id",
    class: "class",
    animations: "animations",
    src: "src",
    util: "util",
    utils: "utils",
    disabled: "disabled",
    status: "status",
    elasticityMultiplier: "elasticityMultiplier",
    layoutDirectionPreference: "layoutDirectionPreference",
    layoutVerticalUtilsPreference: "layoutVerticalUtilsPreference",
    layoutHorizontalUtilsPreference: "layoutHorizontalUtilsPreference",
    layoutVerticalControlGroupsPreference: "layoutVerticalControlGroupsPreference",
    layoutVerticalControlTabsPreference: "layoutVerticalControlTabsPreference",
    layoutVerticalToolbarPreference: "layoutVerticalToolbarPreference",
    imageSourceToImageData: "imageSourceToImageData",
    previewImageData: "previewImageData",
    previewImageDataMaxSize: "previewImageDataMaxSize",
    previewUpscale: "previewUpscale",
    previewPad: "previewPad",
    previewMaskOpacity: "previewMaskOpacity",
    shapePreprocessor: "shapePreprocessor",
    enableCanvasAlpha: "enableCanvasAlpha",
    enableButtonClose: "enableButtonClose",
    enableButtonExport: "enableButtonExport",
    enableButtonResetHistory: "enableButtonResetHistory",
    enableButtonRevert: "enableButtonRevert",
    enableNavigateHistory: "enableNavigateHistory",
    enableToolbar: "enableToolbar",
    enableUtils: "enableUtils",
    enableDropImage: "enableDropImage",
    enablePasteImage: "enablePasteImage",
    enableZoom: "enableZoom",
    enableZoomControls: "enableZoomControls",
    enablePan: "enablePan",
    enablePanLimit: "enablePanLimit",
    panLimitGutterScalar: "panLimitGutterScalar",
    fixScrollDirection: "fixScrollDirection",
    zoomLevel: "zoomLevel",
    zoomPresetOptions: "zoomPresetOptions",
    zoomAdjustStep: "zoomAdjustStep",
    zoomAdjustFactor: "zoomAdjustFactor",
    zoomAdjustWheelFactor: "zoomAdjustWheelFactor",
    enablePanInput: "enablePanInput",
    enableZoomInput: "enableZoomInput",
    enableMultiSelect: "enableMultiSelect",
    willSetMediaInitialTimeOffset: "willSetMediaInitialTimeOffset",
    muteAudio: "muteAudio",
    csp: "csp",
    handleEvent: "handleEvent",
    willRequestResource: "willRequestResource",
    willClose: "willClose",
    willRevert: "willRevert",
    willProcessImage: "willProcessImage",
    willRenderCanvas: "willRenderCanvas",
    willSetHistoryInitialState: "willSetHistoryInitialState",
    willRenderToolbar: "willRenderToolbar",
    willRenderUtilTabs: "willRenderUtilTabs",
    beforeSelectShape: "beforeSelectShape",
    beforeDeselectShape: "beforeDeselectShape",
    beforeAddShape: "beforeAddShape",
    beforeRemoveShape: "beforeRemoveShape",
    beforeUpdateShape: "beforeUpdateShape",
    willRenderShapeControls: "willRenderShapeControls",
    willRenderShapePresetToolbar: "willRenderShapePresetToolbar",
    locale: "locale",
    imageReader: "imageReader",
    imageWriter: "imageWriter",
    imageOrienter: "imageOrienter",
    imageScrambler: "imageScrambler",
    imageBackgroundColor: "imageBackgroundColor",
    imageBackgroundImage: "imageBackgroundImage",
    imageColorMatrix: "imageColorMatrix",
    imageConvolutionMatrix: "imageConvolutionMatrix",
    imageCrop: "imageCrop",
    imageCropAspectRatio: "imageCropAspectRatio",
    imageCropLimitToImage: "imageCropLimitToImage",
    imageCropMaxSize: "imageCropMaxSize",
    imageCropMinSize: "imageCropMinSize",
    imageRedaction: "imageRedaction",
    imageAnnotation: "imageAnnotation",
    imageDecoration: "imageDecoration",
    imageFlipX: "imageFlipX",
    imageFlipY: "imageFlipY",
    imageGamma: "imageGamma",
    imageNoise: "imageNoise",
    imageRotation: "imageRotation",
    imageVignette: "imageVignette",
    imageTargetSize: "imageTargetSize",
    imageFrame: "imageFrame",
    imageMetadata: "imageMetadata",
    imageState: "imageState",
    imageDuration: "imageDuration",
    imageTrim: "imageTrim",
    imageVolume: "imageVolume",
    imageCurrentTime: "imageCurrentTime",
    enableSelectToolToAddShape: "enableSelectToolToAddShape",
    enableTapToAddText: "enableTapToAddText",
    markupEditorToolbar: "markupEditorToolbar",
    markupEditorToolStyles: "markupEditorToolStyles",
    markupEditorShapeStyleControls: "markupEditorShapeStyleControls",
    markupEditorToolSelectRadius: "markupEditorToolSelectRadius",
    markupEditorTextInputMode: "markupEditorTextInputMode",
    markupEditorZoomLevels: "markupEditorZoomLevels",
    markupEditorZoomAdjustStep: "markupEditorZoomAdjustStep",
    markupEditorZoomAdjustFactor: "markupEditorZoomAdjustFactor",
    markupEditorZoomAdjustWheelFactor: "markupEditorZoomAdjustWheelFactor",
    markupEditorZoomLevel: "markupEditorZoomLevel",
    markupEditorToolRetainStyles: "markupEditorToolRetainStyles",
    markupEditorWillStartInteraction: "markupEditorWillStartInteraction",
    markupEditorSnapThreshold: "markupEditorSnapThreshold",
    markupEditorSnapToContext: "markupEditorSnapToContext",
    markupEditorGridSize: "markupEditorGridSize",
    cropAutoCenterImageSelectionTimeout: "cropAutoCenterImageSelectionTimeout",
    cropWillRenderImageSelectionGuides: "cropWillRenderImageSelectionGuides",
    cropEnableButtonFlipHorizontal: "cropEnableButtonFlipHorizontal",
    cropEnableButtonFlipVertical: "cropEnableButtonFlipVertical",
    cropEnableButtonRotateLeft: "cropEnableButtonRotateLeft",
    cropEnableButtonRotateRight: "cropEnableButtonRotateRight",
    cropEnableButtonToggleCropLimit: "cropEnableButtonToggleCropLimit",
    cropEnableCenterImageSelection: "cropEnableCenterImageSelection",
    cropEnableImageSelection: "cropEnableImageSelection",
    cropEnableInfoIndicator: "cropEnableInfoIndicator",
    cropEnableLimitWheelInputToCropSelection: "cropEnableLimitWheelInputToCropSelection",
    cropEnableRotationInput: "cropEnableRotationInput",
    cropEnableSelectPreset: "cropEnableSelectPreset",
    cropEnableZoomInput: "cropEnableZoomInput",
    cropEnableZoomMatchImageAspectRatio: "cropEnableZoomMatchImageAspectRatio",
    cropEnableZoomTowardsWheelPosition: "cropEnableZoomTowardsWheelPosition",
    cropEnableZoomAutoHide: "cropEnableZoomAutoHide",
    cropImageSelectionCornerStyle: "cropImageSelectionCornerStyle",
    cropSelectPresetOptions: "cropSelectPresetOptions",
    cropSelectPresetFilter: "cropSelectPresetFilter",
    cropEnableRotateMatchImageAspectRatio: "cropEnableRotateMatchImageAspectRatio",
    cropMinimizeToolbar: "cropMinimizeToolbar",
    cropWillRenderTools: "cropWillRenderTools",
    annotateActiveTool: "annotateActiveTool",
    annotateEnableButtonFlipVertical: "annotateEnableButtonFlipVertical",
    annotatePresets: "annotatePresets",
    annotateGridSize: "annotateGridSize",
    annotateSnapToContext: "annotateSnapToContext",
    annotateWillRenderShapeStyleControls: "annotateWillRenderShapeStyleControls",
    decorateActiveTool: "decorateActiveTool",
    decorateEnableButtonFlipVertical: "decorateEnableButtonFlipVertical",
    decoratePresets: "decoratePresets",
    decorateGridSize: "decorateGridSize",
    decorateSnapToContext: "decorateSnapToContext",
    decorateWillRenderShapeStyleControls: "decorateWillRenderShapeStyleControls",
    filterFunctions: "filterFunctions",
    filterOptions: "filterOptions",
    finetuneControlConfiguration: "finetuneControlConfiguration",
    finetuneOptions: "finetuneOptions",
    resizeMaxSize: "resizeMaxSize",
    resizeMinSize: "resizeMinSize",
    resizeSizePresetOptions: "resizeSizePresetOptions",
    resizeWidthPresetOptions: "resizeWidthPresetOptions",
    resizeHeightPresetOptions: "resizeHeightPresetOptions",
    resizeWillRenderFooter: "resizeWillRenderFooter",
    frameStyles: "frameStyles",
    frameOptions: "frameOptions",
    fillOptions: "fillOptions",
    stickers: "stickers",
    stickerStickToImage: "stickerStickToImage",
    stickersEnableButtonFlipVertical: "stickersEnableButtonFlipVertical",
    retouchTools: "retouchTools",
    retouchToolShapes: "retouchToolShapes",
    retouchShapeControls: "retouchShapeControls",
    retouchEnableViewTool: "retouchEnableViewTool",
    retouchEnableMoveTool: "retouchEnableMoveTool",
    retouchWillRenderShapeControls: "retouchWillRenderShapeControls",
    retouchWillRenderShapeStyleControls: "retouchWillRenderShapeStyleControls"
  },
  outputs: {
    init: "init",
    loadstart: "loadstart",
    loadabort: "loadabort",
    loaderror: "loaderror",
    loadprogress: "loadprogress",
    load: "load",
    processstart: "processstart",
    processabort: "processabort",
    processerror: "processerror",
    processprogress: "processprogress",
    process: "process",
    update: "update",
    undo: "undo",
    redo: "redo",
    revert: "revert",
    writehistory: "writehistory",
    destroy: "destroy",
    show: "show",
    hide: "hide",
    close: "close",
    ready: "ready",
    loadpreview: "loadpreview",
    addshape: "addshape",
    selectshape: "selectshape",
    updateshape: "updateshape",
    removeshape: "removeshape",
    markuptap: "markuptap",
    markupzoom: "markupzoom",
    markuppan: "markuppan",
    zoom: "zoom",
    pan: "pan",
    selectstyle: "selectstyle",
    selectutil: "selectutil",
    selectcontrol: "selectcontrol"
  },
  standalone: false,
  features: [i0.ɵɵNgOnChangesFeature],
  decls: 0,
  vars: 0,
  template: function PinturaEditorAbstractComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PinturaEditorAbstractComponent, [{
    type: Component,
    args: [{
      template: ""
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, {
    options: [{
      type: Input
    }],
    id: [{
      type: Input
    }],
    class: [{
      type: Input
    }],
    animations: [{
      type: Input
    }],
    src: [{
      type: Input
    }],
    util: [{
      type: Input
    }],
    utils: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    status: [{
      type: Input
    }],
    elasticityMultiplier: [{
      type: Input
    }],
    layoutDirectionPreference: [{
      type: Input
    }],
    layoutVerticalUtilsPreference: [{
      type: Input
    }],
    layoutHorizontalUtilsPreference: [{
      type: Input
    }],
    layoutVerticalControlGroupsPreference: [{
      type: Input
    }],
    layoutVerticalControlTabsPreference: [{
      type: Input
    }],
    layoutVerticalToolbarPreference: [{
      type: Input
    }],
    imageSourceToImageData: [{
      type: Input
    }],
    previewImageData: [{
      type: Input
    }],
    previewImageDataMaxSize: [{
      type: Input
    }],
    previewUpscale: [{
      type: Input
    }],
    previewPad: [{
      type: Input
    }],
    previewMaskOpacity: [{
      type: Input
    }],
    shapePreprocessor: [{
      type: Input
    }],
    enableCanvasAlpha: [{
      type: Input
    }],
    enableButtonClose: [{
      type: Input
    }],
    enableButtonExport: [{
      type: Input
    }],
    enableButtonResetHistory: [{
      type: Input
    }],
    enableButtonRevert: [{
      type: Input
    }],
    enableNavigateHistory: [{
      type: Input
    }],
    enableToolbar: [{
      type: Input
    }],
    enableUtils: [{
      type: Input
    }],
    enableDropImage: [{
      type: Input
    }],
    enablePasteImage: [{
      type: Input
    }],
    enableZoom: [{
      type: Input
    }],
    enableZoomControls: [{
      type: Input
    }],
    enablePan: [{
      type: Input
    }],
    enablePanLimit: [{
      type: Input
    }],
    panLimitGutterScalar: [{
      type: Input
    }],
    fixScrollDirection: [{
      type: Input
    }],
    zoomLevel: [{
      type: Input
    }],
    zoomPresetOptions: [{
      type: Input
    }],
    zoomAdjustStep: [{
      type: Input
    }],
    zoomAdjustFactor: [{
      type: Input
    }],
    zoomAdjustWheelFactor: [{
      type: Input
    }],
    enablePanInput: [{
      type: Input
    }],
    enableZoomInput: [{
      type: Input
    }],
    enableMultiSelect: [{
      type: Input
    }],
    willSetMediaInitialTimeOffset: [{
      type: Input
    }],
    muteAudio: [{
      type: Input
    }],
    csp: [{
      type: Input
    }],
    handleEvent: [{
      type: Input
    }],
    willRequestResource: [{
      type: Input
    }],
    willClose: [{
      type: Input
    }],
    willRevert: [{
      type: Input
    }],
    willProcessImage: [{
      type: Input
    }],
    willRenderCanvas: [{
      type: Input
    }],
    willSetHistoryInitialState: [{
      type: Input
    }],
    willRenderToolbar: [{
      type: Input
    }],
    willRenderUtilTabs: [{
      type: Input
    }],
    beforeSelectShape: [{
      type: Input
    }],
    beforeDeselectShape: [{
      type: Input
    }],
    beforeAddShape: [{
      type: Input
    }],
    beforeRemoveShape: [{
      type: Input
    }],
    beforeUpdateShape: [{
      type: Input
    }],
    willRenderShapeControls: [{
      type: Input
    }],
    willRenderShapePresetToolbar: [{
      type: Input
    }],
    locale: [{
      type: Input
    }],
    imageReader: [{
      type: Input
    }],
    imageWriter: [{
      type: Input
    }],
    imageOrienter: [{
      type: Input
    }],
    imageScrambler: [{
      type: Input
    }],
    imageBackgroundColor: [{
      type: Input
    }],
    imageBackgroundImage: [{
      type: Input
    }],
    imageColorMatrix: [{
      type: Input
    }],
    imageConvolutionMatrix: [{
      type: Input
    }],
    imageCrop: [{
      type: Input
    }],
    imageCropAspectRatio: [{
      type: Input
    }],
    imageCropLimitToImage: [{
      type: Input
    }],
    imageCropMaxSize: [{
      type: Input
    }],
    imageCropMinSize: [{
      type: Input
    }],
    imageRedaction: [{
      type: Input
    }],
    imageAnnotation: [{
      type: Input
    }],
    imageDecoration: [{
      type: Input
    }],
    imageFlipX: [{
      type: Input
    }],
    imageFlipY: [{
      type: Input
    }],
    imageGamma: [{
      type: Input
    }],
    imageNoise: [{
      type: Input
    }],
    imageRotation: [{
      type: Input
    }],
    imageVignette: [{
      type: Input
    }],
    imageTargetSize: [{
      type: Input
    }],
    imageFrame: [{
      type: Input
    }],
    imageMetadata: [{
      type: Input
    }],
    imageState: [{
      type: Input
    }],
    imageDuration: [{
      type: Input
    }],
    imageTrim: [{
      type: Input
    }],
    imageVolume: [{
      type: Input
    }],
    imageCurrentTime: [{
      type: Input
    }],
    enableSelectToolToAddShape: [{
      type: Input
    }],
    enableTapToAddText: [{
      type: Input
    }],
    markupEditorToolbar: [{
      type: Input
    }],
    markupEditorToolStyles: [{
      type: Input
    }],
    markupEditorShapeStyleControls: [{
      type: Input
    }],
    markupEditorToolSelectRadius: [{
      type: Input
    }],
    markupEditorTextInputMode: [{
      type: Input
    }],
    markupEditorZoomLevels: [{
      type: Input
    }],
    markupEditorZoomAdjustStep: [{
      type: Input
    }],
    markupEditorZoomAdjustFactor: [{
      type: Input
    }],
    markupEditorZoomAdjustWheelFactor: [{
      type: Input
    }],
    markupEditorZoomLevel: [{
      type: Input
    }],
    markupEditorToolRetainStyles: [{
      type: Input
    }],
    markupEditorWillStartInteraction: [{
      type: Input
    }],
    markupEditorSnapThreshold: [{
      type: Input
    }],
    markupEditorSnapToContext: [{
      type: Input
    }],
    markupEditorGridSize: [{
      type: Input
    }],
    cropAutoCenterImageSelectionTimeout: [{
      type: Input
    }],
    cropWillRenderImageSelectionGuides: [{
      type: Input
    }],
    cropEnableButtonFlipHorizontal: [{
      type: Input
    }],
    cropEnableButtonFlipVertical: [{
      type: Input
    }],
    cropEnableButtonRotateLeft: [{
      type: Input
    }],
    cropEnableButtonRotateRight: [{
      type: Input
    }],
    cropEnableButtonToggleCropLimit: [{
      type: Input
    }],
    cropEnableCenterImageSelection: [{
      type: Input
    }],
    cropEnableImageSelection: [{
      type: Input
    }],
    cropEnableInfoIndicator: [{
      type: Input
    }],
    cropEnableLimitWheelInputToCropSelection: [{
      type: Input
    }],
    cropEnableRotationInput: [{
      type: Input
    }],
    cropEnableSelectPreset: [{
      type: Input
    }],
    cropEnableZoomInput: [{
      type: Input
    }],
    cropEnableZoomMatchImageAspectRatio: [{
      type: Input
    }],
    cropEnableZoomTowardsWheelPosition: [{
      type: Input
    }],
    cropEnableZoomAutoHide: [{
      type: Input
    }],
    cropImageSelectionCornerStyle: [{
      type: Input
    }],
    cropSelectPresetOptions: [{
      type: Input
    }],
    cropSelectPresetFilter: [{
      type: Input
    }],
    cropEnableRotateMatchImageAspectRatio: [{
      type: Input
    }],
    cropMinimizeToolbar: [{
      type: Input
    }],
    cropWillRenderTools: [{
      type: Input
    }],
    annotateActiveTool: [{
      type: Input
    }],
    annotateEnableButtonFlipVertical: [{
      type: Input
    }],
    annotatePresets: [{
      type: Input
    }],
    annotateGridSize: [{
      type: Input
    }],
    annotateSnapToContext: [{
      type: Input
    }],
    annotateWillRenderShapeStyleControls: [{
      type: Input
    }],
    decorateActiveTool: [{
      type: Input
    }],
    decorateEnableButtonFlipVertical: [{
      type: Input
    }],
    decoratePresets: [{
      type: Input
    }],
    decorateGridSize: [{
      type: Input
    }],
    decorateSnapToContext: [{
      type: Input
    }],
    decorateWillRenderShapeStyleControls: [{
      type: Input
    }],
    filterFunctions: [{
      type: Input
    }],
    filterOptions: [{
      type: Input
    }],
    finetuneControlConfiguration: [{
      type: Input
    }],
    finetuneOptions: [{
      type: Input
    }],
    resizeMaxSize: [{
      type: Input
    }],
    resizeMinSize: [{
      type: Input
    }],
    resizeSizePresetOptions: [{
      type: Input
    }],
    resizeWidthPresetOptions: [{
      type: Input
    }],
    resizeHeightPresetOptions: [{
      type: Input
    }],
    resizeWillRenderFooter: [{
      type: Input
    }],
    frameStyles: [{
      type: Input
    }],
    frameOptions: [{
      type: Input
    }],
    fillOptions: [{
      type: Input
    }],
    stickers: [{
      type: Input
    }],
    stickerStickToImage: [{
      type: Input
    }],
    stickersEnableButtonFlipVertical: [{
      type: Input
    }],
    retouchTools: [{
      type: Input
    }],
    retouchToolShapes: [{
      type: Input
    }],
    retouchShapeControls: [{
      type: Input
    }],
    retouchEnableViewTool: [{
      type: Input
    }],
    retouchEnableMoveTool: [{
      type: Input
    }],
    retouchWillRenderShapeControls: [{
      type: Input
    }],
    retouchWillRenderShapeStyleControls: [{
      type: Input
    }],
    init: [{
      type: Output
    }],
    loadstart: [{
      type: Output
    }],
    loadabort: [{
      type: Output
    }],
    loaderror: [{
      type: Output
    }],
    loadprogress: [{
      type: Output
    }],
    load: [{
      type: Output
    }],
    processstart: [{
      type: Output
    }],
    processabort: [{
      type: Output
    }],
    processerror: [{
      type: Output
    }],
    processprogress: [{
      type: Output
    }],
    process: [{
      type: Output
    }],
    update: [{
      type: Output
    }],
    undo: [{
      type: Output
    }],
    redo: [{
      type: Output
    }],
    revert: [{
      type: Output
    }],
    writehistory: [{
      type: Output
    }],
    destroy: [{
      type: Output
    }],
    show: [{
      type: Output
    }],
    hide: [{
      type: Output
    }],
    close: [{
      type: Output
    }],
    ready: [{
      type: Output
    }],
    loadpreview: [{
      type: Output
    }],
    addshape: [{
      type: Output
    }],
    selectshape: [{
      type: Output
    }],
    updateshape: [{
      type: Output
    }],
    removeshape: [{
      type: Output
    }],
    markuptap: [{
      type: Output
    }],
    markupzoom: [{
      type: Output
    }],
    markuppan: [{
      type: Output
    }],
    zoom: [{
      type: Output
    }],
    pan: [{
      type: Output
    }],
    selectstyle: [{
      type: Output
    }],
    selectutil: [{
      type: Output
    }],
    selectcontrol: [{
      type: Output
    }]
  });
})();
class PinturaEditorComponent extends PinturaEditorAbstractComponent {
  initEditor(element, props) {
    return appendEditor(element, props);
  }
}
PinturaEditorComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵPinturaEditorComponent_BaseFactory;
  return function PinturaEditorComponent_Factory(__ngFactoryType__) {
    return (ɵPinturaEditorComponent_BaseFactory || (ɵPinturaEditorComponent_BaseFactory = i0.ɵɵgetInheritedFactory(PinturaEditorComponent)))(__ngFactoryType__ || PinturaEditorComponent);
  };
})();
PinturaEditorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PinturaEditorComponent,
  selectors: [["pintura-editor"]],
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function PinturaEditorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PinturaEditorComponent, [{
    type: Component,
    args: [{
      selector: 'pintura-editor',
      template: ` <ng-content></ng-content> `,
      styles: []
    }]
  }], null, null);
})();
class PinturaEditorModalComponent extends PinturaEditorAbstractComponent {
  get modal() {
    return this.editor.modal;
  }
  initEditor(element, props) {
    return openEditor(props);
  }
  showEditor() {
    this.editor.show();
  }
  hideEditor() {
    this.editor.hide();
  }
  ngOnDestroy() {
    if (!this.editor) return;
    this.editor = undefined;
  }
}
PinturaEditorModalComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵPinturaEditorModalComponent_BaseFactory;
  return function PinturaEditorModalComponent_Factory(__ngFactoryType__) {
    return (ɵPinturaEditorModalComponent_BaseFactory || (ɵPinturaEditorModalComponent_BaseFactory = i0.ɵɵgetInheritedFactory(PinturaEditorModalComponent)))(__ngFactoryType__ || PinturaEditorModalComponent);
  };
})();
PinturaEditorModalComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PinturaEditorModalComponent,
  selectors: [["pintura-editor-modal"]],
  inputs: {
    preventZoomViewport: "preventZoomViewport",
    preventScrollBodyIfNeeded: "preventScrollBodyIfNeeded",
    preventFooterOverlapIfNeeded: "preventFooterOverlapIfNeeded",
    enableAutoHide: "enableAutoHide",
    enableAutoDestroy: "enableAutoDestroy"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function PinturaEditorModalComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PinturaEditorModalComponent, [{
    type: Component,
    args: [{
      selector: 'pintura-editor-modal',
      template: ` <ng-content></ng-content> `,
      styles: []
    }]
  }], null, {
    preventZoomViewport: [{
      type: Input
    }],
    preventScrollBodyIfNeeded: [{
      type: Input
    }],
    preventFooterOverlapIfNeeded: [{
      type: Input
    }],
    enableAutoHide: [{
      type: Input
    }],
    enableAutoDestroy: [{
      type: Input
    }]
  });
})();
class PinturaEditorOverlayComponent extends PinturaEditorAbstractComponent {
  initEditor(element, props) {
    return overlayEditor(element, props);
  }
}
PinturaEditorOverlayComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵPinturaEditorOverlayComponent_BaseFactory;
  return function PinturaEditorOverlayComponent_Factory(__ngFactoryType__) {
    return (ɵPinturaEditorOverlayComponent_BaseFactory || (ɵPinturaEditorOverlayComponent_BaseFactory = i0.ɵɵgetInheritedFactory(PinturaEditorOverlayComponent)))(__ngFactoryType__ || PinturaEditorOverlayComponent);
  };
})();
PinturaEditorOverlayComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PinturaEditorOverlayComponent,
  selectors: [["pintura-editor-overlay"]],
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function PinturaEditorOverlayComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PinturaEditorOverlayComponent, [{
    type: Component,
    args: [{
      selector: 'pintura-editor-overlay',
      template: ` <ng-content></ng-content> `,
      styles: []
    }]
  }], null, null);
})();
class AngularPinturaModule {}
AngularPinturaModule.ɵfac = function AngularPinturaModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AngularPinturaModule)();
};
AngularPinturaModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: AngularPinturaModule
});
AngularPinturaModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AngularPinturaModule, [{
    type: NgModule,
    args: [{
      declarations: [PinturaEditorAbstractComponent, PinturaEditorComponent, PinturaEditorModalComponent, PinturaEditorOverlayComponent],
      imports: [],
      exports: [PinturaEditorAbstractComponent, PinturaEditorComponent, PinturaEditorModalComponent, PinturaEditorOverlayComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of angular-pintura
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AngularPinturaModule, PinturaEditorAbstractComponent, PinturaEditorComponent, PinturaEditorModalComponent, PinturaEditorOverlayComponent };
