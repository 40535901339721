import { FocusMonitor } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import {
  booleanAttribute,
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  input,
  model,
  OnDestroy,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormFieldControl,
} from '@angular/material/form-field';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'doctorus-front-end-monorepo-field-form-date-time',
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './field-form-date-time.component.html',
  providers: [
    { provide: MatFormFieldControl, useExisting: FieldFormDateTimeComponent },
  ],
  host: {
    '[class.field-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
  styleUrl: './field-form-date-time.component.scss',
})
export class FieldFormDateTimeComponent
  implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy
{
  timzone = input<string>(Intl.DateTimeFormat().resolvedOptions().timeZone);
  static nextId = 0;
  readonly dateInput = viewChild.required<HTMLInputElement>('date');
  ngControl = inject(NgControl, { optional: true, self: true });
  readonly dateCtrl: FormControl<string | null>;
  readonly stateChanges = new Subject<void>();
  readonly touched = signal(false);
  readonly controlType = 'zoned-date-time';
  readonly id = `zoned-date-time-${FieldFormDateTimeComponent.nextId++}`;
  readonly _userAriaDescribedBy = input<string>('', {
    alias: 'aria-describedby',
  });
  readonly _placeholder = input<string>('', { alias: 'placeholder' });
  readonly _required = input<boolean, unknown>(false, {
    alias: 'required',
    transform: booleanAttribute,
  });
  readonly _disabledByInput = input<boolean, unknown>(false, {
    alias: 'disabled',
    transform: booleanAttribute,
  });
  readonly _value = model<string | null>(null, { alias: 'value' });
  onChange = (_: any) => {};
  onTouched = () => {};

  protected readonly _formField = inject(MAT_FORM_FIELD, {
    optional: true,
  });
  private readonly _subs = new Subscription();
  private readonly _focused = signal(false);
  private readonly _disabledByCva = signal(false);
  private readonly _disabled = computed(
    () => this._disabledByInput() || this._disabledByCva(),
  );
  private readonly _focusMonitor = inject(FocusMonitor);
  private readonly _elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  get focused(): boolean {
    return this._focused();
  }
  get empty() {
    return !this.dateCtrl.value;
  }
  get shouldLabelFloat() {
    return true;
  }

  get userAriaDescribedBy() {
    return this._userAriaDescribedBy();
  }

  get placeholder(): string {
    return this._placeholder();
  }

  get required(): boolean {
    return this._required();
  }

  get disabled(): boolean {
    return this._disabled();
  }

  get value(): string | null {
    return this._value();
  }

  get errorState(): boolean {
    return this.dateCtrl.invalid && this.touched();
  }
  constructor() {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.dateCtrl = new FormControl();

    effect(() => {
      // Read signals to trigger effect.
      this._placeholder();
      this._required();
      this._disabled();
      this._focused();
      // Propagate state changes.
      untracked(() => this.stateChanges.next());
    });

    effect(() => {
      if (this._disabled()) {
        untracked(() => this.dateCtrl.disable());
      } else {
        untracked(() => this.dateCtrl.enable());
      }
    });

    // effect(() => {
    //   const value = this._value() || '';
    //   untracked(() => this.dateCtrl.setValue(value));
    // });

    this._subs.add(
      this.dateCtrl.statusChanges.pipe(takeUntilDestroyed()).subscribe(() => {
        this.stateChanges.next();
      }),
    );

    this._subs.add(
      this.dateCtrl.valueChanges.pipe(takeUntilDestroyed()).subscribe(value => {
        const date = this.dateCtrl.valid ? this.dateCtrl.value : null;
        this.onChange(date ? new Date(date).toISOString() : null);
      }),
    );
  }
  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
  autofilled?: boolean | undefined;
  disableAutomaticLabeling?: boolean | undefined;
  onFocusOut(event: FocusEvent) {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched.set(true);
      this._focused.set(false);
      this.onTouched();
    }
  }

  autoFocusNext(
    control: AbstractControl,
    nextElement?: HTMLInputElement,
  ): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.zoned-date-time-container',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this._elementRef?.nativeElement?.querySelector('input')?.focus();
    }
  }

  writeValue(val: string | Date | null): void {
    let _date = null;
    if (val instanceof Date) {
      _date = val;
    }
    if (typeof val === 'string') {
      _date = new Date(val);
    }
    this.dateCtrl.setValue(
      _date ? _date.toISOString().replace(/z|Z/, '') : null,
      {
        emitEvent: false,
      },
    );
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabledByCva.set(isDisabled);
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
  }
}
