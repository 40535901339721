import { CommonModule } from '@angular/common';
import { Component, inject, TemplateRef, ViewChild } from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetModule,
} from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from '@angular/router';
import { AccountRole, AuthService } from '@doctorus-front-end-monorepo/auth';
import {
  BreadcrumbComponent,
  resolveBreadcrumbs,
} from '@doctorus-front-end-monorepo/breadcrumb';
import {
  LayoutStateService,
  NavLink,
  NavTreeComponent,
} from '@doctorus-front-end-monorepo/ui-layout';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  startWith,
} from 'rxjs';
@Component({
  selector: 'account-settings-account-settings',
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatSidenavModule,
    BreadcrumbComponent,
    MatDividerModule,
    NavTreeComponent,
    MatButtonModule,
    MatToolbarModule,
    MatBottomSheetModule,
  ],
  templateUrl: './account-settings.component.html',
  styleUrl: './account-settings.component.scss',
})
export class AccountSettingsComponent {
  @ViewChild('sidenav') sideNavCrm?: MatSidenav;
  @ViewChild('menu', { read: TemplateRef }) menu!: TemplateRef<unknown>;
  private lss = inject(LayoutStateService);

  isCompact$ = this.lss.isCompact$;
  isLarge$ = this.lss.isLarge$;
  roles$ = inject(AuthService).authUserRoles$;
  open = true;
  readonly bottomSheet = inject(MatBottomSheet);
  links: NavLink[] = [
    {
      name: $localize`general`,
      active: true,
      routerLink: ['general-settings'],
    },
    {
      name: $localize`memberships`,
      svgIcon: 'patient_list',
      active: true,
      requiredRoles: [AccountRole.DOCTOR, AccountRole.MEDICAL_ASSISTANT],
      routerLink: ['memberships'],
    },
    {
      name: $localize`forms`,
      active: true,
      routerLink: ['forms-preferences'],
    },
    {
      name: $localize`patient property models`,
      active: true,
      routerLink: ['patient-property-models'],
    },
    {
      name: $localize`medical settings`,
      svgIcon: 'settings',
      active: true,
      children: [
        {
          name: $localize`medical history models`,
          svgIcon: 'settings',
          active: true,
          routerLink: ['medical-history-models'],
        },
        {
          name: $localize`measure models`,
          svgIcon: 'settings',
          active: true,
          routerLink: ['measure-models'],
        },
      ],
    },
    {
      name: $localize`documents settings`,
      svgIcon: 'settings',
      active: true,
      children: [
        {
          name: $localize`document models`,
          svgIcon: 'settings',
          active: true,
          routerLink: ['document-models'],
        },
        {
          name: $localize`print layouts`,
          svgIcon: 'settings',
          active: true,
          routerLink: ['print-layouts'],
        },
      ],
    },
    {
      name: $localize`locations`,
      svgIcon: 'locations',
      active: true,
      routerLink: ['locations'],
    },
    {
      name: $localize`appointment types`,
      svgIcon: 'settings',
      active: true,
      routerLink: ['task-types'],
    },
  ];

  toggle(): void {
    this.isLarge$
      .pipe(first())
      .subscribe(match =>
        match ? this.sideNavCrm?.toggle() : this.bottomSheet.open(this.menu),
      );
  }
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  breadcrumbs$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(''),
    distinctUntilChanged(),
    debounceTime(100),
    map(() => resolveBreadcrumbs(this.route, 'primary')),
  );
}
