import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  BaseEntityFormComponent,
  IEntity,
} from '../../../../feature-entity/src';
import { FormPanelBaseComponent } from '../form-panel-base.component';

@Component({
  selector: 'shared-ui-mat-dialog-form-panel',
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  templateUrl: './mat-dialog-form-panel.component.html',
  styleUrl: './mat-dialog-form-panel.component.scss',
})
export class MatDialogFormPanelComponent<
  E extends IEntity,
  C extends BaseEntityFormComponent<E>,
  R = E,
> extends FormPanelBaseComponent<E, C> {
  override data = inject(MAT_DIALOG_DATA);

  dialogRef = inject(MatDialogRef<MatDialogFormPanelComponent<E, C>, R>, {
    optional: true,
  });

  override closeDialog(payload?: R): void {
    this.dialogRef?.close(payload);
  }
}
