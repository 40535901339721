export type NavLink = {
  name: string;
  routerLink?: any[] | null;
  fragment?: string;
  active?: boolean;
  svgIcon?: string;
  feedBackMessageType?: MessageType;
  children?: NavLink[];
  highlight?: boolean;
  requiredRoles?: string[];
  expanded?: boolean;
};

export type View = 'PRIMARY' | 'SECONDARY';

export enum FeedbackPattern {
  ALERT = 1,
  FLASHBAR,
  SPINNER,
  PROGRESS_BAR,
}
export enum MessageType {
  INFO = 1,
  SUCCESS,
  ERROR,
  WARNING,
}

export const FEEDBACK_MAP: Record<
  MessageType,
  { icon: string; color: string; textClass: string; bgClass: string }
> = {
  [MessageType.INFO]: {
    icon: 'info',
    color: 'blue',
    textClass: 'text-blue-800',
    bgClass: 'bg-blue-200',
  },
  [MessageType.SUCCESS]: {
    icon: 'check_circle',
    color: 'green',
    textClass: 'text-green-800',
    bgClass: 'bg-green-200',
  },
  [MessageType.ERROR]: {
    icon: 'error',
    color: 'red',
    textClass: 'text-red-800',
    bgClass: 'bg-red-200',
  },
  [MessageType.WARNING]: {
    icon: 'warning',
    color: 'orange',
    textClass: 'text-orange-800',
    bgClass: 'bg-orange-200',
  },
};
