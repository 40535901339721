import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { QuillViewComponent } from 'ngx-quill';
import { Patient } from '../../../../../data-access/graphql/src';
import { BaseEntityDetailsComponent } from '../../../../../feature-entity/src';
import { EmptyStateComponent } from '../../../../../ui-layout/src';

@Component({
  selector: 'patient-medical-note-details',
  standalone: true,
  imports: [CommonModule, QuillViewComponent, EmptyStateComponent],
  template: `@if (obj().medical_notes; as notes) {
      <quill-view [content]="notes" format="html" theme="snow"></quill-view>
    } @else {
      <ui-layout-empty-state>
        no medical notes for this patient
      </ui-layout-empty-state>
    }`,
  styles: ``,
})
export class MedicalNoteDetailsComponent extends BaseEntityDetailsComponent<
  Pick<Patient, 'id' | 'medical_notes'>
> {}
