import { CommonModule, DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { ListWrapperComponent } from '@doctorus-front-end-monorepo/feature-entity';
import { PatientDataService } from '@doctorus-front-end-monorepo/graphql';
import {
  ContainerComponent,
  KeyValueComponent,
} from '@doctorus-front-end-monorepo/ui-layout';
import {
  AccountCurrencyPipe,
  CoalescePipe,
} from '@doctorus-front-end-monorepo/util-formatting';

@Component({
  selector: 'lib-patient-payment-list',
  templateUrl: './patient-payment-list.component.html',
  styleUrls: ['./patient-payment-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'paymentsList',
  imports: [
    AccountCurrencyPipe,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatDividerModule,
    ContainerComponent,
    KeyValueComponent,
    MatMenuModule,
    RouterModule,
    MatDividerModule,
    CoalescePipe,
    AccountCurrencyPipe,
    DatePipe,
    MatTableModule,
    ListWrapperComponent,
  ],
})
export class PatientPaymentListComponent {
  private pds = inject(PatientDataService);
  private patient = toSignal(this.pds.selectedPatient$);
  objs = computed(() => this.patient()?.payments);
  paymentStatus = toSignal(this.pds.paymentStatus$, {
    initialValue: { total_due: 0, total_paid: 0 },
  });

  displayedColumns = ['date', 'amount', 'payment_method'];
}
