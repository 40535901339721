<h3 matDialogTitle i18n>{{headerStr | titleCaseFirst}}</h3>

<form class="mt-3 py-2 flex flex-col gap-2" [formGroup]="membershipForm" matDialogContent>
    <p class="text-gray-600 text-base" *ngIf="data.membership">{{data.membership?.user | humanName:'full':true}}</p>

    <mat-form-field *ngIf="!data.membership" class="w-full" uiFormMatFormFieldErrorDisplayer appearance="outline">
        <mat-label i18n>email</mat-label>
        <input matInput required formControlName="email" type="email">
        <mat-error></mat-error>
    </mat-form-field>
    <mat-selection-list formControlName="roles">
        <mat-list-option [value]="role.value" *ngFor="let role of roles">
            <p matListItemTitle>{{role.title | titlecase}}</p>
            <p matListItemLine class="first-letter:capitalize">{{role.description}}</p>
        </mat-list-option>
    </mat-selection-list>
</form>
<footer matDialogContent align="end">
    <button matDialogClose mat-button>
        <span i18n>cancel</span>
    </button>
    <button class="ml-2" (click)="submit()" mat-flat-button color="primary">
        <span i18n>confirm</span>
    </button>


</footer>