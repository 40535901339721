<div matSnackBar class="flex justify-between items-center ">
  <div class="text-white" matSnackBarLabel>

    <p *ngIf="non_field_errors">{{ non_field_errors }}</p>
    <p *ngIf="detail_error">{{ detail_error }}</p>
    <p *ngFor="let item of field_errors | keyvalue">
      <span class="mr-2">{{ item.key }}</span>: {{ item.value }}
    </p>
  </div>
  <button mat-button (click)="close()" matSnackBarAction>OK</button>
</div>