<menu color="primary" class="flex items-center gap-4 border-b border-b-gray-100 px-4">
  @if(!(showAccountCard$|async)) {
  <button (click)="toggleMenu()" mat-icon-button><mat-icon>menu</mat-icon></button>
  }
  <img class="h-10" [src]="amblemSrc$|async">
  <img class="h-24 cursor-pointer" [src]="selectedBanner()" (click)="updateBanner()" alt="">
  <div class="ml-auto flex flex-col gap-4">
    <div class="gap-4 text-black flex items-center justify-end">
      @if ((currentAccount$ | async) ;as office) {

      <button data-rum-id="patients-fast-search" (click)="toggleSearch($event)" mat-icon-button>
        <mat-icon>person_search</mat-icon>
      </button>
      <button data-rum-id="today-appointments" (click)="openTodayAppointments()" mat-icon-button>
        <mat-icon>today</mat-icon>
      </button>

      }
      @if(!(isXsmall$|async)) {


      @if ((authUser$|async) ; as user) {
      <img matRipple (click)="openAuthStaffCard(user)" authAvatarLoader [user]="$any(user)"
        class="hover:cursor-pointer w-10 rounded-full">
      }

      }
    </div>
    @if (isLarge$ |async) {
    <ui-layout-breadcrumb [breadcrumbs]="breadcrumbs$|async"></ui-layout-breadcrumb>

    }

  </div>
</menu>


<ng-template #accountMenu>
  <ng-container *ngIf="(currentAccount$ | async) as office">
    <button class="text-black" mat-button [matMenuTriggerFor]="menu">
      <mat-icon>expand_more</mat-icon>

      <span class="text-sm">{{ office.alias | titlecase}} <span class="text-sm text-gray-700">({{today
          |date:'zzz':currentOffset}})</span></span>
    </button>
    <mat-menu #menu="matMenu">
      <p class="p-4 gap-4 text-gray-700 flex items-center">
        {{ office.alias | titlecase}}
      </p>
      <mat-divider></mat-divider>

      <button [routerLink]="[office.id,'account-settings']" mat-menu-item>
        <mat-icon>settings</mat-icon>
        <span class="text-sm" i18n>settings</span>
      </button>
      <button [routerLink]="[office.id,'account-settings','memberships']" mat-menu-item>
        <mat-icon>group_add</mat-icon>
        <span class="text-sm" i18n>invite a member</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item>
        <mat-icon>domain_add</mat-icon>
        <span class="text-sm" i18n>create office</span>
      </button>
    </mat-menu>
  </ng-container>
</ng-template>