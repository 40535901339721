<form class="flex flex-col gap-6 max-w-screen-md" [formGroup]="form">
  <mat-form-field uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>phone number</mat-label>
    <shared-ui-phone-number-picker [initCountry]="ads.account?.defaultCountryCode" #picker="phonePicker"
      formControlName="phone_number">
    </shared-ui-phone-number-picker>
    <mat-hint *ngIf="picker.exampleNumber"><span class="mr-1" i18n>eg
        :</span>{{picker.exampleNumber}}</mat-hint>
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n>availabilities</mat-label>
    <mat-select multiple formControlName="availabilities">
      <mat-option [value]="'WHATSAPP'">WhatsApp</mat-option>
      <mat-option [value]="'VIBER'">Viber</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n>remarks</mat-label>
    <textarea matInput formControlName="remarks"></textarea>
  </mat-form-field>
</form>
