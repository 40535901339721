import { CommonModule } from '@angular/common';
import { Component, computed, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import {
  ContainerComponent,
  LoadingComponent,
} from '../../../../../shared/ui-layout/src';
import { ActivePatientsListComponent } from '../active-patients-list/active-patients-list.component';
import { InactivePatientsListComponent } from '../inactive-patients-list/inactive-patients-list.component';

type searchType = 'medical-tag' | 'profile' | 'medical-note';
type SearchConfig = Record<
  searchType,
  {
    fields: string[];
    filterKey: string;
  }
>;
const SEARCH_CONFIG_MAP: SearchConfig = {
  'medical-note': {
    fields: ['name', 'reference', 'headline'],
    filterKey: 'medical_notes__in',
  },
  'medical-tag': {
    fields: ['name', 'reference', 'headline'],
    filterKey: 'medical_tags__in',
  },
  profile: {
    fields: ['name', 'reference', 'phone_number', 'added', 'total_due'],
    filterKey: 'search',
  },
};

@Component({
  selector: 'doctorus-front-end-monorepo-patients-list',
  standalone: true,
  imports: [
    CommonModule,
    ContainerComponent,
    LoadingComponent,
    MatButtonToggleModule,
    ActivePatientsListComponent,
    InactivePatientsListComponent,
    MatPaginatorModule,
    FormsModule,
  ],
  templateUrl: './patients-list.component.html',
  styleUrl: './patients-list.component.scss',
})
export class PatientsListComponent {
  loading = signal(false);
  count = signal(0);
  pageIndex = signal<number | null>(null);
  pageSize = signal<number>(50);
  status: 'active' | 'inactive' = 'active';

  title = computed(() => $localize`patients list (${this.count()})`);

  updatePage(event: PageEvent): void {
    this.pageSize.set(event.pageSize);
    this.pageIndex.set(event.pageIndex);
  }
}
