import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imgSrc',
  standalone: true,
})
export class ImgSrcPipe implements PipeTransform {
  transform(value: string | File | Blob) {
    return typeof value === 'string'
      ? value
      : URL.createObjectURL(value as File | Blob);
  }
}
