<main class="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 md:gap-4 gap-6">
  <ui-layout-key-value key="schedules" i18n-key>
    <appointment-dates [appointment]="obj()"></appointment-dates>
  </ui-layout-key-value>
  <ui-layout-key-value key="time zone" i18n-key>
    <span>{{ obj().timezone }}</span>
  </ui-layout-key-value>

  <ui-layout-key-value key="status" i18n-key>
    <appointment-status-picker
      (statusChange)="setStatus($event)"
      [status]="obj().status"
    >
      <doctorus-front-end-monorepo-status
        matChipAvatar
        [status]="obj().status"
        entity="appointment"
        displayMode="BOTH"
      ></doctorus-front-end-monorepo-status>
    </appointment-status-picker>
  </ui-layout-key-value>

  <ui-layout-key-value key="location" i18n-key>
    @if (obj().location) {
      <mat-chip-row>
        <mat-icon matChipAvatar>location_on</mat-icon>
        {{ obj().location?.name }}
      </mat-chip-row>
    }
  </ui-layout-key-value>
  <ui-layout-key-value
    clickable="true"
    (click)="editAppointmentType()"
    key="task type"
    i18n-key
  >
    @if (obj().task_type) {
      <mat-chip>
        <p
          matChipAvatar
          class="w-3 h-3 rounded-full"
          [style.background-color]="obj().task_type?.color"
        ></p>
        <p class="chip-text">{{ obj().task_type?.name }}</p>
      </mat-chip>
    }
  </ui-layout-key-value>
  <ui-layout-key-value key="fees" i18n-key>
    <mat-chip-row [editable]="true" (click)="setFees()">
      <mat-icon matChipAvatar>payments</mat-icon>
      {{ obj().fees | coalesce: '-' }}
    </mat-chip-row>
  </ui-layout-key-value>

  <ui-layout-key-value key="assignees" class="col-span-full" i18n-key>
    <mat-chip-set>
      @for (item of obj().assignees; track $index) {
        <mat-chip-row>
          <img
            matChipAvatar
            doctorusFrontEndMonorepoAuthAvatarLoader
            [user]="$any(item)"
          />
          {{ item | humanName }}
        </mat-chip-row>
      } @empty {
        -
      }
    </mat-chip-set>
  </ui-layout-key-value>
</main>
@if (isDoctor$ | async) {
  <ui-layout-container
    [border]="false"
    [level]="3"
    paddingLevel=""
    i18n-title
    title="medical notes"
  >
    <span ngProjectAs="description">
      {{ obj().description }}
    </span>
    <div ngProjectAs="actions">
      <button (click)="editMedicalNote()" mat-stroked-button>
        <span i18n>edit </span>
      </button>
    </div>

    <quill-view
      [content]="obj().note | coalesce: '-'"
      format="html"
      theme="snow"
    ></quill-view>
  </ui-layout-container>
}
