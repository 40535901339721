import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule, RouterOutlet } from '@angular/router';
import {
  AccountRole,
  AuthService,
  ShowForRolesDirective,
} from '@doctorus-front-end-monorepo/auth';
import { Account } from '@doctorus-front-end-monorepo/graphql';
import { NavLink } from '@doctorus-front-end-monorepo/ui-layout';
import { map } from 'rxjs';
import { AvatarLoaderDirective, HumanNamePipe } from '@doctorus-front-end-monorepo/shared-util';
import { LayoutStateService } from '../layout-state.service';

@Component({
    selector: 'doctorus-front-end-monorepo-office-manager',
    templateUrl: './office-manager.component.html',
    styleUrls: ['./office-manager.component.scss'],
    imports: [
        CommonModule,
        MatIconModule,
        MatDividerModule,
        MatChipsModule,
        ShowForRolesDirective,
        AvatarLoaderDirective,
        MatButtonModule,
        RouterModule,
        HumanNamePipe,
        RouterOutlet,
        AsyncPipe,
        MatSidenavModule,
        MatTooltipModule,
    ]
})
export class OfficeManagerComponent {
  account = input.required<Account>();
  roles = inject(AuthService).authUserInfo()['cognito:groups'];
  user$ = inject(AuthService).authUserInfo$;
  navLinks: NavLink[] = [
    {
      name: $localize`calendar`,
      svgIcon: 'calendar_month',
      active: true,
      routerLink: ['scheduler'],
      requiredRoles: [AccountRole.DOCTOR, AccountRole.MEDICAL_ASSISTANT],
    },
    {
      name: $localize`patients`,
      svgIcon: 'patient_list',
      active: true,
      requiredRoles: [AccountRole.DOCTOR, AccountRole.MEDICAL_ASSISTANT],
      routerLink: ['patients'],
    },
    {
      name: $localize`care plans`,
      svgIcon: 'vaccines',
      active: true,
      requiredRoles: [AccountRole.DOCTOR, AccountRole.MEDICAL_ASSISTANT],
      routerLink: ['care-plans'],
    },
    {
      name: $localize`preferences and settings`,
      svgIcon: 'settings',
      requiredRoles: [AccountRole.MANAGER],
      active: true,
      routerLink: ['settings'],
    },
  ];
  private lss = inject(LayoutStateService);
  largeSideNav$ = this.lss.showAccountCard$.pipe(map(x => !x));
  sideMenuOpen = this.lss.sideMenuOpen$;

  toggle(): void {
    this.lss.toggleSideMenu();
  }
}
