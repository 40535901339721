import { FocusMonitor } from '@angular/cdk/a11y';
import {
  AsYouType,
  CountryCode,
  getCountries,
  getCountryCallingCode,
  getExampleNumber,
  parsePhoneNumberWithError,
} from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples';

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgFor } from '@angular/common';
import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  QueryList,
  Self,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NgControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import {
  MatFormField,
  MatFormFieldControl,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TCountryCode, getCountryData } from 'countries-list';
import { Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'shared-ui-phone-number-picker',
  templateUrl: './ui-phone-number-picker.component.html',
  styleUrls: ['./ui-phone-number-picker.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: UiPhoneNumberPickerComponent,
    },
  ],
  imports: [
    ReactiveFormsModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    MatIconModule,
    MatInputModule,
  ],
  exportAs: 'phonePicker',
})
export class UiPhoneNumberPickerComponent
  implements
    OnInit,
    OnDestroy,
    ControlValueAccessor,
    MatFormFieldControl<string>
{
  @ViewChildren(MatInput, { read: ElementRef<HTMLInputElement> })
  inputs!: QueryList<ElementRef<HTMLInputElement>>;

  private _disabled = false;
  private _placeholder!: string;
  protected _required: boolean | undefined;
  countries = getCountries();
  _initCountry?: CountryCode;
  @Input() set initCountry(payload: any) {
    this._initCountry = payload as CountryCode;
  }

  get initCountry(): CountryCode | undefined {
    return this._initCountry;
  }
  @Input() disabledCountry = false;
  stateChanges = new Subject<void>();
  @HostBinding('[id]') id = 'phone-number-picker';

  focused = false;
  controlType = 'phone-number-picker';
  autofilled?: boolean | undefined;
  touched = false;
  phoneForm: FormGroup;
  subscription = new Subscription();
  onChange = (a: any) => {};
  onTouched = () => {};

  constructor(
    private _elementRef: ElementRef<HTMLElement>,
    private _focusMonitor: FocusMonitor,
    private fb: FormBuilder,
    @Optional() public parentFormField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    this.phoneForm = this.fb.group({
      country: [''],
      phone_number: [''],
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  reset(): void {
    this.phoneForm.reset();
  }

  getCallingCode = (code: CountryCode): string =>
    code && getCountryCallingCode(code);
  getExempleNumber = (code: CountryCode): string =>
    code && this.getExempleNumber(code ?? this.initCountry);

  get exampleNumber(): string {
    return (
      this.phoneForm.get('country')?.value &&
      getExampleNumber(
        this.phoneForm?.get('country')?.value,
        examples,
      )?.formatInternational()
    );
  }

  @HostBinding('aria-describedby') userAriaDescribedBy!: string;
  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.phone-number-input-container',
    );
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }
  get empty() {
    const {
      value: { country, phone_number },
    } = this.phoneForm;

    return !phone_number;
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this._elementRef.nativeElement.querySelector('input')?.focus();
    }
  }
  ngOnInit(): void {
    if (this._initCountry && !this.phoneForm.get('country')?.value) {
      this.phoneForm.get('country')?.setValue(this._initCountry);
    }
    this.subscription.add(
      this.phoneForm.valueChanges
        .pipe(map(x => this.phoneForm.getRawValue()))
        .subscribe(x => {
          const asYouType = new AsYouType(x.country);
          const res = asYouType.input(x.phone_number);
          this.phoneForm.get('phone_number')?.setValue(res, {
            emitEvent: false,
          });
          const _value = asYouType.getNumber()?.number;
          this.onChange(_value);
          //this.updateNgControlErrors();
          this.markAsTouched();
        }),
    );
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.subscription.unsubscribe();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }
  onValueChange(event: any): void {}

  get phoneNumberCtrls(): AbstractControl[] {
    return (this.phoneForm.get('phone_number') as FormArray)?.controls ?? [];
  }

  get selectedCountry(): CountryCode | undefined {
    return this.phoneForm.get('country')?.value;
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    if (this.ngControl && this.ngControl.disabled !== null) {
      return this.ngControl.disabled;
    }
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    if (this.focused) {
      this.focused = false;
    }
    this.stateChanges.next();
  }
  @Input()
  get value(): string | null {
    const {
      value: { country, phone_number },
    } = this.phoneForm;
    return parsePhoneNumberWithError(phone_number, country).number;
  }
  set value(obj: string | null) {
    if (obj) {
      const parsedNumber = parsePhoneNumberWithError(obj);
      console.warn(parsedNumber);
      if (parsedNumber) {
        this.phoneForm.setValue(
          {
            country: parsedNumber.country,
            phone_number: parsedNumber.nationalNumber,
          },
          { emitEvent: false },
        );
      }
    }
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return (
      this._required ??
      this.ngControl?.control?.hasValidator(Validators.required) ??
      false
    );
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
  }

  get telPrefixStr(): string {
    return `( +${
      this.getCallingCode(
        this.phoneForm.get('country')?.value ?? this.initCountry,
      ) ?? '---'
    } )`;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return true || this.focused || !this.empty;
  }

  get errorState(): boolean {
    return (this.ngControl.invalid ?? false) && this.phoneForm.dirty;
  }

  writeValue(obj: string | null): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  markAsTouched() {
    this.onTouched();
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  resolveCountry = (code: string) =>
    getCountryData(code as unknown as TCountryCode);
}
