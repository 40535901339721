@let appointmentDetails = appointmentRx.value();
@let loading = appointmentRx.isLoading();

<header mat-dialog-title class="flex justify-between items-center">
  <h3
    class="hover:cursor-pointer"
    [routerLink]="
      rnhs.getApppointmentRoute(event.extendedProps.patient_info.id, event.id)
    "
  >
    {{ event.start | date: 'fullDate' }}
  </h3>
  <button type="button" (click)="closeWith('NONE')" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</header>
<mat-dialog-content class="flex py-4 flex-col gap-2">
  <div class="flex gap-2 flex-wrap">
    <mat-icon>schedule</mat-icon>
    <div class="flex flex-col gap-2">
      <span>
        {{ event.start | date: 'HH:mm ' }} - {{ event.end | date: 'HH:mm' }}
        @if (differentTimeZone) {
          <span>({{ currentTimezone }} {{ event.end | date: 'OO' }})</span>
        }
      </span>
      @if (differentTimeZone) {
        <span
          >{{ event.start | date: 'HH:mm' : appointmentOffset }} -
          {{ event.end | date: 'HH:mm' : appointmentOffset }}
          <span
            >({{ event.extendedProps.timezone }}
            {{ event.end | date: 'OO' : appointmentOffset }})</span
          ></span
        >
      }
    </div>
  </div>
  @if (event.extendedProps.task_type?.name) {
    <div class="flex gap-2 items-center">
      <!-- <mat-icon>medical_services</mat-icon> -->
      <span
        class="w-4 h-4 rounded-full mx-1"
        [style.background-color]="event.extendedProps.task_type?.color"
      ></span>
      <span>{{ event.extendedProps.task_type?.name | titlecase }}</span>
    </div>
  }
  <div class="flex gap-2 items-center">
    <mat-icon>location_on</mat-icon>
    <span>{{ event.extendedProps.location?.name | titlecase }}</span>
  </div>
  <appointment-status-picker
    (statusChange)="updateStatus($event)"
    [status]="event.extendedProps.status"
  >
    <div class="flex gap-2 items-center">
      <doctorus-front-end-monorepo-status
        [status]="event.extendedProps.status"
        entity="appointment"
        withBorder="false"
        displayMode="ICON_ONLY"
      ></doctorus-front-end-monorepo-status>
      <doctorus-front-end-monorepo-status
        [status]="event.extendedProps.status"
        withBorder="false"
        entity="appointment"
        displayMode="TEXT_ONLY"
      ></doctorus-front-end-monorepo-status>
    </div>
  </appointment-status-picker>
  @if (loading) {
    <div role="status" class="max-w-sm animate-pulse">
      <h3 class="h-3 bg-gray-300 rounded-full w-48 mb-4"></h3>
    </div>
  } @else if (appointmentDetails?.assignees?.length > 0) {
    <div class="flex gap-2 items-center">
      <mat-icon>stethoscope</mat-icon>
      <mat-chip-set>
        @for (user of appointmentDetails?.assignees; track $index) {
          <mat-chip>{{ user | humanName: 'short' }}</mat-chip>
        }
      </mat-chip-set>
    </div>
  }

  <div class="flex gap-2">
    <mat-icon>badge</mat-icon>
    <div class="flex flex-col gap-1">
      <a
        (click)="closeWith('NONE')"
        [routerLink]="
          event.extendedProps.patient_info.is_active
            ? rnhs.getPatientRoute(event.extendedProps.patient_info)
            : null
        "
        class="anchor flex items-center gap-2"
        >{{
          event.extendedProps.patient_info
            | humanName: 'full' : true
            | titlecase
        }}
        @if (event.extendedProps.patient_info.is_active === false) {
          <small i18n>( deleted )</small>
        }
      </a>
      @if (loading) {
        <div role="status" class="max-w-sm animate-pulse">
          <h3 class="h-3 bg-gray-300 rounded-full w-48 mb-4"></h3>
        </div>
      } @else {
        <section class="flex flex-wrap gap-1 items-center">
          @for (
            phonenumber of appointmentDetails.patient_info.phone_numbers;
            track $index;
            let last = $last
          ) {
            <patient-phone-number-box
              class="text-black"
              mode="SMALL"
              [phonenumber]="phonenumber"
            ></patient-phone-number-box>
            @if (!last) {
              &nbsp;/&nbsp;
            }
          }
        </section>
      }
      <div i18n>REF : {{ event.extendedProps.patient_info.reference }}</div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="flex gap-2 justify-end" align="end">
  <button
    class="red-button"
    type="button"
    (click)="closeWith('DELETE')"
    mat-flat-button
  >
    <span i18n>delete</span>
  </button>
  <button type="button" (click)="closeWith('EDIT')" mat-flat-button>
    <span i18n>edit</span>
  </button>
</mat-dialog-actions>
