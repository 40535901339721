import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule, DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {
  AccountDataService,
  AccountRole,
  AuthService,
} from '@doctorus-front-end-monorepo/auth';
import { GetAppointmentsGQL } from '@doctorus-front-end-monorepo/graphql';
import { ArrayExistPipe } from '@doctorus-front-end-monorepo/util-array';
import { RouterNavigationHelperService } from '@doctorus-front-end-monorepo/util-navigation';
import * as dateFns from 'date-fns';
import * as _ from 'lodash';
import { finalize, map } from 'rxjs';
import {
  ContainerComponent,
  EmptyStateComponent,
} from '../../../../../ui-layout/src';
import { AppointmentsListComponent } from '../appointments-list/appointments-list.component';
@Component({
  selector: 'appointment-today-appointments',
  templateUrl: './today-appointments.component.html',
  styleUrls: ['./today-appointments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AppointmentsListComponent,
    MatProgressBarModule,
    MatIconModule,
    MatListModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
    DatePipe,
    ArrayExistPipe,
    ContainerComponent,
    EmptyStateComponent,
  ],
})
export class TodayAppointmentsComponent {
  today = new Date();
  loading = signal(true);
  private dialogRef = inject(DialogRef);
  private getAppointmentsGQL = inject(GetAppointmentsGQL);
  public rhns = inject(RouterNavigationHelperService);
  public auth = inject(AuthService);
  public ads = inject(AccountDataService);
  accountRole = AccountRole;
  appointments$ = this.getAppointmentsGQL
    .fetch(
      {
        day: dateFns.formatISO(new Date(), {
          representation: 'date',
        }),
      },
      {
        fetchPolicy: 'no-cache',
      },
    )
    .pipe(
      map(x => _.sortBy(x.data.getAppointments, ['start'])),
      finalize(() => this.loading.set(false)),
    );

  close(): void {
    this.dialogRef.close();
  }
}
