import { provideDateFnsAdapter } from '@angular/material-date-fns-adapter';
import { MAT_CARD_CONFIG, MatCardConfig } from '@angular/material/card';
import {
  ErrorStateMatcher,
  MAT_DATE_LOCALE,
  ShowOnDirtyErrorStateMatcher,
} from '@angular/material/core';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogConfig,
} from '@angular/material/dialog';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import {
  MAT_ICON_DEFAULT_OPTIONS,
  MatIconDefaultOptions,
} from '@angular/material/icon';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { fr } from 'date-fns/locale';
export const materialProviders = [
  {
    provide: MAT_DIALOG_DEFAULT_OPTIONS,
    useValue: { panelClass: 'drop-shadow-md' } as MatDialogConfig,
  },
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: {
      appearance: 'outline',
    } as MatFormFieldDefaultOptions,
  },
  { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
  {
    provide: MAT_CARD_CONFIG,
    useValue: {
      appearance: 'outlined',
    } as MatCardConfig,
  },

  {
    provide: MAT_ICON_DEFAULT_OPTIONS,
    useValue: {
      fontSet: 'material-symbols-outlined',
    } as MatIconDefaultOptions,
  },
  { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
  { provide: MAT_DATE_LOCALE, useValue: fr },
  provideDateFnsAdapter(),
  // {
  //   provide: MAT_DATE_FORMATS,
  //   useValue: {
  //     parse: {
  //       dateInput: 'P',
  //       timeInput:''
  //     },
  //     display: {
  //       dateInput: 'P',
  //       monthYearLabel: 'LLL uuuu',
  //       dateA11yLabel: 'PP',
  //       monthYearA11yLabel: 'LLLL uuuu',
  //     },
  //   } as MatDateFormats,
  // },
];
