import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarModule,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { BackendError } from '../error.utils';

@Component({
    selector: 'logger-server-error-snack-bar',
    imports: [CommonModule, MatSnackBarModule],
    templateUrl: './server-error-snack-bar.component.html',
    styleUrl: './server-error-snack-bar.component.scss'
})
export class ServerErrorSnackBarComponent {
  non_field_errors: string;
  detail_error: string;
  field_errors: { [key: string]: string };

  constructor(
    private ref: MatSnackBarRef<''>,
    @Inject(MAT_SNACK_BAR_DATA) public data: BackendError<any>
  ) {
    console.warn(data);

    this.non_field_errors = data['non_field_errors'];
    this.detail_error = data['detail'];
    delete data['non_field_errors'];
    delete data['detail'];
    this.field_errors = data;
  }

  close(): void {
    this.ref.dismiss();
  }
}
