import { DatePipe, NgFor, TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Inject,
  Input,
  LOCALE_ID,
  model,
  Output,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import {
  AccountDataService,
  AppointmentsFilterInput,
} from '@doctorus-front-end-monorepo/graphql';
import {
  HumanNamePipe,
  STATUS_CONF,
} from '@doctorus-front-end-monorepo/shared-util';
import { debounceTime, filter } from 'rxjs';

@Component({
  selector: 'medical-space-web-scheduler-filter',
  templateUrl: './scheduler-filter.component.html',
  styleUrls: ['./scheduler-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDatepickerModule,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    HumanNamePipe,
    MatButtonModule,
    TitleCasePipe,
  ],
})
export class SchedulerFilterComponent {
  @Input({ required: true }) selectedDate!: Date;
  @Output() dateChanged = new EventEmitter<Date>();
  filterChanged = model<AppointmentsFilterInput>();
  humanPipe = new HumanNamePipe();
  public account = inject(AccountDataService).account;

  queryParamsFg = this.fb.group({
    location_id__in: [[]],
    status__in: [[]],
    assignees__overlap: [[]],
    task_type_id__in: [[]],
  });
  datePipe = new DatePipe(this.localeId);
  statusList = Object.entries(STATUS_CONF['appointment']);
  constructor(
    @Inject(LOCALE_ID) private localeId: string,
    private fb: FormBuilder,
  ) {
    this.queryParamsFg.valueChanges
      .pipe(
        debounceTime(300),
        filter(() => this.queryParamsFg.valid),
      )
      .subscribe(value => {
        this.filterChanged.set(value);
      });
  }
  toDate(event: Date): void {
    this.dateChanged.next(event);
  }
}
