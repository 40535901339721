import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { MedicalHistoryModel } from '../../../../../shared/data-access/graphql/src';
import { BaseEntityListComponent } from '../../../../../shared/feature-entity/src';

@Component({
    selector: 'lib-ui-medical-history-model',
    imports: [CommonModule, MatTableModule, RouterModule, MatIconModule],
    templateUrl: './ui-medical-history-model.component.html',
    styleUrl: './ui-medical-history-model.component.css'
})
export class UiMedicalHistoryModelComponent extends BaseEntityListComponent<MedicalHistoryModel> {}
