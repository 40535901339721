@let patients = patientsRessource.value();
@let isLoading = patientsRessource.isLoading();

@if (isLoading) {
  <ui-layout-loading></ui-layout-loading>
} @else {
  @if (patients?.length > 0) {
    <dl>
      @for (item of patients; track $index) {
        <dt>
          <a
            class="anchor no-underline text-lg font-medium"
            [routerLink]="['..', item.id]"
          >
            {{ item | humanName }}
          </a>
        </dt>
        <dd [innerHTML]="item.headline"></dd>
      }
    </dl>
  } @else {
    <ui-layout-empty-state title="found no patients" i18n-title
      ><span i18n
        >No patients found matching the medical term '{{
          searchTerm() ?? ''
        }}'</span
      ></ui-layout-empty-state
    >
  }
}
