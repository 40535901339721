import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MeasureModel } from '../../../../../shared/data-access/graphql/src';
import { BaseEntityFormComponent } from '../../../../../shared/feature-entity/src';
import { UiMatFormFieldErrorDisplayerDirective } from '../../../../../shared/form/ui-form/src';

@Component({
  selector: 'lib-measure-model-form',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    UiMatFormFieldErrorDisplayerDirective,
    ReactiveFormsModule,
  ],
  templateUrl: './measure-model-form.component.html',
  styleUrl: './measure-model-form.component.scss',
})
export class MeasureModelFormComponent extends BaseEntityFormComponent<MeasureModel> {
  override initFormFun = () =>
    this.fb.group({
      id: [this.obj()?.id],
      name: [this.obj()?.name, Validators.required],
      unit: [this.obj()?.unit, Validators.required],
      disabled: [this.obj()?.disabled ?? false, Validators.required],
      order: [this.obj()?.order, Validators.min(0)],
      description: [this.obj()?.description],
    });
}
