<!-- <header class="flex gap-2 items-center p-2">
  <button (click)="close()" mat-icon-button><mat-icon>close</mat-icon></button>
  <h3 class="capitalize">{{today | date:'mediumDate'}}</h3>
</header>
@if(loading()) {
<mat-progress-bar mode="query"></mat-progress-bar>

}
<mat-divider></mat-divider>
@if(appointments$|async;as appointments) {
<mat-list>
  <p i18n matSubheader>{{appointments.length}}
    appointments(s) found</p>
  @for (appointment of appointments; track $index) {
  <mat-divider></mat-divider>

  <mat-list-item>
    @if(appointment.status |resolveStatusConfig:'appointment';as status) {
    <mat-icon matListItemIcon [className]="status.colorClass">{{status.svgIcon}}</mat-icon>

    }
    <div class="pt-2" matListItemMeta>
      <mat-icon *showForRoles="[accountRole.DOCTOR]" class="text-black material-symbols-outlined hover:cursor-pointer"
        [queryParams]="{initAppointmentId :appointment.id}"
        [routerLink]="rhns.medicalRecordRoute(appointment.patient_info.id)">medical_information</mat-icon>
    </div>
    <div matListItemTitle>
      <appointment-dates [timezone]="ads.account?.appointmentDefaultTimeZone"
        [appointment]="appointment"></appointment-dates>

    </div>
    <a class="underline" matListItemLine
      [routerLink]="rhns.getPatientRoute(appointment.patient_info.id)">{{appointment.patient_info |
      humanName :'full'}}</a>


  </mat-list-item>

  }
</mat-list>
}@else {
<div class="flex flex-col items-center text-gray-800 p-6">
  <mat-icon class="text-5xl" [inline]="true">today</mat-icon>
  <p class="first-letter:uppercase text-center" i18n>no appointment found for today</p>
</div>
} -->

<ui-layout-container
  headerDivider="true"
  border="false"
  sticky="true"
  [title]="today | date: 'mediumDate'"
>
  <button ngProjectAs="actions" (click)="close()" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
  <div ngProjectAs="description">
    @if (loading()) {
      <mat-progress-bar mode="query"></mat-progress-bar>
    } @else {
      <span i18n
        >appointments(s) found {{ (appointments$ | async)?.length }}</span
      >
    }
  </div>
  @if (appointments$ | async; as appointments) {
    @if (appointments.length > 0) {
      <appointment-appointments-list
        editableStatus="false"
        editable="false"
        timeOnly="true"
        includeMedicalBoardLink="true"
        includeMeta="false"
        [appointments]="appointments"
        includePatientInfo="true"
      >
      </appointment-appointments-list>
    } @else {
      <ui-layout-empty-state>
        <h3 i18n>There are no appointments for today</h3>
      </ui-layout-empty-state>
    }
  }
</ui-layout-container>
