<h2 matDialogTitle>
  {{headerTitle}}
</h2>
<main class="pt-4" mat-dialog-content>
  <ng-container #form [ngComponentOutletInputs]="inputs" [ngComponentOutlet]="$any(componentRef)"></ng-container>

</main>
<footer matDialogActions align="end">
  <button type="button" mat-dialog-close mat-button>
    <span class="capitalize" i18n>cancel</span>
  </button>
  <button class="ml-2" [disabled]="loading()" type="button" (click)="submit()" color="primary" mat-flat-button>
    <span class="capitalize" i18n>submit</span>
  </button>
</footer>