import { humanize } from '@doctorus-front-end-monorepo/shared-util';
import { format } from 'date-fns';
import {
  CarePlan,
  MedicalTask,
} from '../../../../shared/data-access/graphql/src';
import { EntityConfig } from '../../../../shared/feature-entity/src';

export const carePlanEntityConfig: EntityConfig<CarePlan, any> =
  new EntityConfig({
    //baseUri: 'medical_note/generatedDocuments/',
    feature: $localize`care plan`,
    toString: (carePlan: CarePlan) => `${carePlan.name}`,
    description: (carePlan: CarePlan) =>
      carePlan.by &&
      carePlan.at &&
      $localize`created by ${humanize(carePlan.by, 'full')} on ${format(new Date(carePlan.at), 'dd-MM-yyyy')} at ${format(new Date(carePlan.at), 'HH:mm')}`,
  });

export const medicalTaskConfig: EntityConfig<MedicalTask, any> =
  new EntityConfig({
    //baseUri: 'medical_note/generatedDocuments/',
    feature: $localize`medical task`,
    toString: (obj: MedicalTask) => `${obj.id}`,
    description: (obj: MedicalTask) =>
      obj.by &&
      obj.at &&
      $localize`created by ${humanize(obj.by, 'full')} on ${format(new Date(obj.at), 'dd-MM-yyyy')} at ${format(new Date(obj.at), 'HH:mm')}`,
  });
