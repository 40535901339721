<mat-toolbar color="primary" class="uppermenu">
  @if(!(showAccountCard$|async)) {
  <button (click)="toggleMenu()" mat-icon-button><mat-icon>menu</mat-icon></button>
  }
  <img class="h-8" [src]="amblemSrc$|async">
  <div class="ml-auto gap-4   text-black flex items-center">
    <ng-container *ngIf="(currentAccount$ | async) as office">

      <button data-rum-id="patients-fast-search" (click)="toggleSearch($event)" mat-icon-button>
        <mat-icon>person_search</mat-icon>
      </button>
      <button data-rum-id="today-appointments" (click)="openTodayAppointments()" mat-icon-button>
        <mat-icon>today</mat-icon>
      </button>
      @if(showAccountCard$|async) {
      <ng-container *ngTemplateOutlet="accountMenu">
      </ng-container>
      }

    </ng-container>
    <ng-container *ngTemplateOutlet="authUserAvatar">
    </ng-container>
  </div>
</mat-toolbar>

<!-- @if(isTablet$|async) {
<mat-toolbar color="primary" class="uppermenu">
  <img class="h-8" [src]="'assets/img/logo.png'">
  <div class=" gap-4 text-sm  text-black flex items-center">
    <ng-container *ngIf="(currentAccount$ | async) as office">

      <button data-rum-id="patients-fast-search" *ngIf="!searchActive()" (click)="toggleSearch($event)" mat-icon-button>
        <mat-icon>person_search</mat-icon>
      </button>
      <ng-container *ngTemplateOutlet="accountMenu">
      </ng-container>

    </ng-container>
    <ng-container *ngTemplateOutlet="authUserAvatar">
    </ng-container>
  </div>
</mat-toolbar>
}
@if(isHandset$|async) {
<mat-toolbar color="primary" class="uppermenu">

  <img class="h-8" [src]="'assets/img/log-emblem.png'">
  <div class=" gap-4 text-sm  text-black flex items-center">
    <ng-container *ngIf="(currentAccount$ | async) as office">

      <button data-rum-id="patients-fast-search" *ngIf="!searchActive()" (click)="toggleSearch($event)" mat-icon-button>
        <mat-icon>person_search</mat-icon>
      </button>
      <ng-container *ngTemplateOutlet="accountMenu">
      </ng-container>

    </ng-container>
    <ng-container *ngTemplateOutlet="authUserAvatar">
    </ng-container>
  </div>
</mat-toolbar>
} -->


<ng-template #topBar>
  <mat-toolbar color="primary" class="uppermenu">
    <div class=" flex">
      <button mat-icon-button><mat-icon>menu</mat-icon></button>
      <img class="h-10" [src]="'assets/img/log-emblem.png'">
    </div>

    <div class=" gap-4 text-sm  text-black flex items-center">
      <ng-container *ngIf="(currentAccount$ | async) as office">

        <button data-rum-id="patients-fast-search" (click)="toggleSearch($event)" mat-icon-button>
          <mat-icon>person_search</mat-icon>
        </button>
        <button data-rum-id="today-appointments" (click)="openTodayAppointments()" mat-icon-button>
          <mat-icon>today</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngTemplateOutlet="authUserAvatar">
      </ng-container>
    </div>
  </mat-toolbar>
</ng-template>



<ng-template #authUserAvatar>
  @if ((authUser$|async) ; as user) {
  <img matRipple (click)="openAuthStaffCard(user)" authAvatarLoader [user]="$any(user)"
    class="hover:cursor-pointer w-10 rounded-full">
  }

</ng-template>
<ng-template #accountMenu>
  <ng-container *ngIf="(currentAccount$ | async) as office">
    <button class="text-black" mat-button [matMenuTriggerFor]="menu">
      <mat-icon>expand_more</mat-icon>

      <span class="text-sm">{{ office.alias | titlecase}} <span class="text-sm text-gray-700">({{today
          |date:'zzz':currentOffset}})</span></span>
    </button>
    <mat-menu #menu="matMenu">
      <p class="p-4 gap-4 text-gray-700 flex items-center">
        {{ office.alias | titlecase}}
      </p>
      <mat-divider></mat-divider>

      <button [routerLink]="[office.id,'account-settings']" mat-menu-item>
        <mat-icon>settings</mat-icon>
        <span class="text-sm" i18n>settings</span>
      </button>
      <button [routerLink]="[office.id,'account-settings','memberships']" mat-menu-item>
        <mat-icon>group_add</mat-icon>
        <span class="text-sm" i18n>invite a member</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item>
        <mat-icon>domain_add</mat-icon>
        <span class="text-sm" i18n>create office</span>
      </button>
    </mat-menu>
  </ng-container>
</ng-template>
