import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  inject,
  input,
  OnInit,
  Signal,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FetchPolicy } from '@apollo/client/core';
import { AccountRole, AuthService } from '@doctorus-front-end-monorepo/auth';
import {
  AccountDataService,
  FetchPatientService,
  MedicalHistory,
  PatientDataService,
  RealTimeSubscriptionsService,
} from '@doctorus-front-end-monorepo/graphql';
import { HumanNamePipe } from '@doctorus-front-end-monorepo/shared-util';
import {
  LayoutStateService,
  LoadingComponent,
  NavLink,
  NavTreeComponent,
} from '@doctorus-front-end-monorepo/ui-layout';
import { QuillViewHTMLComponent } from 'ngx-quill';
import { map, merge, Subscription, tap } from 'rxjs';

@Component({
  selector: 'doctorus-front-end-monorepo-patient-details',
  imports: [
    CommonModule,
    MatSidenavModule,
    MatChipsModule,
    LoadingComponent,
    QuillViewHTMLComponent,
    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    RouterModule,
    MatIconModule,
    NavTreeComponent,
    HumanNamePipe,
    MatDividerModule,
  ],
  templateUrl: './patient-details.component.html',
  styleUrl: './patient-details.component.scss',
})
export class PatientDetailsComponent implements OnInit {
  @ViewChild('navContent', { read: TemplateRef }) navCmp!: TemplateRef<unknown>;
  readonly bottomSheet = inject(MatBottomSheet);
  patId = input.required<string>();

  accountRole = AccountRole;
  fetchPatientService = inject(FetchPatientService);
  isDoctor = inject(AuthService).isAuthUserDoctor;
  roles$ = inject(AuthService).authUserRoles$;
  rss = inject(RealTimeSubscriptionsService);
  private subscription = new Subscription();
  private fetchPolicy: FetchPolicy = 'cache-first';
  isLarge$ = inject(LayoutStateService).isLarge$;
  router = inject(Router);
  route = inject(ActivatedRoute);
  patientRx = rxResource({
    request: () => ({ id: this.patId() }),
    loader: params =>
      this.fetchPatientService
        .watch({ id: params.request.id }, { fetchPolicy: this.fetchPolicy })
        .valueChanges.pipe(
          map(res => res.data.getPatient),
          tap(patient => {
            this.pds.selectedPatient = patient;
            this.fetchPolicy = 'cache-first';
          }),
        ),
  });
  pds = inject(PatientDataService);
  patient = this.patientRx.value;

  medicalHistoryModels =
    inject(AccountDataService).account?.medicalHistoryModels;

  currentMedicalHistory = computed(
    () =>
      this.patient()
        ?.medical_histories.filter(medHist => medHist.status)
        .reduce(
          (acc: Map<string, MedicalHistory[]>, current) =>
            acc.set(current.name, [...(acc.get(current.name) ?? []), current]),
          new Map(),
        ) ?? new Map(),
  );
  generalRoutes: Signal<NavLink[]> = computed(() => [
    {
      name: $localize`administration`,
      expanded: true,

      children: [
        {
          name: $localize`appointments (${this.patient()?.appointments?.length})`,
          active: true,
          routerLink: ['appointments'],
        },
        {
          name: $localize`payments (${this.patient()?.payments?.length})`,

          active: true,

          routerLink: ['payments'],
        },
        {
          name: $localize`contacts (${this.patient()?.phone_numbers?.length})`,

          active: true,
          routerLink: ['contacts'],
        },
      ],
    },
    {
      name: $localize`medical records`,
      requiredRoles: [AccountRole.DOCTOR],
      expanded: true,

      children: [
        {
          name: $localize`summary`,
          routerLink: ['summary'],
        },
        {
          name: $localize`medical histories (${this.patient()?.medical_histories?.length})`,
          routerLink: ['medical-histories'],
        },
        {
          name: $localize`measures (${this.patient()?.medical_histories?.length})`,
          routerLink: ['measures'],
        },
        {
          name: $localize`documents (${this.patient()?.allDocuments?.length})`,
          routerLink: ['documents'],
        },
        {
          name: $localize`care plans (${this.patient()?.care_plans?.length})`,
          routerLink: ['care-plans'],
        },
        {
          name: $localize`general notes`,
          routerLink: ['general-notes'],
        },
      ],
    },
  ]);

  toggleBottomSheet(): void {
    this.bottomSheet.open(this.navCmp);
  }

  ngOnInit(): void {
    this.subscription.add(
      merge(
        this.rss.listenAppointmentSubscriptions(this.patId()),
        this.rss.listenPaymentsSubscriptions(this.patId()),
      ).subscribe(() => {
        this.fetchPolicy = 'network-only';
        this.patientRx.reload();
      }),
    );
  }
}
