<form (focusin)="onFocusIn($event)" (focusout)="onFocusOut($event)" [formGroup]="phoneForm" class="flex gap-2">
  <mat-select [value]="initCountry" class="country-picker" [disabled]="disabledCountry" [disableOptionCentering]="false"
    data-test-id="country-selector" formControlName="country">
    <mat-select-trigger>
      <span data-test-id="tel-prefix" class="text-gray-700 mr-1">{{ telPrefixStr }}</span>
    </mat-select-trigger>
    <mat-option [id]="code" [value]="code" *ngFor="let code of countries">
      {{ resolveCountry(code).name}}
      ( +{{getCallingCode(code) }} )
    </mat-option>
  </mat-select>
  <input type="tel" class="flex-1 phnoe-number-field" formControlName="phone_number">
  <!-- @if (phoneForm.dirty) {
  <mat-icon (click)="reset()" class="ml-auto hover:cursor-pointer">clear</mat-icon>

  } -->

</form>
