<section class="grid grid-cols-2 gap-6">
  <ui-layout-key-value key="name" i18n-key>
    {{obj().name}}
  </ui-layout-key-value>

  <ui-layout-key-value key="slot interval" i18n-key>
    {{obj().slot_interval}}
  </ui-layout-key-value>

  <ui-layout-key-value class="col-span-full" key="working rules" i18n-key>
    <ul>
      @for (workingRule of obj().working_periods; track $index) {
      <li>{{workingRule[0] | time}} -> {{workingRule[1] | time}}</li>
      }
    </ul>
  </ui-layout-key-value>
</section>
