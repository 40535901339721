import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';
import { Patient } from '../../../../../data-access/graphql/src';
import { BaseEntityFormComponent } from '../../../../../feature-entity/src';

@Component({
    selector: 'patient-medical-note-form',
    imports: [CommonModule, QuillEditorComponent, ReactiveFormsModule],
    template: `<form [formGroup]="form">
    <quill-editor class="h-96" formControlName="medical_notes"></quill-editor>
    <small
      class="pl-4 text-red-600"
      *ngIf="
        form.get('medical_notes') &&
        form.get('medical_notes')?.invalid &&
        (form.get('medical_notes')?.dirty || form.get('medical_notes')?.touched)
      "
      i18n
      >this field can't be empty</small
    >
  </form>`,
    styles: ``
})
export class MedicalNoteFormComponent extends BaseEntityFormComponent<
  Pick<Patient, 'id' | 'medical_notes'>
> {
  override initFormFun = () =>
    this.fb.group({
      id: [this.obj()?.id, Validators.required],
      medical_notes: [this.obj()?.medical_notes],
    });
}
