// export type PatientSearchType =
//   | 'medical-tag'
//   | 'profile'
//   | 'medical-notes-search';

export const PHONE_NUMBER_AVAILABILTIES: Array<
  Record<'title' | 'value', string>
> = [
  {
    title: 'WhatsApp',
    value: 'WHATSAPP',
  },
  {
    title: 'Viber',
    value: 'VIBER',
  },
];
