<mat-sidenav-container class="h-full">
  @if(largeSideNav$|async) {
  <mat-sidenav [opened]="sideMenuOpen|async" mode="over" fixedTopGap="92" fixedInViewport>
    <header class="flex justify-between p-2 py-4 items-start">
      <div class="flex flex-col">
        @if(user$|async;as user) {
        <div class="flex items-center gap-2">
          <img matRipple authAvatarLoader [user]="$any(user)" class="hover:cursor-pointer w-10 rounded-full">
          <div>
            <h3 class="flex flex-col">{{user|humanName:'full'}}</h3>
            <span>{{user.email}}</span>
          </div>

        </div>
        }
      </div>
      <button (click)="toggle()" mat-icon-button><mat-icon>close</mat-icon></button>

    </header>
    <mat-divider></mat-divider>
    <header class="flex flex-col p-2">
      <div class="flex items-center gap-2">
        <mat-icon>account_balance_wallet</mat-icon>
        <h3>{{account().alias}}</h3>
      </div>


      <a class="anchor self-end" [routerLink]="['/','staff-baord']" i18n>switch account</a>

    </header>

    <nav class="flex flex-col items-center gap-4">
      @for (link of navLinks; track $index) {
      <a *showForRoles="link.requiredRoles" [matTooltip]="link.name" matTooltipPosition="right"
        class="w-full rounded-sm hover:bg-gray-100 flex gap-4 p-2 py-4" [routerLink]="link.routerLink"
        [routerLinkActive]="['bg-gray-200','hover:bg-gray-200']">
        <mat-icon>{{link.svgIcon}}</mat-icon>
        <span>{{link.name|titlecase}}</span>
      </a>
      }
    </nav>


  </mat-sidenav>
  }
  @else {
  <mat-sidenav mode="side" fixedTopGap="96" class="w-24 border-r-gray-100 border-r bg-gray-50" fixedInViewport opened>
    <nav class="flex flex-col items-center  gap-4 pt-4 ">
      @for (link of navLinks; track $index) {
      <a *showForRoles="link.requiredRoles" [matTooltip]="link.name" matTooltipPosition="right"
        class="w-full rounded-full hover:bg-blue-100 flex justify-center py-2" [routerLink]="link.routerLink"
        [routerLinkActive]="['bg-blue-200','hover:bg-blue-200']">
        <mat-icon>{{link.svgIcon}}</mat-icon>
      </a>
      }
    </nav>


  </mat-sidenav>
  }

  <mat-sidenav-content>
      <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>