import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from './auth-service/auth.service';

export const authGuard: CanActivateFn = (route, state) => {
  const oauthService: AuthService = inject(AuthService);
  const router: Router = inject(Router);
  return oauthService.isLoggedIn().then(x =>
    x
      ? x
      : router.createUrlTree(['/', 'login'], {
          queryParams: {
            redirect: ['/'],
          },
        }),
  );
};
