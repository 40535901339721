/* eslint-disable @typescript-eslint/no-empty-interface */
import { EntityConfig } from '@doctorus-front-end-monorepo/feature-entity';
import { Patient, PatientPayment } from '@doctorus-front-end-monorepo/graphql';
import * as dateFns from 'date-fns';
// export class PatientPayment extends Entity<PatientPayment> {
//   static deserialize = (args: ApiPatientPaiment): PatientPayment =>
//     new PatientPayment({ ...args });
// }

const toString = (entity: PatientPayment): string =>
  $localize`${entity.amount} payed on ${dateFns.format(
    new Date(entity.date),
    'P',
  )}`;
export const patientPaimentConfig: EntityConfig<PatientPayment, any, Patient> =
  new EntityConfig({
    feature: $localize`paiment`,
    toString: toString,
    // graphQl: {
    //   type: 'PatientPayment',
    //   parent: {
    //     parentProp: 'patient',
    //     typeName: 'Patient',
    //     childProp: 'payments'
    //   },
    //   mutation: {
    //     // put: {
    //     //   name: 'putPatientPayment',
    //     //   query: gql<
    //     //     { putPatientPayment: PatientPayment },
    //     //     {
    //     //       payload: any;
    //     //       id?: any;
    //     //     }
    //     //   >`
    //     //     ${ActionMetadataFieldsFragmentDoc}
    //     //     mutation putPatientPayment(
    //     //       $payload: PatientPaymentInput!
    //     //       $id: ID
    //     //     ) {
    //     //       putPatientPayment(payload: $payload, id: $id) {
    //     //         id
    //     //         amount
    //     //         patient
    //     //         date
    //     //         metadata {
    //     //           ...ActionMetadataFields
    //     //         }
    //     //       }
    //     //     }
    //     //   `
    //     // },
    //     delete: gql`
    //       ${ActionMetadataFieldsFragmentDoc}

    //       mutation deletePatientPayment($id: ID!) {
    //         deletePatientPayment(id: $id) {
    //           id
    //           metadata {
    //             ...ActionMetadataFields
    //           }
    //         }
    //       }
    //     `
    //   }
    // },
    // deserializerFun: PatientPayment.deserialize,
    realTimeDataChangeConfig: {
      soundNotify: true,
      visualNotify: true,
      visualDuration: 20000,
    },
  });
