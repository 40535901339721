<form [formGroup]="formGroup" class="flex flex-wrap gap-4 items-center">
  <mat-form-field uiFormMatFormFieldErrorDisplayer class="flex-auto">
    <mat-label i18n>key</mat-label>

    <input type="text" matInput formControlName="key">
  </mat-form-field>
  <mat-form-field uiFormMatFormFieldErrorDisplayer class="flex-auto">
    <mat-label i18n>value</mat-label>

    <input type="text" matInput formControlName="value">
  </mat-form-field>



</form>