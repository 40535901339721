import { CommonModule, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { BaseEntityFormComponent } from '@doctorus-front-end-monorepo/feature-entity';
import {
  AccountDataService,
  MedicalHistory,
  Patient,
} from '@doctorus-front-end-monorepo/graphql';
import { RteComponent } from '@doctorus-front-end-monorepo/rte';
import { UiMatFormFieldErrorDisplayerDirective } from '@doctorus-front-end-monorepo/ui-form';
import { map } from 'rxjs';
import { MedicalHistoryFormBuilderService } from '../medical-history-form-builder.service';

@Component({
  selector: 'medical-history-medical-history-form',
  templateUrl: './write-medical-history-form.component.html',
  styleUrls: ['./write-medical-history-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    UiMatFormFieldErrorDisplayerDirective,
    MatRadioModule,
    MatInputModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatIconModule,
    MatOptionModule,
    RteComponent,
    NgIf,
    MatButtonModule,
  ],
})
export class WriteMedicalHistoryFormComponent extends BaseEntityFormComponent<
  MedicalHistory,
  { name?: string; patient?: Patient; medicalhistoriesTypeProps?: Set<string> }
> {
  medicalHistoryNames$ = inject(AccountDataService).currentAccount$.pipe(
    map(
      account =>
        new Set(
          account?.medicalHistoryModels
            ?.map(x => x.type.trim())
            .concat(account.account_summary.medical_history_names)
            .sort(),
        ),
    ),
  );
  private fbs = inject(MedicalHistoryFormBuilderService);
  override initFormFun = () =>
    this.fbs.createForm(this.extra()?.patient, this.obj(), this.extra()?.name);
}
