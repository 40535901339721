<div class="relative" [ngClass]="classes()">
  @if (dismissible()) {
    <mat-icon
      (click)="dismiss()"
      class="absolute text-xl right-2 top-2 text-gray-500 hover:text-black hover:cursor-pointer transition-colors"
      >close</mat-icon
    >
  }
  @if (withHeader()) {
    <header [ngClass]="headerClass()">
      <section class="flex flex-wrap gap-4 items-center">
        @if (matIcon) {
          <mat-icon>{{ matIcon }}</mat-icon>
        } @else {
          <ng-content select="media"></ng-content>
        }

        <a class="mr-auto" [routerLink]="link" [class]="titleClass()">{{
          title
        }}</a>
        <ng-content select="actions"></ng-content>
      </section>
      @if (description) {
        <p class="text-gray-600">{{ description }}</p>
      } @else {
        <ng-content
          class="text-gray-600 first-letter:uppercase"
          select="description"
        ></ng-content>
      }
    </header>
  }

  <main [ngClass]="contentClass()">
    <ng-content></ng-content>
  </main>
</div>
