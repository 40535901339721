<input accept='image/*,.heic,.heif' hidden (change)="onImagePick($event)" #fileInput type="file" id="file">
<header class="flex items-center gap-2 p-2 border-b border-b-gray-200 sticky top-0 bg-white z-20">
  <button mat-icon-button (click)="close()"><mat-icon>close</mat-icon></button>
  <h2 class="capitalize" i18n>image editor</h2>
  <button (click)="close(editedImage())" [disabled]="!editedImage()" class="ml-auto" mat-flat-button
    color="primary"><span class="capitalize" i18n>confirm</span></button>
</header>
<main class="content p-4">
  @if (images() | arrayExist) {

  <section class="p-4 flex-col gap-4">
    <div class="flex gap-2 items-center mb-4 border border-gray-100 rounded-md p-4">
      <button class="mr-2" (click)="fileInput.click()" mat-mini-fab>
        <mat-icon>add</mat-icon>
      </button>
      @for (image of images(); track $index) {
      <img (click)="selectImage(image)" [src]="image"
        class="cover-fill hover:cursor-pointer rounded-md border border-transparent"
        [class.border-gray-800]="image === selectedImage" height="64" width="64">
      }


    </div>
    @if(!downloading()) {
    @if(selectedImage) {
    <pintura-editor (update)="handleImageUpdate()" (process)="handleEditorProcess($event)" class="image-editor"
      [src]="selectedImage" [options]="options"></pintura-editor>


    }
    }@else {
    <div class="container flex-col flex gap-4 items-center">
      <mat-spinner [diameter]="72"></mat-spinner>
      <mat-icon>download</mat-icon>
      <h4 i18n>downloading image ....</h4>
    </div>


    }

  </section>
  }
  @else {
  <div class="flex flex-col items-center my-auto p-6 gap-2 border border-dashed container">
    <h2 i18n class="first-letter:uppercase text-gray-800">select an image</h2>
    <h4 class="text-gray-600 mt-3">JPEG, AVIF, GIF, JPEG, PNG, SVG, WEBP, TIFF, HEIC/HEIF</h4>
    <button (click)="fileInput.click()" class="mt-2" color="primary"
      mat-raised-button><mat-icon>upload_file</mat-icon><span i18n>browse your image</span></button>

  </div>
  }
</main>