export function isJSON(str: string) {
  try {
    const obj = JSON.parse(str);
    if (obj && typeof obj === 'object' && obj !== null) {
      return true;
    }
    return false 
  } catch (err) {
    return false;
  }
  
}

export type BackendError<T> = Record<
  keyof T | 'non_field_error' | 'detail',
  string
>;
export type AppsyncError = {
  errorInfo: string;
  errorType: string;
  message: string;
};
export type AppsyncNetworkError = {
  error: { errors: Array<{ errorType: string; message: string }> };
};
