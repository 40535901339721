import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BaseEntityFormComponent } from '@doctorus-front-end-monorepo/feature-entity';
import {
  Account,
  Maybe,
  PatientPropertyModel,
} from '@doctorus-front-end-monorepo/graphql';
import { InlineFormArrayComponent } from '@doctorus-front-end-monorepo/inline-form-array';
import {
  ContainerComponent
} from '@doctorus-front-end-monorepo/ui-layout';
import { PatientPropertiesModelInlineFromComponent } from '../patient-properties-model-inline-from/patient-properties-model-inline-from.component';

@Component({
  selector: 'lib-patient-propery-model-form',
  imports: [
    CommonModule,
    PatientPropertiesModelInlineFromComponent,
    InlineFormArrayComponent,
    ReactiveFormsModule,
    ContainerComponent,
    MatIconModule,
    MatButtonModule
],
  templateUrl: './patient-propery-model-form.component.html',
  styleUrl: './patient-propery-model-form.component.scss',
})
export class PatientProperyModelFormComponent extends BaseEntityFormComponent<Account> {
  get propertiesContainerTitle(): string {
    return $localize`patient property models(${(this.form.get('patientPropertyModels') as FormArray<FormControl>).length ?? 0})`;
  }

  override initFormFun = () =>
    this.fb.group({
      patientPropertyModels: this.fb.array([]),
    });

  addProperty(payload?: Maybe<PatientPropertyModel>): void {
    (this.form.get('patientPropertyModels') as FormArray<FormGroup>).push(
      this.createFormFun(payload),
    );
  }

  createFormFun = (payload?: Maybe<PatientPropertyModel>) =>
    this.fb.group({
      key: [payload?.key, Validators.required],
      is_medical: [payload?.is_medical ?? false, Validators.required],
      is_required: [payload?.is_required ?? false, Validators.required],
    });
}
