<full-calendar
  class="bg-white"
  #calendar
  [deepChangeDetection]="true"
  [events]="appointments"
  [options]="calendarOptions"
>
  <ng-template #eventContent let-arg>
    <medical-space-web-calendar-event
      [arg]="arg"
    ></medical-space-web-calendar-event>
  </ng-template>
</full-calendar>
