import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  booleanAttribute,
  computed,
  input,
} from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Measure } from '../../../../../shared/data-access/graphql/src';

@Component({
  selector: 'medical-measure-measure-graph',
  standalone: true,
  imports: [CommonModule, BaseChartDirective],
  templateUrl: './measure-graph.component.html',
  styleUrl: './measure-graph.component.scss',
})
export class MeasureGraphComponent {
  measure = input.required<Measure>();
  @Input({ transform: booleanAttribute }) withScales = true;
  @Input() height?: string;

  lineChartData = computed<ChartConfiguration['data']>(() => {
    return {
      datasets: [
        {
          data: this.measure().observations.map(x => x.value),
          backgroundColor: 'rgba(148,159,177,0.2)',
          borderColor: 'rgba(148,159,177,1)',
          pointBackgroundColor: 'rgba(148,159,177,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)',
          fill: 'origin',
        },
      ],
      labels: this.measure().observations.map(x => x.date),
    };
  });
  public lineChartOptions = computed<ChartConfiguration['options']>(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0.5,
        },
      },
      scales: {
        y: {
          display: this.withScales,
        },
        x: {
          display: this.withScales,
        },
      },

      plugins: {
        legend: { display: false },
      },
    };
  });
}
