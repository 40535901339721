import { Dialog } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
  input,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { Patient, UploadDocument } from '@doctorus-front-end-monorepo/graphql';
import { isAnImage } from '@doctorus-front-end-monorepo/util-document';
import { BaseEntityDetailsComponent } from '../../../../../shared/feature-entity/src';
import {
  ContainerComponent,
  KeyValueComponent,
} from '../../../../../shared/ui-layout/src';
import { CoalescePipe } from '../../../../../shared/util-formatting/src';
import { ImagesExplorerComponent } from '../images-explorer/images-explorer.component';

@Component({
    selector: 'uploaded-document-upload-document-box',
    templateUrl: './upload-document-box.component.html',
    styleUrls: ['./upload-document-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        ContainerComponent,
        KeyValueComponent,
        CoalescePipe,
        RouterModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatMenuModule,
    ]
})
export class UploadDocumentBoxComponent extends BaseEntityDetailsComponent<UploadDocument> {
  @Input() mode: 'page' | 'timeline' = 'page';
  patient = input<Pick<Patient, 'upload_documents'>>();

  get isImage(): boolean {
    return isAnImage(this.obj().multi_sizes_urls.O);
  }

  dialog = inject(Dialog);
  overlay = inject(Overlay);

  preview(): void {
    console.warn(this.patient());
    this.dialog.open<Blob | undefined>(ImagesExplorerComponent, {
      width: 'calc(100vw - 148px)',
      height: '100vh',
      hasBackdrop: true,
      panelClass: ['rounded-md'],
      positionStrategy: this.overlay.position().global().top('0').right('0'),
      data: {
        images: this.patient()?.upload_documents,
        selected: this.obj(),
      },
    });
  }
}
