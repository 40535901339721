import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { PrintLayout } from '../../../../../shared/data-access/graphql/src';
import { BaseEntityFormComponent } from '../../../../../shared/feature-entity/src';
import { UiMatFormFieldErrorDisplayerDirective } from '../../../../../shared/form/ui-form/src';
import { ContainerComponent } from '../../../../../shared/ui-layout/src';
import { PAPER_FORMATS } from '../../../../../shared/util-document/src';

@Component({
    selector: 'lib-print-layout-form',
    imports: [
        CommonModule,
        ContainerComponent,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
        UiMatFormFieldErrorDisplayerDirective,
    ],
    templateUrl: './print-layout-form.component.html',
    styleUrl: './print-layout-form.component.scss'
})
export class PrintLayoutFormComponent extends BaseEntityFormComponent<PrintLayout> {
  paperFormats = PAPER_FORMATS;

  override initFormFun = () =>
    this.fb.group({
      id: [this.obj()?.id],
      disabled: [this.obj()?.disabled ?? false, Validators.required],
      name: [this.obj()?.name, Validators.required],
      topMargin: [
        this.obj()?.topMargin ?? 1,
        [Validators.min(0), Validators.required],
      ],
      bottomMargin: [
        this.obj()?.bottomMargin ?? 1,
        [Validators.min(0), Validators.required],
      ],
      leftMargin: [
        this.obj()?.leftMargin ?? 1,
        [Validators.min(0), Validators.required],
      ],
      rightMargin: [
        this.obj()?.rightMargin ?? 1,
        [Validators.min(0), Validators.required],
      ],
      paperFormat: [this.obj()?.paperFormat ?? 'A5', Validators.required],
    });
}
