<main class="flex flex-col items-center container max-w-screen-md">
  <mat-icon class="mt-12" inline="true" svgIcon="medical-office"></mat-icon>
  <h1 i18n class="my-8 font--primary">CONGRATULATION</h1>
  <h2 class="text-gray-700 first-letter:uppercase justify-center text-center" i18n>
    your medical accountwas successfully created , you can now invite more users to join your team
  </h2>
  <button class="my-6" [routerLink]="['..','settings']" mat-raised-button color="primary">
    <span class="mx-8" i18n>ENTER YOUR MEDICAL ACCOUNT</span>
  </button>
</main>
