
<mat-sidenav-container class="h-full">
    <mat-sidenav class="max-w-xs border-r border-r-gray-200 p-2" mode="side" opened>
        <mat-nav-list >
            <mat-list-item >
                <a matListItemTitle class="text-lg first-letter:uppercase" i18n [routerLink]="['.']">care plans</a>
             </mat-list-item>

        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="p-6">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>