import { Routes } from '@angular/router';
import {
  DetailsWrapperComponent,
  ENTITY_CONFIG,
  ENTITY_DETAILS_COMPONENT,
  ENTITY_WRITE_COMPONENT,
  EntityConfig,
  EntityMutationService,
  LAYOUT_CONFIG,
  WriteWrapperComponent,
} from '@doctorus-front-end-monorepo/feature-entity';
import { UpdatePreferencesGQL } from '../../../../shared/data-access/graphql/src';
import { uiDocumentModelsRoutes } from '../../../ui-document-models/src';
import { uiLocationsRoutes } from '../../../ui-locations/src';
import { uiMeasureModelsRoutes } from '../../../ui-measure-models/src';
import { uiMedicalHistoryModelRoutes } from '../../../ui-medical-history-model/src';
import { uiPatientPropertiesRoutes } from '../../../ui-patient-properties/src';
import { uiPrintLayoutRoutes } from '../../../ui-print-layout/src';
import { uiTaskTypesRoute } from '../../../ui-task-types/src';
import { FormPreferencesFormComponent } from './components/forms-preferences/form-preferences-form/form-preferences-form.component';
import { FormsPreferencesComponent } from './components/forms-preferences/forms-preferences.component';
import { GeneralAccountSettingsComponent } from './components/general-account-settings/general-account-settings.component';
import { OfficeMembershipsComponent } from './components/office-memberships/office-memberships.component';
import { PatientSettingsComponent } from './components/patient-settings/patient-settings.component';

export const settingsRoutes: Routes = [
  {
    path: '',
    data: {
      breadcrumbChunck: { name: null },
    },
    providers: [
      {
        provide: LAYOUT_CONFIG,
        useValue: { canDelete: false },
      },
      {
        provide: EntityMutationService,
        useClass: UpdatePreferencesGQL,
      },
    ],
    children: [
      {
        path: '',
        redirectTo: 'general-settings',
        pathMatch: 'full',
      },
      {
        path: 'general-settings',
        component: GeneralAccountSettingsComponent,
        data: {
          breadcrumbChunck: { name: $localize`general settings` },
        },
      },
      {
        path: 'memberships',
        component: OfficeMembershipsComponent,
        data: {
          breadcrumbChunck: { name: $localize`memberships` },
        },
      },
      uiLocationsRoutes,
      {
        path: 'forms-preferences',
        providers: [
          {
            provide: LAYOUT_CONFIG,
            useValue: { withBorder: false },
          },
          {
            provide: ENTITY_CONFIG,
            useValue: new EntityConfig({
              feature: $localize`forms preferences`,
            }),
          },
          {
            provide: ENTITY_DETAILS_COMPONENT,
            useValue: FormsPreferencesComponent,
          },
          {
            provide: ENTITY_WRITE_COMPONENT,
            useValue: FormPreferencesFormComponent,
          },
        ],
        data: {
          breadcrumbChunck: { name: null },
        },
        children: [
          {
            path: '',
            component: DetailsWrapperComponent,
            data: {
              breadcrumbChunck: { name: $localize`forms preferences` },
            },
          },
          {
            path: 'edit',
            component: WriteWrapperComponent,
            data: {
              breadcrumbChunck: { name: $localize`edit` },
            },
          },
        ],
      },
      // {
      //   path: 'patient-properties',
      //   component: PatientPropertiesModelComponent,
      //   providers: [
      //     {
      //       provide: ENTITY_CONFIG,
      //       useValue: new EntityConfig({
      //         feature: $localize`patient properties`,
      //       }),
      //     },
      //   ],
      //   data: {
      //     breadcrumbChunck: { name: $localize`patient properties` },
      //   },
      // },
      {
        path: 'patient-settings',
        component: PatientSettingsComponent,
        data: {
          breadcrumbChunck: { name: $localize`patient` },
        },
      },
      {
        path: '',
        providers: [
          {
            provide: LAYOUT_CONFIG,
            useValue: { canDelete: true },
          },
          {
            provide: EntityMutationService,
            useClass: UpdatePreferencesGQL,
          },
        ],
        children: [
          uiTaskTypesRoute,
          uiPrintLayoutRoutes,
          uiDocumentModelsRoutes,
          uiMedicalHistoryModelRoutes,
          uiMeasureModelsRoutes,
        ],
      },
      uiPatientPropertiesRoutes,
    ],
  },
];
