import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
@Pipe({
  name: 'coalesce',
  standalone: true,
})
export class CoalescePipe implements PipeTransform {
  transform(
    value: number | string | Array<any> | undefined | null,
    alt: string
  ) {
    if (Array.isArray(value) || typeof value === 'string') {
      return value.length === 0 ? alt : value;
    }

    return _.isNil(value) ? alt : value;
  }
}
