import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from '@angular/router';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
} from 'rxjs';
import {
  BreadcrumbComponent,
  resolveBreadcrumbs,
} from '../../../../../../../libs/shared/breadcrumb/src';

@Component({
  selector: 'doctorus-front-end-monorepo-medical-record-center-board',
  standalone: true,
  imports: [CommonModule, RouterModule, BreadcrumbComponent],
  templateUrl: './medical-record-center-board.component.html',
  styleUrl: './medical-record-center-board.component.scss',
})
export class MedicalRecordCenterBoardComponent {
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  breadcrumbs$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(''),
    distinctUntilChanged(),
    debounceTime(300),
    map(() => resolveBreadcrumbs(this.route, 'center')),
  );
}
