import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { map, switchMap } from 'rxjs';
import { GetPresignedPutUrlGQL } from '../../../data-access/graphql/src';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  getPresignedPutUrlGql = inject(GetPresignedPutUrlGQL);
  private httpClient: HttpClient;

  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }
  uploadFile(file: File | Blob, key: string, content_type: string) {
    return this.getPresignedPutUrlGql
      .fetch({
        payload: {
          key,
          content_type,
        },
      })
      .pipe(
        map(x => JSON.parse(x.data.getPresignedPutUrl)),
        switchMap(presignParam => {
          const formData = new FormData();
          const headers = new HttpHeaders({ Accept: '*/*' });
          Object.keys(presignParam.fields).forEach((key: string) => {
            formData.append(key, presignParam.fields[key]);
          });
          formData.append('file', file);
          return this.httpClient
            .post<any>(presignParam.url, formData, { headers })
            .pipe(map(x => presignParam.fields['key']));
        }),
      );
  }
}
