import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IsActiveMatchOptions, RouterModule } from '@angular/router';
import { Breadcrumb } from '../types';

@Component({
  selector: 'ui-layout-breadcrumb',
  standalone: true,
  imports: [CommonModule, RouterModule, MatIconModule],
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss',
})
export class BreadcrumbComponent {
  @Input({ required: true }) breadcrumbs!: Breadcrumb[];
  routerLinkActiveOptions: IsActiveMatchOptions = {
    matrixParams: 'ignored',
    fragment: 'ignored',
    paths: 'exact',
    queryParams: 'ignored',
  };
}
