import { Pipe, PipeTransform } from '@angular/core';
import { timezoneToOffset } from '../utils';

@Pipe({
  name: 'timezoneOffset',
  standalone: true,
})
export class TimezoneOffsetPipe implements PipeTransform {
  transform(timeZoneIdentifer: string): string {
    return timezoneToOffset(timeZoneIdentifer);
  }
}
