import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  inject,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AccountRole, AuthService } from '@doctorus-front-end-monorepo/auth';
import {
  BaseEntityDetailsComponent,
  BaseEntityFormComponent,
  EntityConfig,
  EntityDialogService,
  PutEntityService,
} from '@doctorus-front-end-monorepo/feature-entity';
import {
  Appointment,
  SetAppointmentFeesGQL,
  SetAppointmentMedicalNotesGQL,
  SetAppointmentStatusGQL,
  SetAppointmentTypeGQL,
} from '@doctorus-front-end-monorepo/graphql';

import {
  AppointmentStatus,
  AvatarLoaderDirective,
  HumanNamePipe,
  StatusComponent,
} from '@doctorus-front-end-monorepo/shared-util';
import { SlideOutPanelService } from '@doctorus-front-end-monorepo/slide-out-panel';
import {
  ContainerComponent,
  KeyValueComponent,
} from '@doctorus-front-end-monorepo/ui-layout';
import { CoalescePipe } from '@doctorus-front-end-monorepo/util-formatting';
import { orderBy } from 'lodash';
import { QuillViewComponent } from 'ngx-quill';
import { map } from 'rxjs';
import { AppointmentTypeDialogComponent } from '../appointment-type-dialog/appointment-type-dialog.component';
import { DatesComponent } from '../dates/dates.component';
import { FeesDialogComponent } from '../fees-dialog/fees-dialog.component';
import { WriteMedicaNoteFromComponent } from '../forms/write-medica-note-from/write-medica-note-from.component';
import { StatusPickerComponent } from '../status-picker/status-picker.component';
@Component({
  selector: 'appointment-medical-record-appointment-details',
  imports: [
    CommonModule,
    StatusPickerComponent,
    MatButtonModule,
    CoalescePipe,
    StatusComponent,
    HumanNamePipe,
    MatIconModule,
    DatesComponent,
    AvatarLoaderDirective,
    ContainerComponent,
    MatFormFieldModule,
    MatDividerModule,
    MatSelectModule,
    ReactiveFormsModule,
    KeyValueComponent,
    MatChipsModule,
    MatInputModule,
    QuillViewComponent,
    CoalescePipe,
  ],
  templateUrl: './medical-record-appointment-details.component.html',
  styleUrls: ['./medical-record-appointment-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedicalRecordAppointmentDetailsComponent extends BaseEntityDetailsComponent<Appointment> {
  //@Input({ required: true }) patient!: CorePatientFieldsFragment;
  // appointment = model.required<
  //   AppointmentMinimumFieldsFragment &
  //     Partial<{
  //       note: string;
  //       description: string;
  //     }>
  // >();
  //@Output() deselect = new EventEmitter();

  isDoctor$ = inject(AuthService).user$.pipe(
    map(_user => _user?.['cognito:groups']?.includes(AccountRole.DOCTOR)),
  );

  private injector = inject(Injector);
  private eds = inject(SlideOutPanelService);
  private dialogService = inject(EntityDialogService);
  private setStatusGql = inject(SetAppointmentStatusGQL);
  private medicalNoteInjector = Injector.create({
    parent: this.injector,
    providers: [
      {
        provide: PutEntityService,
        useClass: SetAppointmentMedicalNotesGQL,
      },
    ],
  });
  private appointmentFeesInjector = Injector.create({
    parent: this.injector,
    providers: [
      {
        provide: PutEntityService,
        useClass: SetAppointmentFeesGQL,
      },
    ],
  });
  private appointmentTypeInjector = Injector.create({
    parent: this.injector,
    providers: [
      {
        provide: PutEntityService,
        useClass: SetAppointmentTypeGQL,
      },
    ],
  });
  editMedicalNote(): void {
    this.eds
      .openDialogWriteForm<Appointment, any, Appointment>(
        {
          cmp: WriteMedicaNoteFromComponent,
          entity: this.obj(),
          entityConfig: new EntityConfig({
            feature: $localize`medical note`,
          }),
        },
        this.medicalNoteInjector,
      )
      .subscribe(res =>
        this.obj.update(x => ({
          ...x,
          note: res?.note,
          description: res?.description,
        })),
      );
  }

  editAppointmentType(): void {
    this.eds
      .openDialogWriteForm<
        Appointment,
        BaseEntityFormComponent<Appointment>,
        Appointment
      >(
        {
          cmp: AppointmentTypeDialogComponent,
          entity: this.obj(),
          entityConfig: new EntityConfig({
            feature: $localize`appointment task type`,
          }),
        },
        this.appointmentTypeInjector,
      )
      .subscribe(res =>
        this.obj.update(obj => ({
          ...obj,
          task_type: res?.task_type,
        })),
      );
  }

  setStatus(status: AppointmentStatus | undefined): void {
    if (status) {
      this.setStatusGql
        .mutate({
          payload: {
            id: this.obj().id,
            status,
          },
        })
        .subscribe(res =>
          this.obj.update(x => ({
            ...x,
            status: res?.data?.setAppointmentStatus.status,
          })),
        );
    }

    //this.eds.openEntityWriteForm({});
  }
  setFees(): void {
    const suggestions = [60, 100, 200, 55];
    if (this.obj().task_type?.default_fees) {
      suggestions.unshift(this.obj().task_type?.default_fees);
    }

    this.eds
      .openDialogWriteForm<
        Appointment,
        BaseEntityFormComponent<Appointment>,
        Appointment
      >(
        {
          cmp: FeesDialogComponent,
          entity: this.obj(),
          entityConfig: new EntityConfig({
            feature: $localize`appointment fees`,
          }),
          extra: {
            suggestions: orderBy(suggestions, {}, 'asc'),
          },
        },
        this.appointmentFeesInjector,
      )
      .subscribe(res =>
        this.obj.update(obj => ({
          ...obj,
          fees: res?.fees,
        })),
      );
  }
}
