@if(patient().medicalHistoriesSurvey.length > 0) {
<div class="max-h-full relative">
  <div>
    <ul class="flex flex-col gap-4">
      @for (item of patient().medicalHistoriesSurvey; track $index; let last = $last) {

      <li class="flex flex-col">
        <div class="flex gap-2 items-center">
          <button (click)="writeMedicalHistory({name:item.name})" mat-icon-button>
            <mat-icon>add_notes</mat-icon>
          </button>

          <p class="text-base  text-gray-600 font-medium">{{item.name}}
          </p>
        </div>

        @if (item.checked) {
        <div class="flex flex-col gap-2">

          @for (entry of item.entries; track $index) {
          @if(entry) {
          <div class="px-1 bg-white rounded-sm">
            <div class="text-sm flex justify-between items-center">
              <p>{{entry.date | date}}</p>
              <button [mat-menu-trigger-for]="appMenu" [matMenuTriggerData]="{entry}"
                mat-icon-button><mat-icon>more_vert</mat-icon></button>
            </div>
            <quill-view [class.line-through]="entry.is_current === false" [content]="entry.content" format="html"
              theme="snow"></quill-view>
          </div>
          }

          }
        </div>
        }
        @else {

        <ui-layout-feedback message="check this medical history" type="1">
          <button (click)="writeMedicalHistory({name:item.name})" ngProjectAs="action"
            mat-button><mat-icon>check</mat-icon><span i18n>check</span></button>


        </ui-layout-feedback>



        }
      </li>





      <!-- @if (!last) {
      <mat-divider></mat-divider>

      } -->
      }

    </ul>
  </div>
</div>
}
@else {
<ui-layout-empty-state>
  <span i18n class="first-letter:uppercase">no medical histories</span>
  <button ngProjectAs="actions" (click)="writeMedicalHistory()" mat-stroked-button><span i18n>new
      medical history
    </span></button>
</ui-layout-empty-state>
}

<mat-menu #appMenu="matMenu">
  <ng-template matMenuContent let-entry="entry">
    <button mat-menu-item (click)="writeMedicalHistory({medicalHistory:entry})" mode="write">
      <mat-icon>edit</mat-icon>
      <span i18n>edit</span>
    </button>
    <button mat-menu-item (click)="delete(entry)">
      <mat-icon>delete</mat-icon>
      <span i18n>delete</span>
    </button>
    <button mat-menu-item (click)="updateMedicalHistoryStatus(entry)">
      <mat-icon>disable</mat-icon>
      <span i18n>disable</span>
    </button>
  </ng-template>
</mat-menu>


<!-- <ng-container [ngSwitch]="mode"> 
<ng-container *ngSwitchCase="'compact'">
  <div *ngIf="hasCurrentAntecedents() else noHistory" class="max-h-full relative">


    <div>
      <ul class="flex flex-col gap-4">
        @for (item of medicalHistoriesMap(); track $index; let last = $last) {

        <li>
          <div class="flex gap-2 items-center">
            @if (item.type == 'model') {
            <mat-icon>info</mat-icon>

            }
            <p class="text-base  text-gray-600 font-medium">{{item.name}}
            </p>
          </div>

          @switch (item.type) {
          @case ('medicalNote') {
          <div class="flex flex-col gap-2">

            @for (entry of item.entries; track $index) {
            <div class="p-2 bg-white rounded-sm">
              <div class="text-sm flex justify-between items-center">
                <p>{{entry.date | date}}</p>
                <div class="flex gap-0.5">
                  <span (click)="delete(entry)" class="hover:cursor-pointer text-blue-600 font-medium">delete</span>
                  <span>|</span>
                  <span (click)="writeMedicalHistory({medicalHistory:entry})"
                    class="hover:cursor-pointer text-blue-600 font-medium">edit</span>
                  <span>|</span>

                  <span (click)="updateMedicalHistoryStatus(entry)"
                    class="hover:cursor-pointer text-blue-600 font-medium">{{statusStr(entry)}}</span>
                </div>
              </div>
              <quill-view [class.line-through]="entry.is_current === false" [content]="entry.content" format="html"
                theme="snow"></quill-view>
            </div>
            }
          </div>
          }
          @default {
          <span class="first-letter:uppercase text-sm text-wrap" i18n>this medical history type needs to be
            checked</span>
          <div class="flex gap-0.5 justify-end pr-2 text-sm">
            <span class="hover:cursor-pointer text-blue-600 font-medium">check</span>
            <span>|</span>
            <span class="hover:cursor-pointer text-blue-600 font-medium">NTR</span>
          </div>



          }
          }
        </li>





        @if (!last) {
        <mat-divider></mat-divider>

        }
        }

      </ul>
    </div>
  </div>
  <ng-template #noHistory>
    <div class="flex  flex-col items-center gap-2 px-2 h-full justify-center text-gray-700">

      <div *ngIf="hasNonCurrentAntecedents() else anyMedical" class=" flex flex-col items-center gap-2">
        <p class="mt-2 text-center text-sm first-letter:uppercase" i18n>this patient has no current
          medical history</p>
        <button mat-stroked-button><span i18n>more
            details</span></button>
      </div>
      <ng-template #anyMedical>
        <div class="my-2 flex flex-col items-center gap-2">
          <p class="text-center text-sm first-letter:uppercase" i18n>this patient has no reported
            medical
            histories</p>
          <button (click)="writeMedicalHistory()" mat-mini-fab><mat-icon>add</mat-icon></button>
        </div>
      </ng-template>


    </div>

  </ng-template>
</ng-container>
<ng-container *ngSwitchCase="'full'">
  <ui-layout-group-header>
    <span i18n title>medical history</span>
    <button (click)="writeMedicalHistory()" mat-button><span>add medical history</span></button>
  </ui-layout-group-header>
  <div class="mt-8 flex flex-col gap-8">

    @for (item of medicalHistoriesMap(); track $index;let isFirst = $first) {
    <section>
      <h2 class="mb-2 items-center flex gap-1">
        <button (click)="writeMedicalHistory({name:item.name})" mat-icon-button>
          <mat-icon>add_circle</mat-icon>
        </button>
        {{item.name}}
      </h2>
      <main class="grid grid-cols-2 gap-4">
        @switch (item.type) {
        @case ('medicalNote') {
        @for (medicalNote of item.entries; track $index) {
        <mat-card class="w-full">
          <mat-card-header>
            <mat-card-subtitle>{{medicalNote.date | date}}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>

            <quill-view [class.line-through]="medicalNote.is_current === false" class="flex-1"
              [content]="medicalNote.content" format="html"></quill-view>
          </mat-card-content>
          <mat-card-actions>
            <button (click)="writeMedicalHistory({medicalHistory:medicalNote})" mat-icon-button>
              <mat-icon>edit</mat-icon>
            </button>
            <button (click)="updateMedicalHistoryStatus(medicalNote)" mat-icon-button>
              <mat-icon>{{statusIcon(medicalNote)}}</mat-icon>
            </button>
            <button (click)="delete(medicalNote)" mat-icon-button>
              <mat-icon>delete</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
        }

        }
        @default {
        <mat-card class="w-full">
          <mat-card-header>
          </mat-card-header>
          <mat-card-content>
            <p class="flex items-center gap-4 px-4 py-2 bg-slate-200 rounded-md">
              <mat-icon>info</mat-icon>
              <span class="first-letter:uppercase" i18n>this medical history type needs to be
                checked</span>
            </p>
          </mat-card-content>
          <mat-card-actions>
            <button disabled mat-icon-button>
              <mat-icon>done</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
        }
        }







      </main>
    </section>

    }

    <mat-menu #menuToCheck="matMenu">
      <ng-template matMenuContent let-type="type">

      </ng-template>
    </mat-menu>
    <mat-menu #menu="matMenu">
      <ng-template matMenuContent let-medicalHistory="medicalHistory">
        <button (click)="writeMedicalHistory({medicalHistory})" mat-icon-button>
          <mat-icon>edit</mat-icon>
          <span i18n>edit</span>
        </button>
        <button (click)="writeMedicalHistory({medicalHistory,mode:'status'})" mat-icon-button>
          <mat-icon>{{statusIcon(medicalHistory)}}</mat-icon>
          <span>{{statusStr(medicalHistory)}}</span>
        </button>
        <button (click)="delete(medicalHistory)" mat-icon-button>
          <mat-icon>delete</mat-icon>
          <span i18n>delete</span>
        </button>
      </ng-template>
    </mat-menu>
    <ng-template #noHistory>
      <div class="flex  flex-col items-center gap-2 px-2 h-full justify-center text-gray-700">
        <p class="first-letter:uppercase font-medium" i18n>no medical history</p>
        <div class="my-2 flex flex-col items-center gap-2">
          <p class="text-center text-sm first-letter:uppercase" i18n>this patient has no reported medical
            histories</p>
          <button (click)="writeMedicalHistory()" mat-stroked-button><span i18n>add
              one</span></button>
        </div>
      </div>
    </ng-template>
    -->