<form class="flex flex-col gap-4 max-w-screen-md" [formGroup]="form">
  <mat-form-field appearance="outline" uiFormMatFormFieldErrorDisplayer>
    <mat-label i18n>date</mat-label>
    <input placeholder="DD/MM/YYYY" #input matInput type="date" formControlName="date" />
    <mat-icon matIconSuffix class="hover:cursor-pointer" (click)="input.showPicker()">calendar_month</mat-icon>
    <mat-error></mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label i18n>document model</mat-label>
    <mat-select [formControl]="documentModelControl">
      <mat-option [value]="documentModel.name" *ngFor="let documentModel of documentModels$ | async">
        {{ documentModel.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="col-span-2" uiFormMatFormFieldErrorDisplayer appearance="outline">
    <mat-label i18n>title</mat-label>
    <input matInput type="text" required formControlName="title" />
    <mat-error></mat-error>
  </mat-form-field>
  <div class="col-span-2">
    <label for="content" class="capitalize text-gray-800" i18n>document content</label>
    <div class="h-60 mt-2">
      <quill-editor id="content" i18n-placeholder class="h-40 text-black" placeholder="insert document content"
        theme="snow" formControlName="content">
      </quill-editor>

    </div>
  </div>
</form>
