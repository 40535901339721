import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  RouterStateSnapshot,
} from '@angular/router';
import { map } from 'rxjs';
import { BreadcrumbRouteData } from '../../../../shared/breadcrumb/src/lib/types';
import {
  DeleteCarePlanGQL,
  GetCarePlanGQL,
  GetMedicalTaskGQL,
  PutCarePlanGQL,
  resolveMedicalRecordCollection,
  resolveMedicalRecordItem,
} from '../../../../shared/data-access/graphql/src';
import {
  DetailsWrapperComponent,
  ENTITY_CONFIG,
  ENTITY_DETAILS_COMPONENT,
  ENTITY_LIST_COMPONENT,
  ENTITY_WRITE_COMPONENT,
  EntityMutationService,
  ListWrapperComponent,
  WriteWrapperComponent,
} from '../../../../shared/feature-entity/src';
import { EntityDeleteMutationService } from '../../../../shared/ui-entity-dialog/src';
import { CarePlanDetailsComponent } from './care-plan-details/care-plan-details.component';
import { CarePlanTableComponent } from './care-plan-table/care-plan-table.component';
import { CarePlanWriteComponent } from './care-plan-write/care-plan-write.component';
import { CarePlansListComponent } from './care-plans-list/care-plans-list.component';
import { carePlanEntityConfig } from './entity-config';
import { FeatureCarePlanComponent } from './feature-care-plan/feature-care-plan.component';

const carePlanResolver = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(GetCarePlanGQL)
    .fetch({
      id: route.paramMap.get('id')!,
    })
    .pipe(map(_res => _res.data.getCarePlan!));
};

const medicalTaskResolver = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(GetMedicalTaskGQL)
    .fetch({
      id: route.paramMap.get('id')!,
    })
    .pipe(map(_res => _res.data.getMedicalTask!));
};

const medicalTaskRoutes: Route = {
  path: ':id',
  runGuardsAndResolvers: 'always',
  resolve: {
    obj: medicalTaskResolver,
  },
  data: {
    breadcrumbChunck: {
      name: $localize`medical task:{{obj.id}}`,
    } as BreadcrumbRouteData,
  },
  children: [
    {
      path: '',
      component: DetailsWrapperComponent,
      data: {
        deleteRedirect: ['..'],

        breadcrumbChunck: {
          name: null,
        } as BreadcrumbRouteData,
      },
    },
    {
      path: 'edit',
      component: WriteWrapperComponent,
      data: {
        breadcrumbChunck: {
          name: $localize`edit`,
        } as BreadcrumbRouteData,
      },
    },
  ],
};

export const featureCarePlanMedicalRecordRoutes: Route = {
  path: 'care-plans',
  runGuardsAndResolvers: 'always',
  resolve: {
    objs: resolveMedicalRecordCollection('care_plans'),
  },
  providers: [
    {
      provide: ENTITY_CONFIG,
      useValue: carePlanEntityConfig,
    },
    {
      provide: EntityDeleteMutationService,
      useClass: DeleteCarePlanGQL,
    },
    {
      provide: EntityMutationService,
      useClass: PutCarePlanGQL,
    },
    {
      provide: ENTITY_DETAILS_COMPONENT,
      useValue: CarePlanDetailsComponent,
    },
    {
      provide: ENTITY_WRITE_COMPONENT,
      useValue: CarePlanWriteComponent,
    },
    {
      provide: ENTITY_LIST_COMPONENT,
      useValue: CarePlansListComponent,
    },
  ],
  data: {
    breadcrumbChunck: {
      name: $localize`care plans`,
    },
  },
  children: [
    {
      path: '',
      component: ListWrapperComponent,
      data: {
        breadcrumbChunck: {
          name: null,
        },
      },
    },
    {
      path: 'new',
      component: WriteWrapperComponent,
      data: {
        cancelRedirect: ['..'],
        successRedirect: ['..'],
        obj: null,
        breadcrumbChunck: {
          name: $localize`new`,
        } as BreadcrumbRouteData,
      },
    },
    {
      path: ':id',
      resolve: {
        obj: resolveMedicalRecordItem('care_plans'),
      },
      data: {
        breadcrumbChunck: {
          name: $localize`care plan:{{obj.name}}`,
        } as BreadcrumbRouteData,
      },
      children: [
        {
          path: '',
          component: DetailsWrapperComponent,
          data: {
            deleteRedirect: ['..'],

            breadcrumbChunck: {
              name: null,
            } as BreadcrumbRouteData,
          },
        },
        {
          path: 'edit',
          component: WriteWrapperComponent,
          data: {
            breadcrumbChunck: {
              name: $localize`edit`,
            } as BreadcrumbRouteData,
          },
        },
      ],
    },
  ],
};

export const featureCarePlanMainRoutes: Route = {
  path: 'care-plans',
  component: FeatureCarePlanComponent,
  data: {
    breadcrumbChunck: {
      name: null,
    },
  },
  children: [
    {
      path: '',
      runGuardsAndResolvers: 'always',
      providers: [
        {
          provide: ENTITY_CONFIG,
          useValue: carePlanEntityConfig,
        },
        {
          provide: EntityDeleteMutationService,
          useClass: DeleteCarePlanGQL,
        },
        {
          provide: EntityMutationService,
          useClass: PutCarePlanGQL,
        },
        {
          provide: ENTITY_DETAILS_COMPONENT,
          useValue: CarePlanDetailsComponent,
        },
        {
          provide: ENTITY_WRITE_COMPONENT,
          useValue: CarePlanWriteComponent,
        },
      ],
      data: {
        breadcrumbChunck: {
          name: $localize`care plans`,
        },
      },
      children: [
        {
          path: '',
          component: CarePlanTableComponent,
          data: {
            breadcrumbChunck: {
              name: null,
            },
          },
        },
        {
          path: 'new',
          component: WriteWrapperComponent,
          data: {
            cancelRedirect: ['..'],
            successRedirect: ['..'],
            obj: null,
            breadcrumbChunck: {
              name: $localize`new`,
            } as BreadcrumbRouteData,
          },
        },
        {
          path: ':id',
          runGuardsAndResolvers: 'always',
          resolve: {
            obj: carePlanResolver,
          },
          data: {
            breadcrumbChunck: {
              name: $localize`care plan:{{obj.name}}`,
            } as BreadcrumbRouteData,
          },
          children: [
            {
              path: '',
              component: DetailsWrapperComponent,
              data: {
                deleteRedirect: ['..'],

                breadcrumbChunck: {
                  name: null,
                } as BreadcrumbRouteData,
              },
            },
            {
              path: 'edit',
              component: WriteWrapperComponent,
              data: {
                breadcrumbChunck: {
                  name: $localize`edit`,
                } as BreadcrumbRouteData,
              },
            },
          ],
        },
      ],
    },
  ],
};
