@if(!fetchingSlots()) {
<mat-chip-listbox [compareWith]="compareFun" (change)="updateSelection($event)" class="text-sm"
  [formControl]="slotSelectorCtr">
  <mat-chip-option [value]="slot" *ngFor="let  slot of availableSlots">
    {{ slot | date:'HH:mm':offset}}
  </mat-chip-option>
</mat-chip-listbox>
}@else {
<ui-layout-loading>
  <p i18n>fetching available slots ...</p>

</ui-layout-loading>
}